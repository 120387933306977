import React, { Component } from 'react';
import moment from 'moment'
import uuid from 'react-uuid'
import _ from 'lodash';
import PreventTransitionPrompt from '../../layout/Main/PreventTransitionPrompt.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import {RoleAccessService} from '../../data/role-access'; 
import { ReactComponent as InfoIcon } from '../../assets/img/icons/information.svg';
import { 
  Box,
  Grid,
  Divider,
  InputLabel,
  Select,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import {Verdana} from '../../components/Fonts/Verdana'
import { Anmollipi } from '../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../components/Fonts/InterBold.jsx';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import DropdownMenu from '../../components/Dropdown/DropdownMenuFilter.jsx';
import CloseIcon from '@material-ui/icons/Close';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import Barcode from 'react-barcode';
import EVFYLogo from '../../assets/img/evfy-logo.png';
import location from '../../assets/img/Vector.png';
import building from '../../assets/img/building.png';
import box from '../../assets/img/box.png';
import comment from '../../assets/img/comment.png';
import person from '../../assets/img/person.png';
import time from '../../assets/img/access_time.png';
import phone from '../../assets/img/local_phone.png';
import bottomTxt from '../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as TimeIcon } from '../../assets/img/icons/time.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import RTextEditor from '../../components/RichTextEditor/RTextEditor.jsx';
import 'suneditor/dist/css/suneditor.min.css';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
import CustomTooltip from '../../components/Tooltip/CustomTooltip.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomGoogleMapSearch from '../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import DepartmentPICList from '../Jobs/Partial/DepartmentPICList.jsx';
import ItemsInJobList from '../Jobs/Partial/ItemsInJobList.jsx';
import StepsList from '../Jobs/Partial/StepsList.jsx';

import CustomerForm from '../Jobs/Partial/CustomerForm.jsx';
import DriverForm from '../Jobs/Partial/DriverForm.jsx';
import LogisticsAssistanceForm from '../Jobs/Partial/LogisticsAssistanceForm.jsx';

import Tour from 'reactour'
import axios from 'axios';
import { TimerSharp } from '@material-ui/icons';
import { remove } from 'lodash';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFile } from '@fortawesome/free-solid-svg-icons';

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

export class OrderDetailForm extends Component {
  static contextType = AuthContext;


  constructor(props){
    super(props);

    let id = (this.props.orderId && this.props.orderId > 0) ? this.props.orderId : 0;
    let image = (this.props.image) ? this.props.image : '';
    let is_noti = (this.props.is_noti) ? this.props.is_noti : '';
    let isAllocationStep2 = (props.isAllocationStep2 && props.isAllocationStep2 == true) ? true : false
    this.isCustomerRequired = false;
    let grantedAccess = new RoleAccessService();
    this.state = {
      id: id,
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      isLoading: false,
      tableStatus: [],
      drop_off_date: '',
      is_noti:is_noti,
      user: JSON.parse(localStorage.getItem('user')),
      drop_off_dateValidated: false,
      drop_off_time_planned: '',
      // drop_off_time_plannedValidated: false,
      drop_off_description: null,
      //drop_off_descriptionValidated: false,
      drop_off_address: '',
      address: [],
      cloneAddress: [],
      drop_off_addressValidated: false,
      //New feature of postal code
      // drop_off_postal_code: '',
      prev_worker_id: null,
      prev_vendor_id: null,
      zoneItems: "undefined",
      zone_id:"",
      item_tracking_number:'',
      boxes:"",
      document: "",
      pallets: "",
      cartons: "",
      drop_off_postal_code: null,
      drop_off_postal_codeValidated: false,

      company_logo: '',
      // amountValidated: false,
      latitude : 0,
      longitude : 0,
      drop_off_name: '',
      drop_off_nameValidated: false,

      drop_off_contact_name: '',
      drop_off_contact_nameValidated: false,

      drop_off_contact_no: '',
      drop_off_contact_noValidated: false,

      notification_email: '',
      notification_emailValidated: false,

      assign_type: true,
      job_type: '',
      picItems: [],
      itemsInJobList: [],
      job_steps: [],

      openDialogCustomer: false,
      itemDialogCustomer: null,
      
      openDialogDriver: false,
      itemDialogDriver: null,

      openDialogLogisticsAssistance: false,
      itemDialogLogisticsAssistance: null,

      customerItems: [],
      customerItemsIsLoading: false,
      customerItem: null,
      customerId: '',
      customerName: '',
      customerNameValidated: false,
      departmentId: '',
      vendorItems: [],
      vendorItemsIsLoading: false,
      vendorItem: null,
      vendorId: '',
      vendorName: '',
      vendorNameValidated: false,

      workerItems: [],
      workerItemsIsLoading: false,
      workerItem: null,
      workerId: '',
      workerName: '',

      manPowerItems: [],
      manPowerItemsIsLoading: false,
      manPowerItem: null,
      manPowerId: '',
      manPowerName: '',

      branchItems: [],
      order_branch_id: '',
      
      departmentItems: [],
      departmentItem: null,
      department_id: '',

      customerDetailsIsLoading: true,
      customerDetails: null,

      orderStatusesIsLoading: false,
      orderStatuses: [],
      order_status: null,
      order_status_id: 0,

      jobStepStatusesIsLoading: false,
      jobStepStatuses: [],

      isFormChanged: false,

      openDialog: false,
      openDialogItem: null,
      isLoadingWarehouse: false,
      isTourOpen: false,
      openSkipDialog: false,
      amount: '',
      driver_payout: '',
      vendor_payout: '',
      prev_amount: '',
      prev_driver_payout: '',
      prev_vendor_payout: '',
      do_number: '',
      delivery_typeValidated: false,
      package_list: [],
      selected_package: [],
      package_types: [],
      customer_rate: [],
      period_of_deliveryValidated: false,
      period_of_delivery: '',
      signatureImg: [],
      proofImg: [],
      data:'',
      order_histories: [],
      reasons_for_failure: '',
      fail_reason: "",
      order_attempts: "",
      size_l_w_h: '',
      weight: '',
      waiting_time: '',
      no_of_movers: '',
      depalletization_request: '',
      base_charge: '',
      surcharge: '',
      isAllocationStep2: isAllocationStep2,
      collection_reference: '',
      created_by: null,
      collection_from_time: "9:00",
      collection_to_time: "18:00",
      collection_timeValidated: false,
      company: "",

      drop_off_alternate_contact : '',
      drop_off_alt_contact_noValidated: false,
      oldCustomerItems: [],
      oldDriverItems: [],
      time_window: '',
      temp_fail_reason: '',
      hideButton: this.props.hideButton ? this.props.hideButton : false,
      is_deli_use_saved_address: false,
      zip_from_address: null
    }

  }

  componentDidMount() {
    const { isCustomerRequired, isOnBoarding } = this.context;
    this.isCustomerRequired = isCustomerRequired;
    
    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }

    this.callAddressApi();
    this.callOrderStatusesApi();

    this.callWorkerDriverReadApi('', (arr) => {
      this.setState({
        workerItems: arr.sort((a, b) => {
         const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
         const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
         if (nameA < nameB) {
           return -1;
         }
         if (nameA > nameB) {
           return 1;
         }
       
         // names must be equal
         return 0;
       }),
        oldDriverItems: arr
      });
      // this.callVendorReadApi('', (arr) => {
      //   //  console.log('vendorarr', arr)
      //    workerItems.push(arr);
      //    var result = [].concat.apply([],Object.values(workerItems));
      //    this.setState({
      //      workerItems: result.sort((a, b) => {
      //       const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
      //       const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
      //       if (nameA < nameB) {
      //         return -1;
      //       }
      //       if (nameA > nameB) {
      //         return 1;
      //       }
      //     
      //       // names must be equal
      //       return 0;
      //     }),
      //      oldDriverItems: result
      //    });
      //  });                
    });
    
    this.callCustomerReadApi(this.state.customerName, (arr) => {
      this.setState({
        customerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        }),
        oldCustomerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        })
      });
    });
    // this.callVendorReadApi();
    this.callReasonForFailure();
    this.callJobStepStatusesApi();
    //this.callZoneList();
    this.loadStatusApi();
    //EDIT FORM CONTENT
    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data, itemsInTheJob) => {
        /* VENDOR */
        this.callGeoCoderAddressApi(data.drop_off_address, (data) => {
          if(data){
            this.setState({
              latitude: data.lat,
              longitude: data.lng,
              zip_from_address: parseInt(data.zip)
            })
          } else {
            this.setState({
              latitude: '',
              longitude: '',
              zip_from_address: null,
            })
          }
        });

        let vendorItems = [];
        let vendorItem = null;
        if(data && data.vendor){
          vendorItem = {
            value: (data.vendor_id) ? data.vendor_id : '',
            label: (data.vendor && data.vendor.company_name ) ? data.vendor.company_name : '',

            item: (data.vendor) ? data.vendor : null,
          };
          vendorItems.push(vendorItem);
        }

        //ORDER PACKAGE
        if(data && data.order_packages) {
          let order_package = data.order_packages
          let selected_package = []
          let s_package = ''
          let m_package = ''
          let xs_package = ''
          let l_package = ''
          let oversized_package = ''
          let pallets=''
          order_package.forEach(op => {
            let o_package = {}
            o_package.id = op.id
            o_package.package_id = op.package_id
            o_package.package_quantity = op.package_quantity

            
            if(op.package_id == 1) {
              xs_package = op.package_quantity
              o_package.name = "XS"
            }
            if(op.package_id == 2) {
              s_package = op.package_quantity
              o_package.name = "S"
            }
            if(op.package_id == 3) {
              m_package = op.package_quantity
              o_package.name = "M"
            }
            if(op.package_id == 4) {
              l_package = op.package_quantity
              o_package.name = "L"
            }
            if(op.package_id == 4) {
              l_package = op.package_quantity
              o_package.name = "L"
            }
            if(op.package_id == 5) {
              pallets = op.package_quantity
              o_package.name = "Pallets"
            }
            if(op.package_id == 6) {
              oversized_package = op.package_quantity
              o_package.name = "Oversized Package"
            }

            selected_package.push(o_package)
          })
          this.setState({
            selected_package,
            xs_package,
            m_package,
            s_package,
            l_package,
            oversized_package,
            pallets
          })
        }

        //CHECK BOXES, DOCUMENT AND OTHERS
        // if(data && data.extra_data){
        //   let extraData = JSON.parse(data.extra_data);
        //   this.setState({
        //     boxes: (extraData && extraData.boxes) ? extraData.boxes : '',
        //     document: (extraData && extraData.document) ? extraData.document : '',
        //     pallets: (extraData && extraData.pallets) ? extraData.pallets : '',
        //     cartons: (extraData && extraData.cartons) ? extraData.cartons : ''
        //   })

        // }
        //CHECK Package Info
        if(data && data.package_info){
          let package_info = JSON.parse(data.package_info);
          this.setState({
            depalletization_request: (package_info && package_info.depalletization_request) ? package_info.depalletization_request : '',
            size_l_w_h: (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '',
            weight: (package_info && package_info.weight) ? package_info.weight : '',
            waiting_time: (package_info && package_info.waiting_time) ? package_info.waiting_time : '',
            no_of_movers: (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '',
          })

        }

        this.setState({
          data: data,
          job_type: (data && data.job_type) ? data.job_type : '',
          assign_type: (data && data.assign_type && data.assign_type === 'vendor') ? false : true,
          vendorNameValidated: false,
          vendorName: (vendorItem && vendorItem.label) ? vendorItem.label : '',
          vendorId: (vendorItem && vendorItem.value) ? vendorItem.value : '',
          vendorItem: (vendorItem && vendorItem.item) ? vendorItem.item : null,
          vendorItems: vendorItems,
        });
        /* END VENDOR */

        // POD
		    let job_step = data.job_steps && data.job_steps.length > 0 ? data.job_steps[data.job_steps.length - 1] : [];
        let signature_img = job_step && job_step.order_attempts && job_step.order_attempts.length > 0 && job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images && job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images.length > 0 ? job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
        let proof_img =  job_step && job_step.order_attempts && job_step.order_attempts.length > 0 && job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images && job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images.length > 0 ? job_step.order_attempts[job_step.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : [];

        this.setState({
          fail_reason: data.job_steps.length > 0 && data.job_steps[data.job_steps.length - 1].order_attempts.length > 0 ? data.job_steps[data.job_steps.length - 1].order_attempts[data.job_steps[data.job_steps.length - 1].order_attempts.length - 1].reason : '',
          order_attempts: data.job_steps.length > 0 && data.job_steps[data.job_steps.length - 1].order_attempts.length > 0 ? data.job_steps[data.job_steps.length - 1].order_attempts[data.job_steps[data.job_steps.length - 1].order_attempts.length - 1] : '',
          signatureImg : signature_img,
          proofImg : proof_img,
        })

         /* HISTORY */
         this.setState({
          order_histories: (data && data.order_histories && data.order_histories.length > 0) ? data.order_histories : [],
        });
      /* END HISTORY */

        /* STATUS */
        this.setState({
          order_status: (data && data.order_status) ? data.order_status : null,
          order_status_id: (data && data.order_status_id) ? data.order_status_id : '',
        });
        /* END STATUS */


        
        /* JOB DETAILS SECTION */
        let time_window = (data && data.drop_off_time_planned) ? data.drop_off_time_planned.split("-") : [];
        this.setState({
          is_project: (data && data.is_project) ? 'Project' : 'Standard',
          drop_off_date: (data && data.drop_off_date) ? moment(data.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '',
          collection_from_time: time_window && time_window.length > 0 && typeof time_window[0] === 'string' ? time_window[0].trim() : '0:00',
          collection_to_time: time_window && time_window.length > 0 && typeof time_window[1] === 'string' ? time_window[1].trim() : '23:00',
          drop_off_description: (data && data.drop_off_description) ? data.drop_off_description : '',
          driver_notes: (data && data.driver_notes) ? data.driver_notes : '',
          company: (data && data.drop_off_name) ? data.drop_off_name : '',
          drop_off_alternate_contact: (data && data.drop_off_alternate_contact) ? data.drop_off_alternate_contact : "",
          amount: (data && data.amount) ? parseFloat(data.amount).toFixed(2) : '',
          driver_payout: (data && data.driver_payout && data.driver_payout !== 0.0) ? parseFloat(data.driver_payout).toFixed(2) : '',
          vendor_payout: (data && data.vendor_payout && data.vendor_payout !== 0.0) ? parseFloat(data.vendor_payout).toFixed(2) : '',
          prev_amount: (data && data.amount) ? parseFloat(data.amount).toFixed(2) : '',
          prev_driver_payout: (data && data.driver_payout && data.driver_payout !== 0.0) ? parseFloat(data.driver_payout).toFixed(2) : '',
          prev_vendor_payout: (data && data.vendor_payout && data.vendor_payout !== 0.0) ? parseFloat(data.vendor_payout).toFixed(2) : '',
          do_number: (data && data.do_number) ? data.do_number : '',
          latitude: (data && data.drop_off_latitude) ? data.drop_off_latitude : 0,
          longitude: (data && data.drop_off_longitude) ? data.drop_off_longitude : 0,
          zone_id: (data && data.zone_id) ? data.zone_id : 0,
          period_of_delivery: (data && data.period_of_delivery) ? data.period_of_delivery : '',
          collection_reference: (data && data.collection_reference) ? data.collection_reference : '',
          created_by: (data && data.created_by) ? data.created_by : '',
        },() => {
          let timeslot = this.getTimeWindow(this.state.collection_from_time,this.state.collection_to_time);
          this.setState({
            time_window: timeslot
          });              
        });
        /* END JOB DETAILS SECTION */


        if(this.isCustomerRequired){
          /* CUSTOMERS DETAILS SECTION */
          let customerItems = [];
          let customerItem = null;
          if(data && data.customer){
            customerItem = {
              value: (data.customer) ? data.customer.id : '',
              label: (data.customer && data.customer.company_name) ? data.customer.company_name : '',
              item: (data.customer) ? data.customer : null,
            };
            
            customerItems.push(customerItem);
            this.setState({
              customerNameValidated: false,
              customerName: (customerItem && customerItem.label) ? customerItem.label : '',
              customerId: (customerItem && customerItem.value) ? customerItem.value : '',
              customerItem: (customerItem && customerItem.item) ? customerItem.item : null,
              departmentId: data.department_id, 
              item_tracking_number: (data.order_number) ? data.order_number : '',
              is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
              is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
            }, () => {
              
              let branchItems = [];
              if(data.customer && data.customer.customer_addresses && data.customer.customer_addresses.length > 0) {
                branchItems = data.customer.customer_addresses.map((item, i) => {
                  return {
                    value: item.id,
                    label: item.name,
                    item: item,
                  }
                });                
              }
              
              let departmentItems = [];
              if(data.customer.departments.length > 0){
                departmentItems = data.customer.departments.map((item, i) => {
                  return {
                    value: item.id,
                    label: item.name,
                    item: item,
                  }
                });
              }
              
              this.setState({
                customer_rate: data.customer && data.customer.customer_rates && data.customer.customer_rates.length > 0 ? data.customer.customer_rates : [],
                branchItems: branchItems,
                departmentItems: departmentItems,
              }, () => {
                let drop_off_address = '';
                let drop_off_postal_code = '';
                let order_branch_id = '';
                let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
                if(drop_off_addressItem && drop_off_addressItem.length > 0){
                  drop_off_address = drop_off_addressItem[0].item.address_line1;
                  drop_off_postal_code = drop_off_addressItem[0].item.postal_code;
                }

            
                let departmentItem = null;
                let department_id = (data && data.department_id) ? data.department_id : '';
                let departmentArray = (departmentItems && departmentItems.length > 0) ? departmentItems.filter(x => x.value === department_id) : [];
                if(departmentArray && departmentArray.length > 0){
                  departmentItem = departmentArray[0].item;
                }
                
                this.setState({
                  department_id: department_id,
                  departmentItem: departmentItem,
                  order_branch_id: order_branch_id,
                  drop_off_address:  data.drop_off_address? data.drop_off_address: '',
                  drop_off_postal_code: data.drop_off_postal_code? data.drop_off_postal_code: ''
                });
              });
            });
          } else {
            this.setState({
              item_tracking_number: (data.order_number) ? data.order_number : '',
              drop_off_address:  data.drop_off_address? data.drop_off_address: '',
              drop_off_postal_code: data.drop_off_postal_code? data.drop_off_postal_code: ''           
            });  
          }



          let picItems = [];
          let drop_off_name = [];
          if(data.drop_off_name && data.drop_off_name !== ''){
            drop_off_name = data.drop_off_name.split(',');
          }

          let drop_off_contact_no = [];
          if(data.drop_off_contact_no && data.drop_off_contact_no !== ''){
            drop_off_contact_no = data.drop_off_contact_no.split(',');
          }

          let notification_email = [];
          if(data.notification_email && data.notification_email !== ''){
            notification_email = data.notification_email.split(',');
          }

          if(drop_off_name && drop_off_name.length > 0){
            for(let i = 0; i < drop_off_name.length; i++){
              let name = '';
              let contact_no = '';
              let email = '';
              
              if(drop_off_name[i] && drop_off_name[i] !== ''){
                name = drop_off_name[i];
              }
              if(drop_off_contact_no[i] && drop_off_contact_no[i] !== ''){
                contact_no = drop_off_contact_no[i];
              }
              if(notification_email[i] && notification_email[i] !== ''){
                email = notification_email[i];
              }
              
              picItems.push({
                uuid: uuid(),
                drop_off_name: name,
                drop_off_contact_no: contact_no,
                notification_email: email,
                remove: false,
              });
              this.setState({
                notification_email: email? email: data.notification_email? data.notification_email:'',
                drop_off_name: name? name : data.drop_off_name? data.drop_off_name: '',
                drop_off_contact_name: data.drop_off_contact_name? data.drop_off_contact_name:'',
                drop_off_contact_no: contact_no? contact_no : data.drop_off_contact_no? data.drop_off_contact_no: '',
              });
            }
          } else {
            this.setState({
              notification_email: data.notification_email? data.notification_email:'',
              drop_off_name: data.drop_off_name? data.drop_off_name: '',
              drop_off_contact_name: data.drop_off_contact_name? data.drop_off_contact_name:'',
              drop_off_contact_no: data.drop_off_contact_no? data.drop_off_contact_no: '',
            });
          }

          this.setState({
            picItems: picItems,
          });
          /* END CUSTOMERS DETAILS SECTION */
        } else {
          /* END RECIPIENT DETAILS SECTION */
          this.setState({
            item_tracking_number: (data.order_number) ? data.order_number : '',
            is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
            is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
            drop_off_address: (data.drop_off_address) ? data.drop_off_address : '',
            drop_off_postal_code: (data.drop_off_postal_code) ? data.drop_off_postal_code : '',
            drop_off_name: (data.drop_off_name) ? data.drop_off_name : '',
            drop_off_contact_name: (data.drop_off_contact_name) ? data.drop_off_contact_name : '',
            drop_off_contact_no: (data.drop_off_contact_no) ? data.drop_off_contact_no : '',
            notification_email: (data.notification_email) ? data.notification_email : '',
          });
          /* END RECIPIENT DETAILS SECTION */
        }
        


        /* ITEMS IN JOB LIST SECTION */
        let itemsInJobList = [];
        if(itemsInTheJob && itemsInTheJob.length > 0){
          itemsInJobList = itemsInTheJob.map((item, i) => {
            return {
              uuid: uuid(),
              id: item.id,
              description: item.description,
              quantity: item.quantity,
              uom: item.uom,
              weight: item.weight,
              remarks: item.remarks,
              remove: false,
            }
          });
        }
        this.setState({
          itemsInJobList: itemsInJobList,
        });
        /* END ITEMS IN JOB LIST SECTION */
        

        /* STEPS SECTION */
        let job_steps = [];
        if(data && data.job_steps && data.job_steps.length > 0){
          job_steps = data.job_steps.map((item, i) => {
            let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'value', item.job_step_status_id);
            let job_step_status = (orderStatusItem) ? orderStatusItem : null;
            
            return {
              uuid: uuid(),
              id: item.id,
              job_step_id: item.id,
              job_step_name: item.job_step_name,
              job_step_status_id: (item.job_step_status_id && item.job_step_status_id !== '') ? item.job_step_status_id : 1,
              job_step_status: job_step_status,
              location: item.location,
              is_signature_required: item.is_signature_required,
              is_scan_required: item.is_scan_required,
              job_step_pic: item.job_step_pic,
              job_step_pic_contact: item.job_step_pic_contact,
              description: item.description,
              order_sequence: (i + 1),
              remove: false,
            }
          });
        }
        this.setState({
          job_steps: job_steps,
        });
        /* END STEPS SECTION */


        /* ASSIGN WORKERS SECTION */
        let manPowerItem = (data && data.extra_workers && data.extra_workers.length > 0) ? data.extra_workers[0] : null;
        this.setState({
          workerItem: data.drop_off_worker? data.drop_off_worker : '',
          workerName:  data.drop_off_worker && data.drop_off_worker.first_name? data.drop_off_worker.first_name +" "+ data.drop_off_worker.last_name : '',
          workerId: data.drop_off_worker_id,
          prev_worker_id: data.drop_off_worker_id,
          // vendorItem: data.vendor? data.vendor : '',
          prev_vendor_id: data.vendor_id,
          // vendorName: data.vendor? (data.vendor.first_name? data.vendor.first_name : '') + ' ' + (data.vendor.last_name? data.vendor.last_name : '') : '',
          manPowerItem: manPowerItem,
          manPowerName: (manPowerItem) ? ((manPowerItem.first_name && manPowerItem.first_name !== '') ? manPowerItem.first_name : '') + ' ' + ((manPowerItem.last_name && manPowerItem.last_name !== '') ? manPowerItem.last_name : '') : '',
          manPowerId: (manPowerItem) ? manPowerItem.id : 0,
          isLoading: false,
        });
        /* END ASSIGN WORKERS SECTION */
      });
    }

    
  }
  
  callAddressApi = () => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoadingWarehouse: true,
      }, () => {
        let param = {
          sort: 'latest'
        };
  
        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                address: (data.result && data.result.length > 0) ? data.result : [],
                cloneAddress: _.cloneDeep(data.result),
                total: data.total,
                isLoadingWarehouse: false,
              });
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoadingWarehouse: false,
              });
            });
        });
      });
}


  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
       data.map((item, i) => {
        if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 7,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 8,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } 
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });
  
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  /* GEO FUNCTIONS API */
  callGeoCoderAddressApi = (address = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      geocoderAddressIsLoading: true,
    }, () => {
      let params = {
        address: address,
      };

      ldsApi.create('geo_functions/geocoder_address', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              if(callback){
                callback(data.result);
              }
            } else {
              if(callback){
                callback(null);
              }
            }
          }, (error, type) => {
            if(callback){
              callback(null);
            }
          });
      });
    });
  }
  
  /* ORDER STATUSES API */
  callOrderStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  
  callJobStepStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      jobStepStatusesIsLoading: true,
      jobStepStatuses: [],
    }, () => {
      ldsApi.get('api/job_step_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.result && data.result.length > 0){
              arr = data.result.map((item, i) => {
                let value = item.id;
                let label = item.name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              jobStepStatusesIsLoading: false,
              jobStepStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END ORDER STATUSES API */

  callZoneList = () => {
    const { accessToken } = this.context;
    axios.get(
      // `${process.env.REACT_APP_AUTH_API_URL}/admin/api/zones`,{
        `${process.env.REACT_APP_AUTH_API_URL}/admin/api/worker_polygons`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
       })
    .then((response) => {
      if (response.data.status === true) {
        this.setState({
          zoneItems: response.data.result
        });
      }else{
        this.setState({
          zoneItems:[]
        });
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  }
  
  getTimeWindow = ( s_time = '', e_time = '' ) => {
    let start_time = moment(s_time,'hh:mm');
    let end_time = moment(e_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    let timeslot = '';
    if(diff_time > 0) {
      if(diff_time < 3){
        timeslot = 'express (2h slot)'; 
      } else if(diff_time >= 3 && diff_time <= 5) {
        timeslot = 'next day (3h slot)'; 
      }
    }      
    return timeslot;
  }
  
  generatePDF = (row) => {
    const { accessToken } = this.context;
    let selectedRow = row?[row]:this.state.data;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + 'multiple_generate_label.pdf?ids=' + ids,'_blank');    
    }
  } 

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      workerItemsIsLoading: true,
      workerItems: [],
    }, () => {
      let param = {
        disabled: false,
        is_active: true,
        search: search ? search.trim() : search,
        is_truck_driver: true,
        // require_worker_only: true,
        light_data: true
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              if(callback){
                callback(arr);
              }
              this.setState({
                workerItemsIsLoading: false,
              })
            } else {
              if(callback){
                callback([]);
              }
              this.setState({
                workerItemsIsLoading: false,
              })
            }
          }, (error, type) => {
            if(callback){
              callback([]);
            }
            this.setState({
              workerItemsIsLoading: false,
            })
          });
      });
    });
  }

  callWorkerManPowerReadApi = (search = '', callback = null) => {
    /*const { accessToken } = this.context;

    this.setState({
      manPowerItemsIsLoading: true,
      manPowerItems: [],
    }, () => {
      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
        search: search ? search.trim() : search,
        is_man_power: true,
        require_worker_only: true,
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              manPowerItemsIsLoading: false,
              manPowerItems: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });*/
  }
  /* END WORKER API */

  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      vendorItemsIsLoading: true,
      vendorItems: [],
    }, () => {
      let param = {
        // page: 1,
        // take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              // this.setState({
              //   vendorItemsIsLoading: false,
              //   vendorItems: arr,
              // }, () => {
                if(callback){
                  callback(arr);
                }
              // });
            } else {
              // this.setState({
              //   vendorItemsIsLoading: false,
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItemsIsLoading: false,
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
    });
  }
  /* END VENDOR API */


  /* CUSTOMER API */
  callCustomerReadApi = (search = '', callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState({
      customerItemsIsLoading: true,
      customerItems: [],
    }, () => {

      if(!isOnBoarding){
        let param = {
          page: 1,
          // take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          light_data_customer_rate: true
        };
  
        wmsApi.read('customers', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              let customer_rate = []
              if(result && result.length > 0){
                for(let i = 0; i < result.length; i++){
                  let item = result[i];
                  
                  let value = item.id;
                  let label = item.company_name ? item.company_name : '';

                  if (item.company_name == this.state.customerName) {
                    customer_rate = item.customer_rates ? item.customer_rates : []
                  }

  
                  if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                      value: value,
                      label: label,
                      item: item
                    });
                  }
                }
                
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: arr,
                  // customer_rate: customer_rate.length > 0 ? customer_rate : this.state.customer_rate
                }, () => {
                  if(callback){
                    callback(arr);
                  }
                });

              } else {
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
      } else {
        let jsonCustomer = null;
        try {
            jsonCustomer = JSON.parse(onBoardingCustomer);
        } catch(e){}

        let customer = null;
        if(jsonCustomer){
          customer = {
            value: jsonCustomer.id,
            label: jsonCustomer.company_name,
            item: jsonCustomer
          }
        }

        let arr = [];
        if(customer){
          arr.push(customer);

          this.setState({
            customerItemsIsLoading: false,
            customerItems: arr.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            }),
          }, () => {
            if(callback){
              callback(arr);
            }
          });
        } else {
          this.setState({
            customerItemsIsLoading: false,
            customerItems: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        }
      }
    });
  }

  callCustomerDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      customerDetailsIsLoading: true,
      customerDetails: null,
    }, () => {
      wmsApi.details('customers', id, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data && data.customer) ? data.customer : null;

            if(result){
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: result,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: null,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              customerDetailsIsLoading: false,
              customerDetails: null,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END CUSTOMER */

  callReasonForFailure = (callback = null) => {
    const { accessToken } = this.context;
      ldsApi.worker_get('reject_reasons', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = data? data.result : null;

            if(result){
              this.setState({
                reasons_for_failure: result,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: null,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              customerDetailsIsLoading: false,
              customerDetails: null,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
  }
  /* END CUSTOMER */

  /* API */
  callPackageTypes = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('package_types' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let package_types = data.result && data.result.length > 0 ? data.result: []
            let data_list = []
            package_types.forEach(element => {
              let dl = {}
              dl.package_id = element.id
              dl.name = element.package_name
              data_list.push(dl)
            });
            this.setState({
              package_types,
              package_list: data_list,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            });
          });
      });
    });
  }


  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
              company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
              is_deli_use_saved_address: data.is_deli_use_saved_address
            }, () => {
            if(callback){
              callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
            }
          });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
             apiUtil.toast(error,"check_circle")
             this.props.onClose();
            });
          });
      });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            apiUtil.toast( 'Successfully Updated', 'check_circle', 'success');
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle"
            )
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        this.props.history.push('/job-list');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={isOnBoarding ? 'auto' : true}>
            <CustomLabel
                className={'no-margin'}
                label={(this.state.id > 0) ? 'Edit Job' : 'Add New Job'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
       
            <CustomButton 
              className={'create-job-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit job</Box>
            </CustomButton>
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* FORM */
  jobDetailsForm = () => {
    let description = this.state.drop_off_description != '' ? this.state.drop_off_description : '';
    let user = JSON.parse(window.localStorage.getItem("user"));
    if(user == null) {
      window.location.reload();   
    }
    let isOwner = user.owner_info !== 'undefined' && user.owner_info.is_customer_admin !== undefined ? user.owner_info.is_customer_admin : false;
    let isNewRole = this.state.grantedAccess.admin_roles_with_customer.includes(this.state.currentRole) ? false : true;
    return <Container fluid>
      {this.state.order_histories.filter(dd => dd.current_status.status == "Completed" || dd.current_status.status == "Failed" ).length > 0?
      <Row>
        <Grid item xs={12}>
          <CustomLabel
              label={'Proof of Delivery:'}
              weight={'bold'}
              size={'md'}
              className="d-flex justify-content-start"
          />
        </Grid>
        <div className="d-flex justify-content-center align-items-center">
        {this.state.signatureImg.length > 0  && this.state.signatureImg.map(img => {
          return <div className="position-relative m-2 image-preview-detail" style={{width : "330px"}} onClick={()=> window.open(img.image_url, '_blank')}>
                    <img src={img.image_url} name="hello" style={{margin: "auto", height: "130px", width:"100%", objectFit: "cover"}} />
                    <div className="image-label">
                        <span className="text-white image-label-text">{img.description}</span>
                        <span className="text-white image-label-text">{moment.parseZone(img.updated_at).format('DD-MM-YYYY h:mm A')}</span>
                    </div>
                    <span className='image-container'>
                      <img src={img.image_url} name="hello" className="hovered-image" style={{margin: "auto"}}/>
                    </span>
                </div>
        })}
        {this.state.proofImg.length > 0  && this.state.proofImg.map(img => {
          return <div className="position-relative m-2 image-preview-detail" style={{width : "330px"}} onClick={()=> window.open(img.image_url, '_blank')}>
                    <img src={img.image_url} name="hello2" style={{margin: "auto", height: "130px", width:"100%", objectFit: "cover"}} />
                    <div className="image-label">
                        <span className="w-100 text-white image-label-text">{img.description}</span>
                        <span className="w-100 text-white image-label-text">{moment.parseZone(img.updated_at).format('DD-MM-YYYY h:mm A')}</span>
                    </div>
                    <span className='image-container'>
                      <img src={img.image_url} name="hello2" className="hovered-image" style={{margin: "auto"}}/>
                    </span>
                </div>
        })} 
        </div>
        <div className="w-50 d-flex justify-content-start p-3 align-items-center max-xs-width-flex-577">
          <div className="w-50 d-flex align-item-center">Reason for Failure</div>
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "8px", backgroundColor: 'transparent'}}
                  placeholder={'select reasons here...'}
                  // ref={this.refTableUploaderSearch}
                  value={ this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed" ? this.state.fail_reason : ''}
                  disabled={this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed" ? false : true}
                  native
                  onChange={(e) => {
                    this.setState({
                      temp_fail_reason: e.target.value,
                      fail_reason: e.target.value,
                      isFormChanged: true
                    },()=> {
                      if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                        let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                          this.setState({
                            base_charge, 
                            surcharge
                          })
                      }
                    })
                  }}
                >
                  <option value=""> Select reason </option>
                  {this.state.reasons_for_failure.length > 0 && this.state.reasons_for_failure.map(reason => {
                  return( <option value={reason.reject_reason}>{reason.reject_reason}</option>)
                  })
                  }
                </Select>
        </div>
        <div className="d-flex justify-content-center align-items-center px-2 max-xs-width-control-900">
            <div 
             onClick={() => {
              window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + this.state.id + '&application_company_id=' + this.state.user.owner_info.application_company_id+''+(window.location.pathname == "/order-schedule" || window.location.pathname == "/order-summary-list" ? '&is_customer=true' : ''),'_blank');
            }}
            className="upload-style text-white btn my-2"><FontAwesomeIcon icon={faFile} />  Download POD</div>
            <div 
            onClick={() => {
              this.generatePDF(this.state.data);
            }} className="upload-style text-white btn my-2"><FontAwesomeIcon icon={faTag} /> Generate Label</div>
        </div>
      </Row>
      :  <Row>
        <div className="d-flex justify-content-center align-items-center">
          <div 
          onClick={() => {
            this.generatePDF(this.state.data);
          }} className="upload-style text-white btn my-2"><FontAwesomeIcon icon={faTag} /> Generate Label</div>
        </div>
      </Row>}
    <Row className="p-4">
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Ordered By</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            readOnly={true}
            type={"text"}
            color={'secondary'}
            value={this.state.data.vendorId && this.state.data.vendorId == this.state.user.owner_info.application_company_id? this.state.data.uploader.application_company.company_name : this.state.data.uploader? `${this.state.data.uploader.first_name} ${this.state.data.uploader.last_name ? this.state.data.uploader.last_name: '' }`: ''}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Order Status</div>
        <div className="max-xs-width-control-900 ps-2 w-50 order-detail-status">
              <DropdownMenu 
                isShouldEnable={false}
                disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
                className={'status-btn ' + apiUtil.statusToClassName((this.state.data.order_status && this.state.data.order_status.status && this.state.data.order_status.status !== '') ?  this.state.data.order_status.status == "Not Assigned" ? "Pending" :  this.state.data.order_status.status == "Assign Pending" ? "On Hold"+'   ' : this.state.data.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role"? "In Progress"+'   ' : this.state.data.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : this.state.data.order_status.status : '')}
                text={(this.state.data && this.state.data.order_status && this.state.data.order_status.status) ? this.state.data.order_status.status == "Not Assigned" ? "Pending"+'   ': this.state.data.order_status.status == "Assign Pending" ? "On Hold"+'   ' : this.state.data.order_status.status == "Acknowledged" && this.state.currentRole == "Customer Template Role"? "In Progress"+'   ' : this.state.data.order_status.status == "Assigned To Vendor" && this.state.currentRole == "Customer Template Role" ? "Assigned"+'   ' : this.state.data.order_status.status+'   ' : ''}
                rightIcon={<ArrowDownIcon />}
                items={this.state.tableStatus}
                onClick={(item) => {
                  if(this.state.data.drop_off_worker_id && this.state.data.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'pending'){
                    let data = {
                      data: [
                        {
                          id: [ this.state.data.id ],
                          job_steps: (this.state.data.job_steps && this.state.data.job_steps.length > 0) ? this.state.data.job_steps.map(step => {
                            step.job_step_id = step.id;
                            if (item.text.toString().toLowerCase().trim() === "completed") {
                              step.job_step_status_id = 3;
                            } else {
                              step.job_step_status_id = 1;
                            }
                            return step
                          }): this.state.data.job_steps,
                          order_status_id: item.id,
                          send_notification_to_customer: true,
                        }
                      ]
                    };
                    if(item.text.toString().toLowerCase().trim() === 'pending'){
                      data.data[0]['drop_off_worker_id'] = null;
                      data.data[0]['extra_worker_ids'] = null;
                    }

                    this.callUpdateStatusApi(data, (newRow) => {
                      if(newRow){
                        let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                      }
                    });
                  } else {
                    apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                  }
                }}
              />    
        </div>
      </Col>
      {this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company') && <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Client Company</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomAutosuggest 
          placeholder={'Enter any keyword to search for customers'}
          disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed") || this.state.currentRole == "Customer Template Role"? true : false }
          labelComponent={<CustomTooltip title={'Select from your list of customers. Create one if u dont have them in your list.'} />}
          items={this.state.customerItems}
          isLoading={this.state.customerItemsIsLoading}
          value={this.state.customerName}
          onChange={(value) => {
            if(value){
              if(this.state.customerId > 0 ){
                this.setState({
                  //customerNameValidated: false,
                  customerName: value.label,
                  customerId: value.value,
                  customerItem: value.item,
                  departmentId: '',
                  customer_rate: value.item.customer_rates && value.item.customer_rates.length > 0 ? value.item.customer_rates : [],
                  isFormChanged: true,
                }, () => {
                  if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId)) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      openDialog: true,
                      openDialogItem: value.item,
                      amount: client,
                      driver_payout: payout,
                      vendor_payout: subcon,
                      base_charge,
                      surcharge
                    });
                  }
                });
              } else {
                  this.setState({
                    //customerNameValidated: false,
                    customerName: value.label,
                    customerId: value.value,
                    customerItem: value.item,
                    departmentId: '',
                    customer_rate: value.item.customer_rates && value.item.customer_rates.length > 0 ? value.item.customer_rates : [],
                    isFormChanged: true,
                  }, () => {
                    //this.addNewCustomerDetails(value.item);
                    if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId)) {
                      let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        amount: client,
                        driver_payout: payout,
                        vendor_payout: subcon,
                        base_charge, 
                        surcharge
                      })
                    }
                  });
              }
            } else {
                this.setState({
                  //customerNameValidated: true,
                  customerName: '',
                  customerId: '',
                  departmentId: '',
                  customerItem: null,
                  isFormChanged: true,
                  customer_rate: [],
                  branchItems: [],
                  departmentItems: [],
                }, () => {
                  if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId)) {
                   this.setState({
                     amount: '',
                     driver_payout: '',
                     vendor_payout: '',
                     base_charge: '', 
                     surcharge: ''
                   });
                 }
                });
              }
          }}
          onSearch={(value, e) => {
            this.setState({
               customerName: value,
               customerId: value != '' ? this.state.customerId : '',   
               customerItem: value != '' ? this.state.customerItem : null,
               departmentId: value != '' ? this.state.departmentId : '',
               isFormChanged: true,               
            },() => {
               e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));                
            });

            if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId)) {
              this.setState({
                amount: value != '' ? this.state.amount : '',
                driver_payout: value != '' ? this.state.driver_payout : '',
                vendor_payout: value != '' ? this.state.vendor_payout : ''
              });
            }
          }}
          onClick={(value, e) => {
            e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
          }}
          />
        </div>
      </Col> }
      
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Trip Date*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
          placeholder={'DD/MM/YYYY'}
          color={'secondary'}
          type={"date"}
          disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          startAdornment={(obj) => {
            return <Box
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CalendarIcon />
            </Box>
          }}
          value={this.state.drop_off_date}
          error={this.state.drop_off_dateValidated}
          required={true}
          onChange={(e) => {
              this.setState({
                drop_off_date: e.target.value,
                drop_off_dateValidated: (e.target.value !== '') ? false : true,
                isFormChanged: true,
              }, ()=>{
                if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    amount: client,
                    driver_payout: payout,
                    vendor_payout : subcon,
                    base_charge,
                    surcharge
                  })
                }
              });
          }}
      />
        
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Department</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="max-xs-width-control-900 custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Select Department here...'}
          value={this.state.customerId ? this.state.departmentId : ''}
          disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          native
          disableUnderline
          onChange={(e) => {
            this.setState({
                departmentId: e.target.value,
                isFormChanged: true,
            },()=> {
              if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    base_charge, 
                    surcharge
                  })
              }
            })
          }}
        >
          <option value=""> Select Department </option>
          {this.state.customerItem && this.state.customerItem.departments.map((dd, index)=> 
            <option value={dd.id}>{dd.name}</option>
          )}
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
            <div className="max-xs-width-control-900 text-end-style w-50">
              Time Window*
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50 d-flex">
              <Select
              disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
                className="select-width-control-100 custom-input-component padding-right-select"
                error={this.state.collection_timeValidated}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.collection_from_time}
                native
                disableUnderline
                onChange={(e) => {
                  this.setState(
                    {
                      collection_from_time: e.target.value,
                      isFormChanged: true,
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.collection_from_time,this.state.collection_to_time);
                      this.setState({
                        time_window: timeslot
                      }, () => {
                        if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            this.state.selected_package,
                            this.state.customer_rate,
                            this.state.is_project,
                            this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                            this.state.drop_off_date,
                            this.state.drop_off_postal_code,
                            this.state.job_type,
                            this.state.drop_off_address, 
                            this.state.address,
                            this.state.created_by,
                            this.state.is_deli_use_saved_address,
                            this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                          );
                          this.setState({
                            amount: client,
                            driver_payout: payout,
                            vendor_payout: subcon,
                            base_charge: base_charge,
                            surcharge: surcharge
                          });
                        }
                      });
                    }
                  );
                }}
              >
                 <option value="0:00"> 0:00 </option>
                  <option value="0:30"> 0:30 </option>
                  <option value="1:00"> 1:00 </option>
                  <option value="1:30"> 1:30 </option>
                  <option value="2:00"> 2:00 </option>
                  <option value="2:30"> 2:30 </option>
                  <option value="3:00"> 3:00 </option>
                  <option value="3:30"> 3:30 </option>
                  <option value="4:00"> 4:00 </option>
                  <option value="4:30"> 4:30 </option>
                  <option value="5:00"> 5:00 </option>
                  <option value="5:30"> 5:30 </option>
                  <option value="6:00"> 6:00 </option>
                  <option value="6:30"> 6:30 </option>
                  <option value="7:00"> 7:00 </option>
                  <option value="7:30"> 7:30 </option>
                  <option value="8:00"> 8:00 </option>
                  <option value="8:30"> 8:30 </option>
                  <option value="9:00"> 9:00 </option>
                  <option value="9:30"> 9:30</option>
                  <option value="10:00"> 10:00 </option>
                  <option value="10:30"> 10:30 </option>
                  <option value="11:00"> 11:00 </option>
                  <option value="11:30"> 11:30 </option>
                  <option value="12:00"> 12:00 </option>
                  <option value="12:30"> 12:30 </option>
                  <option value="13:00"> 13:00 </option>
                  <option value="13:30"> 13:30 </option>
                  <option value="14:00"> 14:00 </option>
                  <option value="14:30"> 14:30 </option>
                  <option value="15:00"> 15:00 </option>
                  <option value="15:30"> 15:30 </option>
                  <option value="16:00"> 16:00 </option>
                  <option value="16:30"> 16:30 </option>
                  <option value="17:00"> 17:00 </option>
                  <option value="17:30"> 17:30 </option>
                  <option value="18:00"> 18:00 </option>
                  <option value="18:30"> 18:30 </option>
                  <option value="19:00"> 19:00 </option>
                  <option value="19:30"> 19:30 </option>
                  <option value="20:00"> 20:00 </option>
                  <option value="20:30"> 20:30 </option>
                  <option value="21:00"> 21:00 </option>
                  <option value="21:30"> 21:30 </option>
                  <option value="22:00"> 22:00 </option>
                  <option value="22:30"> 22:30 </option>
                  <option value="23:00"> 23:00 </option>
                  <option value="23:30"> 23:30 </option>
              </Select>
              <div className="text-end-style m-2">To</div>
              <Select
                disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
                className="select-width-control-100 custom-input-component  padding-right-select"
                error={this.state.collection_timeValidated}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.collection_to_time}
                native
                disableUnderline
                onChange={(e) => {
                  this.setState(
                    {
                      collection_to_time: e.target.value,
                      isFormChanged: true,
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.collection_from_time,this.state.collection_to_time);
                      this.setState({
                        time_window: timeslot
                      }, () =>{
                      if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                        let {
                          client,
                          payout,
                          subcon,
                          base_charge,
                          surcharge,
                        } = this.state.grantedAccess.getPriceAndDriverPayout(
                          this.state.selected_package,
                          this.state.customer_rate,
                          this.state.is_project,
                          this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                          this.state.drop_off_date,
                          this.state.drop_off_postal_code,
                          this.state.job_type,
                          this.state.drop_off_address, 
                          this.state.address,
                          this.state.created_by,
                          this.state.is_deli_use_saved_address,
                          this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                        );
                        this.setState({
                          amount: client,
                          driver_payout: payout,
                          vendor_payout: subcon,
                          base_charge: base_charge,
                          surcharge: surcharge
                        });
                      }
                      });
                    }
                  );
                }}
              >
                 <option value="0:00"> 0:00 </option>
                  <option value="0:30"> 0:30 </option>
                  <option value="1:00"> 1:00 </option>
                  <option value="1:30"> 1:30 </option>
                  <option value="2:00"> 2:00 </option>
                  <option value="2:30"> 2:30 </option>
                  <option value="3:00"> 3:00 </option>
                  <option value="3:30"> 3:30 </option>
                  <option value="4:00"> 4:00 </option>
                  <option value="4:30"> 4:30 </option>
                  <option value="5:00"> 5:00 </option>
                  <option value="5:30"> 5:30 </option>
                  <option value="6:00"> 6:00 </option>
                  <option value="6:30"> 6:30 </option>
                  <option value="7:00"> 7:00 </option>
                  <option value="7:30"> 7:30 </option>
                  <option value="8:00"> 8:00 </option>
                  <option value="8:30"> 8:30 </option>
                <option value="9:30"> 9:30</option>
                <option value="10:00"> 10:00 </option>
                <option value="10:30"> 10:30 </option>
                <option value="11:00"> 11:00 </option>
                <option value="11:30"> 11:30 </option>
                <option value="12:00"> 12:00 </option>
                <option value="12:30"> 12:30 </option>
                <option value="13:00"> 13:00 </option>
                <option value="13:30"> 13:30 </option>
                <option value="14:00"> 14:00 </option>
                <option value="14:30"> 14:30 </option>
                <option value="15:00"> 15:00 </option>
                <option value="15:30"> 15:30 </option>
                <option value="16:00"> 16:00 </option>
                <option value="16:30"> 16:30 </option>
                <option value="17:00"> 17:00 </option>
                <option value="17:30"> 17:30 </option>
                <option value="18:00"> 18:00 </option>
                <option value="18:30"> 18:30 </option>
                <option value="19:00"> 19:00 </option>
                <option value="19:30"> 19:30 </option>
                <option value="20:00"> 20:00 </option>
                <option value="20:30"> 20:30 </option>
                <option value="21:00"> 21:00 </option>
                <option value="21:30"> 21:30 </option>
                <option value="22:00"> 22:00 </option>
                <option value="22:30"> 22:30 </option>
                <option value="23:00"> 23:00 </option>
                <option value="23:30"> 23:30 </option>
              </Select>
            </div>
          </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Rate*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:""}}
          placeholder={'Enter Job Type here...'}
          value={this.state.is_project}
          native
          required={true}
          disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          error={this.state.delivery_typeValidated}
          disableUnderline
          onChange={(e) => {
            this.setState({
                is_project: e.target.value,
                isFormChanged: true,
            },() =>{
              if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                this.setState({
                  amount: client,
                  driver_payout: payout,
                  vendor_payout: subcon, 
                  base_charge, 
                  surcharge
                })
              } else {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                this.setState({
                  base_charge, 
                  surcharge
                })
            }
            });
            // if(this.state.job_steps.length > 0) {
              
            //   let step = this.state.job_steps[0]
              
            //   step.job_step_name = (e.target.value !== '') ? e.target.value : 'Delivery'
            //   let job_steps = [step]
            //   this.setState({
            //     job_steps
            //   })
            // }
        }}
        >
          <option value=""> Select Job Type </option>
          <option value={'Standard'}>Standard</option>
          <option value={'Project'}>Project</option>
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Category*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Enter Job Type here...'}
          value={this.state.period_of_delivery}
          disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          native
          required={true}
          error={this.state.period_of_deliveryValidated}
          disableUnderline
          onChange={(e) => {
            let peroid = e.target.value

            this.setState({
                period_of_delivery: e.target.value,
                isFormChanged: true,
            },() =>{
              if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                this.setState({
                  amount: client,
                  driver_payout: payout,
                  vendor_payout: subcon, 
                  base_charge, 
                  surcharge
                })
              } else {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by, false, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    base_charge, 
                    surcharge
                  })
              }
            });
        }}
        >
          <option value=""> Select Period of Delivery </option>
          <option value={'Same Day'}>Same Day</option>
          <option value={'Next Day'}>Next Day</option>
          
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Pick Up Ref (D.O)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            disabled={true}
            placeholder={'Pick Up Ref (D.O)'}
            type={'text'}
            value={this.state.collection_reference}
            onChange={(e) => {
                this.setState({
                    collection_reference: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Tracker Number</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Tracker Number'}
            value={this.state.item_tracking_number}
            disabled
            onChange={(e) => {
                this.setState({
                  tracking_number: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col>
     
      {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) && <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end pe-1 col-3">D.O Number</div>
        <div className="max-xs-width-control-900 col-9">
        <CustomInput
            disabled={true}
            placeholder={'D.O Number'}
            type={'text'}
            value={this.state.do_number}
            onChange={(e) => {
                this.setState({
                    do_number: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col>}
    </Row>
    <Row className="p-4">
    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) && <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end pe-1 col-3">Address*</div>
        <div className="max-xs-width-control-900 col-9">
        <CustomGoogleMapSearch
          placeholder={'Address'}
          value={this.state.drop_off_address}
          error={this.state.drop_off_addressValidated}
          disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed") ? true : false }
          required={true}
          onBlur={() => {
            this.callGeoCoderAddressApi(this.state.drop_off_address, (data) => {
              if(data){
                this.setState({
                  latitude: data.lat,
                  longitude: data.lng,
                  zip_from_address: parseInt(data.zip)
                },() =>{
                  if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers,this.state.zip_from_address,false)
                    this.setState({
                      base_charge, 
                      surcharge,
                      amount: client,
                      driver_payout: payout,
                      vendor_payout: subcon
                    })
                  }                       
                })
              } else {
                this.setState({
                  latitude: '',
                  longitude: '',
                  zip_from_address: null,
                })
              }
            });             
          }}
          onChange={(e) => {     
            if(this.state.currentRole == 'Super Admin Role' || !(this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")){                                       
              this.setState({
                  drop_off_address: e.target.value.trim().length === 0 ? '' : e.target.value,
                  drop_off_addressValidated: (e.target.value != '' && e.target.value != null) ? false : true,
                  isFormChanged: true,
              });
            }
          }}
          onPlacesChanged={(place, e) => {
            if(this.state.currentRole == 'Super Admin Role' || !(this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")){ 
            let address = apiUtil.getGmapAddress(place);
            // console.log('address', address, place)
                // console.log('gometry',place);
              this.setState({
                // job_steps: [],
                drop_off_address: address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                drop_off_addressValidated: (address !== '') ? false : true,
                isFormChanged: true,
              },()=> {
                if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    base_charge, 
                    surcharge,
                    amount: client,
                    driver_payout: payout,
                    vendor_payout: subcon, 
                  })
                }
              });
              //this.deliveryAddressOnChangeHandler(address)
            }
          }}
        />
        </div>
      </Col>}
      
      { (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') || this.state.currentRole == 'Ops Admin' || this.state.currentRole == 'BD Admin' || this.state.currentRole == 'Finance Admin') && <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Driver-Partner</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomAutosuggest
          placeholder={'Enter any keyword to search for Driver'}
          items={this.state.workerItems}
          disabled={this.state.currentRole == 'BD Admin' || this.state.currentRole == 'Finance Admin' || (this.state.currentRole != 'Super Admin Role' && this.state.currentRole != 'Ops Admin' && ((!this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver')) || (this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Completed' || this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Failed'))) ? true : false}
          isLoading={this.state.workerItemsIsLoading}
          value={this.state.vendorId ? ( this.state.vendorId != this.state.user.owner_info.application_company_id ? this.state.vendorName : this.state.workerName) : this.state.workerName}
          onSelect={(event) => {
            event.preventDefault();
          }}
          onChange={(value) => {
            let oldWorkderId = this.state.vendorId ? ( this.state.vendorId != this.state.user.owner_info.application_company_id ? this.state.vendorId : this.state.workerId) : this.state.workerId;
            // console.log("change event", value)
            if (value) {
              if(oldWorkderId != value.value) {
                if (typeof(value.item.application_company_id) != 'undefined') {
                    this.setState({
                      workerName: value.label,
                      workerId: value.value,
                      workerItem: value.item,
                      isFormChanged: true,
                      assign_type: true,
                      vendorId: null,
                      vendorName: ''
                    }, () => {
                      let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                      if(orderStatusItem){
                        this.setState({
                          order_status: orderStatusItem.item,
                          order_status_id: orderStatusItem.value,
                        });
                      }
                      if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Cancelled')) {
                        if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            this.state.selected_package,
                            this.state.customer_rate,
                            this.state.is_project,
                            this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                            this.state.drop_off_date,
                            this.state.drop_off_postal_code,
                            this.state.job_type, 
                            this.state.drop_off_address, 
                            this.state.address,
                            this.state.created_by,
                            this.state.is_deli_use_saved_address,
                            this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                          );
                          this.setState({
                            amount: client,
                            driver_payout: payout,
                            vendor_payout: subcon,
                            base_charge: base_charge,
                            surcharge: surcharge
                          });
                        }
                      } else {
                        if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            this.state.selected_package,
                            this.state.customer_rate,
                            this.state.is_project,
                            this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                            this.state.drop_off_date,
                            this.state.drop_off_postal_code,
                            this.state.job_type, 
                            this.state.drop_off_address, 
                            this.state.address,
                            this.state.created_by,
                            this.state.is_deli_use_saved_address,
                            this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                          );
                          this.setState({
                            base_charge: base_charge,
                            surcharge: surcharge
                          });
                        }
                      }
                    });                
                } else if(typeof(value.item.company_id) != 'undefined') {
                  this.setState({
                    assign_type: false,
                    vendorName: value.label,
                    vendorId: value.value,
                    vendorItem: value.item,
                    isFormChanged: true,
                  }, ()=> {
                    if ((this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Cancelled')) {
                      if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                        let {
                          client,
                          payout,
                          subcon,
                          base_charge,
                          surcharge,
                        } = this.state.grantedAccess.getPriceAndDriverPayout(
                          this.state.selected_package,
                          this.state.customer_rate,
                          this.state.is_project,
                          this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                          this.state.drop_off_date,
                          this.state.drop_off_postal_code,
                          this.state.job_type, 
                          this.state.drop_off_address, 
                          this.state.address,
                          this.state.created_by,
                          this.state.is_deli_use_saved_address,
                          this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                        );
                        this.setState({
                          amount: client,
                          driver_payout: payout,
                          vendor_payout: subcon,
                          base_charge: base_charge,
                          surcharge: surcharge
                        });
                      }
                    } else {
                      if (this.state.customerId > 0 && this.state.job_type != '' && ((this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                        let {
                          client,
                          payout,
                          subcon,
                          base_charge,
                          surcharge,
                        } = this.state.grantedAccess.getPriceAndDriverPayout(
                          this.state.selected_package,
                          this.state.customer_rate,
                          this.state.is_project,
                          this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                          this.state.drop_off_date,
                          this.state.drop_off_postal_code,
                          this.state.job_type, 
                          this.state.drop_off_address, 
                          this.state.address,
                          this.state.created_by,
                          this.state.is_deli_use_saved_address,
                          this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                        );
                        this.setState({
                          base_charge: base_charge,
                          surcharge: surcharge
                        });
                      }
                    }
                  });
                 
                }
              }
            } else {
              this.setState({
                assign_type: true,
                workerName: '',
                workerId: '',
                workerItem: null,
                isFormChanged: true,
                vendorName : '',
                vendorId : null,
                vendorItem : null,
              }, () => {
                let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                if(orderStatusItem){
                  this.setState({
                    order_status: orderStatusItem.item,
                    order_status_id: orderStatusItem.value,
                  });
                }
                if (this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Cancelled') {
                  let {
                    client,
                    payout,
                    subcon,
                    base_charge,
                    surcharge,
                  } = this.state.grantedAccess.getPriceAndDriverPayout(
                    this.state.selected_package,
                    this.state.customer_rate,
                    this.state.is_project,
                    this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                    this.state.drop_off_date,
                    this.state.drop_off_postal_code,
                    this.state.job_type, 
                    this.state.drop_off_address, 
                    this.state.address,
                    this.state.created_by,
                    this.state.is_deli_use_saved_address,
                    this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                  );
                  this.setState({
                    amount: client,
                    driver_payout: payout,
                    vendor_payout: subcon,
                    base_charge: base_charge,
                    surcharge: surcharge
                  });
                } else {
                    let {
                      client,
                      payout,
                      subcon,
                      base_charge,
                      surcharge,
                    } = this.state.grantedAccess.getPriceAndDriverPayout(
                      this.state.selected_package,
                      this.state.customer_rate,
                      this.state.is_project,
                      this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                      this.state.drop_off_date,
                      this.state.drop_off_postal_code,
                      this.state.job_type, 
                      this.state.drop_off_address, 
                      this.state.address,
                      this.state.created_by,
                      this.state.is_deli_use_saved_address,
                      this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address
                    );
                    this.setState({
                      base_charge: base_charge,
                      surcharge: surcharge
                    });
                }
              });
            }
          }}
          onSearch={(value, e) => {
            // console.log(value);
            this.setState({
              workerName : value,
              vendorName : value,    
              vendorId: value != '' ? this.state.vendorId : null,
              workerId: value != '' ? this.state.workerId : '',
              isFormChanged: true,
              workerItem: value == '' ? null : this.state.workerItem,
              vendorItem: value == '' ? null : this.state.vendorItem
            }, ()=> {
               if(value == '') {
                 let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                 if(orderStatusItem){
                   this.setState({
                     order_status: orderStatusItem.item,
                     order_status_id: orderStatusItem.value,
                   });
                 }  
                              
               }
               e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
            })
             
          }}
          onClick={(value, e) => {
            e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
          }}
        /> 
        
        </div>
      </Col> }
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Postal Code</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Postal Code'}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            value ={this.state.drop_off_postal_code}
            error ={this.state.drop_off_postal_codeValidated}
            type={'number'}
            // required={true}
            labelComponent={<CustomTooltip title={'Postal Code'} />}
            onPaste = {(e) => {
              if(/^[0-9\b]+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }               
            }}
            onInput = {(e) =>{
                e.target.value =  e.target.value.toString().slice(0,6)
            }}
            onChange={(e) => {
              if(/^[0-9\b]+$/.test(e.target.value)) {
                this.setState({
                    drop_off_postal_code: e.target.value,
                    isFormChanged: true,
                    drop_off_postal_codeValidated: (e.target.value === '') ? true : false,
                }, () => {
                  if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      amount: client,
                      driver_payout: payout,
                      vendor_payout: subcon, 
                      base_charge, 
                      surcharge
                    })
                  } else {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      base_charge, 
                      surcharge
                    })
                  }
                });
              } else if (this.state.drop_off_postal_code != null && e.target.value == '') {
                this.setState({
                  drop_off_postal_code: e.target.value,
                  isFormChanged: true,
                }, ()=> {
                  if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      amount: client,
                      driver_payout: payout,
                      vendor_payout: subcon, 
                      base_charge, 
                      surcharge
                    })
                  } else {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      base_charge, 
                      surcharge
                    })
                  }
                })
              }
            }}
        />
        </div>
      </Col>
     
     
    </Row>
    <Row className="p-4">
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Name</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            placeholder={'Recipient Name'}
            value={this.state.drop_off_contact_name}
            onChange={(e) => {
                this.setState({
                  drop_off_contact_name: e.target.value,
                  isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
       <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
            <div className="max-xs-width-control-900 text-end w-50">
              Recipient Company
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50">
              <CustomInput
                placeholder={"Company"}
                disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
                type={"text"}
                value={this.state.company}
                onChange={(e) => {
                  this.setState({
                    company: e.target.value,
                    isFormChanged: true,
                  },()=> {
                    if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                      let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                        this.setState({
                          base_charge, 
                          surcharge
                        })
                    }
                  });
                }}
              />
            </div>
          </Col>

      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Contact</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Recipient Contact'}
            value={this.state.drop_off_contact_no}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            error={this.state.drop_off_contact_noValidated}
            onChange={(e) => { 
                this.setState({
                    drop_off_contact_no: e.target.value.replaceAll(/\s/g, ''), 
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Email</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Recipient Email'}
            type={'email'}
            value={this.state.notification_email}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            error={this.state.notification_emailValidated}
            onChange={(e) => {
                this.setState({
                    notification_email: e.target.value,
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Alternative Contact</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Alternative Contact'}
            value={this.state.drop_off_alternate_contact}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            error={this.state.drop_off_alt_contact_noValidated}
            onChange={(e) => { 
                this.setState({
                    drop_off_alternate_contact: e.target.value.replaceAll(/\s/g, ''), 
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
     
    </Row>
    <Row className="p-4" style={{pointerEvents: 'auto' }}>
    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Description')) && <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
      <div className="max-xs-width-control-900 w-25 text-end">Item Description</div>
      <div className="max-xs-width-control-900 ps-2 w-75">
      {this.state.id != '' && description != '' ?
        <>
        { description != '' && description != null ?
        <RTextEditor
            editorState={description}
            value={this.state.drop_off_description}
            disabled={this.state.currentRole != 'Super Admin Role' ? true : false }
            error={this.state.drop_off_descriptionValidated}
            placeholder={'Enter your description here...'}
            onChange={(editorState) => {
                this.setState({
                    drop_off_description: editorState,
                    drop_off_descriptionValidated: (editorState !== '') ? false : true,
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />: '' } 
        </> : <>
        {this.state.copyData != null && description != '' && description != null ?
        <>
        { description != '' && description != null ?
        <RTextEditor
            editorState={description}
            value={this.state.drop_off_description}
            disabled={this.state.currentRole != 'Super Admin Role' ? true : false }
            error={this.state.drop_off_descriptionValidated}
            placeholder={'Enter your description here...'}
            onChange={(editorState) => { 
                this.setState({
                    drop_off_description: editorState,
                    drop_off_descriptionValidated: (editorState !== '') ? false : true,
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />: '' } 
        </>  :
        <RTextEditor
        editorState={description}
        disabled={this.state.currentRole != 'Super Admin Role' ? true : false }
        value={this.state.drop_off_description}
        error={this.state.drop_off_descriptionValidated}
        placeholder={'Enter your description here...'}
        onChange={(editorState) => {
            this.setState({
                drop_off_description: editorState,
                drop_off_descriptionValidated: (editorState !== '') ? false : true,
                isFormChanged: true,
            },()=> {
              if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    base_charge, 
                    surcharge
                  })
              }
            });
        }}
    /> } </> } 
      </div>
      </Col>}
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <80cm / 1KG">
            XS Packages <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
              placeholder={'5'}
              disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
              type={'number'}
              inputProps={{min: 0}}
              pattern="^-?[0-9]\d*\.?\d*$"
              value={this.state.xs_package}
              onPaste={(e) => {
                if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                  e.preventDefault()
                  return false;
                }
              }}
              onKeyDown={(e) => {
                if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let selected_package = this.state.selected_package
                let index = selected_package.findIndex(dd => dd.package_id === 1);
                if (e.target.value >= 1 && e.target.value !== '') {
                   if(index === -1) {
                    let pack = {
                      package_id: 1,
                      name: 'XS',
                      package_quantity: e.target.value
                    }
                    selected_package.push(pack)
                   } else {
                      selected_package[index]["package_quantity"] = e.target.value
                      delete selected_package[index]["delete"] 
                   }
                } else {
                  if (index !== -1 && selected_package[index]["id"] > 0 ) {
                    selected_package[index]["delete"] = true                   
                  }      
                  if(selected_package[index]) {
                    selected_package[index]["package_quantity"] = '0'     
                  }                  
                }
                
                  this.setState({
                    xs_package: e.target.value,
                    selected_package: selected_package,
                    isFormChanged: true,
                  }, () => {
                    if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        amount: client,
                        driver_payout: payout,
                        vendor_payout: subcon,
                        base_charge, 
                        surcharge
                      })
                    }
                  });
              }}
          />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <120cm / 5KG">
            S Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'5'}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            type={'number'}
            inputProps={{min: 0}}
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.s_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 2);
              if (e.target.value >= 1 && e.target.value !== '') {
                if(index === -1) {
                  let pack = {
                    package_id: 2,
                    name: 'S',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                
                }
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
                
              this.setState({
                s_package: e.target.value,
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address,this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    amount: client,
                    driver_payout: payout,
                    vendor_payout: subcon,
                    base_charge, 
                    surcharge
                  })
                }
              });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <150cm / 15KG">
            M Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.m_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 3);
              if (e.target.value >= 1 && e.target.value !==  '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 3,
                    name: 'M',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                 
                } 
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
                
              this.setState({
                m_package: e.target.value,
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    amount: client,
                    driver_payout: payout,
                    vendor_payout: subcon,
                    base_charge, 
                    surcharge
                  })
                }
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <180cm / 25KG">
            L Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.l_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 4);
              if (e.target.value >= 1 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 4,
                    name: 'L',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                 
                } 
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
              this.setState({
                l_package: e.target.value,
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    amount: client,
                    driver_payout: payout,
                    vendor_payout: subcon,
                    base_charge, 
                    surcharge
                  })
                }
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Please check with Ops">
            Oversized Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.oversized_package}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 6);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 6,
                    name: 'Oversized Package',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 6)
                }
                 
              }
                
              this.setState({
                oversized_package: e.target.value,
                selected_package: selected_package,
                isFormChanged: true,
              }, () => {
                // let {client, payout} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.period_of_delivery, this.state.drop_off_date)
                // this.setState({
                //   amount: client,
                //   driver_payout: payout
                // })
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="LxWxH (1.1M x 1.2M x 1.5M) / 100KG">
            Pallets  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            disabled={this.state.currentRole != 'Super Admin Role' &&  (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.pallets}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 5);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 5,
                    name: 'Pallets',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 5)
                }
                 
              }
                
              this.setState({
                pallets: e.target.value,
                selected_package: selected_package,
                isFormChanged: true,
              },()=> {
                if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      amount: client,
                      driver_payout: payout,
                      vendor_payout: subcon,
                      base_charge, 
                      surcharge
                    })
                  }
              });
            }}
        />
        </div>
      </Col>
      {/* <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
        <div className="max-xs-width-control-900 text-end w-50">Boxes</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'5'}
            type={'number'}
            value={this.state.boxes}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    boxes: e.target.value,
                });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
        <div className="max-xs-width-control-900 text-end w-50">Documents</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Document'}
            type={'number'}
            value={this.state.document}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    document: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
        <div className="max-xs-width-control-900  text-end w-50">Cartons</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'3'}
            type={'number'}
            value={this.state.cartons}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    cartons: e.target.value,
                    isFormChanged: true,
                });
            }}
        />
        </div>
      </Col> */}
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Depalletization Request</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Depalletization Request'}
          value={this.state.depalletization_request}
          disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          native
          disableUnderline
          onChange={(e) => {
            this.setState({
                depalletization_request: e.target.value,
                isFormChanged: true,
            },()=> {
              if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                  this.setState({
                    base_charge, 
                    surcharge
                  })
              }
            });
        }}
        >
          <option value=""> Select Depalletization Request </option>
          <option value={'Y'}>Yes</option>
          <option value={'N'}>No</option>
          
        </Select>
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Size in cm<br />(L + W + H)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Size'}
            type={'text'}
            disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            value={this.state.size_l_w_h}
            // onPaste={(e) => {
            //   if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
            //     e.preventDefault()
            //     return false;
            //   }
            // }}
            // onKeyDown={(e) => {
            //   if(e.key == "e" || e.key == "E" || e.key == "-") {
            //     e.preventDefault();
            //   }
            // }}
            onChange={(e) => {
                this.setState({
                  size_l_w_h: e.target.value,
                  isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Weight (KG)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Weight'}
            type={'text'}
            disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            value={this.state.weight}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                  weight: e.target.value,
                  isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Waiting Time</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Waiting Time'}
            type={'text'}
            disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed") || this.state.currentRole == 'Customer Template Role'? true : false }
            value={this.state.waiting_time}
            onChange={(e) => {
                this.setState({
                  waiting_time: e.target.value,
                  isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Additional Manpower</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'No, movers'}
            type={'number'}
            disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
            value={this.state.no_of_movers}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                  no_of_movers: e.target.value,
                  isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                      this.setState({
                        amount: client,
                        driver_payout: payout,
                        vendor_payout: subcon,
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
    </Row>
   {this.state.data && <Row className="p-4">
    <Col xs={12} md={6} lg={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Price</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'0.00'}
            type={'number'}
            disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed") || this.state.currentRole == 'Customer Template Role' ? true : false }
            startAdornment={<p>$</p>}
            value={this.state.amount}
            onPaste={(e) => {
              if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                this.setState({
                    amount: e.target.value != '' ? e.target.value : "0.00",
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address,false)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        />
        </div>
      </Col>
      { (this.state.currentRole != 'Customer Template Role' && this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout') || this.state.currentRole == "BD Admin" || this.state.currentRole == 'Finance Admin')  && <Col xs={12} md={6} lg={4} className="max-xs-width-control-900  d-flex py-2 align-items-center" >
        <div className="max-xs-width-control-900  text-end w-50">Driver Payout</div>
        <div className="max-xs-width-control-900  ps-2 w-50">
        <CustomInput
            placeholder={'Payout'}
            disabled={this.state.currentRole == "BD Admin" || this.state.currentRole == 'Finance Admin' || (this.state.currentRole != 'Super Admin Role' && (!(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) || (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed"))) ? true : false }
            value={this.state.driver_payout}
            type={'number'}
            min={0}
            startAdornment={<p>$</p>}
            onPaste={(e) => {
              if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
                console.log('value',e.target.value);
                this.setState({
                    driver_payout: e.target.value != '' ? e.target.value : "0.00",
                    isFormChanged: true,
                },()=> {
                  if (this.state.customerId > 0) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address,false)
                      this.setState({
                        base_charge, 
                        surcharge
                      })
                  }
                });
            }}
        /> 
        </div>
      </Col>}
    {this.state.currentRole != 'Customer Template Role' && <Col xs={12} md={6} lg={4} className="max-xs-width-control-900 d-flex py-2 align-items-center" >
      <div className="max-xs-width-control-900 text-end w-50">Vendor Payout</div>
      <div className="max-xs-width-control-900 ps-2 w-50">
      <CustomInput
          placeholder={'0.00'}
          type={'number'}
          disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          startAdornment={<p>$</p>}
          value={this.state.vendor_payout}
          onPaste={(e) => {
            if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
              e.preventDefault()
              return false;
            }
          }}
          onKeyDown={(e) => {
            if(e.key == "e" || e.key == "E" || e.key == "-") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
              this.setState({
                vendor_payout: e.target.value != '' ? e.target.value : "0.00",
                isFormChanged: true,
              },()=> {
                if (this.state.customerId > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address,this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address,false)
                    this.setState({
                      base_charge, 
                      surcharge
                    })
                }
              });
          }}
      />
      </div>
    </Col>}
    </Row>}
    <Row className="p-4">
      <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
      <div className="max-xs-width-control-900 text-start w-25 text-end">Notes to Driver-Partner</div>
      <div className="max-xs-width-control-900 ps-2 w-75">
      <CustomInput
          placeholder={'Enter driver notes here...'}
          value={this.state.driver_notes}
          disabled={this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")? true : false }
          multiline={true}
          rows={5}
          onChange={(e) => {
              this.setState({
                  driver_notes: e.target.value,
                  isFormChanged: true,
              },()=> {
                if (this.state.customerId > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.selected_package, this.state.customer_rate, this.state.is_project, this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery, this.state.drop_off_date, this.state.drop_off_postal_code,this.state.job_type, this.state.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.latitude, this.state.longitude, this.state.no_of_movers, this.state.zip_from_address)
                    this.setState({
                      base_charge, 
                      surcharge
                    })
                }
              });
          }}
      />
      
      </div>
      </Col>
    </Row>
</Container>
  }

  customersDetailsForm = () => {
      return <Box>
          <Grid container>
              <Grid item xs={12}>
                  <Container maxWidth={'xs'}>
                      <Box clone>
                          <Grid container>
                            { this.isCustomerRequired ? (
                              <>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomAutosuggest 
                                        label={'Order Placed By'}
                                        placeholder={'Enter any keyword to search for customers'}
                                        labelComponent={<CustomTooltip title={'Select from your list of customers. Create one if u dont have them in your list.'} />}
                                        items={this.state.customerItems}
                                        isLoading={this.state.customerItemsIsLoading}
                                        value={this.state.customerName}
                                        // error={this.state.customerNameValidated}
                                        onChange={(value) => {
                                          if(value){
                                            if(this.state.customerId > 0 && ((this.state.data && this.state.data.order_status && this.state.data.order_status.status != 'Cancelled') || (this.state.workerId && this.state.prev_worker_id != this.state.workerId) || (this.state.vendorId && this.state.prev_vendor_id != this.state.vendorId))){
                                              this.setState({
                                                //customerNameValidated: false,
                                                customerName: value.label,
                                                customerId: value.value,
                                                customerItem: value.item,
                                                isFormChanged: true,
                                              }, () => {
                                                this.setState({
                                                  openDialog: true,
                                                  openDialogItem: value.item,
                                                });
                                              });
                                            } else {
                                              this.setState({
                                                //customerNameValidated: false,
                                                customerName: value.label,
                                                customerId: value.value,
                                                customerItem: value.item,
                                                isFormChanged: true,
                                              }, () => {
                                                this.addNewCustomerDetails(value.item);
                                              });
                                            }
                                          } else {
                                            this.setState({
                                              //customerNameValidated: true,
                                              customerName: '',
                                              customerId: '',
                                              customerItem: null,
                                              isFormChanged: true,
                                            }, () => {
                                              this.setState({
                                                branchItems: [],
                                                departmentItems: [],
                                              });
                                            });
                                          }
                                        }}
                                        onSearch={(value, e) => {
                                          this.setState({
                                            customerName: value,
                                            isFormChanged: true,
                                          }, () => {
                                            this.callCustomerReadApi(value, (arr) => {
                                              this.setState({
                                                customerItems: arr.sort((a, b) => {
                                                  const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                                                  const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                                                  if (nameA < nameB) {
                                                    return -1;
                                                  }
                                                  if (nameA > nameB) {
                                                    return 1;
                                                  }
                                                
                                                  // names must be equal
                                                  return 0;
                                                })
                                              }, () => {
                                                e.showAllSuggestions();
                                              });
                                            });
                                          });
                                        }}
                                        onClick={(value, e) => {
                                          this.callCustomerReadApi(value, (arr) => {
                                            this.setState({
                                              customerItems: arr.sort((a, b) => {
                                                const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                                                const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                                                if (nameA < nameB) {
                                                  return -1;
                                                }
                                                if (nameA > nameB) {
                                                  return 1;
                                                }
                                              
                                                // names must be equal
                                                return 0;
                                              })
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        }}
                                        onInit={() => {
                                          //this.callCustomerReadApi();
                                        }}
                                      />
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Name'}
                                          placeholder={'Recipient Name'}
                                          value={this.state.drop_off_name}
                                          // error={this.state.drop_off_contact_nameValidated}
                                          //required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                drop_off_name: e.target.value,
                                                  //drop_off_nameValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              </>
                            ):(
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Name'}
                                          placeholder={'Recipient Name'}
                                          value={this.state.drop_off_name}
                                          //error={this.state.drop_off_contact_nameValidated}
                                          //required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  drop_off_name: e.target.value,
                                                  //drop_off_nameValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box> )}
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Email'}
                                          placeholder={'Recipient Email'}
                                          type={'email'}
                                          value={this.state.notification_email}
                                          error={this.state.notification_emailValidated}
                                          onChange={(e) => {
                                              this.setState({
                                                  notification_email: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Recipient Contact'}
                                          placeholder={'Recipient Contact'}
                                          value={this.state.drop_off_contact_no}
                                          error={this.state.drop_off_contact_noValidated}
                                          errorText={'Phone number is invalid'}
                                          type="tel"
                                          pattern="^-?[0-9]\d*\.?\d*$"
                                          // required={true} 
                                          onChange={(e) => { 
                                              this.setState({
                                                  drop_off_contact_no: e.target.value.replaceAll(/\s/g, ''), 
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                <Grid item xs={12}>
                                  <CustomGoogleMapSearch
                                          label={'Address*'}
                                          placeholder={'Address'}
                                          value={this.state.drop_off_address}
                                          error={this.state.drop_off_addressValidated}
                                          required={true}
                                          onChange={(e) => {                                            
                                             this.deliveryAddressOnChangeHandler(e.target.value)
                                              this.setState({
                                                  drop_off_address: e.target.value.trim().length === 0 ? '' : e.target.value,
                                                  drop_off_addressValidated: (e.target.value != '' && e.target.value != null) ? false : true,
                                              });
                                          }}
                                          onPlacesChanged={(place, e) => {
                                            // this.deliveryAddressOnChangeHandler(place)
                                            let address = apiUtil.getGmapAddress(place);
                                              this.setState({
                                                // job_steps: [],
                                                drop_off_address: address,
                                                latitude: place.geometry.location.lat(),
                                                longitude: place.geometry.location.lng(),
                                                drop_off_addressValidated: (address !== '') ? false : true,
                                              });
                                              this.deliveryAddressOnChangeHandler(address)
                                          }}
                                          // onBlur={(e)=> {                                            
                                          //   this.deliveryAddressOnChangeHandler(this.state.drop_off_address)
                                          // }}
                                          // onClick ={()=> {                                          
                                          //   this.setState({
                                          //     job_steps: []
                                          //   });
                                          // }}
                                        />
                                    </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <span></span>
                                      <CustomInput
                                          label={'Postal Code'}
                                          placeholder={'Postal Code'}
                                          value ={this.state.drop_off_postal_code}
                                          error ={this.state.drop_off_postal_codeValidated}
                                          type={'number'}
                                          // required={true}
                                          labelComponent={<CustomTooltip title={'Allow only 6 digits number'} />}
                                          onInput = {(e) =>{
                                              e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                                          }}
                                          onChange={(e) => {
                                              this.setState({
                                                  drop_off_postal_code: e.target.value,
                                                  isFormChanged: true,
                                                  drop_off_postal_codeValidated: (e.target.value === '') ? true : false,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'D.O Number'}
                                          placeholder={'D.O Number'}
                                          type={'text'}
                                          value={this.state.do_number}
                                          onChange={(e) => {
                                              this.setState({
                                                  do_number: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Zone')) && this.state.currentRole !== "Customer Template Role" && <Box clone>
                              <Grid item xs={12} >
                                <Box className="custom-label-component">
                                  <InputLabel >Zone</InputLabel>
                                </Box>
                                <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                                  placeholder={'Select Zone here...'}
                                  value={this.state.zone_id}
                                  native
                                  required={false}
                                  disableUnderline
                                  onChange={(e) => {
                                    this.setState({
                                        zone_id: e.target.value,
                                        isFormChanged: true,
                                    });
                                }}
                                >
                                  <option value=""> Select Zone </option>
                                  {
                                    this.state.zoneItems && this.state.zoneItems.length > 0 &&
                                      Object.keys(this.state.zoneItems).map(zone => (
                                        <option key={this.state.zoneItems[zone].id} value={this.state.zoneItems[zone].id}>
                                          {this.state.zoneItems[zone].polygon_name}
                                        </option>
                                      ))
                                  }
                                </Select>                 
                                </Grid>                              
                              </Box>  }
                              
                              <Box clone>
                                  <Grid item xs={12}>
                                  <Box className="custom-label-component">
                                  <CustomLabel
                                    label={'Packages'}
                                    labelComponent={<CustomTooltip title={'Job Type need to be choosen first to calculate driver payout and price.'} />}
                                    
                                  />
                                  </Box>
                                  </Grid>
                              </Box>
                              {this.state.grantedAccess.admin_roles.includes(this.state.currentRole) && <Box clone mt={3}>
                                  <Grid item xs={12} >
                                      <CustomInput
                                          label={'Price'}
                                          placeholder={'0.00'}
                                          type={'number'}
                                          color={'secondary'}
                                          disabled={this.state.currentRole == 'Customer Template Role' ? true : false}
                                          startAdornment={<p>$</p>}
                                          // endAdornment={<span>SGD</span>}
                                          value={this.state.amount}
                                          // error={this.state.amountValidated}
                                          // required={true}
                                          onChange={(e) => {
                                              this.setState({
                                                  amount: e.target.value,
                                                  // amountValidated: (e.target.value !== '') ? false : true,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box> }
                              <Box clone>
                                <Grid item xs={12}>
                                {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) && <CustomInput
                                        label={'Driver Payout'}
                                        placeholder={'Enter driver payout here...'}
                                        value={this.state.driver_payout}
                                        type={'number'}
                                        startAdornment={<p>$</p>}
                                        endAdornment={<span>SGD</span>}
                                        required={false}
                                        onChange={(e) => {
                                            this.setState({
                                                driver_payout: e.target.value,
                                            });
                                        }}
                                    /> }
                                </Grid>
                              </Box>
                              <Box clone >
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Document'}
                                          placeholder={'Document'}
                                          type={'text'}
                                          value={this.state.document}
                                          onChange={(e) => {
                                              this.setState({
                                                  document: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Boxes'}
                                          placeholder={'5'}
                                          type={'text'}
                                          value={this.state.boxes}
                                          onChange={(e) => {
                                              this.setState({
                                                  boxes: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Pallets'}
                                          placeholder={'3'}
                                          type={'text'}
                                          value={this.state.pallets}
                                          onChange={(e) => {
                                              this.setState({
                                                  pallets: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Cartons'}
                                          placeholder={'3'}
                                          type={'text'}
                                          value={this.state.cartons}
                                          onChange={(e) => {
                                              this.setState({
                                                  cartons: e.target.value,
                                                  isFormChanged: true,
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box>

                              <Box clone>
                                  <Grid item xs={12}>
                                      <Grid container>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Notify recipient'}
                                                      checked={this.state.is_notify_customer}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_notify_customer: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(!checked){
                                                              this.setState({
                                                                is_tracking_link_required: false,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                          <Box clone>
                                              <Grid item xs={'auto'}>
                                                  <CustomCheckbox
                                                      label={'Send tracking link'}
                                                      checked={this.state.is_tracking_link_required}
                                                      onChange={(checked) => {
                                                          this.setState({
                                                              is_tracking_link_required: checked,
                                                              isFormChanged: true,
                                                          }, () => {
                                                            if(checked){
                                                              this.setState({
                                                                is_notify_customer: true,
                                                              });
                                                            }
                                                          });
                                                      }}
                                                  />
                                              </Grid>
                                          </Box>
                                      </Grid>
                                  </Grid>
                              </Box>

                          </Grid>
                      </Box>
                  </Container>
              </Grid>
          </Grid>
      </Box>
   }

  personInChargeForm = () => {
    if(this.isCustomerRequired){
      return <Box>
          <DepartmentPICList 
              items={this.state.picItems}
              onAddNew={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
              onEdit={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
              onRemove={(data) => {
                  this.setState({
                    picItems: data,
                    isFormChanged: true,
                  });
              }}
          />
      </Box>
    }
  }

  itemsInJobForm = () => {
    return <Box>
        <ItemsInJobList 
            items={this.state.itemsInJobList}
            onAddNew={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
            onEdit={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
            onRemove={(data) => {
                this.setState({
                  itemsInJobList: data,
                  isFormChanged: true,
                });
            }}
        />
    </Box>
  }

  jobStepForm = () => {
    return <Box style={{width:'102.4%', maxWidth: '102.4%', margin:'0px', padding:'0px', backgroundColor:'transparent'}}>
        <StepsList 
            items={this.state.job_steps}
            isAddJob={this.state.id === 0}
            onAddNew={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
            onEdit={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
            onRemove={(data) => {
                this.setState({
                  job_steps: data,
                  isFormChanged: true,
                });
            }}
        />
    </Box>
  }

  assignWorkersForm = () => {
    return <>
        {/* <Grid container>
            <Grid item xs={12} className="pt-5">
                <CustomLabel
                    label={<React.Fragment>Assign Driver <small className={'muted'}>Optional</small></React.Fragment>}
                    labelComponent={<CustomTooltip title={'The job status will be \'Unassigned\' if no drivers are assigned here.'} />}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid> */}
            {/* <Grid item xs={12}>
                <Container maxWidth={'xs'}> */}
                <Grid item xs={12} className="pt-5">
                    {/* <Container maxWidth={'xs'}> */}

                            <Box clone>
                                <Grid item xs={12}>
                                    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) && 
                                    <CustomAutosuggest 
                                      // disabled = {this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" ? true: false}
                                      label={'Driver-Partner'}
                                      placeholder={'Enter any keyword to search for Driver'}
                                      // helperText={<Box>
                                      //   <Grid container alignItems={'center'}>
                                      //       <Box clone>
                                      //           <Grid item xs={'auto'}>Driver not in list?</Grid>
                                      //       </Box>
                                      //       <Box clone>
                                      //           <Grid item xs={'auto'}>
                                      //               <CustomButton 
                                      //                   className={'underline'}
                                      //                   href={'/'}
                                      //                   color={'primary'}
                                      //                   onClick={(e) => {
                                      //                       e.preventDefault();
                                      //                       e.stopPropagation();

                                      //                       this.setState({
                                      //                         openDialogDriver: true,
                                      //                         itemDialogDriver: null,
                                      //                       });
                                      //                   }}
                                      //               >
                                      //                   Create new Transport operator
                                      //               </CustomButton>
                                      //           </Grid>
                                      //       </Box>
                                      //   </Grid>
                                      // </Box>}
                                      items={this.state.workerItems}
                                      isLoading={this.state.workerItemsIsLoading}
                                      value={this.state.workerName}
                                      onChange={(value) => {
                                        if(value){
                                          this.setState({
                                            workerName: value.label,
                                            workerId: value.value,
                                            workerItem: value.item,
                                            isFormChanged: true,
                                          }, () => {
                                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                                            if(orderStatusItem){
                                              this.setState({
                                                order_status: orderStatusItem.item,
                                                order_status_id: orderStatusItem.value,
                                              });
                                            }
                                          });
                                        } else {
                                          this.setState({
                                            workerName: '',
                                            workerId: '',
                                            workerItem: null,
                                            isFormChanged: true,
                                          }, () => {
                                            let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                                            if(orderStatusItem){
                                              this.setState({
                                                order_status: orderStatusItem.item,
                                                order_status_id: orderStatusItem.value,
                                              });
                                            }
                                          });
                                        }
                                      }}
                                      onSearch={(value, e) => {
                                        this.setState({
                                          workerName: value,
                                          isFormChanged: true,
                                        }, () => {
                                          e.showAllSuggestions(this.state.workerItems.filter(dd => dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase())));
                                        });
                                      }}
                                      onClick={(value, e) => {
                                        e.showAllSuggestions(this.state.workerItems.filter(dd => dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase())));
                                      }}
                                      onInit={() => {
                                        //this.callWorkerDriverReadApi();
                                      }}
                                    /> }
                                </Grid>
                            </Box>

                            <Box clone>
                                <Grid item xs={12}>
                                {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver & Manpower Listing')) && <CustomAutosuggest 
                                      label={'Driver-Partner Assistant'}
                                      placeholder={'Enter any keyword to search for Driver Assistant'}
                                      // helperText={<Box>
                                      //     <Grid container alignItems={'center'}>
                                      //         <Box clone>
                                      //             <Grid item xs={'auto'}>Logistic assistant not in list?</Grid>
                                      //         </Box>
                                      //         <Box clone>
                                      //             <Grid item xs={'auto'}>
                                      //                 <CustomButton 
                                      //                     className={'underline'}
                                      //                     href={'/'}
                                      //                     color={'primary'}
                                      //                     onClick={(e) => {
                                      //                         e.preventDefault();
                                      //                         e.stopPropagation();

                                      //                         this.setState({
                                      //                           openDialogLogisticsAssistance: true,
                                      //                           itemDialogLogisticsAssistance: null,
                                      //                         });
                                      //                     }}
                                      //                 >
                                      //                     Create new Logistics assistant
                                      //                 </CustomButton>
                                      //             </Grid>
                                      //         </Box>
                                      //     </Grid>
                                      // </Box>}
                                      items={this.state.manPowerItems}
                                      isLoading={this.state.manPowerItemsIsLoading}
                                      value={this.state.manPowerName}
                                      onChange={(value) => {
                                        if(value){
                                          this.setState({
                                            manPowerName: value.label,
                                            manPowerId: value.value,
                                            manPowerItem: value.item,
                                            isFormChanged: true,
                                          });
                                        } else {
                                          this.setState({
                                            manPowerName: '',
                                            manPowerId: '',
                                            manPowerItem: null,
                                          });
                                        }
                                      }}
                                      onSearch={(value, e) => {
                                        this.setState({
                                          manPowerName: value,
                                          isFormChanged: true,
                                        }, () => {
                                          this.callWorkerManPowerReadApi(value, (arr) => {
                                            this.setState({
                                              manPowerItems: arr
                                            }, () => {
                                              e.showAllSuggestions();
                                            });
                                          });
                                        });
                                      }}
                                      onClick={(value, e) => {
                                        this.callWorkerManPowerReadApi(value, (arr) => {
                                          this.setState({
                                            manPowerItems: arr
                                          }, () => {
                                            e.showAllSuggestions();
                                          });
                                        });
                                      }}
                                      onInit={() => {
                                        this.callWorkerManPowerReadApi();
                                      }}
                                    /> }
                                </Grid>
                            </Box>
                            

                </Grid>
                {/* </Container>
            </Grid> */}
        {/* </Grid>*/}
    </> 
  }
  /* END FORM */

  addNewCustomerDetails = (data) => {
    const { isOnBoarding, onBoardingCustomer } = this.context;

    if(!isOnBoarding){
      /* ADD NEW CUSTOMER DETAILS */
      if(data && data.id){
        this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
          let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
          let branchItems = customerAddresses.map((item, i) => {
            return {
              value: item.id,
              label: item.name,
              item: item,
            }
          });

          let departmentItems = [];
          if(customerData && customerData.departments && customerData.departments.length > 0){
            departmentItems = customerData.departments.map((item, i) => {
              return {
                value: item.id,
                label: item.name,
                item: item,
              }
            });
          }

          this.setState({
            branchItems: branchItems,
            departmentItems: departmentItems,
          }, () => {
            let drop_off_address = '';
            let drop_off_postal_code = '';
            let order_branch_id = (data && data.customer_addresses && data.customer_addresses[0] && data.customer_addresses[0].id) ? data.customer_addresses[0].id : '';
            let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
            if(drop_off_addressItem && drop_off_addressItem.length > 0){
              drop_off_address = drop_off_addressItem[0].item.address_line1;
              drop_off_postal_code = drop_off_addressItem[0].item.postal_code;
            }
            this.setState({
              // order_branch_id: order_branch_id,
              // drop_off_address: drop_off_address,
              // drop_off_postal_code: drop_off_postal_code
            });
            
            let departmentItem = null;
            let department_id = (this.state.departmentItems && this.state.departmentItems[0] && this.state.departmentItems[0].value) ? this.state.departmentItems[0].value : '';
            let departmentArray = (this.state.departmentItems && this.state.departmentItems.length > 0) ? this.state.departmentItems.filter(x => x.value === department_id) : [];
            if(departmentArray && departmentArray.length > 0){
              departmentItem = departmentArray[0];
            }
            this.setState({
              // department_id: department_id,
              // departmentItem: departmentItem,
            });

            let picItems = [];
            if(departmentItem && departmentItem.item && departmentItem.item.contact_people && departmentItem.item.contact_people.length > 0){
              for(let x = 0; x < departmentItem.item.contact_people.length; x++){
                let item = departmentItem.item.contact_people[x];

                picItems.push({
                  uuid: uuid(),
                  id: item.id,
                  drop_off_name: item.first_name,
                  drop_off_contact_no: item.contact_no,
                  notification_email: item.email,
                  remove: false,
                });

                this.setState({
                  // notification_email: item.email,
                  // drop_off_name: item.first_name + item.last_name,
                  // drop_off_contact_name: item.first_name,
                  // drop_off_contact_no: item.contact_no,
                });
              }
            }

            this.setState({
              picItems: picItems
            });


            /* STEPS SECTION */
            let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'label', 'Pending');
            let job_step_status = (orderStatusItem) ? orderStatusItem : null;
            
            let job_step_pic = '';
            let job_step_pic_contact = '';
            let picItem = (picItems && picItems.length > 0) ? picItems[0] : null;
            if(picItem){
              job_step_pic = picItem.drop_off_name;
              job_step_pic_contact = picItem.drop_off_contact_no;
            }

            let job_steps = this.state.job_steps;
            if(job_steps && job_steps.length > 0){
              job_steps = job_steps.map((item, i) => {
                item.remove = true;
                return item;
              });
            }

            let step = {
              uuid: uuid(),
              job_step_name: this.state.job_type ? this.state.job_type : 'Delivery',
              is_signature_required: true,
              is_scan_required: false,
              description: '',
              order_sequence: 1,
              remove: false,
              job_step_status_id: (job_step_status && job_step_status.value && job_step_status.value !== '')? job_step_status.value : 1,
              job_step_status: job_step_status,
              location: drop_off_address,
              latitude: 0,
              longitude: 0,
              job_step_pic: job_step_pic,
              job_step_pic_contact: job_step_pic_contact,
            };

            // this.callGeoCoderAddressApi(step.location, (data) => {
            //     if(data){
            //       this.setState({
            //         // latitude: data.lat,
            //         // longitude: data.lng
            //       },() => {
            //         // step.latitude = data.lat;
            //         // step.longitude = data.lng;
            //       })
            //     }
            // });
            
            job_steps.push(step);

            this.setState({
              job_steps: job_steps,
            });
            /* END STEPS SECTION */
          });
        });
      }
      /* END ADD NEW CUSTOMER DETAILS */
    } else {
      let customerData = null;
      try {
        customerData = JSON.parse(onBoardingCustomer);
      } catch(e){}

      if(customerData){
        let customerAddresses = (customerData && customerData.addresses && customerData.addresses.length > 0) ? customerData.addresses : [];
        let branchItems = customerAddresses.map((item, i) => {
          return {
            value: item.uuid,
            label: item.name,
            item: item,
          }
        });

        let departmentItems = [];
        if(customerData && customerData.departments && customerData.departments.length > 0){
          departmentItems = customerData.departments.map((item, i) => {
            return {
              value: item.uuid,
              label: item.department_name,
              item: item,
            }
          });
        }

        this.setState({
          branchItems: branchItems,
          departmentItems: departmentItems,
        }, () => {
          let drop_off_address = '';
          let order_branch_id = (data && data.addresses && data.addresses[0] && data.addresses[0].id) ? data.addresses[0].id : '';
          let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
          if(drop_off_addressItem && drop_off_addressItem.length > 0){
            drop_off_address = drop_off_addressItem[0].item.address_line1;
          }
          this.setState({
            order_branch_id: order_branch_id,
            drop_off_address: drop_off_address,
          });
          
          let departmentItem = null;
          let department_id = (this.state.departmentItems && this.state.departmentItems[0] && this.state.departmentItems[0].uuid) ? this.state.departmentItems[0].uuid : '';
          let departmentArray = (this.state.departmentItems && this.state.departmentItems.length > 0) ? this.state.departmentItems.filter(x => x.uuid === department_id) : [];
          if(departmentArray && departmentArray.length > 0){
            departmentItem = departmentArray[0];
          }
          this.setState({
            department_id: department_id,
            departmentItem: departmentItem,
          });

          let picItems = [];
          if(departmentItem && departmentItem.item && departmentItem.item.contact_people && departmentItem.item.contact_people.length > 0){
            for(let x = 0; x < departmentItem.item.contact_people.length; x++){
              let item = departmentItem.item.contact_people[x];

              picItems.push({
                uuid: uuid(),
                id: item.id,
                drop_off_name: item.first_name,
                drop_off_contact_no: item.contact_no,
                notification_email: item.email,
                remove: false,
              });

              this.setState({
                notification_email: item.email,
                drop_off_name: item.first_name + item.last_name,
                drop_off_contact_name: item.first_name,
                drop_off_contact_no: item.contact_no,
              });
            }
          }

          this.setState({
            picItems: picItems,
          });
        });
      }
    }
  }

  
  // ON CHANGE JOBSETP WHEN DELIVERY ADDRESS CHANGED
  deliveryAddressOnChangeHandler = (dropoff_address) => {
    /* STEPS SECTION */
    let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'label', 'Pending');
    let job_step_status = (orderStatusItem) ? orderStatusItem : null;
    let job_step_pic = '';
    let job_step_pic_contact = '';
    let picItem = (this.state.picItems && this.state.picItems.length > 0) ? this.state.picItems[0] : null;
    if(picItem){
      job_step_pic = picItem.drop_off_name;
      job_step_pic_contact = picItem.drop_off_contact_no;
    }

    // let job_steps = this.state.job_steps;
    // if(job_steps && job_steps.length > 0){
    //   job_steps = job_steps.map((item, i) => {
    //     item.remove = true;
    //     console.log(i)
    //     return item;
    //   });
    // }

    let step = {
      uuid: uuid(),
      job_step_name: this.state.job_type ? this.state.job_type : 'Delivery',
      is_signature_required: true,
      is_scan_required: false,
      description: '',
      order_sequence: 1,
      remove: false,
      job_step_status_id: (job_step_status && job_step_status.value && job_step_status.value !== '' )? job_step_status.value : 1,
      job_step_status: job_step_status,
      location: dropoff_address,
      latitude: 0,
      longitude: 0,
      job_step_pic: job_step_pic ? job_step_pic : this.state.drop_off_contact_name,
      job_step_pic_contact: job_step_pic_contact ? job_step_pic_contact : this.state.drop_off_contact_no
    };

    this.callGeoCoderAddressApi(step.location, (data) => {
      
        if(data){
          step.latitude = data.lat;
          step.longitude = data.lng;
          this.setState({
            latitude: data.lat,
            longitude: data.lng
          })
        }
    });

    let job_steps = this.state.job_steps;
    job_steps = job_steps.filter(st => st.remove === false);
    
    //Edit Delivery Address 
    if(this.state.id && this.state.id > 0) {
      step = this.getEditedJobStep(step);
    }

   
    if(job_steps && job_steps.length > 0){
      job_steps = job_steps.map((item, i) => {
        // item.remove = true;
        if(i === 0) return step
        return item;
      });
    } else {
      job_steps.push(step);
    }
    // job_steps.push(step);
    this.setState({
      job_steps: job_steps,
    });
    /* END STEPS SECTION */
  }
   

  //Get Edited first job step
  getEditedJobStep = (step) =>{
    let job_step = this.state.job_steps
    let edit_jop_step = {};
     if( job_step && job_step.length > 0) {
      edit_jop_step = {...job_step[0] , ...step}
      return edit_jop_step;
      
     }
  }

  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isOnBoarding, setOnBoardingJob } = this.context;
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
      // isFormChanged: false,
      drop_off_dateValidated: false,
      isCustomerRequired : false,
      drop_off_descriptionValidated: false,
      drop_off_contact_nameValidated: false,
      drop_off_addressValidated: false,
      customerNameValidated: false,
      vendorNameValidated: false,
      drop_off_contact_noValidated: false,
      drop_off_postal_codeValidated: false,
      notification_emailValidated: false,
      period_of_deliveryValidated: false,
      delivery_typeValidated: false
    });
    
    let notification_emailValidated = true
    if(this.state.notification_email !== '' && !apiUtil.emailValidation(this.state.notification_email)){
      notification_emailValidated = false
      this.setState({
        notification_emailValidated: true
      });
    }
   
    let postal_code_validation = true
    if(this.state.drop_off_postal_code && (this.state.drop_off_postal_code < 0 || this.state.drop_off_postal_code === 0 || this.state.drop_off_postal_code === '' || this.state.drop_off_postal_code === null || this.state.drop_off_postal_code.length < 2)) {
      postal_code_validation = false
      this.setState({
        drop_off_postal_codeValidated: true
      });
    }

    if(this.state.drop_off_address === ''){
      this.setState({
        drop_off_addressValidated: true
      });
    }

    if(this.state.drop_off_date === ''){
      this.setState({
        drop_off_dateValidated: true
      });
    }
    
    let contact_validated = true
    if(this.state.drop_off_contact_no && !apiUtil.phoneValidationWithPlus(this.state.drop_off_contact_no) ){
      contact_validated = false
      this.setState({
        drop_off_contact_noValidated: true,
      });
    } 

    let alt_contact_validated = true
    if(this.state.drop_off_alternate_contact && !apiUtil.phoneValidationWithPlus(this.state.drop_off_alternate_contact) ){
      alt_contact_validated = false
      this.setState({
        drop_off_alt_contact_noValidated: true,
      });
    } 


    let job_type_arr = ['Standard', 'Project'] 
    if(!job_type_arr.includes(this.state.is_project)){
      this.setState({
        delivery_typeValidated: true
      });
    }
    
    if(this.state.vendorName === ''){
      this.setState({
        vendorNameValidated: true
      });
    }
 
    let period_arr =['Same Day', 'Next Day'] 
    if(!period_arr.includes(this.state.period_of_delivery)){
      this.setState({
        period_of_deliveryValidated: true
      });
    }

    let collection_timeValidate =true;
    let start_time = moment(this.state.collection_from_time,'hh:mm');
    let end_time = moment(this.state.collection_to_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    if (this.state.collection_from_time == this.state.collection_to_time || diff_time < 0) {
      collection_timeValidate =  false;
      this.setState({
        collection_timeValidated: true,
        isLoading: false,
      });
    } else {
      this.setState({
        collection_timeValidated: false,
      });
    }
    let do_validated = true;
    if((this.state.xs_package == '' || this.state.xs_package < 1) && (this.state.s_package == '' || this.state.s_package < 1) && (this.state.m_package == '' || this.state.m_package < 1) && (this.state.l_package == '' || this.state.l_package < 1 ) && (this.state.oversized_package == '' || this.state.oversized_package < 1) && (this.state.pallets == '' || this.state.pallets < 1)) {
      apiUtil.toast("Please input the quantity of packages!")
      do_validated =false;       
    }
    
    if (form.checkValidity() === true && this.state.isFormChanged == true && do_validated && notification_emailValidated && contact_validated && postal_code_validation && collection_timeValidate && alt_contact_validated) {
      let drop_off_name = '';
      let drop_off_contact_no = '';
      let drop_off_alternate_contact = '';
      let notification_email = '';
      
      // if(this.isCustomerRequired){
      //   if(this.state.picItems && this.state.picItems.length > 0){
      //     drop_off_name = this.state.picItems.filter(x => !x.remove).map((item, i) => {
      //       return item.drop_off_name;
      //     }).join(',');

      //     drop_off_contact_no = this.state.picItems.filter(x => !x.remove).map((item, i) => {
      //       return item.drop_off_contact_no;
      //     }).join(',');

      //     notification_email = this.state.picItems.filter(x => !x.remove).map((item, i) => {
      //       return item.notification_email;
      //     }).join(',');
      //   }
      // } else {
      //   drop_off_name = this.state.drop_off_name;
      //   drop_off_contact_no = this.state.drop_off_contact_no;
      //   notification_email = this.state.notification_email;
      // }

      // New Logic 
        drop_off_name = this.state.drop_off_name;
        drop_off_contact_no = this.state.drop_off_contact_no.length === 8 ? '+65' +this.state.drop_off_contact_no : this.state.drop_off_contact_no ;
        drop_off_alternate_contact = this.state.drop_off_alternate_contact.length === 8 ? '+65' +this.state.drop_off_alternate_contact : this.state.drop_off_alternate_contact ;
        notification_email = this.state.notification_email;
      

      let order_details = [];
      if(this.state.itemsInJobList && this.state.itemsInJobList.length > 0){
        order_details = this.state.itemsInJobList.filter(x => !x.remove).map((item, i) => {
          delete item.uuid;
          delete item.remove;

          return item;
        });
      }
      

      let job_steps = [];
      if(this.state.job_steps && this.state.job_steps.length > 0){
        job_steps = this.state.job_steps.map((item, i) => {
          delete item.uuid;
          delete item.job_step_status;

          return item;
        });
      }

      if(this.state.fail_reason) {
        let order_attempts = {}
        order_attempts['reason'] = this.state.fail_reason
        if (this.state.order_attempts !== "" ) {
          order_attempts = this.state.order_attempts
          order_attempts['reason'] = this.state.fail_reason
          order_attempts['order_attempt_id'] = this.state.order_attempts.id
        }
        if (job_steps.length > 0) {
          job_steps[job_steps.length - 1]['step_attempts'] = [order_attempts]
        } else {
          // Handle case when job_steps is empty
          // console.log('job_steps is empty');
        }
      }
      
      let departmentItem = (this.state.departmentItem && this.state.departmentItem.item) ? this.state.departmentItem.item : null;
      if(departmentItem){
        delete departmentItem['created_at'];
        delete departmentItem['updated_at'];
        delete departmentItem['application_company_id'];

        if(departmentItem.contact_people && departmentItem.contact_people.length > 0){
          departmentItem.contact_people = departmentItem.contact_people.filter(x => !x.remove).map((item, i) => {
            delete item['created_at'];
            delete item['updated_at'];
  
            return item;
          });
        }
      }

      let drop_off_date = moment(this.state.drop_off_date);
      let drop_off_time_planned = this.state.drop_off_time_planned;
      // if(this.state.drop_off_time_planned && this.state.drop_off_time_planned !== ''){
      //   drop_off_time_planned = moment(drop_off_date.format(apiUtil.getDefaultDateFormat()) + ' ' + this.state.drop_off_time_planned).local().format('YYYY-MM-DD HH:mm:ss');
      // }

      // let extra_data = {
      //   boxes: this.state.boxes,
      //   document: this.state.document,
      //   pallets : this.state.pallets, 
      //   cartons: this.state.cartons
      // }

      let package_info = {
        depalletization_request: this.state.depalletization_request,
        size_l_w_h: this.state.size_l_w_h,
        weight : this.state.weight, 
        waiting_time: this.state.waiting_time,
        no_of_movers: this.state.no_of_movers,
      }

    //   this.callGeoCoderAddressApi(this.state.drop_off_address, (data) => {
    //     if (data.lat == null && data.long == null) {
    //       apiUtil.toast('address are not reconginze Lat Long from address', 'check_circle');
    //     }
    //     if(data){
    //       this.setState({
    //         latitude : data.lat,
    //         longitude : data.lng,
    //       })
          
    //     }
    // });

      let data = {
        order_details: order_details,
        job_steps: job_steps,
        driver_notes: this.state.driver_notes,
        job_type: this.state.job_type,
        is_project: this.state.is_project == "Project" ? true : false,
        is_notify_customer: this.state.is_notify_customer,
        zone_id: this.state.zone_id,
        drop_off_date: this.state.drop_off_date,
        drop_off_time_planned: this.state.collection_from_time + ' - ' + this.state.collection_to_time,
        drop_off_description: this.state.drop_off_description,
        customer_id: this.state.customerId,
        drop_off_alternate_contact: drop_off_alternate_contact ? drop_off_alternate_contact : '',
        // drop_off_contact_name: this.state.customerName? this.state.customerName : this.state.drop_off_contact_name,
        department_id: this.state.customerId ? this.state.departmentId : '',
        notification_email: notification_email,
        drop_off_contact_no: drop_off_contact_no,
        drop_off_contact_name: this.state.drop_off_contact_name,
        drop_off_name: this.state.company,
        order_branch_id: this.state.order_branch_id,
        drop_off_address: this.state.drop_off_address,
        // drop_off_latitude: this.state.latitude,
        // drop_off_longitude: this.state.longitude,
        drop_off_postal_code: this.state.drop_off_postal_code,
        item_tracking_number: this.state.item_tracking_number,
        is_tracking_link_required: this.state.is_tracking_link_required,
        order_status_id: this.state.order_status_id,
        total_package: (this.state.itemsInJobList && this.state.itemsInJobList.length > 0) ? this.state.itemsInJobList.reduce((total, x) => parseFloat(total) + parseFloat(x.quantity), 0) : 0,
        total_kg: (this.state.itemsInJobList && this.state.itemsInJobList.length > 0) ? this.state.itemsInJobList.reduce((total, x) => parseFloat(total) + parseFloat(x.weight), 0) : 0,
        // extra_data: JSON.stringify(extra_data),
        package_info: JSON.stringify(package_info),
        do_number: this.state.do_number,
        order_packages: this.state.selected_package,
        period_of_delivery: this.state.period_of_delivery,
        base_charge: this.state.base_charge,
        surcharge: this.state.surcharge,
        // reason: this.state.fail_reason,
        is_detail: true, 
        driver_payout : this.state.driver_payout,
        vendor_payout : this.state.vendor_payout,
        amount : this.state.amount,
        is_order_detail_update: true
      }

      if(this.state.assign_type){
        data['drop_off_worker_id'] = this.state.workerId;
        data['driver_name'] = this.state.workerName;
        data['extra_worker_ids'] = this.state.manPowerId.toString();
        data['manpower_name'] = this.state.manPowerName;
        data['vendor_id'] = null;
      } else if(this.state.assign_type == false) {
        data['assign_type'] = "vendor";
        data['vendor_id'] = this.state.vendorId;
        data['assigned_vendor'] = this.state.vendorName;
      }

      if(this.state.latitude != 0 || this.state.longitude != 0) {
        data['drop_off_latitude'] = this.state.latitude;
        data['drop_off_longitude'] = this.state.longitude;
      }

      if(this.state.data.vendor_id && this.state.data.vendor_id == this.state.user.owner_info.application_company_id && this.state.temp_fail_reason != '') {
        data = {       
          job_steps: job_steps,
          do_number: this.state.do_number,
        }        
      }
      if(this.state.id && this.state.id > 0){
        this.callUpdateApi(this.state.id, data, (row) => {
          if(this.state.vendorId == '' && this.state.workerId == '') {

            let unAssignData = {             
              data: [
                {
                  id: [ this.state.data.id ],
                  job_steps: this.state.data.job_steps,
                  order_status_id: this.state.tableStatus[1].id,
                  send_notification_to_customer: true,
                  drop_off_worker_id: null,
                  extra_worker_ids: null,
                }
              ]
            };

            this.callUpdateStatusApi(unAssignData, (newRow) => {
              this.setState({
                isLoading: false
              }, () => {
                if(row) {
                  if(this.props.onSave) {
                    this.props.onSave()
                  }
                }
              });               
            });           
          } else {
            this.setState({
              isLoading: false
            }, () => {
              if(this.props.onSave) {
                this.props.onSave()
              }
            });
          }
        });
      } else {
        if(!isOnBoarding){
          this.callCreateApi(data, () => {
            this.setState({
              isLoading: false
            }, () => {
              this.props.history.push({
                pathname: '/dashboard',
                state: {
                  isShouldDisableSocketToast: true
                }
              });
              if(this.state.data.order_status.status == "Completed" || this.state.data.order_status.status == "Failed") {
                apiUtil.toast('Complete or Fail Order, Nothing Change!!', 'check_circle');
              } else {
                apiUtil.toast('Successfully Created', 'check_circle');
              }
              
            });
          });
        } else {
          setOnBoardingJob(JSON.stringify(data));
          this.props.history.push('/onboarding-complete');
          apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
        }
      }
    } else {
      if (this.state.isFormChanged == false) {
        this.props.onClose();
      } else {
        this.setState({
          isLoading: false
        });
      }
    }
  }
  /* END SUBMIT */


  /* DIALOG */
  

  
  


  render() {
    return <Container fluid>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            {/* <StickyPageHeader isSmall={true}>
                {this.customStickyPageHeader()}
            </StickyPageHeader> */}
            
            <Container maxwidth="lg">
                {
                  (!this.state.isLoading && !this.state.isLoadingWarehouse)
                  ?
                  <Card className="text-center  custom-card-margin-x" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                    {this.jobDetailsForm()}
                    {this.state.currentRole != 'Customer Template Role' || (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Not Assigned") ? <>
                    <button type='button' className="upload-style-border me-2 primary-color"
                    onClick={e=>{
                      if(this.props.onClose){
                        this.props.onClose();
                      }
                    }}
                    >Cancel</button>
                    {((this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Edit')) && this.state.data.deleted_at == null &&  this.state.is_noti != true) && <button type="submit" className="upload-style text-white " 
                    disabled= {(this.state.data.vendor_id && this.state.data.vendor_id == this.state.user.owner_info.application_company_id && this.state.temp_fail_reason == '') || (this.state.currentRole != 'Super Admin Role' && (this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")) ? true : false}
                    >Save Change</button>}</>
                    : '' }
                  </Card>
                  :
                  <Card className="overflow-hidden custom-card-margin-x">
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
            </Container>
            
        </form>
    </Container>
  }
}

export default OrderDetailForm