import React, { Component } from 'react';
import moment from 'moment'
import uuid from 'react-uuid'
import _ from 'lodash';
import PreventTransitionPrompt from '../../layout/Main/PreventTransitionPrompt.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import {RoleAccessService} from '../../data/role-access.js'; 
import { ReactComponent as InfoIcon } from '../../assets/img/icons/information.svg';
import { 
  Box,
  Grid,
  Divider,
  InputLabel,
  Select,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import {Verdana} from '../../components/Fonts/Verdana.jsx'
import { Anmollipi } from '../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../components/Fonts/InterBold.jsx';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import DropdownMenu from '../../components/Dropdown/DropdownMenuFilter.jsx';
import CloseIcon from '@material-ui/icons/Close';
import jsPDF from "jspdf";
import QRCode  from "qrcode.react";
import Barcode from 'react-barcode';
import EVFYLogo from '../../assets/img/evfy-logo.png';
import location from '../../assets/img/Vector.png';
import building from '../../assets/img/building.png';
import box from '../../assets/img/box.png';
import comment from '../../assets/img/comment.png';
import person from '../../assets/img/person.png';
import time from '../../assets/img/access_time.png';
import phone from '../../assets/img/local_phone.png';
import bottomTxt from '../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as TimeIcon } from '../../assets/img/icons/time.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import RTextEditor from '../../components/RichTextEditor/RTextEditor.jsx';
import 'suneditor/dist/css/suneditor.min.css';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption.jsx';
import CustomTooltip from '../../components/Tooltip/CustomTooltip.jsx';
import CustomFormDialog from '../../components/Dialog/CustomFormDialog.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomGoogleMapSearch from '../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import DepartmentPICList from '../Jobs/Partial/DepartmentPICList.jsx';
import ItemsInJobList from '../Jobs/Partial/ItemsInJobList.jsx';
import StepsList from '../Jobs/Partial/StepsList.jsx';

import CustomerForm from '../Jobs/Partial/CustomerForm.jsx';
import DriverForm from '../Jobs/Partial/DriverForm.jsx';
import LogisticsAssistanceForm from '../Jobs/Partial/LogisticsAssistanceForm.jsx';

import Tour from 'reactour'
import axios from 'axios';
import { TimerSharp } from '@material-ui/icons';
import { remove } from 'lodash';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faFile } from '@fortawesome/free-solid-svg-icons';

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

export class ImportDetailForm extends Component {
  static contextType = AuthContext;


  constructor(props){
    super(props);

    let order = this.props.order ? this.props.order : {};
    const grantedAccess = new RoleAccessService();
    this.state = {
      order:order,
      // isLoading: false,
      // workerItems: null,
      // oldDriverItems: null,
      // customerItems: null,
      // oldCustomerItems: null,
      grantedAccess : grantedAccess,
      isLoading: false,
      tableStatus: [],
      drop_off_date: '',
      is_noti:'',
      user: JSON.parse(localStorage.getItem('user')),
      currentRole: window.localStorage.getItem("current_role") || null,
      drop_off_dateValidated: false,
      drop_off_time_planned: '',
      // drop_off_time_plannedValidated: false,
      drop_off_description: null,
      //drop_off_descriptionValidated: false,
      drop_off_address: '',
      address: [],
      cloneAddress: [],
      drop_off_addressValidated: false,
      //New feature of postal code
      // drop_off_postal_code: '',
      prev_worker_id: null,
      prev_vendor_id: null,
      zoneItems: "undefined",
      zone_id:"",
      item_tracking_number:'',
      boxes:"",
      document: "",
      pallets: "",
      cartons: "",
      drop_off_postal_code: null,
      drop_off_postal_codeValidated: false,

      company_logo: '',
      // amountValidated: false,
      latitude : 0,
      longitude : 0,
      drop_off_name: '',
      drop_off_nameValidated: false,

      drop_off_contact_name: '',
      drop_off_contact_nameValidated: false,

      drop_off_contact_no: '',
      drop_off_contact_noValidated: false,

      notification_email: '',
      notification_emailValidated: false,

      assign_type: true,
      job_type: '',
      picItems: [],
      itemsInJobList: [],
      job_steps: [],

      openDialogCustomer: false,
      itemDialogCustomer: null,
      
      openDialogDriver: false,
      itemDialogDriver: null,

      openDialogLogisticsAssistance: false,
      itemDialogLogisticsAssistance: null,

      customerItems: [],
      customerItemsIsLoading: false,
      customerItem: null,
      customerId: '',
      customerName: '',
      customerNameValidated: false,
      departmentId: '',
      vendorItems: [],
      vendorItemsIsLoading: false,
      vendorItem: null,
      vendorId: '',
      vendorName: '',
      vendorNameValidated: false,

      workerItems: [],
      workerItemsIsLoading: false,
      workerItem: null,
      workerId: '',
      workerName: '',

      manPowerItems: [],
      manPowerItemsIsLoading: false,
      manPowerItem: null,
      manPowerId: '',
      manPowerName: '',

      branchItems: [],
      order_branch_id: '',
      
      departmentItems: [],
      departmentItem: null,
      department_id: '',

      customerDetailsIsLoading: true,
      customerDetails: null,

      orderStatusesIsLoading: false,
      orderStatuses: [],
      order_status: null,
      order_status_id: 0,

      jobStepStatusesIsLoading: false,
      jobStepStatuses: [],

      isFormChanged: false,

      openDialog: false,
      openDialogItem: null,
      isLoadingWarehouse: false,
      isTourOpen: false,
      openSkipDialog: false,
      amount: '',
      driver_payout: '',
      vendor_payout: '',
      prev_amount: '',
      prev_driver_payout: '',
      prev_vendor_payout: '',
      do_number: '',
      delivery_typeValidated: false,
      package_list: [],
      selected_package: [],
      package_types: [],
      customer_rate: [],
      period_of_deliveryValidated: false,
      period_of_delivery: '',
      signatureImg: [],
      proofImg: [],
      data:'',
      order_histories: [],
      reasons_for_failure: '',
      fail_reason: "",
      order_attempts: "",
      size_l_w_h: '',
      weight: '',
      waiting_time: '',
      no_of_movers: '',
      depalletization_request: '',
      base_charge: '',
      surcharge: '',
      isAllocationStep2: '',
      collection_reference: '',
      created_by: null,
      collection_from_time: "9:00",
      collection_to_time: "18:00",
      collection_timeValidated: false,
      company: "",

      drop_off_alternate_contact : '',
      drop_off_alt_contact_noValidated: false,
      oldCustomerItems: [],
      oldDriverItems: [],
      time_window: '',
      temp_fail_reason: '',
      hideButton: false,
      is_deli_use_saved_address: this.props.is_deli_use_saved_address
    }

  }

  componentDidMount() {
    let order = this.state.order

    this.callAddressApi('', ()=> {
      order.no_of_movers = JSON.parse(order.package_info).no_of_movers
      order.package_info = JSON.parse(order.package_info)
      if (!order.amount && !order.driver_payout && !order.vendor_payout) {
        if (this.state.order.customer_id > 0) {
          let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
          order.amount = client
          order.driver_payout = payout
          order.vendor_payout = subcon
          order.base_charge = base_charge
          order.surcharge = surcharge
          this.setState({
            order
          });
        }
      }
      this.setState({
        order
      });
    });
    this.callOrderStatusesApi();
    this.callWorkerDriverReadApi('', (arr) => {
      this.setState({
        workerItems: arr.sort((a, b) => {
         const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
         const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
         if (nameA < nameB) {
           return -1;
         }
         if (nameA > nameB) {
           return 1;
         }
       
         // names must be equal
         return 0;
       }),
        oldDriverItems: arr
      });
      // this.callVendorReadApi('', (arr) => {
      //   //  console.log('vendorarr', arr)
      //    workerItems.push(arr);
      //    var result = [].concat.apply([],Object.values(workerItems));
      //    this.setState({
      //      workerItems: result.sort((a, b) => {
      //       const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
      //       const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
      //       if (nameA < nameB) {
      //         return -1;
      //       }
      //       if (nameA > nameB) {
      //         return 1;
      //       }
      //     
      //       // names must be equal
      //       return 0;
      //     }),
      //      oldDriverItems: result
      //    });
      //  });                
    });
    
    this.callCustomerReadApi(this.state.customerName, (arr) => {
      this.setState({
        customerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        }),
        oldCustomerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        })
      });
    });
    
  }
  
  callOrderStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }

  callAddressApi = (data, callback = null) => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoadingWarehouse: true,
      }, () => {
        let param = {
          sort: 'latest'
        };
  
        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                address: (data.result && data.result.length > 0) ? data.result : [],
                cloneAddress: _.cloneDeep(data.result),
                total: data.total,
                isLoadingWarehouse: false,
              });

              if(callback){
                callback();
              }
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoadingWarehouse: false,
              });
            });
        });
      });
}

  
  getTimeWindow = ( s_time = '', e_time = '' ) => {
    let start_time = moment(s_time,'hh:mm');
    let end_time = moment(e_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    let timeslot = '';
    if(diff_time > 0) {
      if(diff_time < 3){
        timeslot = 'express (2h slot)'; 
      } else if(diff_time >= 3 && diff_time <= 5) {
        timeslot = 'next day (3h slot)'; 
      }
    }      
    return timeslot;
  }

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      workerItemsIsLoading: true,
      workerItems: [],
    }, () => {
      let param = {
        disabled: false,
        is_active: true,
        search: search ? search.trim() : search,
        is_truck_driver: true,
        // require_worker_only: true,
        light_data: true
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              if(callback){
                callback(arr);
              }
              this.setState({
                workerItemsIsLoading: false,
              })
            } else {
              if(callback){
                callback([]);
              }
              this.setState({
                workerItemsIsLoading: false,
              })
            }
          }, (error, type) => {
            if(callback){
              callback([]);
            }
            this.setState({
              workerItemsIsLoading: false,
            })
          });
      });
    });
  }

  callWorkerManPowerReadApi = (search = '', callback = null) => {
    /*const { accessToken } = this.context;

    this.setState({
      manPowerItemsIsLoading: true,
      manPowerItems: [],
    }, () => {
      let param = {
        page: 1,
        take: apiUtil.getDefaultPageSize(),
        is_active: true,
        search: search ? search.trim() : search,
        is_man_power: true,
        require_worker_only: true,
      };

      api.read('workers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.id;
                let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                manPowerItemsIsLoading: false,
                manPowerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              manPowerItemsIsLoading: false,
              manPowerItems: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });*/
  }
  /* END WORKER API */

  /* VENDOR API */
  callVendorReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      vendorItemsIsLoading: true,
      vendorItems: [],
    }, () => {
      let param = {
        // page: 1,
        // take: apiUtil.getDefaultPageSize(),
        is_active: true,
      };

      api.read('vendors/active_vendor', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : [];

            let arr = [];
            if(result && result.length > 0){
              arr = result.map((item, i) => {
                let value = item.company_id;
                let label = item.company_name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              // this.setState({
              //   vendorItemsIsLoading: false,
              //   vendorItems: arr,
              // }, () => {
                if(callback){
                  callback(arr);
                }
              // });
            } else {
              // this.setState({
              //   vendorItemsIsLoading: false,
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            }
          }, (error, type) => {
            // this.setState({
            //   vendorItemsIsLoading: false,
            //   vendorItems: [],
            // }, () => {
              if(callback){
                callback([]);
              }
            // });
          });
      });
    });
  }
  /* END VENDOR API */


  /* CUSTOMER API */
  callCustomerReadApi = (search = '', callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState({
      customerItemsIsLoading: true,
      customerItems: [],
    }, () => {

      if(!isOnBoarding){
        let param = {
          page: 1,
          // take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          light_data_customer_rate: true
        };
  
        wmsApi.read('customers', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              let customer_rate = []
              if(result && result.length > 0){
                for(let i = 0; i < result.length; i++){
                  let item = result[i];
                  
                  let value = item.id;
                  let label = item.company_name;

                  if (item.company_name == this.state.customerName) {
                    customer_rate = item.customer_rates ? item.customer_rates : []
                  }

  
                  if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                      value: value,
                      label: label,
                      item: item
                    });
                  }
                }
                
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: arr.sort((a, b) => {
                    const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  }),
                  customer_rate: customer_rate.length > 0 ? customer_rate : this.state.customer_rate
                }, () => {
                  if(callback){
                    callback(arr);
                  }
                });

              } else {
                this.setState({
                  customerItemsIsLoading: false,
                  customerItems: [],
                }, () => {
                  if(callback){
                    callback([]);
                  }
                });
              }
            }, (error, type) => {
              this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            });
        });
      } else {
        let jsonCustomer = null;
        try {
            jsonCustomer = JSON.parse(onBoardingCustomer);
        } catch(e){}

        let customer = null;
        if(jsonCustomer){
          customer = {
            value: jsonCustomer.id,
            label: jsonCustomer.company_name,
            item: jsonCustomer
          }
        }

        let arr = [];
        if(customer){
          arr.push(customer);

          this.setState({
            customerItemsIsLoading: false,
            customerItems: arr.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            }),
          }, () => {
            if(callback){
              callback(arr);
            }
          });
        } else {
          this.setState({
            customerItemsIsLoading: false,
            customerItems: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        }
      }
    });
  }

  /* FORM */
  jobDetailsForm = () => {
    let description = this.state.order.drop_off_description != '' ? this.state.drop_off_description : '';
    // console.log('userinfo',this.state.user);
    return <Container fluid>
    <Row className="p-4">
      <Col xs={12} md={6} lg={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Ordered By</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            readOnly={true}
            type={"text"}
            color={'secondary'}
            value={this.state.currentRole != 'Customer Template Role' ? this.state.user?.owner_info?.application_company?.company_name : this.state.user?.owner_info?.first_name+' '+this.state.user?.owner_info?.last_name}
        />
        </div>
      </Col>
      <Col xs={12} md={6} lg={6}></Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Client Company</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomAutosuggest 
          placeholder={'Enter any keyword to search for customers'}
          labelComponent={<CustomTooltip title={'Select from your list of customers. Create one if u dont have them in your list.'} />}
          items={this.state.customerItems}
          isLoading={this.state.customerItemsIsLoading}
          value={this.state.order.company_name}
          disabled={this.state.currentRole == "Customer Template Role" ? true : false}
          onChange={(value) => {
            if(value){
                let order = this.state.order
                order.customer_id = value.value
                order.company_name = value.label
                order.customer_item = value.item
                order.department_id = ''
                order.departments = []
                order.departments = value.item.departments
                order.customer_rate = value.item.customer_rates && value.item.customer_rates.length > 0 ? value.item.customer_rates : []
                this.setState({
                  order,
                  customerItem: value.item,
                }, () => {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                    let order = this.state.order
                    order.amount = client
                    order.driver_payout = payout
                    order.vendor_payout = subcon
                    order.base_charge = base_charge
                    order.surcharge = surcharge
                    this.setState({
                      order
                    });
                });
            } else {
              let order = this.state.order
                order.customer_id = ''
                order.company_name = ''
                order.customer_item = ''
                order.department_id = ''
                order.customer_rate = []
                order.amount = ''
                order.driver_payout = ''
                order.vendor_payout = ''
                order.base_charge = ''
                order.surcharge = ''
              this.setState({
                order
              });
            }
          }}
          onSearch={(value, e) => {
            let order = this.state.order
            order.customer_id = value != ''? this.state.order.customer_id : ''
            order.company_name = value != ''? value : ''
            order.customer_item = value != ''? this.state.order.customer_item : ''
            order.department_id = value != ''? this.state.order.department_id : ''
            order.customer_rate = value != ''? this.state.order.customer_rate : []
            order.amount = value != ''? this.state.order.amount : ''
            order.driver_payout = value != ''? this.state.order.driver_payout : ''
            order.vendor_payout = value != ''? this.state.order.vendor_payout : ''
            order.base_charge = value != ''? this.state.order.base_charge : ''
            order.surcharge = value != ''? this.state.order.surcharge : ''
            this.setState({
              order
            }, () => {
              e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
            });
          }}
          onClick={(value, e) => {
            e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
          }}
          />
        </div>
      </Col>
      
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Trip Date*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
          placeholder={'DD/MM/YYYY'}
          color={'secondary'}
          type={"date"}
          startAdornment={(obj) => {
            return <Box
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CalendarIcon />
            </Box>
          }}
          value={this.state.order.drop_off_date}
          error={this.state.order.drop_off_date_validate}
          required={true}
          onChange={(e) => {
            let order = this.state.order
            order.drop_off_date = e.target.value
            order.drop_off_date_validate = (e.target.value !== '') ? false : true
              this.setState({
               order
              }, ()=>{
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
          }}
      />
        
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Department</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="max-xs-width-control-900 custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Select Department here...'}
          value={this.state.order.department_id}
          native
          disableUnderline
          onChange={(e) => {
            let order = this.state.order
            order.department_id = e.target.value  
            let department_name = this.state.order.departments.filter(dd => dd.id == e.target.value)            
            order.department_name = department_name.length > 0 ? department_name[0]?.name : order.department_name       
            this.setState({
                order
            }, ()=> {
              // console.log('order order order', order)
            })
          }}
        >
          <option value=""> Select Department </option>
          {this.state.order && this.state.order.departments && this.state.order.departments.map((dd, index)=> 
            <option value={dd.id}>{dd.name}</option>
          )}
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
            <div className="max-xs-width-control-900 text-end-style w-50">
              Time Window*
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50 d-flex">
              <Select
                className="select-width-control-100 custom-input-component padding-right-select"
                error={this.state.time_window_validate}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.order.drop_off_from_time}
                native
                disableUnderline
                onChange={(e) => {
                  let order = this.state.order
                  order.drop_off_from_time = e.target.value      
                  this.setState(
                    {
                     order
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.order.drop_off_from_time,this.state.order.drop_off_to_time);
                      let order = this.state.order
                      order.time_window = timeslot
                      this.setState({
                       order
                      }, () => {
                        if (this.state.order.customer_id > 0) {
                          let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                          let order = this.state.order
                          order.amount = client
                          order.driver_payout = payout
                          order.vendor_payout = subcon
                          order.base_charge = base_charge
                          order.surcharge = surcharge
                          this.setState({
                            order
                          });
                        }
                      });
                    }
                  );
                }}
              >
                 <option value=""> Select Time </option>
                 <option value="0:00"> 0:00 </option>
                  <option value="0:30"> 0:30 </option>
                  <option value="1:00"> 1:00 </option>
                  <option value="1:30"> 1:30 </option>
                  <option value="2:00"> 2:00 </option>
                  <option value="2:30"> 2:30 </option>
                  <option value="3:00"> 3:00 </option>
                  <option value="3:30"> 3:30 </option>
                  <option value="4:00"> 4:00 </option>
                  <option value="4:30"> 4:30 </option>
                  <option value="5:00"> 5:00 </option>
                  <option value="5:30"> 5:30 </option>
                  <option value="6:00"> 6:00 </option>
                  <option value="6:30"> 6:30 </option>
                  <option value="7:00"> 7:00 </option>
                  <option value="7:30"> 7:30 </option>
                  <option value="8:00"> 8:00 </option>
                  <option value="8:30"> 8:30 </option>
                  <option value="9:00"> 9:00 </option>
                  <option value="9:30"> 9:30</option>
                  <option value="10:00"> 10:00 </option>
                  <option value="10:30"> 10:30 </option>
                  <option value="11:00"> 11:00 </option>
                  <option value="11:30"> 11:30 </option>
                  <option value="12:00"> 12:00 </option>
                  <option value="12:30"> 12:30 </option>
                  <option value="13:00"> 13:00 </option>
                  <option value="13:30"> 13:30 </option>
                  <option value="14:00"> 14:00 </option>
                  <option value="14:30"> 14:30 </option>
                  <option value="15:00"> 15:00 </option>
                  <option value="15:30"> 15:30 </option>
                  <option value="16:00"> 16:00 </option>
                  <option value="16:30"> 16:30 </option>
                  <option value="17:00"> 17:00 </option>
                  <option value="17:30"> 17:30 </option>
                  <option value="18:00"> 18:00 </option>
                  <option value="18:30"> 18:30 </option>
                  <option value="19:00"> 19:00 </option>
                  <option value="19:30"> 19:30 </option>
                  <option value="20:00"> 20:00 </option>
                  <option value="20:30"> 20:30 </option>
                  <option value="21:00"> 21:00 </option>
                  <option value="21:30"> 21:30 </option>
                  <option value="22:00"> 22:00 </option>
                  <option value="22:30"> 22:30 </option>
                  <option value="23:00"> 23:00 </option>
                  <option value="23:30"> 23:30 </option>
              </Select>
              <div className="text-end-style m-2">To</div>
              <Select
                className="select-width-control-100 custom-input-component  padding-right-select"
                error={this.state.order.time_window_validate}
                style={{
                  width: "100%",
                  border: "1px solid #E0E1E4",
                  borderRadius: "4px",
                  padding: "",
                  backgroundColor: "transparent",
                }}
                placeholder={"From"}
                value={this.state.order.drop_off_to_time}
                native
                disableUnderline
                onChange={(e) => {
                  let order = this.state.order
                  order.drop_off_to_time = e.target.value      
                  this.setState(
                    {
                     order
                    },
                    () => {
                      let timeslot = this.getTimeWindow(this.state.order.drop_off_from_time,this.state.order.drop_off_to_time);
                      let order = this.state.order
                      order.time_window = timeslot
                      this.setState({
                       order
                      }, () => {
                        if (this.state.order.customer_id > 0) {
                          let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                          let order = this.state.order
                          order.amount = client
                          order.driver_payout = payout
                          order.vendor_payout = subcon
                          order.base_charge = base_charge
                          order.surcharge = surcharge
                          this.setState({
                            order
                          });
                        }
                      });
                    }
                  );
                }}
              >
                 <option value=""> Select Time </option>
                 <option value="0:00"> 0:00 </option>
                  <option value="0:30"> 0:30 </option>
                  <option value="1:00"> 1:00 </option>
                  <option value="1:30"> 1:30 </option>
                  <option value="2:00"> 2:00 </option>
                  <option value="2:30"> 2:30 </option>
                  <option value="3:00"> 3:00 </option>
                  <option value="3:30"> 3:30 </option>
                  <option value="4:00"> 4:00 </option>
                  <option value="4:30"> 4:30 </option>
                  <option value="5:00"> 5:00 </option>
                  <option value="5:30"> 5:30 </option>
                  <option value="6:00"> 6:00 </option>
                  <option value="6:30"> 6:30 </option>
                  <option value="7:00"> 7:00 </option>
                  <option value="7:30"> 7:30 </option>
                  <option value="8:00"> 8:00 </option>
                  <option value="8:30"> 8:30 </option>
                  <option value="9:00"> 9:00 </option>
                  <option value="9:30"> 9:30</option>
                  <option value="10:00"> 10:00 </option>
                  <option value="10:30"> 10:30 </option>
                  <option value="11:00"> 11:00 </option>
                  <option value="11:30"> 11:30 </option>
                  <option value="12:00"> 12:00 </option>
                  <option value="12:30"> 12:30 </option>
                  <option value="13:00"> 13:00 </option>
                  <option value="13:30"> 13:30 </option>
                  <option value="14:00"> 14:00 </option>
                  <option value="14:30"> 14:30 </option>
                  <option value="15:00"> 15:00 </option>
                  <option value="15:30"> 15:30 </option>
                  <option value="16:00"> 16:00 </option>
                  <option value="16:30"> 16:30 </option>
                  <option value="17:00"> 17:00 </option>
                  <option value="17:30"> 17:30 </option>
                  <option value="18:00"> 18:00 </option>
                  <option value="18:30"> 18:30 </option>
                  <option value="19:00"> 19:00 </option>
                  <option value="19:30"> 19:30 </option>
                  <option value="20:00"> 20:00 </option>
                  <option value="20:30"> 20:30 </option>
                  <option value="21:00"> 21:00 </option>
                  <option value="21:30"> 21:30 </option>
                  <option value="22:00"> 22:00 </option>
                  <option value="22:30"> 22:30 </option>
                  <option value="23:00"> 23:00 </option>
                  <option value="23:30"> 23:30 </option>
              </Select>
            </div>
          </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Rate*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:""}}
          placeholder={'Enter Job Type here...'}
          value={this.state.order.is_project}
          native
          required={true}
          disableUnderline
          onChange={(e) => {
            let order = this.state.order
            order.is_project = e.target.value      
            this.setState({
               order
            },() =>{
              if (this.state.order.customer_id > 0) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                let order = this.state.order
                order.amount = client
                order.driver_payout = payout
                order.vendor_payout = subcon
                order.base_charge = base_charge
                order.surcharge = surcharge
                this.setState({
                  order
                });
              }
            });
        }}
        >
          <option value=""> Select Job Type </option>
          <option value={false}>Standard</option>
          <option value={true}>Project</option>
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Category*</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Enter Job Type here...'}
          value={this.state.order.period_of_delivery}
          native
          required={true}
          error={this.state.order.period_of_delivery_validate}
          disableUnderline
          onChange={(e) => {
            let order = this.state.order
            order.period_of_delivery = e.target.value     
            this.setState({
                order
            },() =>{
              if (this.state.order.customer_id > 0) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                let order = this.state.order
                order.amount = client
                order.driver_payout = payout
                order.vendor_payout = subcon
                order.base_charge = base_charge
                order.surcharge = surcharge
                this.setState({
                  order
                });
              }
            });
        }}
        >
          <option value=""> Select Period of Delivery </option>
          <option value={'Same Day'}>Same Day</option>
          <option value={'Next Day'}>Next Day</option>
          
        </Select>
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Pick Up Ref (D.O)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Pick Up Ref (D.O)'}
            style={{whiteSpace : 'pre'}}
            type={'text'}
            value={this.state.order.collection_reference}
            onChange={(e) => {
              let order = this.state.order
              order.collection_reference = e.target.value     
                this.setState({
                   order
                });
            }}
        />
        </div>
      </Col>
      
      <Col xs={12} md={6} lg={6}></Col>
      <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end pe-1 col-3">D.O Number</div>
        <div className="max-xs-width-control-900 col-9">
        <CustomInput
            placeholder={'D.O Number'}
            type={'text'}
            style={{whiteSpace : 'pre'}}
            value={this.state.order.do_number}
            onChange={(e) => {
              let order = this.state.order
              order.do_number = e.target.value     
                this.setState({
                   order
                });
            }}
        />
        </div>
      </Col>
    </Row>
    <Row className="p-4">
    <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end pe-1 col-3">Address*</div>
        <div className="max-xs-width-control-900 col-9">
        <CustomGoogleMapSearch
          placeholder={'Address'}
          value={this.state.order.drop_off_address}
          required={true}
          onBlur={() => {
            this.deliveryAddressOnChangeHandler(this.state.order.drop_off_address)   
            if (this.state.order.customer_id > 0) {
              let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
              let order = this.state.order
              order.amount = client
              order.driver_payout = payout
              order.vendor_payout = subcon
              order.base_charge = base_charge
              order.surcharge = surcharge
              this.setState({
                order
              });
            }
          }}
          onChange={(e) => {     
                                                 
              let order = this.state.order
              order.drop_off_address = e.target.value.trim().length === 0 ? '' : e.target.value;
              order.latitude = ''
              order.longitude = ''
              this.setState({
                  order
              });
            
          }}
          onPlacesChanged={(place, e) => {
            if(this.state.currentRole == 'Super Admin Role' || !(this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Completed" || this.state.data && this.state.data.order_status && this.state.data.order_status.status == "Failed")){ 
            let address = apiUtil.getGmapAddress(place);
            let order = this.state.order
              order.drop_off_address = address;
              order.latitude = place.geometry.location.lat();
              order.longitude = place.geometry.location.lng();
              this.setState({
                order
              });
              this.deliveryAddressOnChangeHandler(address)
              if (this.state.order.customer_id > 0) {
                let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                let order = this.state.order
                order.amount = client
                order.driver_payout = payout
                order.vendor_payout = subcon
                order.base_charge = base_charge
                order.surcharge = surcharge
                this.setState({
                  order
                });
              }
            }
          }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Driver-Partner</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomAutosuggest
          placeholder={'Enter any keyword to search for Driver'}
          items={this.state.workerItems}
          isLoading={this.state.workerItemsIsLoading}
          disabled={this.state.currentRole == "Customer Template Role" ? true : false}
          value={this.state.order.vendor_name != '' ? this.state.order.vendor_name : this.state.order.drop_off_worker_name}
          onSelect={(event) => {
            event.preventDefault();
          }}
          onChange={(value) => {
            if (value) {
                if (typeof(value.item.application_company_id) != 'undefined') {
                  let order = this.state.order
                  order.drop_off_worker_name = value.label
                  order.drop_off_worker_id = value.value
                  order.workerItem = value.item
                  order.assign_type = true
                  order.vendor_id = null
                  order.vendor_name = ''
                    this.setState({
                      order
                    }, () => {
                      let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned');
                      if(orderStatusItem){
                        let order = this.state.order
                        order.order_status = orderStatusItem.item
                        order.order_status_id = orderStatusItem.value
                        this.setState({
                          order
                        });
                      }
                    });                
                } else if(typeof(value.item.company_id) != 'undefined') {
                  let order = this.state.order
                  order.assign_type = false
                  order.vendor_id = value.value
                  order.vendor_name = value.label
                  order.vendorItem = value.item
                  this.setState({
                   order
                  }, ()=> {
                    let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'assigned to vendor');
                      if(orderStatusItem){
                        let order = this.state.order
                        order.order_status = orderStatusItem.item
                        order.order_status_id = orderStatusItem.value
                        this.setState({
                          order
                        });
                      }
                  });
                 
                }
            } else {
              let order = this.state.order;
              order.assign_type = true
              order.drop_off_worker_name = ''
              order.drop_off_worker_id = null
              order.workerItem = ''
              order.vendor_name = ''
              order.vendor_id = ''
              order.vendorItem = ''
              this.setState({
                order
              }, () => {
                let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                if(orderStatusItem){
                  let order = this.state.order;
                  order.order_status = orderStatusItem.item
                  order.order_status_id = orderStatusItem.value
                  this.setState({
                   order
                  });
                }
                // if (this.state.data && this.state.data.order_status && this.state.data.order_status.status == 'Cancelled') {
                //   let {
                //     client,
                //     payout,
                //     subcon,
                //     base_charge,
                //     surcharge,
                //   } = this.state.grantedAccess.getPriceAndDriverPayout(
                //     this.state.selected_package,
                //     this.state.customer_rate,
                //     this.state.is_project,
                //     this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                //     this.state.drop_off_date,
                //     this.state.drop_off_postal_code,
                //     this.state.job_type, 
                //     this.state.drop_off_address, 
                //     this.state.address,
                //     this.state.created_by
                //   );
                //   this.setState({
                //     amount: client,
                //     driver_payout: payout,
                //     vendor_payout: subcon,
                //     base_charge: base_charge,
                //     surcharge: surcharge
                //   });
                // } else {
                //     let {
                //       client,
                //       payout,
                //       subcon,
                //       base_charge,
                //       surcharge,
                //     } = this.state.grantedAccess.getPriceAndDriverPayout(
                //       this.state.selected_package,
                //       this.state.customer_rate,
                //       this.state.is_project,
                //       this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                //       this.state.drop_off_date,
                //       this.state.drop_off_postal_code,
                //       this.state.job_type, 
                //       this.state.drop_off_address, 
                //       this.state.address,
                //       this.state.created_by
                //     );
                //     this.setState({
                //       base_charge: base_charge,
                //       surcharge: surcharge
                //     });
                // }
              });
            }
          }}
          onSearch={(value, e) => {
            let order = this.state.order;
            order.assign_type = true
            order.drop_off_worker_name = value
            order.drop_off_worker_id = null
            order.workerItem = ''
            order.vendor_name = value
            order.vendor_id = ''
            order.vendorItem = ''
            this.setState({
              order
            }, () => {
               if(value == '') {
               let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'label', 'not assigned');
                if(orderStatusItem){
                  let order = this.state.order;
                  order.order_status = orderStatusItem.item
                  order.order_status_id = orderStatusItem.value
                  this.setState({
                  order
                  });
                }  
               }
               e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
            })
             
          }}
          onClick={(value, e) => {
            e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
          }}
        /> 
        
        </div>
      </Col> 
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Postal Code</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Postal Code'}
            
            value ={this.state.order.drop_off_postal_code}
            error ={this.state.order.drop_off_postal_code_validate}
            type={'number'}
            labelComponent={<CustomTooltip title={'Postal Code'} />}
            onPaste = {(e) => {
              if(/^[0-9\b]+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }               
            }}
            onInput = {(e) =>{
                e.target.value =  e.target.value.toString().slice(0,6)
            }}
            onChange={(e) => {
              if(/^[0-9\b]+$/.test(e.target.value) || e.target.value == null || e.target.value == '') {
                let order = this.state.order
                order.drop_off_postal_code = e.target.value
                this.setState({
                    order
                },()=> {
                  if (this.state.order.customer_id > 0) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                    let order = this.state.order
                    order.amount = client
                    order.driver_payout = payout
                    order.vendor_payout = subcon
                    order.base_charge = base_charge
                    order.surcharge = surcharge
                    this.setState({
                      order
                    });
                  }
                });
              } else {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              }
            }}
        />
        </div>
      </Col>
     
     
    </Row>
    <Row className="p-4">
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Name</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            
            placeholder={'Recipient Name'}
            value={this.state.order.drop_off_contact_name}
            onChange={(e) => {
                let order = this.state.order
                order.drop_off_contact_name = e.target.value
                this.setState({
                  order
                });
            }}
        />
        </div>
      </Col>
       <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
            <div className="max-xs-width-control-900 text-end w-50">
              Recipient Company
            </div>
            <div className="max-xs-width-control-900 ps-2 w-50">
              <CustomInput
                placeholder={"Company"}
                
                type={"text"}
                value={this.state.order.drop_off_name}
                onChange={(e) => {
                  let order = this.state.order
                  order.drop_off_name = e.target.value
                  this.setState({
                    order
                  });
                }}
              />
            </div>
          </Col>

      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Contact</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Recipient Contact'}
            value={this.state.order.drop_off_contact_no}
            onChange={(e) => { 
              let order = this.state.order
              order.drop_off_contact_no =e.target.value.replaceAll(/\s/g, '')
                this.setState({
                  order
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Recipient Email</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Recipient Email'}
            type={'email'}
            value={this.state.order.notification_email}
            onChange={(e) => {
                let order = this.state.order
                order.notification_email =e.target.value
                this.setState({
                    order
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Alternative Contact</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Alternative Contact'}
            value={this.state.order.drop_off_alternate_contact}
            onChange={(e) => {
              let order = this.state.order
              order.drop_off_alternate_contact =e.target.value
              this.setState({
                  order
              });
          }}
        />
        </div>
      </Col>
     
    </Row>
    <Row className="p-4" style={{pointerEvents: 'auto' }}>
    <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
      <div className="max-xs-width-control-900 w-25 text-end">Item Description</div>
      <div className="max-xs-width-control-900 ps-2 w-75">
        <RTextEditor
        editorState={this.state.order.drop_off_description}
        value={this.state.order.drop_off_description}
        placeholder={'Enter your description here...'}
        onChange={(editorState) => { 
          let order = this.state.order
          order.drop_off_description = editorState
            this.setState({
                order
            });
        }}
      />  
      </div>
      </Col>
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <80cm / 1KG">
            XS Packages <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
              placeholder={'5'}
              
              type={'number'}
              inputProps={{min: 0}}
              pattern="^-?[0-9]\d*\.?\d*$"
              value={this.state.order.xs_packages}
              onPaste={(e) => {
                if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                  e.preventDefault()
                  return false;
                }
              }}
              onKeyDown={(e) => {
                if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
              
                let selected_package = this.state.order.selected_package
                let index = selected_package.findIndex(dd => dd.package_id === 1);
                if (e.target.value >= 1 && e.target.value !== '') {
                   if(index === -1) {
                    let pack = {
                      package_id: 1,
                      name: 'XS',
                      package_quantity: e.target.value
                    }
                    selected_package.push(pack)
                   } else {
                      selected_package[index]["package_quantity"] = e.target.value
                      delete selected_package[index]["delete"] 
                   }
                } else {
                  if (index !== -1 && selected_package[index]["id"] > 0 ) {
                    selected_package[index]["delete"] = true                   
                  }      
                  if(selected_package[index]) {
                    selected_package[index]["package_quantity"] = '0'     
                  }                  
                }
                
                  let order = this.state.order
                  order.xs_packages = e.target.value
                  order.selected_package = selected_package
                  this.setState({
                      order
                  }, () => {
                    if (this.state.order.customer_id > 0) {
                      let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                      let order = this.state.order
                      order.amount = client
                      order.driver_payout = payout
                      order.vendor_payout = subcon
                      order.base_charge = base_charge
                      order.surcharge = surcharge
                      this.setState({
                        order
                      });
                    }
                  });
              }}
          />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <120cm / 5KG">
            S Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'5'}
            
            type={'number'}
            inputProps={{min: 0}}
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.order.s_packages}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
             
              let selected_package = this.state.order.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 2);
              if (e.target.value >= 1 && e.target.value !== '') {
                if(index === -1) {
                  let pack = {
                    package_id: 2,
                    name: 'S',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                
                }
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
                
              let order = this.state.order
              order.s_packages = e.target.value
              order.selected_package = selected_package
              this.setState({
                  order
              }, () => {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <150cm / 15KG">
            M Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.order.m_packages}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.order.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 3);
              if (e.target.value >= 1 && e.target.value !==  '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 3,
                    name: 'M',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                 
                } 
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
                
              let order = this.state.order
              order.m_packages = e.target.value
              order.selected_package = selected_package
              this.setState({
                  order
              }, () => {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Size limit (L+W+H) <180cm / 25KG">
            L Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.order.l_packages}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.order.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 4);
              if (e.target.value >= 1 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 4,
                    name: 'L',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true                 
                } 
                if(selected_package[index]) {
                  selected_package[index]["package_quantity"] = '0'
                }
              }
              let order = this.state.order
              order.l_packages = e.target.value
              order.selected_package = selected_package
              this.setState({
                  order
              }, () => {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by, false, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="Please check with Ops">
            Oversized Packages  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
          <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.order.oversized_packages}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.order.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 6);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 6,
                    name: 'Oversized Package',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 6)
                }
                 
              }
                
              let order = this.state.order
              order.oversized_packages = e.target.value
              order.selected_package = selected_package
              this.setState({
                  order
              }, () => {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
            }}
        /></div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">
          <span data-tooltip="LxWxH (1.1M x 1.2M x 1.5M) / 100KG">
            Pallets  <InfoIcon />
          </span>
        </div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'3'}
            type={'number'}
            inputProps={{min: 0}}
            
            pattern="^-?[0-9]\d*\.?\d*$"
            value={this.state.order.pallets}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let selected_package = this.state.order.selected_package
              let index = selected_package.findIndex(dd => dd.package_id === 5);
              if (e.target.value > 0 && e.target.value !== '' ) {
                if(index === -1) {
                  let pack = {
                    package_id: 5,
                    name: 'Pallets',
                    package_quantity: e.target.value
                  }
                  selected_package.push(pack)
                 } else {
                  selected_package[index]["package_quantity"] = e.target.value
                  delete selected_package[index]["delete"] 
                 }
              } else {
                if (index !== -1 && selected_package[index]["id"] > 0 ) {
                  selected_package[index]["delete"] = true
                } else {
                  selected_package = selected_package.filter(pp => pp.package_id !== 5)
                }
                 
              }
                
              let order = this.state.order
              order.pallets = e.target.value
              order.selected_package = selected_package
              this.setState({
                  order
              }, () => {
                if (this.state.order.customer_id > 0) {
                  let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                  let order = this.state.order
                  order.amount = client
                  order.driver_payout = payout
                  order.vendor_payout = subcon
                  order.base_charge = base_charge
                  order.surcharge = surcharge
                  this.setState({
                    order
                  });
                }
              });
            }}
        />
        </div>
      </Col>
    </Row>
    <Row className="p-4">
    <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Depalletization Request</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <Select className="custom-input-component" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
          placeholder={'Depalletization Request'}
          value={this.state.order.depalletization_request}
          native
          disableUnderline
          onChange={(e) => {
            let order = this.state.order
            order.depalletization_request = e.target.value
              this.setState({
                  order
              });
          }}
        >
          <option value=""> Select Depalletization Request </option>
          <option value={'Y'}>Yes</option>
          <option value={'N'}>No</option>
          
        </Select>
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Size in cm<br />(L + W + H)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Size'}
            type={'text'}
            value={this.state.order.size_l_w_h}
            // onPaste={(e) => {
            //   if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
            //     e.preventDefault()
            //     return false;
            //   }
            // }}
            // onKeyDown={(e) => {
            //   if(e.key == "e" || e.key == "E" || e.key == "-") {
            //     e.preventDefault();
            //   }
            // }}
            onChange={(e) => {
              let order = this.state.order
              order.size_l_w_h = e.target.value
                this.setState({
                    order
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Weight (KG)</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Weight'}
            type={'text'}
            value={this.state.order.weight}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let order = this.state.order
              order.weight = e.target.value
                this.setState({
                    order
                });
            }}
        />
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Waiting Time</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'Waiting Time'}
            type={'text'}
            disabled={this.state.currentRole == "Customer Template Role" ? true : false}
            value={this.state.order.waiting_time}
            onChange={(e) => {
              let order = this.state.order
              order.waiting_time = e.target.value
                this.setState({
                    order
                });
            }}
        />
        
        </div>
      </Col>
      <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Additional Manpower</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'No, movers'}
            type={'number'}
            value={this.state.order.no_of_movers}
            onPaste={(e) => {
              if(/^\d+$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              let order = this.state.order
              order.no_of_movers = e.target.value
                this.setState({
                    order
                }, () => {
                  if (this.state.order.customer_id > 0) {
                    let {client, payout, subcon, base_charge, surcharge} = this.state.grantedAccess.getPriceAndDriverPayout(this.state.order.selected_package, this.state.order.customer_rate, this.state.order.is_project, this.state.order.time_window != '' ? this.state.order.time_window : this.state.order.period_of_delivery, this.state.order.drop_off_date, this.state.order.drop_off_postal_code,this.state.order.job_type, this.state.order.drop_off_address, this.state.address, this.state.created_by,this.state.is_deli_use_saved_address, this.state.order.drop_off_latitude, this.state.order.drop_off_longitude, this.state.order.no_of_movers)
                    let order = this.state.order
                    order.amount = client
                    order.driver_payout = payout
                    order.vendor_payout = subcon
                    order.base_charge = base_charge
                    order.surcharge = surcharge
                    this.setState({
                      order
                    });
                  }
                });
            }}
        />
        </div>
      </Col>
    </Row>
    <Row className="p-4">
      <Col xs={12} md={6} lg={4} className="max-xs-width-control-900 d-flex py-2 align-items-center">
        <div className="max-xs-width-control-900 text-end w-50">Price</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'0.00'}
            type={'number'}
            startAdornment={<p>$</p>}
            value={this.state.order.amount}
            disabled={this.state.currentRole == "Customer Template Role" ? true : false}
            onPaste={(e) => {
              if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
              }
              let order = this.state.order
              order.amount = e.target.value
                this.setState({
                    order
                });
            }}
        />
        </div>
      </Col>
      {this.state.currentRole == "Customer Template Role" ? "" : <><Col xs={12} md={6} lg={4} className="max-xs-width-control-900  d-flex py-2 align-items-center" >
        <div className="max-xs-width-control-900  text-end w-50">Driver Payout</div>
        <div className="max-xs-width-control-900  ps-2 w-50">
        <CustomInput
            placeholder={'Payout'}
            value={this.state.order.driver_payout}
            type={'number'}
            min={0}
            startAdornment={<p>$</p>}
            onPaste={(e) => {
              if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
              }
              let order = this.state.order
              order.driver_payout = e.target.value
                this.setState({
                    order
                });
            }}
        /> 
        </div>
      </Col>
      <Col xs={12} md={6} lg={4} className="max-xs-width-control-900 d-flex py-2 align-items-center" >
        <div className="max-xs-width-control-900 text-end w-50">Vendor Payout</div>
        <div className="max-xs-width-control-900 ps-2 w-50">
        <CustomInput
            placeholder={'0.00'}
            type={'number'}
            startAdornment={<p>$</p>}
            value={this.state.order.vendor_payout}
            onPaste={(e) => {
              if(/^\d+(\.\d{1,5})?$/.test(e.clipboardData.getData('Text')) == false ){
                e.preventDefault()
                return false;
              }
            }}
            onKeyDown={(e) => {
              if(e.key == "e" || e.key == "E" || e.key == "-") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
              }
              let order = this.state.order
              order.vendor_payout = e.target.value
                this.setState({
                    order
                });
            }}
        />
        </div>
      </Col></> }
    </Row>
    <Row className="p-4">
      <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
      <div className="max-xs-width-control-900 text-start w-25 text-end">Notes to Driver-Partner</div>
      <div className="max-xs-width-control-900 ps-2 w-75">
      <CustomInput
          placeholder={'Enter driver notes here...'}
          value={this.state.order.driver_notes}
          multiline={true}
          rows={5}
          onChange={(e) => {
            let order = this.state.order
            order.driver_notes = e.target.value
              this.setState({
                  order
              });
          }}
      />
      
      </div>
      </Col>
    </Row>
</Container>
  }
  
  // ON CHANGE JOBSETP WHEN DELIVERY ADDRESS CHANGED
  deliveryAddressOnChangeHandler = (dropoff_address) => {
    /* STEPS SECTION */
    let orderStatusItem = apiUtil.customFilter(this.state.jobStepStatuses, 'label', 'Pending');
    let job_step_status = (orderStatusItem) ? orderStatusItem : null;
    let job_step_pic = '';
    let job_step_pic_contact = '';
    let picItem = (this.state.picItems && this.state.picItems.length > 0) ? this.state.picItems[0] : null;
    if(picItem){
      job_step_pic = picItem.drop_off_name;
      job_step_pic_contact = picItem.drop_off_contact_no;
    }

    // let job_steps = this.state.job_steps;
    // if(job_steps && job_steps.length > 0){
    //   job_steps = job_steps.map((item, i) => {
    //     item.remove = true;
    //     console.log(i)
    //     return item;
    //   });
    // }

    let step = {
      uuid: uuid(),
      job_step_name: this.state.job_type ? this.state.job_type : 'Delivery',
      is_signature_required: true,
      is_scan_required: false,
      description: '',
      order_sequence: 1,
      remove: false,
      job_step_status_id: (job_step_status && job_step_status.value && job_step_status.value !== '' )? job_step_status.value : 1,
      job_step_status: job_step_status,
      location: dropoff_address,
      latitude: 0,
      longitude: 0,
      job_step_pic: job_step_pic ? job_step_pic : this.state.drop_off_contact_name,
      job_step_pic_contact: job_step_pic_contact ? job_step_pic_contact : this.state.drop_off_contact_no
    };


    let job_steps = this.state.job_steps;
    job_steps = job_steps.filter(st => st.remove === false);

  }

  render() {
    return <Container fluid>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>

            <Container maxwidth="lg">
                {
                  (!this.state.isLoading)
                  ?
                  <Card className="text-center custom-card-margin-x" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                    {this.jobDetailsForm()}
                    <button type='button' className="upload-style-border me-2 primary-color"
                    onClick={e=>{
                      if(this.props.onClose){
                        this.props.onClose();
                      }
                    }}
                    >Cancel</button>
                    <button 
                     onClick={e=>{
                      e.preventDefault();
                      e.stopPropagation();

                      if(this.props.onSaveChange){
                        this.props.onSaveChange(this.state.order);
                      }
                    }}
                    className="upload-style text-white " 
                    >Save Change</button>
                  </Card>
                  :
                  <Card className="overflow-hidden custom-card-margin-x">
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                      <CircularProgress size={35} />
                    </Box>
                  </Card>
                }
            </Container>
            
        </form>
    </Container>
  }
}
