import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import Api from "../../api/api.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import { withRouter, Link } from 'react-router-dom';
import { 
  Box,
  Grid,
  Badge,
  Dialog,
  IconButton,
} from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/img/icons/arrow-up.svg';
import CustomerTemplate from "../../assets/files/customers.xlsx"
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import OldTable from '../../components/Table/OldTable.jsx';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass,faPlus } from '@fortawesome/free-solid-svg-icons'
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomSwitch from '../../components/CustomFields/CustomSwitch.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomUploadButton from '../../components/CustomFields/CustomUploadButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import CustomerPreviewDialog from './Partial/CustomerPreviewDialog.jsx';
import CustomerImportDialog from './Partial/CustomerImportDialog.jsx';
import {RoleAccessService} from '../../data/role-access'; 

import Tour from 'reactour'

let activeInactiveGlobal = 0
export class Customers extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    
    this.state = {
      isLoadingStatus: false,
      totalActive: 0,
      totalDisabled: 0,
      totalActiveDisabled: 0,
      activeInactiveItem: activeInactiveGlobal,
      isLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,
      search: '',
      activeInactiveCustomers: 0,
      preActInact: 0,

      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,

      isTourOpen: false,
      openSkipDialog: false,
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;

    this.callInitApis();

    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeInactiveCustomers !== this.state.activeInactiveCustomers) {
      this.setState({
        preActInact: prevState.activeInactiveCustomers
      })
    } 
  }

  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = () => {
    this.callStatusApi(true, (data) => {
      if(data){
        this.setState({
          totalActive: data.total,
          totalActiveDisabled: (data.total + this.state.totalDisabled),
        });
      }
    });
    this.callStatusApi(false, (data) => {
      if(data){
        this.setState({
          totalDisabled: data.total,
          totalActiveDisabled: (data.total + this.state.totalActive),
        });
      }
    });
  }


  /* API */
  callStatusApi = (is_active = true, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
        is_active: is_active,
        light_data: true,
        // application_registration_ids:1
      };

      wmsApi.read('customers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            // console.log("data", data.result);
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    let page = this.state.page
    if(this.state.activeInactiveCustomers !== this.state.preActInact) {
       page = 1
    }

    this.setState({
      isLoading: true,
      page: page,
      preActInact: this.state.activeInactiveCustomers,
    }, () => {
      let param = {
        page: search ? 1 : page,
        take: this.state.limit,
        is_active: (this.state.activeInactiveCustomers === 0) ? true : false,
        search: search ? decodeURIComponent(search.replace(/\s+/g, ' ').trim()) : search,
        sorting_array: ["id desc"],
        light_data_address: true,
        // application_registration_ids:1
      };

      wmsApi.read('customers', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });

            //this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  updateCustomerAccount = ( row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.updateCustomerAccount('admins/'+ row.id, row, accessToken)
    .then((result) => {
        apiUtil.parseResult(result, (data) => {
            if(callback) {
                callback(data.result);
            }
        }, (error, type) => {
            // console.log("error", error)
            if(callback) {
                callback({status: false, ...error});
            }
        });
    }).catch(
        error => console.log(error)
    )
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.update('customers/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
           
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
            let admins = data.customer.admins;
            // console.log("asdf", result, data)
            if(admins && admins.length > 0){
              admins.map((admin_info)=> {
                  if (row.is_active == false) {
                    admin_info.disabled = true;
                  } else {
                    admin_info.disabled = false;
                  }
                  this.updateCustomerAccount(admin_info, (data) => {
                    if(data.status == false) {
                      delete data.status;
                      let error = JSON.stringify(data);
                      apiUtil.toast(error, 'check_circle', 'error');
                    } else {
                      this.setState({
                        isLoading: false
                      }, () => {
                        // apiUtil.toast('Customer Admin Successfully updated', 'check_circle');
                      });
                    }
                  })
                })
            } 
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      wmsApi.delete('customers/' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'check_circle');
            });
          });
      });
    });
  }

  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('create/customers/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding , currentRole} = this.context;
    let grantedMenu = new RoleAccessService();

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
          {(grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Add')) && 
          <CustomUploadButton 
              disabled={true}
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if(files && files.length > 0){
                    this.setState({
                      openImportDialog: true,
                      openImportDialogItem: files[0],
                    });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Customer
            </CustomUploadButton>
          }
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            {(grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Add')) && <CustomButton 
              className={'new-customer-step'}
              color={'secondary'}
              onClick={() => {
                  this.props.history.push('/customers-form');
              }}
            >
              + New Customer
            </CustomButton>
          }
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <CircleIcon />
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Customer Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Customers'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{this.state.totalActiveDisabled}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="primary" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Active customers</span>
            </Badge>}
            count={this.state.totalActive}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={<Badge 
              color="error" 
              variant="dot" 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <span>Disable customers</span>
            </Badge>}
            count={this.state.totalDisabled}
          />
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */

  customToolbar = () => {
    return <Box>
      <Grid container>
      <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              className="driver-tag-style"
              selected={this.state.activeInactiveCustomers}
              buttons={[ "Active Customers", "Inactive Customers" ]}
              onClick={(selected, btn) => {
                this.setState({
                  preActInact: this.state.activeInactiveCustomers,
                  activeInactiveCustomers: selected,
                }, () => {
                  this.callReadApi(this.refTableSearch.current.state.value);
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  
  renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  /* TABLE */
  customTable = () => {
    const { currentRole} = this.context;
    let grantedMenu = new RoleAccessService();
    return <OldTable 
    ref={this.refTable}
    isDraggable={false}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    // grouping={this.state.grouping}
    limit={this.state.limit}
    // pageSize= {this.state.pageSize}
    pageSizeOptions ={this.state.pageSizeOptions}
    page={this.state.page}
    total={this.state.total}
    data={this.state.data}
    defaultExpanded={true}
    selection={true}
    onPageChange={(page,size) => {
      
      this.state.limit = size;
      this.setState({
        page: page,
      }, () => { 
        this.callReadApi(this.refTableSearch.current.state.value);
      });
    }}
      Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Clients\' Name", 
          field: "company_name",
          cellStyle: {
            minWidth: 300,
            maxWidth: 300
          },
          render: (row, type) => {
            if(type === 'row'){
              return <Box>
                <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}><CustomButton
                  className={grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Read')? 'underline primary-color' : 'underline text-muted'}
                  href={'/'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Read')) {
                      this.setState({
                        openPreviewDialog: true,
                        openPreviewDialogItem: row.id,
                      });
                    }
                  }}
                >
                  {(row && row.company_name) ? row.company_name.replace(/\s/g, '\u00A0') : ''}
                </CustomButton></span>
              </Box>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Code", 
          field: "company_code",
          render: (row) => {
            return (row && row.company_code) ? <span>{row.company_code.replace(/\s/g, '\u00A0')}</span> : '';
          }
        },
        { 
          title: "Billing Email", 
          field: "company_email",
          cellStyle: {
            minWidth: 300,
            maxWidth: 300
          },
          // customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : '')
          render: (row) => {
            return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{(row && row.company_email) ? row.company_email.replace(/\s/g, '\u00A0') : ''}</span>;
          }
        },
        { 
          title: "Postal Code", 
          customSort: (a,b) => (a.customer_addresses.filter(x => x.is_main === true).length > 0 && a.customer_addresses.filter(x => x.is_main === true)[0].postal_code? Number(a.customer_addresses.filter(x => x.is_main === true)[0].postal_code) : 0) - (b.customer_addresses.filter(x => x.is_main === true).length > 0 && b.customer_addresses.filter(x => x.is_main === true)[0].postal_code? Number(b.customer_addresses.filter(x => x.is_main === true)[0].postal_code) : 0),
          render: (row) => {
            let postalCode = '';
            
            if(row.customer_addresses && row.customer_addresses.length > 0){
              let mainAddress = row.customer_addresses.filter(x => x.is_main === true);
              postalCode = (mainAddress && mainAddress.length > 0) ? mainAddress[0].postal_code : row.customer_addresses[0].postal_code;
            }
            
            return postalCode;
          }
        },
        { 
          title: "Contact Number", 
          customSort: (a,b) => (a.customer_addresses.filter(x => x.is_main === true).length > 0 && a.customer_addresses.filter(x => x.is_main === true)[0].contact_no? a.customer_addresses.filter(x => x.is_main === true)[0].contact_no : '').localeCompare(b.customer_addresses.filter(x => x.is_main === true).length > 0 && b.customer_addresses.filter(x => x.is_main === true)[0].contact_no? b.customer_addresses.filter(x => x.is_main === true)[0].contact_no : ''),
          render: (row) => {          
            return row.contact_no1 && row.contact_no1.length == 8 ? "+65" + row.contact_no1: row.contact_no1;
          }
        },
        { 
          title: "Status", 
          width: "100px", 
          sorting: false,
          render: (row) => {
            
              return <CustomSwitch 
              checked={row.is_active}
              disabled={grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Edit') && currentRole != "Ops Admin"? false : true}
              onChange={(isChecked) => {
                row.is_active = isChecked;
                this.callUpdateIsActiveApi(row, () => {
                  this.callReadApi(this.refTableSearch.current.state.value);
                  apiUtil.toast('Successfully Updated', 'check_circle');
                });
              }}
              />
            
          }
        },
        { 
          title: "ACTIONS", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                  <IconButton
                      disabled={grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Edit') && currentRole != "Ops Admin"? false : true}
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/customers-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                   <IconButton
                     disabled={grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Delete') && currentRole != "Ops Admin" ? false : true}
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  // customToolbar = () => {
  //   return <Box>
  //     <Grid container>
  //       <Box clone order={{ xs: 3, md: 1}}>
  //         <Grid item xs={12} md={3}>
  //           <CustomInput
  //             ref={this.refTableSearch}
  //             placeholder={'Search Customer'}
  //             endAdornment={
  //               <IconButton
  //                 onClick={(e) => {
  //                   this.callReadApi(e.target.value);
  //                 }}
  //               >
  //                 <SearchIcon />
  //               </IconButton>
  //             }
  //             onPressEnter={(e) => {
  //               this.callReadApi(e.target.value);
  //             }}
  //           />
  //         </Grid>
  //       </Box>
       
  //       {/* <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
  //         <Grid item xs={12} md={'auto'}>
  //             <CustomButton 
  //               color={'primary'}
  //               onClick={() => {
  //                 console.log("Click Export", this.refTable.current);
  //               }}
  //             >
  //               <ExportIcon />
  //               <Box display={'inline-block'} pl={1}>Export</Box>
  //             </CustomButton>
  //         </Grid>
  //       </Box> */}
  //     </Grid>
  //   </Box>
  // }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <Modal centered show={this.state.openDialog} onHide={() => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            });
            }}>
            <Modal.Header className="" closeButton>
            </Modal.Header>
            <div className="justify-content-center p-4">
            <div className="text-center mb-4">Are you sure you want to delete?</div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 border-delete-b" onClick={() => {
                this.setState({
                  openDialog: false,
                  openDialogItem: null,
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 border-delete" 
                disabled={this.state.isLoading ? true : false}
                onClick={() => {
                  let row = this.state.openDialogItem;
                  this.callDeleteApi(row, () => {
                    this.setState({
                      openDialog: false,
                      openDialogItem: null,
                    }, () => {
                      this.callReadApi(this.refTableSearch.current.state.value);
                      apiUtil.toast('Successfully Deleted', 'check_circle');
                    });
                  });
                }}>
                Delete
              </Button>
            </div>
            </div>
          </Modal>
  // <CustomDialog 
  //     open={this.state.openDialog}
  //     title={'Delete'}
  //     onClose={() => {
  //       this.setState({
  //         openDialog: false,
  //         openDialogItem: null,
  //       });
  //     }}
  //     onOk={() => {
  //       let row = this.state.openDialogItem;
  //       this.callDeleteApi(row, () => {
  //         this.setState({
  //           openDialog: false,
  //           openDialogItem: null,
  //         }, () => {
  //           this.callReadApi(this.refTableSearch.current.state.value);
  //           apiUtil.toast('Successfully Deleted', 'check_circle');
  //         });
  //       });
  //     }}
  //   >
  //     <Box>Are you sure you want to delete?</Box>
  //   </CustomDialog>
  }

  previewDialog = () => {
    const { currentRole} = this.context;
    let grantedMenu = new RoleAccessService();
    if (grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Read')) {
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Customer Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <CustomerPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
          this.callReadApi()
        }}
      />
    </CustomDialog>
    }
  }

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      isLoading={this.state.openImportDialogIsLoading}
      title={'Import Customers'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <CustomerImportDialog
        file={this.state.openImportDialogItem}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_customers = (form && form.length > 0) ? form.length : 0;
              let failed_customers = (data && data.failed_customers && data.failed_customers.length > 0) ? data.failed_customers.length : 0;
              let saved_customers = (data && data.saved_customers && data.saved_customers.length > 0) ? data.saved_customers.length : 0;
              
              if(failed_customers > 0){
                apiUtil.toast(failed_customers + ' of ' + all_customers + ' has not been successfully imported, Company code(s) already exist', 'check_circle', 'error');
              }

              if(saved_customers > 0){
                apiUtil.toast(saved_customers + ' of ' + all_customers + ' customer(s) has been successfully imported', 'check_circle', 'success');
              }

              this.callReadApi(this.refTableSearch.current.state.value);

              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={0}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            // this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Customer list page. Click <b className={'txt-second'}>New Customer</b> to begin</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.new-customer-step',
            content: <Box>
              <h2>Create Customer</h2>
              <p>Click here to create a new customer</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        accentColor={'#007CDE'}       
        //  accentColor={'#64CCC9'}
        badgeContent={'#007CDE'}       
        //  badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={false}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  render() {
    const { isOnBoarding , currentRole} = this.context;
    let grantedMenu = new RoleAccessService();

    return <Container fluid>
      {/* <StickyPageHeader isSmall={false}> */}
        {/* {this.customStickyPageHeader()} */}
      {/* </StickyPageHeader> */}
      
      {/* <Card>
        {this.customStatus()}
      </Card> */}

      <Card className="overflow-hidden custom-card-margin-x" >
          <div className="row border-0 ps-2">
            <div className="col-xs-12 col-md-12 col-lg-6 d-flex justify-content-start media-width-control-845 align-items-center mb-2">
              <div className="row d-flex max-xs-width-control justify-content-start align-items-center mb-2">
                <div className="col-xs-12 col-md-6 my-2">
                <CustomInput
                className="input-custom-style"
                ref={this.refTableSearch}
                value={this.state.search}
                placeholder={'Search Customer'}
                onChange = {(e)=> {
                  this.setState({
                    search: e.target.value
                  })
                }}
                onPaste = {(e) => {
                  this.setState({
                    search: e.target.value
                  })             
                }}
                onPressEnter={(e) => {
                  this.callReadApi(e.target.value);
                }}
                
              />
                </div>
                <div className="col-xs-12 col-md-6 my-2">
                <Button className="upload-style max-height-btn w-100" 
                  onClick={(e) => {
                    this.callReadApi(this.state.search);
                  }}><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
                </div>
            </div>
            </div>
            <div className="col-xs-12 col-md-12 col-lg-6 d-flex justify-content-end max-xs-width-control align-items-center mb-2">
              <div className=" position-relative mt-2 mb-4">
            {(grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Add')) &&
            <CustomUploadButton 
              className={currentRole=="Ops Admin" ? "customer-upload-xls-disabled" : "customer-upload-xls"}
              color={'primary'}
              accept={'.xls, .xlsx'}
              disabled={currentRole=="Ops Admin" ? true : false}
              onChange={(files) => {
                if(currentRole != "Ops Admin" && files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Customer
            </CustomUploadButton>}
            {(grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Add')) && <Link to={CustomerTemplate} target="_blank" download style={{ textDecoration: 'none' }} className="download-template">download customer template</Link>}
              </div>
              {(grantedMenu.isPermissionActionAllowed( currentRole, 'Customer Add')) &&  
              <Button className="justify-self-end upload-style mt-2 mb-4" 
                disabled={currentRole=="Ops Admin" ? true : false} 
                onClick={() => {
                  this.props.history.push('/customers-form');
              }}><FontAwesomeIcon icon={faPlus} /> New Customer</Button> }
            </div>
          </div>
      </Card> 

      <Card className="custom-card-margin-x">
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
      {this.previewDialog()}
      {this.importDialog()}
      {this.setTour()}
      {this.skipDialog()}
    </Container>;
  }
}

