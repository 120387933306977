import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider.jsx";
import { withRouter } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { 
  Grid,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import apiUtil from "../api/apiUtil.jsx";
import authApi from "../api/api.jsx";
import ldsApi from "../api/ldsApi.jsx";
import { withStyles } from '@material-ui/core/styles';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import MenuIcon from '@material-ui/icons/Menu';
import EvfyLogo from '../assets/img/evfy-logo2.png';
import ProfileDropdown from "../components/Header/ProfileDropdown.jsx";
import NotificationDropdown from "../components/Header/NotificationDropdown.jsx";
import NotiBox from "../components/Header/NotiBox.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import {RoleAccessService} from '../data/role-access'; 
import CustomDialog2 from '../components/Dialog/CustomDialog2.jsx';
import JobPreviewDialog from '../views/Jobs/Partial/JobPreviewDialog.jsx';
import OrderDetailForm from '../views/Dashboard/OrderDetailForm';

const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
});

class CustomerHeader extends Component {
  static contextType = AuthContext;
  notiPages = 1;
  notiLimit = 8;
  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);
    let showMenuIcon = ((props.showMenuIcon === true || props.showMenuIcon === false) ? props.showMenuIcon : true);

    this.state = {
      grantedAccess : new RoleAccessService(),
      data :  null,
      isLoadingStatus: false,
      appcom_data:[],
      showHeader: showHeader,
      showMenuIcon: showMenuIcon,
      isLogedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
      notiLoading: false,
      listItems: [],
      openPreviewDialog: false,
      openPreviewDialogItem: null,
    };
  }


  componentDidMount() {
    this.checkIsUserLogedIn();
    this.callReadApi();
    this.callNotiApi();
  }
  

  callReadApi = ( callback = null) => {
    const accessToken  = window.localStorage.getItem('access_token');
    let param = {
        is_paid: false,
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            
            this.setState({
              isLoading: false,
              data:data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
            // console.log("sad", data.result);
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }
  
  callNotiApi = (refresh=true) => {
    const accessToken  = window.localStorage.getItem('access_token');
    this.setState({
      isLoading: true
    }, () => {
      if(refresh) {
        this.notiPages = 1;   
      }
      let param = {
        page: this.notiPages,
        take: this.notiLimit,
      }
      ldsApi.read('notifications' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => { 
            if(data.result.length > 0) {
              this.notiPages += 1;        
            }
            if(refresh == false && this.state.listItems.length > 0) {
              let newNoti = data.result.filter((obj) => !this.state.listItems.filter((item) => item.list[0].id == obj.list[0].id).length > 0 )
              // if(newNoti.length > 0) {
              //   data.result = [...newNoti,...this.state.listItems];
              // } else {
              //   // data.result = this.state.listItems;
              // }                  
            }    
            
            let ids = '';
            data.result && data.result.map( dd => {
              const dateStr = dd.UTC;
              const formattedDate = moment(dateStr, "DD MMM YY");
              const isToday = formattedDate.isSame(moment(), 'day'); 
              if (isToday && dd.list[0].is_prompted == false) {
                // apiUtil.toastNoti(dd.list[0])
                ids += dd.list[0].id +',';
              }
            })
            
            if (ids != '') {
              ids = ids.slice(0, -1);
              this.callNotiMultiUpdateApi(ids)
            }

            this.setState({
              notiLoading: false,
              listItems:refresh == true ? data.result : [...this.state.listItems,...data.result],
            });
            
            
          }, (error, type) => {
            this.setState({
              notiLoading: false,
            });
          });
      });
    });     
  }

  callNotiMultiUpdateApi = (ids) => {
    const accessToken  = window.localStorage.getItem('access_token');
    let params = {
      ids : ids,
      is_prompted: true,
    }
    ldsApi.create('notifications/multiple',params, accessToken)
  }

  callNotiUpdateApi = (id) => {
    const accessToken  = window.localStorage.getItem('access_token');
    ldsApi.update('notifications/' + id,[], accessToken)
    let listItems = this.state.listItems.map((obj) => {
      if(obj.list[0].id == id) {
        obj.list[0].is_seen = true
      }
      return obj;      
    });
    this.setState({
      listItems: listItems
    });
  }


  checkIsUserLogedIn = () => {
    const { authenticated } = this.context;
    this.setState({ 
      isLogedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();

    // window.location.reload();
    window.location = "/";
  }

  alertShow = () => {
    let billDate = this.state.data[0] ? this.state.data[0].billing_date : new Date();
    let suspendedDate = new Date(billDate)
    let currnetDate = new Date()
    let appcom_status_id = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_status_id : null;
    let appcom_is_trial  = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_license.is_trial : false;
    let date_subscription_end = this.state.appcom_data[0] ? new Date(this.state.appcom_data[0].application_company_license.date_subscription_end) :new Date();
    suspendedDate.setDate(suspendedDate.getDate() + 14);
    if(suspendedDate < currnetDate ) {
     let timeDiff = Math.abs(suspendedDate.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', backgroundColor:'#f8d7da', padding:'10px'}}>
          Your Evfy Tracker services are suspended. 
          <NavLink to={{pathname: '/invoice'}} > Please pay </NavLink> within {total_days} days to avoid account removal.</div>
    } else if(
      appcom_status_id == null && appcom_is_trial) {
     let timeDiff = Math.abs(date_subscription_end.getTime() - currnetDate.getTime());
     let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <div style={{textAlign:'center', color: '#fff', paddingLeft:'25px'}}>
          You have {total_days} days left in your trial. Subscribe to evfy.sg by
          <span 
          style={{cursor:"pointer"}}
          onClick={()=>{
            this.setState({
              openDialog:true,
            })
           }} 
          
          ><u> setting your device quota</u></span> now.</div>
    } else {

    }
}

  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog2 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      // hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
      btnOkText={"Save Changes"}
      hideCancelButtons={true}
      hideOkButtons={true}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        is_noti={true}
        onSave={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });       
        }}
    /> 
    </CustomDialog2>
    }
  }
 
  callDeleteNotiApi = ( date_data = null, callback = null) => {
    const { accessToken } = this.context;
      let listItems = this.state.listItems
   
      let param = {
        destroy_all: true,
      };
      let date = moment(date_data).format(apiUtil.getDefaultDateFormat())
      // const idStrings = listItems.flatMap((item) =>
      //   item.list.map((entry) => `${entry.id}`)
      // );
      // let ids = null;
      // if (id != null) {
      //   ids = id
      // } else {
      //   ids = idStrings.join(',');
      // }
      
      this.setState({
      isLoading: true
      }, () => {
      ldsApi.delete('notifications?date=' + date, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.callNotiApi();
            apiUtil.toast('Successfully Deleted', 'check_circle');

            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle"
            );
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
      });
  }

  getHeader = () => {
    const { isCustomerRequired, currentRole } = this.context;
    let grantedMenu = new RoleAccessService();
    let role = localStorage.getItem('current_role');
    let user_info = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).result : null
    if(user_info == null) {
      window.location.reload();   
    }
    return <Container fluid className="header-warpper cd-shadow">
      <Row className=' d-flex align-items-center'>
      <Col xs={3} sm={2} md={2} lg={1}>
      <div className="d-flex justify-content-end align-items-center header-toggle align-self-center pe-1 m-0" style={{minWidth:'86px', width:'86px', backgroundColor:'#007CDE'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="center"
            onClick={() => {
              if(this.props.onDrawerToggle){
                this.props.onDrawerToggle();
              }
            }}
            className={this.props.classes.menuButton}
          >
            <MenuIcon style={{
              color: 'white',
              fontSize:'24px'
            }} />
          </IconButton>
        </div>
      </Col>
      <Col  xs={9} sm={10} md={10} lg={11} className="pe-5">
      <Row className="d-flex align-items-center">
      <Col xs={9} sm={9} md={6} lg={6}> 
      <Row className="d-flex align-items-center not-mobile-view">
      <Col xs={12} sm={12} md={12} lg={12} className="header-tabs-cus d-flex justify-content-start align-items-center">
      <div className="pe-3"><img src={EvfyLogo} alt="evfy logo" width={'100px'}/></div>
        {window.location.pathname.includes("/dashboard") || window.location.pathname.includes("/recycle-bin")?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/dashboard" className={window.location.pathname == "/dashboard" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Dashboard</div></NavLink>
            <NavLink to="/dashboards/allocation" className={(window.location.pathname == "/dashboards/allocation" || window.location.pathname == "/dashboards/allocation1" || window.location.pathname == "/dashboards/allocation2") ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
              <div>Allocation</div></NavLink>
              <NavLink to="/dashboards/new-order" className={window.location.pathname == "/dashboards/new-order" || window.location.pathname == "/dashboards/new-order-form"? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
              <div>New Orders</div></NavLink>
              <NavLink to="/recycle-bin" className={window.location.pathname == "/recycle-bin" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Recycle Bin</div></NavLink>
        </div> : ''}

        {window.location.pathname.includes("/analytics") || window.location.pathname.includes("/job-schedule-list") ?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/analytics/overview" className={window.location.pathname == "/analytics/overview" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Overview</div></NavLink>
          <NavLink to="/analytics/sustainability" className={window.location.pathname == "/analytics/sustainability" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Sustainability</div></NavLink>
          <NavLink to="/analytics/client" className={window.location.pathname == "/analytics/client" || window.location.pathname.includes("/analytics/order-summary-list")  ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Clients</div></NavLink>
          <NavLink to="/analytics/driver" className={window.location.pathname == "/analytics/driver" || window.location.pathname.includes("/analytics/order-summary-list")  ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Driver-Partner</div></NavLink>
        </div> : ''}

        {window.location.pathname.includes("/customers")?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/customers" className={window.location.pathname == "/customers" || window.location.pathname == "/customers-form" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Clients</div></NavLink>
            {/* <NavLink to="/customers-in-active" className={window.location.pathname == "/customers-in-active" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Inactive Clients</div></NavLink> */}
            {(user_info.id == 117 || user_info.id == 354) && <NavLink to="/customers-pending" className={window.location.pathname == "/customers-pending" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Pending Clients</div></NavLink> }
        </div> : ''}

        {window.location.pathname == "/live-tracking" || window.location.pathname == "/zones"?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/live-tracking/reload" className={window.location.pathname == "/live-tracking" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Live Tracking</div></NavLink>
            {(grantedMenu.isShouldShowMenu(currentRole, "zones")) && <NavLink to="/zones" className={window.location.pathname == "/zones" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Create Zone</div></NavLink>}
        </div> : ''}

        {window.location.pathname.includes("/resources")?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200" >
          {(grantedMenu.isShouldShowMenu(currentRole, "transport_operator")) &&<NavLink to="/resources/transport-operators" className={window.location.pathname == "/resources/transport-operators" || window.location.pathname == "/resources/transport-operator/detail"? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Driver</div></NavLink>}
            {/* <NavLink to="/resources/transport-operator-pending" className={window.location.pathname == "/resources/transport-operator-pending" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Pending Applications</div></NavLink>
            <NavLink to="/resources/subcons" className={window.location.pathname == "/resources/subcons" || window.location.pathname == "/resources/subcons-edit"? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Subcons</div></NavLink> */}
            <NavLink to="#" className={window.location.pathname == "/resources/transport-operator-pending" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4 text-muted"}>
            <div className="text-muted">Pending Applications</div></NavLink>
            {/* (grantedMenu.isShouldShowMenu(currentRole, "vendor")) && <NavLink to="/resources/vendor" className={window.location.pathname == "/resources/vendor" || window.location.pathname == "/resources/vendor-form"? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4 "}>
            <div >Vendor</div></NavLink> */}
            {/*(grantedMenu.isShouldShowMenu(currentRole, "vendor")) && (user_info.id == 117 || user_info.id == 354) && <NavLink to="/resources/vendor-pending" className={window.location.pathname == "/resources/vendor-pending" ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4 "}>
            <div >Pending Vendor</div></NavLink> */}
        </div> : ''}

        {window.location.pathname.includes("/invoice")?
        <div className="d-flex justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <NavLink to="/invoice/clients" className={window.location.pathname.includes("/invoice/client") ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Client Invoices</div></NavLink>
            <NavLink to="/invoice/drivers" className={window.location.pathname.includes("/invoice/driver") ? "active-header-style h-100 align-items-center d-flex me-4" : "not-active-header-style me-4"}>
            <div>Driver’s Statement</div></NavLink>
        </div> : ''}
      
      </Col>
      </Row>
      <Row className="d-flex align-items-center mobile-view">
        <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <FontAwesomeIcon icon={faEllipsis} className="color-primary fs-4"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
        {window.location.pathname.includes("/dashboard") || window.location.pathname.includes("/recycle-bin")?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <Dropdown.Item href="/dashboard" className={window.location.pathname == "/dashboard" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Dashboard</div></Dropdown.Item>
            <Dropdown.Item href="/dashboards/allocation" className={(window.location.pathname == "/dashboards/allocation" || window.location.pathname == "/dashboards/allocation1" || window.location.pathname == "/dashboards/allocation2") ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Allocation</div></Dropdown.Item>
              <Dropdown.Item href="/dashboards/new-order" className={window.location.pathname == "/dashboards/new-order" || window.location.pathname == "/dashboards/new-order-form"? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>New Orders</div></Dropdown.Item>
              <Dropdown.Item href="/recycle-bin" className={window.location.pathname == "/recycle-bin" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Recycle Bin</div></Dropdown.Item>
        </div> : ''}

        {window.location.pathname.includes("/analytics") || window.location.pathname.includes("/job-schedule-list") ?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <Dropdown.Item href="/analytics/overview" className={window.location.pathname == "/analytics/overview" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Overview</div></Dropdown.Item>
            <Dropdown.Item href="/analytics/sustainability" className={window.location.pathname == "/analytics/sustainability" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Sustainability</div></Dropdown.Item>
            <Dropdown.Item href="/analytics/client" className={window.location.pathname == "/analytics/client"  || window.location.pathname.includes("/analytics/order-summary-list") ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Clients</div></Dropdown.Item>
            <Dropdown.Item href="/analytics/driver" className={window.location.pathname == "/analytics/driver"  || window.location.pathname.includes("/analytics/order-summary-list") ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Driver-Partner</div></Dropdown.Item>
        </div> : ''}
        

        {window.location.pathname.includes("/customers")?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center">
          <Dropdown.Item href="/customers" className={window.location.pathname == "/customers" || window.location.pathname == "/customers-form" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Clients</div></Dropdown.Item>
            {/* <Dropdown.Item href="/customers-in-active" className={window.location.pathname == "/customers-in-active" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Inactive Clients</div></Dropdown.Item> */}
            {(user_info.id == 117 || user_info.id == 354) && <Dropdown.Item href="/customers-pending" className={window.location.pathname == "/customers-pending" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Pending Clients</div></Dropdown.Item> }
        </div> : ''}

        {window.location.pathname == "/live-tracking" || window.location.pathname == "/zones"?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center  z-index-200">
          <Dropdown.Item href="/live-tracking/reload" className={window.location.pathname == "/live-tracking" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Live Tracking</div></Dropdown.Item>
            {(grantedMenu.isShouldShowMenu(currentRole, "zones")) && <Dropdown.Item href="/zones" className={window.location.pathname == "/zones" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
              <div>Create Zone</div></Dropdown.Item>}
        </div> : ''}

        {window.location.pathname.includes("/resources")?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center z-index-200">
          {(grantedMenu.isShouldShowMenu(currentRole, "transport_operator")) &&  <Dropdown.Item href="/resources/transport-operators" className={window.location.pathname == "/resources/transport-operators" || window.location.pathname == "/resources/transport-operator/detail"? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Driver</div></Dropdown.Item>}
            {/* <Dropdown.Item href="/resources/transport-operator-pending" className={window.location.pathname == "/resources/transport-operator-pending" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Pending Applications</div></Dropdown.Item>
            <Dropdown.Item href="/resources/subcons" className={window.location.pathname == "/resources/subcons" || window.location.pathname == "/resources/subcons-edit"? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Subcons</div></Dropdown.Item> */}
            <Dropdown.Item href="#" className={window.location.pathname == "/resources/transport-operator-pending" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style text-muted"}>
            <div className="text-muted">Pending Applications</div></Dropdown.Item>
            {/*(grantedMenu.isShouldShowMenu(currentRole, "vendor")) && <Dropdown.Item href="/resources/vendor" className={window.location.pathname == "/resources/vendor" || window.location.pathname == "/resources/vendor-form"? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Vendor</div></Dropdown.Item> */}
            {/*(grantedMenu.isShouldShowMenu(currentRole, "vendor")) && (user_info.id == 117 || user_info.id == 354) && <Dropdown.Item href="/resources/vendor-pending" className={window.location.pathname == "/resources/vendor-pending" ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Pending Vendor</div></Dropdown.Item>*/}
        </div> : ''}

        {window.location.pathname.includes("/invoice")?
        <div className="d-flex flex-column justify-content-evenly font-medium h-100 align-items-center z-index-200">
          <Dropdown.Item href="/invoice/clients" className={window.location.pathname.includes("/invoice/client") ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Client Invoices</div></Dropdown.Item>
            <Dropdown.Item href="/invoice/drivers" className={window.location.pathname.includes("/invoice/driver") ? "active-header-style h-100 align-items-center d-flex" : "not-active-header-style"}>
            <div>Driver’s Statement</div></Dropdown.Item>
        </div> : ''}
          {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      </Row>
      </Col>
      <Col xs={3} sm={3} md={6} lg={6} className="d-flex align-items-center justify-content-end ">
      {(role != 'Customer Template Role')?
       <NotiBox 
       fetchNoti={() => {
           this.callNotiApi(false)
       }} 
       callDelete={(date)=> {
          this.callDeleteNotiApi(date);
       }}
       onClick={(item) => {
        if (item.type == "Unassigned Orders") {
          if(item.is_seen == false) {
            this.callNotiUpdateApi(item.id);
          }    
          this.props.history.push("/dashboards/allocation");
        } else if (item.type == "New Orders") {
          if(item.is_seen == false) {
            this.callNotiUpdateApi(item.id);
          }    
          const nowInSeconds = moment().unix(); // Get the current time in seconds since UNIX epoch
          const randomSeconds = Math.floor(Math.random() * 60); // Generate a random number between 0 and 59 (inclusive)

          if (window.location.pathname == "/dashboard") {
            this.props.history.push(`/dashboard?advance_search=true&client_id=${item.customer_id}&rand_id=${nowInSeconds + randomSeconds}`);          
          } else {
            this.props.history.push({
              pathname: '/dashboard',
              state: {
                advance_search: true,
                client_id: item.customer_id,
                click_noti_from_other: true
              }
            });
          }
         
        } else {
          this.setState({
            openPreviewDialog: true,
            openPreviewDialogItem: item.order_id,
          },() => {
            if(item.is_seen == false) {
              this.callNotiUpdateApi(item.id);
            }           
          });     
        }
                  
       }} 
       listItems={this.state.listItems} 
       notiLoading={this.state.notiLoading}/> 
       :
       '' 
      }
         <Grid item xs={'auto'}>
         <ProfileDropdown 
           profileMenu={this.state.profileMenu}
           onLogout={this.logout}
         />
      </Grid>
      </Col>
      </Row>
      </Col>
      </Row>
    {this.previewDialog()}
    </Container>
  }

  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withRouter(withStyles(useStyles)(CustomerHeader));
 