import React, { Component } from "react";
import moment from "moment";
import uuid from "react-uuid";

import PreventTransitionPrompt from "../../layout/Main/PreventTransitionPrompt.jsx";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import { RoleAccessService } from "../../data/role-access";
import {
  Box,
  Grid,
  Divider,
  InputLabel,
  Select,
  Input,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import _ from 'lodash';
import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as CalendarIcon } from "../../assets/img/icons/calendar.svg";
import { ReactComponent as TimeIcon } from "../../assets/img/icons/time.svg";

import StickyPageHeader from "../../components/PageHeader/StickyPageHeader.jsx";
import Card from "../../components/Card/Card.jsx";
import CustomLabel from "../../components/CustomFields/CustomLabel.jsx";
import CustomInput from "../../components/CustomFields/CustomInput.jsx";
import RTextEditor from "../../components/RichTextEditor/RTextEditor.jsx";
import "suneditor/dist/css/suneditor.min.css";
import CustomButton from "../../components/CustomFields/CustomButton.jsx";
import CustomCheckbox from "../../components/CustomFields/CustomCheckbox.jsx";
import CustomAutosuggest from "../../components/CustomFields/CustomAutosuggest.jsx";
import CustomSelectOption from "../../components/CustomFields/CustomSelectOption.jsx";
import CustomTooltip from "../../components/Tooltip/CustomTooltip.jsx";
import CustomFormDialog from "../../components/Dialog/CustomFormDialog.jsx";
import CustomDialog from "../../components/Dialog/CustomDialog.jsx";
import CustomGoogleMapSearch from "../../components/GoogleMap/CustomGoogleMapSearch.jsx";
import CustomGuideStepper from "../../components/Stepper/CustomGuideStepper.jsx";

import DepartmentPICList from "../Jobs/Partial/DepartmentPICList.jsx";
import ItemsInJobList from "../Jobs/Partial/ItemsInJobList.jsx";
import StepsList from "../Jobs/Partial/StepsList.jsx";

import CustomerForm from "../Jobs/Partial/CustomerForm.jsx";
import DriverForm from "../Jobs/Partial/DriverForm.jsx";
import LogisticsAssistanceForm from "../Jobs/Partial/LogisticsAssistanceForm.jsx";
import { ReactComponent as InfoIcon } from '../../assets/img/icons/information.svg';
import Tour from "reactour";
import axios from "axios";
import { IndeterminateCheckBox, TimerSharp } from "@material-ui/icons";
import { remove } from "lodash";
import { Container, Row, Col, Accordion, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faXmarkCircle, faBars, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';

export class NewOrderForm extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    let id =
      this.props.location &&
        this.props.location.state &&
        this.props.location.state.id &&
        this.props.location.state.id > 0
        ? this.props.location.state.id
        : 0;
    this.isCustomerRequired = false;
    let grantedAccess = new RoleAccessService();
    this.state = {
      id: id,
      grantedAccess: grantedAccess,
      currentRole: window.localStorage.getItem("current_role") || null,
      user: window.localStorage.getItem("user") || null,
      isLoading: false,
      data: [],
      drop_off_date: "",
      drop_off_dateValidated: false,
      drop_off_time_planned: "",
      // drop_off_time_plannedValidated: false,
      drop_off_description: null,
      //drop_off_descriptionValidated: false,
      drop_off_address: "",
      drop_off_addressValidated: false,
      //New feature of postal code
      // drop_off_postal_code: '',
      zoneItems: "undefined",
      zone_id: "",
      boxes: "",
      document: "",
      pallets: "",
      cartons: "",
      drop_off_postal_code: null,
      drop_off_postal_codeValidated: false,
      amount: 0,
      collection_amount: 0,
      delivery_amount: 0,
      // amountValidated: false,
      created_order: "",
      latitude: 0,
      longitude: 0,
      drop_off_name: "",
      drop_off_nameValidated: false,

      drop_off_contact_name: "",
      drop_off_contact_nameValidated: false,

      drop_off_contact_no: "",
      drop_off_contact_noValidated: false,

      notification_email: "",
      notification_emailValidated: false,
      collection_emailValidated: false,
      delivery_emailValidated: false,

      assign_type: true,
      job_type: "",
      picItems: [],
      itemsInJobList: [],
      job_steps: [],
      is_project: false,
      openDialogCustomer: false,
      itemDialogCustomer: null,

      openDialogDriver: false,
      itemDialogDriver: null,

      openDialogLogisticsAssistance: false,
      itemDialogLogisticsAssistance: null,

      customerItems: [],
      customerItemsIsLoading: false,
      customerItem: null,
      customerId: "",
      customerName: "",
      customerNameValidated: false,

      departmentId: "",
      vendorItems: [],
      vendorItemsIsLoading: false,
      vendorItem: null,
      vendorId: "",
      vendorName: "",
      vendorNameValidated: false,

      workerItems: [],
      workerItemsIsLoading: false,
      workerItem: null,
      workerId: "",
      workerName: "",

      manPowerItems: [],
      manPowerItemsIsLoading: false,
      manPowerItem: null,
      manPowerId: "",
      manPowerName: "",

      branchItems: [],
      order_branch_id: "",

      departmentItems: [],
      departmentItem: null,
      department_id: "",

      customerDetailsIsLoading: true,
      customerDetails: null,

      orderStatusesIsLoading: false,
      orderStatuses: [],
      order_status: null,
      order_status_id: null,

      jobStepStatusesIsLoading: false,
      jobStepStatuses: [],

      isFormChanged: false,

      openDialog: false,
      openDialogItem: null,

      isTourOpen: false,
      openSkipDialog: false,

      copyData: this.props.location.copyData,
      driver_payout: 0.0,
      collection_driver_payout: 0.0,

      collection_data: {
        is_project: false,
        driver_notes: null,
        job_type: null,
        is_notify_customer: true,
        zone_id: null,
        drop_off_date: null,
        drop_off_address: null,
        drop_off_latitude: null,
        drop_off_longitude: null,
        drop_off_time_planned: null,
        drop_off_description: null,
        customer_id: null,
        drop_off_contact_name: null,
        department_id: null,
        notification_email: null,
        drop_off_contact_no: null,
        drop_off_alternate_contact: null,
        drop_off_name: null,
        order_branch_id: null,
        drop_off_address: null,
        drop_off_postal_code: null,
        amount: null,
        item_tracking_number: null,
        is_tracking_link_required: true,
        order_status_id: null,
        total_package: 0,
        total_kg: 0,
        from_time: '9:00',
        to_time: '18:00',
        package_info: null,
        driver_payout: null,
        do_number: null,
        collection_reference: null,
        order_packages: {},
        period_of_delivery: null,
        base_charge: null,
        surcharge: null,
        vendor_payout: null,
        time_window: '',
        xs_packages: '',
        s_packages: '',
        m_packages: '',
        l_packages: '',
        oversized_packages: '',
        pallets:'',
        amount: 0,
        driver_payout: 0,
        vendor_payout: 0,
        base_charge: null,
        surcharge: null,
        selected_package: [],
        is_collection_alt: false,
        // ===== validate ======
        do_validated : false,
        package_validated : false,
        email_validated : false,
        postal_code_validated : false,
        address_validated : false,
        drop_off_date_validated : false,
        contact_no_validated : false,
        alt_contact_no_validated : false,
        drop_off_time_validated : false,
        is_manual: true,
        no_of_movers: null,
      },
      delivery_data:{
        is_project: false,
        driver_notes: null,
        job_type: null,
        is_notify_customer: null,
        zone_id: null,
        drop_off_date: null,
        drop_off_time_planned: null,
        drop_off_description: null,
        customer_id: null,
        from_time: '9:00',
        to_time: '18:00',
        drop_off_contact_name: null,
        department_id: null,
        notification_email: null,
        drop_off_contact_no: null,
        drop_off_alternate_contact: null,
        drop_off_name: null,
        order_branch_id: null,
        drop_off_address: null,
        drop_off_postal_code: null,
        amount: null,
        item_tracking_number: null,
        is_tracking_link_required: true,
        order_status_id: null,
        total_package: 0,
        total_kg: 0,
        package_info: null,
        driver_payout: null,
        do_number: null,
        collection_reference: null,
        order_packages: {},
        period_of_delivery: null,
        base_charge: null,
        surcharge: null,
        vendor_payout: null,
        time_window: '',
        xs_packages: '',
        s_packages: '',
        m_packages: '',
        l_packages: '',
        oversized_packages: '',
        pallets:'',
        amount: 0,
        driver_payout: 0,
        vendor_payout: 0,
        base_charge: null,
        surcharge: null,
        selected_package: [],
        is_collection_alt: false,
        // ===== validate ======
        do_validated : false,
        package_validated : false,
        email_validated : false,
        postal_code_validated : false,
        address_validated : false,
        drop_off_date_validated : false,
        contact_no_validated : false,
        alt_contact_no_validated : false,
        drop_off_time_validated : false,
        no_of_movers: null
      },
      delivery_array: [],
      delivery_driver_payout: 0.0,
      do_number: "",
      delivery_typeValidated: false,
      package_list: [],
      selected_package: [],
      package_types: [],
      customer_rate: [],
      period_of_deliveryValidated: false,
      period_of_delivery: "",
      depalletization_request: "",
      size_l_w_h: "",
      weight: "",
      waiting_time: "",
      no_of_movers: "",
      base_charge: {},
      collection_base_charge: {},
      delivery_base_charge: {},
      surcharge: {},
      collection_surcharge: {},
      delivery_surcharge: {},
      vendor_payout: 0.0,
      collection_vendor_payout: 0.0,
      delivery_vendor_payout: 0.0,

      address: [],
      deepCloneAddress:[],
      is_deli_use_saved_address: false

    };
  }

  componentDidMount() {
    let driver = [];
    this.callAddressApi();
    this.callWorkerDriverReadApi('', (arr) => {
      this.setState({
        workerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        })
      });
      // this.callVendorReadApi('', (arr) => {
      //   driver.push(arr);
      //   var result = [].concat.apply([],Object.values(driver));
      //   this.setState({
      //     workerItems: result.sort((a, b) => {
      //       const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
      //       const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
      //       if (nameA < nameB) {
      //         return -1;
      //       }
      //       if (nameA > nameB) {
      //         return 1;
      //       }
      //     
      //       // names must be equal
      //       return 0;
      //     })
      //   });
      // });
    });

    this.callCustomerReadApi(this.state.customerName, (arr) => {
      this.setState({
        customerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        }),
        oldCustomerItems: arr.sort((a, b) => {
          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        })
      });
    });
    // const { isCustomerRequired, isOnBoarding } = this.context;
    // this.isCustomerRequired = isCustomerRequired;

    // if (isOnBoarding) {
    //   this.setState({
    //     isTourOpen: true,
    //   });
    // }

    // this.callOrderStatusesApi((data) => {
    //   let orderStatusItem = apiUtil.customFilter(data, "label", "not assigned");
    //   if (orderStatusItem) {
    //     this.setState({
    //       order_status: orderStatusItem.item,
    //       order_status_id: orderStatusItem.value,
    //     });
    //   }
    // });
    // this.callWorkerDriverReadApi();
    // this.callVendorReadApi();
    // this.callJobStepStatusesApi();
    // this.callZoneList();

    // //EDIT FORM CONTENT
    // if (this.state.id && this.state.id > 0) {
    //   this.callDetailsApi(this.state.id, (data, itemsInTheJob) => {
    //     /* VENDOR */
    //     let vendorItems = [];
    //     let vendorItem = null;
    //     if (data && data.vendor) {
    //       vendorItem = {
    //         value: data.vendor_id ? data.vendor_id : "",
    //         label:
    //           data.vendor.application_company &&
    //             data.vendor.application_company.company_name &&
    //             data.vendor.application_company.company_name !== ""
    //             ? data.vendor.application_company.company_name
    //             : "",
    //         item: data.vendor ? data.vendor : null,
    //       };
    //       vendorItems.push(vendorItem);
    //     }

    //     //ORDER PACKAGE
    //     if (data && data.order_packages) {
    //       let order_package = data.order_packages;
    //       let selected_package = [];
    //       let s_package = "";
    //       let m_package = "";
    //       let xs_package = "";
    //       let l_package = "";
    //       let oversized_package = "";
    //       order_package.forEach((op) => {
    //         let o_package = {};
    //         o_package.id = op.id;
    //         o_package.name = op.package_name;
    //         o_package.package_id = op.package_id;
    //         o_package.package_quantity = op.package_quantity;

    //         selected_package.push(o_package);
    //         if (op.package_name == "XS") {
    //           xs_package = op.package_quantity;
    //         }
    //         if (op.package_name == "S") {
    //           s_package = op.package_quantity;
    //         }
    //         if (op.package_name == "L") {
    //           l_package = op.package_quantity;
    //         }
    //         if (op.package_name == "M") {
    //           m_package = op.package_quantity;
    //         }
    //         if (op.package_name == "Oversized Package") {
    //           oversized_package = op.package_quantity;
    //         }
    //       });
    //       this.setState({
    //         selected_package,
    //         xs_package,
    //         m_package,
    //         s_package,
    //         l_package,
    //         oversized_package,
    //       });
    //     }

    //     //CHECK BOXES, DOCUMENT AND OTHERS
    //     if (data && data.extra_data) {
    //       let extraData = JSON.parse(data.extra_data);
    //       this.setState({
    //         boxes: extraData && extraData.boxes ? extraData.boxes : "",
    //         document: extraData && extraData.document ? extraData.document : "",
    //         pallets: extraData && extraData.pallets ? extraData.pallets : "",
    //         cartons: extraData && extraData.cartons ? extraData.cartons : "",
    //       });
    //     }
    //     this.setState({
    //       job_type: data && data.job_type ? data.job_type : "",
    //       assign_type:
    //         data && data.assign_type && data.assign_type === "vendor"
    //           ? false
    //           : true,
    //       vendorNameValidated: false,
    //       vendorName: vendorItem && vendorItem.label ? vendorItem.label : "",
    //       vendorId: vendorItem && vendorItem.value ? vendorItem.value : "",
    //       vendorItem: vendorItem && vendorItem.item ? vendorItem.item : null,
    //       vendorItems: vendorItems,
    //     });
    //     /* END VENDOR */

    //     /* STATUS */
    //     this.setState({
    //       order_status: data && data.order_status ? data.order_status : null,
    //       order_status_id:
    //         data && data.order_status_id ? data.order_status_id : null,
    //     });
    //     /* END STATUS */

    //     /* JOB DETAILS SECTION */
    //     this.setState({
    //       drop_off_date:
    //         data && data.drop_off_date
    //           ? moment(data.drop_off_date).format(
    //             apiUtil.getDefaultDateFormat()
    //           )
    //           : "",
    //       drop_off_time_planned:
    //         data && data.drop_off_time_planned
    //           ? data.drop_off_time_planned
    //           : "",
    //       drop_off_description:
    //         data && data.drop_off_description ? data.drop_off_description : "",
    //       driver_notes: data && data.driver_notes ? data.driver_notes : "",
    //       driver_payout:
    //         data && data.driver_payout && data.driver_payout !== 0.0
    //           ? data.driver_payout
    //           : "",
    //       do_number: data && data.do_number ? data.do_number : "",
    //       latitude: data && data.drop_off_latitude ? data.drop_off_latitude : 0,
    //       longitude:
    //         data && data.drop_off_longitude ? data.drop_off_longitude : 0,
    //       zone_id: data && data.zone_id ? data.zone_id : 0,
    //     });
    //     /* END JOB DETAILS SECTION */

    //     if (this.isCustomerRequired) {
    //       /* CUSTOMERS DETAILS SECTION */
    //       let customerItems = [];
    //       let customerItem = null;
    //       if (data && data.customer) {
    //         customerItem = {
    //           value: data.customer_id ? data.customer_id : "",
    //           label: data.company_name ? data.company_name : "",
    //           item: data.customer ? data.customer : null,
    //         };
    //         customerItems.push(customerItem);
    //       }
    //       this.setState(
    //         {
    //           customerNameValidated: false,
    //           customerName:
    //             customerItem && customerItem.label
    //               ? customerItem.label
    //               : data.drop_off_contact_name
    //                 ? data.drop_off_contact_name
    //                 : "",
    //           customerId:
    //             customerItem && customerItem.value ? customerItem.value : "",
    //           customerItem:
    //             customerItem && customerItem.item ? customerItem.item : null,
    //           customerItems: customerItems,
    //           amount: data && data.amount ? data.amount : "",
    //           item_tracking_number: data.item_tracking_number
    //             ? data.item_tracking_number
    //             : "",
    //           is_tracking_link_required: data.is_tracking_link_required
    //             ? data.is_tracking_link_required
    //             : false,
    //           is_notify_customer: data.is_notify_customer
    //             ? data.is_notify_customer
    //             : false,
    //         },
    //         () => {
    //           if (data && data.customer_id) {
    //             this.callCustomerDetailsApi(
    //               this.state.customerId,
    //               (customerData) => {
    //                 let customerAddresses =
    //                   customerData &&
    //                     customerData.customer_addresses &&
    //                     customerData.customer_addresses.length > 0
    //                     ? customerData.customer_addresses
    //                     : [];
    //                 let branchItems = customerAddresses.map((item, i) => {
    //                   return {
    //                     value: item.id,
    //                     label: item.name,
    //                     item: item,
    //                   };
    //                 });

    //                 let departmentItems = [];
    //                 if (
    //                   customerData &&
    //                   customerData.departments &&
    //                   customerData.departments.length > 0
    //                 ) {
    //                   departmentItems = customerData.departments.map(
    //                     (item, i) => {
    //                       return {
    //                         value: item.id,
    //                         label: item.name,
    //                         item: item,
    //                       };
    //                     }
    //                   );
    //                 }

    //                 this.setState(
    //                   {
    //                     branchItems: branchItems,
    //                     departmentItems: departmentItems,
    //                   },
    //                   () => {
    //                     let drop_off_address = "";
    //                     let drop_off_postal_code = "";
    //                     let order_branch_id =
    //                       data && data.order_branch_id
    //                         ? data.order_branch_id
    //                         : "";
    //                     let drop_off_addressItem = branchItems.filter(
    //                       (x) => x.value === order_branch_id
    //                     );
    //                     if (
    //                       drop_off_addressItem &&
    //                       drop_off_addressItem.length > 0
    //                     ) {
    //                       drop_off_address =
    //                         drop_off_addressItem[0].item.address_line1;
    //                       drop_off_postal_code =
    //                         drop_off_addressItem[0].item.postal_code;
    //                     }
    //                     this.setState({
    //                       order_branch_id: order_branch_id,
    //                       drop_off_address:
    //                         drop_off_address && drop_off_address != ""
    //                           ? drop_off_address
    //                           : data.drop_off_address
    //                             ? data.drop_off_address
    //                             : "",
    //                       drop_off_postal_code:
    //                         drop_off_postal_code && drop_off_postal_code != ""
    //                           ? drop_off_postal_code
    //                           : data.drop_off_postal_code
    //                             ? data.drop_off_postal_code
    //                             : "",
    //                     });

    //                     let departmentItem = null;
    //                     let department_id =
    //                       data && data.department_id ? data.department_id : "";
    //                     let departmentArray =
    //                       departmentItems && departmentItems.length > 0
    //                         ? departmentItems.filter(
    //                           (x) => x.value === department_id
    //                         )
    //                         : [];
    //                     if (departmentArray && departmentArray.length > 0) {
    //                       departmentItem = departmentArray[0].item;
    //                     }
    //                     this.setState({
    //                       department_id: department_id,
    //                       departmentItem: departmentItem,
    //                     });
    //                   }
    //                 );
    //               }
    //             );
    //           } else {
    //             this.setState({
    //               drop_off_address: data.drop_off_address
    //                 ? data.drop_off_address
    //                 : "",
    //               drop_off_postal_code: data.drop_off_postal_code
    //                 ? data.drop_off_postal_code
    //                 : "",
    //             });
    //           }
    //         }
    //       );

    //       let picItems = [];
    //       let drop_off_name = [];
    //       if (data.drop_off_name && data.drop_off_name !== "") {
    //         drop_off_name = data.drop_off_name.split(",");
    //       }

    //       let drop_off_contact_no = [];
    //       if (data.drop_off_contact_no && data.drop_off_contact_no !== "") {
    //         drop_off_contact_no = data.drop_off_contact_no.split(",");
    //       }

    //       let notification_email = [];
    //       if (data.notification_email && data.notification_email !== "") {
    //         notification_email = data.notification_email.split(",");
    //       }

    //       if (drop_off_name && drop_off_name.length > 0) {
    //         for (let i = 0; i < drop_off_name.length; i++) {
    //           let name = "";
    //           let contact_no = "";
    //           let email = "";

    //           if (drop_off_name[i] && drop_off_name[i] !== "") {
    //             name = drop_off_name[i];
    //           }
    //           if (drop_off_contact_no[i] && drop_off_contact_no[i] !== "") {
    //             contact_no = drop_off_contact_no[i];
    //           }
    //           if (notification_email[i] && notification_email[i] !== "") {
    //             email = notification_email[i];
    //           }

    //           picItems.push({
    //             uuid: uuid(),
    //             drop_off_name: name,
    //             drop_off_contact_no: contact_no,
    //             notification_email: email,
    //             remove: false,
    //           });
    //           this.setState({
    //             notification_email: email
    //               ? email
    //               : data.notification_email
    //                 ? data.notification_email
    //                 : "",
    //             drop_off_name: name
    //               ? name
    //               : data.drop_off_name
    //                 ? data.drop_off_name
    //                 : "",
    //             drop_off_contact_name: name
    //               ? name
    //               : data.drop_off_contact_name
    //                 ? data.drop_off_contact_name
    //                 : "",
    //             drop_off_contact_no: contact_no
    //               ? contact_no
    //               : data.drop_off_contact_no
    //                 ? data.drop_off_contact_no
    //                 : "",
    //           });
    //         }
    //       } else {
    //         this.setState({
    //           notification_email: data.notification_email
    //             ? data.notification_email
    //             : "",
    //           drop_off_name: data.drop_off_name ? data.drop_off_name : "",
    //           drop_off_contact_name: data.drop_off_contact_name
    //             ? data.drop_off_contact_name
    //             : "",
    //           drop_off_contact_no: data.drop_off_contact_no
    //             ? data.drop_off_contact_no
    //             : "",
    //         });
    //       }

    //       this.setState({
    //         picItems: picItems,
    //       });
    //       /* END CUSTOMERS DETAILS SECTION */
    //     } else {
    //       /* END RECIPIENT DETAILS SECTION */
    //       this.setState({
    //         item_tracking_number: data.item_tracking_number
    //           ? data.item_tracking_number
    //           : "",
    //         is_tracking_link_required: data.is_tracking_link_required
    //           ? data.is_tracking_link_required
    //           : false,
    //         is_notify_customer: data.is_notify_customer
    //           ? data.is_notify_customer
    //           : false,
    //         drop_off_address: data.drop_off_address
    //           ? data.drop_off_address
    //           : "",
    //         drop_off_postal_code: data.drop_off_postal_code
    //           ? data.drop_off_postal_code
    //           : "",
    //         drop_off_name: data.drop_off_name ? data.drop_off_name : "",
    //         drop_off_contact_name: data.drop_off_contact_name
    //           ? data.drop_off_contact_name
    //           : "",
    //         drop_off_contact_no: data.drop_off_contact_no
    //           ? data.drop_off_contact_no
    //           : "",
    //         notification_email: data.notification_email
    //           ? data.notification_email
    //           : "",
    //       });
    //       /* END RECIPIENT DETAILS SECTION */
    //     }

    //     /* ITEMS IN JOB LIST SECTION */
    //     let itemsInJobList = [];
    //     if (itemsInTheJob && itemsInTheJob.length > 0) {
    //       itemsInJobList = itemsInTheJob.map((item, i) => {
    //         return {
    //           uuid: uuid(),
    //           id: item.id,
    //           description: item.description,
    //           quantity: item.quantity,
    //           uom: item.uom,
    //           weight: item.weight,
    //           remarks: item.remarks,
    //           remove: false,
    //         };
    //       });
    //     }
    //     this.setState({
    //       itemsInJobList: itemsInJobList,
    //     });
    //     /* END ITEMS IN JOB LIST SECTION */

    //     /* STEPS SECTION */
    //     let job_steps = [];
    //     if (data && data.job_steps && data.job_steps.length > 0) {
    //       job_steps = data.job_steps.map((item, i) => {
    //         let orderStatusItem = apiUtil.customFilter(
    //           this.state.jobStepStatuses,
    //           "value",
    //           item.job_step_status_id
    //         );
    //         let job_step_status = orderStatusItem ? orderStatusItem : null;

    //         return {
    //           uuid: uuid(),
    //           id: item.id,
    //           job_step_id: item.id,
    //           job_step_name: item.job_step_name,
    //           job_step_status_id:
    //             item.job_step_status_id && item.job_step_status_id !== ""
    //               ? item.job_step_status_id
    //               : 1,
    //           job_step_status: job_step_status,
    //           location: item.location,
    //           is_signature_required: item.is_signature_required,
    //           is_scan_required: item.is_scan_required,
    //           job_step_pic: item.job_step_pic,
    //           job_step_pic_contact: item.job_step_pic_contact,
    //           description: item.description,
    //           order_sequence: i + 1,
    //           remove: false,
    //         };
    //       });
    //     }
    //     this.setState({
    //       job_steps: job_steps,
    //     });
    //     /* END STEPS SECTION */

    //     /* ASSIGN WORKERS SECTION */
    //     let manPowerItem =
    //       data && data.extra_workers && data.extra_workers.length > 0
    //         ? data.extra_workers[0]
    //         : null;
    //     this.setState({
    //       workerItem: data.drop_off_worker,
    //       workerName: data.drop_off_worker_name,
    //       workerId: data.drop_off_worker_id,
    //       manPowerItem: manPowerItem,
    //       manPowerName: manPowerItem
    //         ? (manPowerItem.first_name && manPowerItem.first_name !== ""
    //           ? manPowerItem.first_name
    //           : "") +
    //         " " +
    //         (manPowerItem.last_name && manPowerItem.last_name !== ""
    //           ? manPowerItem.last_name
    //           : "")
    //         : "",
    //       manPowerId: manPowerItem ? manPowerItem.id : 0,
    //     });
    //     /* END ASSIGN WORKERS SECTION */
    //   });
    // }

    // // COPY AND DUPLICATE JOB
    // if (this.state.copyData && this.state.copyData.id > 0) {
    //   this.prepareCopyDataForm(this.state.copyData);
    // }
  }

  callAddressApi = () => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let param = {
          sort: 'latest'
        };
  
        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let address = (data.result && data.result.length > 0) ? data.result : []
              let user_customer_id = this.state.user && this.state.user.owner_info && this.state.user.owner_info.customers && this.state.user.owner_info.customers.length > 0 && this.state.user.owner_info.customers[0].id ? this.state.user.owner_info.customers[0].id : null
              let user = JSON.parse(window.localStorage.getItem('user'));
              let inner_user_id = user && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id ? user.owner_info.customers[0].id : null
              if (this.state.currentRole === "Customer Template Role") {
                address = address.filter(dd => dd.customer_id === null || dd.customer_id === user_customer_id || dd.customer_id === inner_user_id);
              } else {
                address = address.filter(dd => dd.customer_id === null);
              }
              this.setState({
                address: address,
                deepCloneAddress:_.cloneDeep(address),
                total: data.total,
                isLoading: false,
              });
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}

  /* GEO FUNCTIONS API */
  callGeoCoderAddressApi = (address = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        geocoderAddressIsLoading: true,
      },
      () => {
        let params = {
          address: address,
        };

        ldsApi
          .create("geo_functions/geocoder_address", params, accessToken)
          .then((result) => {
            apiUtil.parseResult(
              result,
              (data) => {
                if (data && data.result) {
                  if (callback) {
                    callback(data.result);
                  }
                } else {
                  if (callback) {
                    callback(null);
                  }
                }
              },
              (error, type) => {
                if (callback) {
                  callback(null);
                }
              }
            );
          });
      }
    );
  };

  /* ORDER STATUSES API */
  callOrderStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        orderStatusesIsLoading: true,
        orderStatuses: [],
      },
      () => {
        ldsApi.read("order_statuses", null, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let arr = [];
              if (data && data.length > 0) {
                arr = data.map((item, i) => {
                  let value = item.id;
                  let label = item.status;

                  if (arr.findIndex((x) => x.value === value) === -1) {
                    return {
                      value: value,
                      label: label,
                      item: item,
                    };
                  } else {
                    return null;
                  }
                });

                this.setState(
                  {
                    orderStatusesIsLoading: false,
                    orderStatuses: arr,
                  },
                  () => {
                    if (callback) {
                      callback(arr);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    orderStatusesIsLoading: false,
                    orderStatuses: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  orderStatusesIsLoading: false,
                  orderStatuses: [],
                },
                () => {
                  if (callback) {
                    callback([]);
                  }
                }
              );
            }
          );
        });
      }
    );
  };

  callJobStepStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        jobStepStatusesIsLoading: true,
        jobStepStatuses: [],
      },
      () => {
        ldsApi
          .get("api/job_step_statuses", null, accessToken)
          .then((result) => {
            apiUtil.parseResult(
              result,
              (data) => {
                let arr = [];
                if (data && data.result && data.result.length > 0) {
                  arr = data.result.map((item, i) => {
                    let value = item.id;
                    let label = item.name;

                    if (arr.findIndex((x) => x.value === value) === -1) {
                      return {
                        value: value,
                        label: label,
                        item: item,
                      };
                    } else {
                      return null;
                    }
                  });

                  this.setState(
                    {
                      jobStepStatusesIsLoading: false,
                      jobStepStatuses: arr,
                    },
                    () => {
                      if (callback) {
                        callback(arr);
                      }
                    }
                  );
                } else {
                  this.setState(
                    {
                      jobStepStatusesIsLoading: false,
                      jobStepStatuses: [],
                    },
                    () => {
                      if (callback) {
                        callback([]);
                      }
                    }
                  );
                }
              },
              (error, type) => {
                this.setState(
                  {
                    jobStepStatusesIsLoading: false,
                    jobStepStatuses: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            );
          });
      }
    );
  };
  /* END ORDER STATUSES API */

  callZoneList = () => {
    const { accessToken } = this.context;
    axios
      .get(
        // `${process.env.REACT_APP_AUTH_API_URL}/admin/api/zones`,{
        `${process.env.REACT_APP_AUTH_API_URL}/admin/api/worker_polygons`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          this.setState({
            zoneItems: response.data.result,
          });
        } else {
          this.setState({
            zoneItems: [],
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  /* WORKER API */
  callWorkerDriverReadApi = (search = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        workerItemsIsLoading: true,
        workerItems: [],
      },
      () => {
        let param = {
          // page: 1,
          // take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          is_truck_driver: true,
          // require_worker_only: true,
          light_data: true,
        };

        api.read("workers", param, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let result =
                data.result && data.result.length > 0 ? data.result : [];

              let arr = [];
              if (result && result.length > 0) {
                arr = result.map((item, i) => {
                  let value = item.id;
                  let label =
                    (item.first_name && item.first_name !== ""
                      ? item.first_name
                      : "") +
                    " " +
                    (item.last_name && item.last_name !== ""
                      ? item.last_name
                      : "");

                  if (arr.findIndex((x) => x.value === value) === -1) {
                    return {
                      value: value,
                      label: label,
                      item: item,
                    };
                  } else {
                    return null;
                  }
                });

                this.setState(
                  {
                    workerItemsIsLoading: false,
                    workerItems: arr.sort((a, b) => {
                      const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                    }),
                  },
                  () => {
                    if (callback) {
                      callback(arr);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    workerItemsIsLoading: false,
                    workerItems: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  workerItemsIsLoading: false,
                  workerItems: [],
                },
                () => {
                  if (callback) {
                    callback([]);
                  }
                }
              );
            }
          );
        });
      }
    );
  };

  callWorkerManPowerReadApi = (search = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        manPowerItemsIsLoading: true,
        manPowerItems: [],
      },
      () => {
        let param = {
          page: 1,
          take: apiUtil.getDefaultPageSize(),
          is_active: true,
          search: search ? search.trim() : search,
          is_man_power: true,
          require_worker_only: true,
        };

        api.read("workers", param, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let result =
                data.result && data.result.length > 0 ? data.result : [];

              let arr = [];
              if (result && result.length > 0) {
                arr = result.map((item, i) => {
                  let value = item.id;
                  let label =
                    (item.first_name && item.first_name !== ""
                      ? item.first_name
                      : "") +
                    " " +
                    (item.last_name && item.last_name !== ""
                      ? item.last_name
                      : "");

                  if (arr.findIndex((x) => x.value === value) === -1) {
                    return {
                      value: value,
                      label: label,
                      item: item,
                    };
                  } else {
                    return null;
                  }
                });

                this.setState(
                  {
                    manPowerItemsIsLoading: false,
                    manPowerItems: arr,
                  },
                  () => {
                    if (callback) {
                      callback(arr);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    manPowerItemsIsLoading: false,
                    manPowerItems: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  manPowerItemsIsLoading: false,
                  manPowerItems: [],
                },
                () => {
                  if (callback) {
                    callback([]);
                  }
                }
              );
            }
          );
        });
      }
    );
  };
  /* END WORKER API */

  /* VENDOR API */
  callVendorReadApi = (search = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        vendorItemsIsLoading: true,
        vendorItems: [],
      },
      () => {
        let param = {
          page: 1,
          take: apiUtil.getDefaultPageSize(),
          is_active: true,
        };

        api.read("vendors/active_vendor", param, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let result =
                data.result && data.result.length > 0 ? data.result : [];

              let arr = [];
              if (result && result.length > 0) {
                arr = result.map((item, i) => {
                  let value = item.company_id;
                  let label = item.company_name;

                  if (arr.findIndex((x) => x.value === value) === -1) {
                    return {
                      value: value,
                      label: label,
                      item: item,
                    };
                  } else {
                    return null;
                  }
                });

                this.setState(
                  {
                    vendorItemsIsLoading: false,
                    vendorItems: arr,
                  },
                  () => {
                    if (callback) {
                      callback(arr);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    vendorItemsIsLoading: false,
                    vendorItems: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  vendorItemsIsLoading: false,
                  vendorItems: [],
                },
                () => {
                  if (callback) {
                    callback([]);
                  }
                }
              );
            }
          );
        });
      }
    );
  };
  /* END VENDOR API */

  /* CUSTOMER API */
  callCustomerReadApi = (search = "", callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;

    this.setState(
      {
        customerItemsIsLoading: true,
        customerItems: [],
      },
      () => {
        if (!isOnBoarding) {
          let param = {
            page: 1,
            // take: apiUtil.getDefaultPageSize(),
            is_active: true,
            search: search ? search.trim() : search,
            light_data_customer_rate: true,
          };

          wmsApi.read("customers", param, accessToken).then((result) => {
            apiUtil.parseResult(
              result,
              (data) => {
                let result =
                  data.result && data.result.length > 0 ? data.result : [];

                let arr = [];
                if (result && result.length > 0) {
                  for (let i = 0; i < result.length; i++) {
                    let item = result[i];

                    let value = item.id;
                    let label = item.company_name ? item.company_name : '';

                    if (arr.findIndex((x) => x.value === value) === -1) {
                      arr.push({
                        value: value,
                        label: label,
                        item: item,
                      });
                    }
                  }
                  if (this.state.currentRole == 'Customer Template Role') {
                    this.setState(
                      {
                        customerItemsIsLoading: false,
                        customerItems: arr.sort((a, b) => {
                          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                        
                          // names must be equal
                          return 0;
                        }),
                        customerName: result[0].company_name,
                        customerId: result[0].id,
                        customerItem: result[0],
                        customer_rate: result[0].customer_rates, 
                      },
                      () => {
                        if (callback) {
                          callback(arr);
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        customerItemsIsLoading: false,
                        customerItems: arr.sort((a, b) => {
                          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                        
                          // names must be equal
                          return 0;
                        }),
                        // customerName: value.label,
                        // customerId: value.value,
                        // customerItem: value.item,
                      },
                      () => {
                        if (callback) {
                          callback(arr);
                        }
                      }
                    );
                  }
                } else {
                  this.setState(
                    {
                      customerItemsIsLoading: false,
                      customerItems: [],
                    },
                    () => {
                      if (callback) {
                        callback([]);
                      }
                    }
                  );
                }
              },
              (error, type) => {
                this.setState(
                  {
                    customerItemsIsLoading: false,
                    customerItems: [],
                  },
                  () => {
                    if (callback) {
                      callback([]);
                    }
                  }
                );
              }
            );
          });
        } else {
          let jsonCustomer = null;
          try {
            jsonCustomer = JSON.parse(onBoardingCustomer);
          } catch (e) { }

          let customer = null;
          if (jsonCustomer) {
            customer = {
              value: jsonCustomer.id,
              label: jsonCustomer.company_name,
              item: jsonCustomer,
            };
          }

          let arr = [];
          if (customer) {
            arr.push(customer);

            this.setState(
              {
                customerItemsIsLoading: false,
                customerItems: arr.sort((a, b) => {
                  const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
                }),
              },
              () => {
                if (callback) {
                  callback(arr);
                }
              }
            );
          } else {
            this.setState(
              {
                customerItemsIsLoading: false,
                customerItems: [],
              },
              () => {
                if (callback) {
                  callback([]);
                }
              }
            );
          }
        }
      }
    );
  };

  callCustomerDetailsApi = (id = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        customerDetailsIsLoading: true,
        customerDetails: null,
      },
      () => {
        wmsApi.details("customers", id, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let result = data && data.customer ? data.customer : null;

              if (result) {
                this.setState(
                  {
                    customerDetailsIsLoading: false,
                    customerDetails: result,
                  },
                  () => {
                    if (callback) {
                      callback(result);
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    customerDetailsIsLoading: false,
                    customerDetails: null,
                  },
                  () => {
                    if (callback) {
                      callback(null);
                    }
                  }
                );
              }
            },
            (error, type) => {
              this.setState(
                {
                  customerDetailsIsLoading: false,
                  customerDetails: null,
                },
                () => {
                  if (callback) {
                    callback(null);
                  }
                }
              );
            }
          );
        });
      }
    );
  };
  /* END CUSTOMER */

  /* API */
  callPackageTypes = (id = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        ldsApi.read("package_types" + id, null, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              let package_types =
                data.result && data.result.length > 0 ? data.result : [];
              let data_list = [];
              package_types.forEach((element) => {
                let dl = {};
                dl.package_id = element.id;
                dl.name = element.package_name;
                data_list.push(dl);
              });
              this.setState({
                package_types,
                package_list: data_list,
                isLoading: false,
              });
            },
            (error, type) => {
              this.setState({
                isLoading: false,
              });
            }
          );
        });
      }
    );
  };

  /* API */
  callDetailsApi = (id = "", callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        ldsApi.read("orders/" + id, null, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  if (callback) {
                    callback(
                      data && data.result ? data.result : null,
                      data && data.sorted_order_details
                        ? data.sorted_order_details
                        : []
                    );
                  }
                }
              );
            },
            (error, type) => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  if (callback) {
                    callback(null, []);
                  }
                }
              );
            }
          );
        });
      }
    );
  };

  callCreateApi = (row, callback = null) => {
    const { accessToken, setToLoad } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
           setToLoad(false);
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              isLoading: false,
            }, () => {
              if(callback){
                callback(data.result);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(
                error,
                "check_circle"
              );
            });
          });
      });
    });
  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        ldsApi.update("orders/" + id, row, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  if (callback) {
                    callback();
                  }
                }
              );
            },
            (error, type) => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  if (callback) {
                    callback();
                  }
                }
              );
            }
          );
        });
      }
    );
  };
  /* END API */

  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return (
      <Box clone pl={4} pr={4} height={"100%"} alignItems={"center"}>
        <Grid container>
          {!isOnBoarding && (
            <Box clone>
              <Grid item xs={"auto"}>
                <IconButton
                  onClick={() => {
                    this.props.history.push("/job-list");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Box>
          )}
          {!isOnBoarding && (
            <Box clone>
              <Grid item xs={"auto"}>
                <Divider orientation={"vertical"} />
              </Grid>
            </Box>
          )}
          <Box clone>
            <Grid item xs={isOnBoarding ? "auto" : true}>
              <CustomLabel
                className={"no-margin"}
                label={this.state.id > 0 ? "Edit Job" : "Add New Job"}
                weight={"bold"}
                size={"md"}
              />
            </Grid>
          </Box>
          {isOnBoarding && (
            <Box clone pr={1}>
              <Grid item xs={12} sm={true}>
                {this.setStepper()}
              </Grid>
            </Box>
          )}
          <Box clone>
            <Grid item xs={"auto"}>
              <CustomButton
                className={"create-job-step"}
                type={"submit"}
                color={"secondary"}
                isLoading={this.state.isLoading}
              >
                <Box pl={2} pr={2}>
                  Submit job
                </Box>
              </CustomButton>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };
  /* END STICKY PAGE HEADER */
  
  getTimeWindow = ( s_time = '', e_time = '' ) => {
    let start_time = moment(s_time,'hh:mm');
    let end_time = moment(e_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    let timeslot = '';
    if(diff_time > 0) {
      if(diff_time < 3){
        timeslot = 'express (2h slot)'; 
      } else if(diff_time >= 3 && diff_time <= 5) {
        timeslot = 'next day (3h slot)'; 
      }
    }      
    return timeslot;
  }

  /* FORM */
  jobDetailsForm = () => {
    // let all_data = this.state.collection_data.concat(this.state.delivery_data)
    let description =
      this.state.drop_off_description != ""
        ? this.state.drop_off_description
        : "";
    let user = JSON.parse(window.localStorage.getItem("user"));
    let isOwner =
      user.owner_info.is_customer_admin !== undefined
        ? user.owner_info.is_customer_admin
        : false;
    let isNewRole = this.state.grantedAccess.admin_roles_with_customer.includes(
      this.state.currentRole
    )
      ? false
      : true;
      // all_data && all_data.map (()=> {
      return (
        <Container fluid key={'1'}>
           <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Ordered By
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    readOnly={true}
                    type={"text"}
                    color={"secondary"}
                    value={
                      user.owner_info
                        ? `${user.owner_info.first_name} ${user.owner_info.last_name
                          ? user.owner_info.last_name
                          : ""
                        }`
                        : ""
                    }
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Client Company
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <CustomAutosuggest
                    placeholder={"Enter any keyword to search for customers"}
                    labelComponent={
                      <CustomTooltip
                        title={
                          "Select from your list of customers. Create one if u dont have them in your list."
                        }
                      />
                    }
                    items={this.state.customerItems}
                    isLoading={this.state.customerItemsIsLoading}
                    value={this.state.customerName}
                    onChange={(value) => {
                      if (value) {
                        this.setState(
                          {
                            //customerNameValidated: false,
                            customerName: value.label,
                            customerId: value.value,
                            customerItem: value.item,
                            departmentId: '',
                            customer_rate:
                              value.item.customer_rates &&
                                value.item.customer_rates.length > 0
                                ? value.item.customer_rates
                                : [],
                            isFormChanged: true,
                          },
                          () => {
                            if (value) {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                this.state.collection_data.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                                this.state.collection_data.drop_off_date,
                                this.state.collection_data.drop_off_postal_code,
                                'Collection',
                                this.state.collection_data.drop_off_address,
                                this.state.address,
                                null,
                                this.state.is_deli_use_saved_address,
                                this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                                this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                              );
                              let collection_data = this.state.collection_data;
                              collection_data.amount = client;
                              collection_data.driver_payout = payout;
                              collection_data.vendor_payout = subcon;
                              collection_data.base_charge = base_charge;
                              collection_data.surcharge = surcharge;
                              this.setState({
                                openDialog: true,
                                openDialogItem: value.item,
                                collection_data: collection_data,
                              });
                            } 

                            if (value) {
                              this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
                                let {
                                  client,
                                  payout,
                                  subcon,
                                  base_charge,
                                  surcharge,
                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                  dd.selected_package,
                                  this.state.customer_rate,
                                  this.state.job_type,
                                  dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                  dd.drop_off_date,
                                  dd.drop_off_postal_code,
                                  'Delivery',
                                  dd.drop_off_address,
                                  this.state.address,
                                  null,
                                  false,
                                  dd.drop_off_latitude, dd.drop_off_longitude,
                                  dd.no_of_movers, dd.zip_from_address
                                );

                               let delivery_array = this.state.delivery_array;
                                delivery_array[deli_index].amount = client;
                                delivery_array[deli_index].driver_payout = payout;
                                delivery_array[deli_index].vendor_payout = subcon;
                                delivery_array[deli_index].base_charge = base_charge;
                                delivery_array[deli_index].surcharge = surcharge;
                                this.setState({
                                  openDialog: true,
                                  openDialogItem: value.item,
                                  delivery_array: delivery_array,
                                });
                            
                              })
                              }
                            }
                          );
                        } else {
                        this.setState(
                          {
                            //customerNameValidated: true,
                            customerName: "",
                            customerId: "",
                            departmentId: "",
                            customerItem: null,
                            customer_rate: [],
                            isFormChanged: true,
                          },
                          () => {
                            let collection_data = this.state.collection_data;
                            let delivery_array = this.state.delivery_array;
                              collection_data.amount = 0.00;
                              collection_data.driver_payout = 0.00;
                              collection_data.vendor_payout = 0.00;
                              collection_data.base_charge = 0.00;
                              collection_data.surcharge = 0.00;

                              this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
                                delivery_array[deli_index].amount = 0.00;
                                delivery_array[deli_index].driver_payout = 0.00;
                                delivery_array[deli_index].vendor_payout = 0.00;
                                delivery_array[deli_index].base_charge = 0.00;
                                delivery_array[deli_index].surcharge = 0.00;
                              })
                              this.setState({
                                collection_data: collection_data,
                                delivery_array: delivery_array,
                              });
                          }
                        );
                      }
                    }}
                    onSearch={(value, e) => {
                      let collection_data = this.state.collection_data;
                      let delivery_array = this.state.delivery_array;
                        collection_data.amount = 0.00;
                        collection_data.driver_payout = 0.00;
                        collection_data.vendor_payout = 0.00;
                        collection_data.base_charge = 0.00;
                        collection_data.surcharge = 0.00;

                        this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
                          delivery_array[deli_index].amount = 0.00;
                          delivery_array[deli_index].driver_payout = 0.00;
                          delivery_array[deli_index].vendor_payout = 0.00;
                          delivery_array[deli_index].base_charge = 0.00;
                          delivery_array[deli_index].surcharge = 0.00;
                        })
                      this.setState(
                        {
                          customerName: value,
                          collection_data: collection_data,
                          delivery_array: delivery_array,
                          customerId: value != "" ? this.state.customerId : "",
                          customerItem: value != '' ? this.state.customerItem : null,
                          isFormChanged: true,
                        },
                        () => {
                          e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase() || dd.label.toLowerCase().includes(value.toLowerCase()) ))));
                        }
                      );
                    }}
                    onClick={(value, e) => {
                      e.showNewSuggestions(this.state.customerItems.filter(dd => dd.label && (dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase()))));
                    }}
                  />
                </div>
              </Col>
                 <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Category*
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <Select
                    className="max-xs-width-control-900 custom-input-component"
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"Enter Job Type here..."}
                    value={this.state.period_of_delivery}
                    native
                    required={true}
                    error={this.state.period_of_deliveryValidated}
                    disableUnderline
                    onChange={(e) => {
                      let peroid = e.target.value;

                      let time_slot = "";

                      this.setState(
                        {
                          period_of_delivery: e.target.value,
                          
                          period_of_deliveryValidated:
                            e.target.value === "" ? true : false,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                            }
                          if (this.state.customerId > 0) {
                            this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                dd.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                dd.drop_off_date,
                                dd.drop_off_postal_code,
                                'Delivery',
                                dd.drop_off_address,
                                this.state.address,
                                null,
                                false,
                                dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                              );

                             let delivery_array = this.state.delivery_array;
                              delivery_array[deli_index].amount = client;
                              delivery_array[deli_index].driver_payout = payout;
                              delivery_array[deli_index].vendor_payout = subcon;
                              delivery_array[deli_index].base_charge = base_charge;
                              delivery_array[deli_index].surcharge = surcharge;
                              this.setState({
                                delivery_array: delivery_array,
                              });
                            })
                            }
                          }
                      );
                    }}
                  >
                    <option value=""> Select Period of Delivery </option>
                    <option value={"Same Day"}>Same Day</option>
                    <option value={"Next Day"}>Next Day</option>
                    {/* <option value={'Next Day (10am-2pm)'}>Next Day (10am-2pm)</option>
                  <option value={'Next Day (2pm-6pm)'}>Next Day (2pm-6pm)</option>
                  <option value={'Next day (6pm-9pm)'}>Next day (6pm-9pm)</option>
                  <option value={'Express (2h Slot)'}>Express (2h Slot)</option> */}
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Department
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <Select
                    className="max-xs-width-control-900 custom-input-component"
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"Select Department here..."}
                    value={this.state.customerId ? this.state.departmentId : ""}
                    native
                    disableUnderline
                    onChange={(e) => {
                      this.setState({
                        departmentId: e.target.value,
                        
                      });
                    }}
                  >
                    <option value=""> Select Department </option>
                    {this.state.customerItem &&
                      this.state.customerItem.departments.map((dd, index) => (
                        <option value={dd.id}>{dd.name}</option>
                      ))}
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Rates*
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <Select
                    className="max-xs-width-control-900 custom-input-component"
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"Enter Job Type here..."}
                    value={this.state.job_type}
                    native
                    required={true}
                    error={this.state.delivery_typeValidated}
                    disableUnderline
                    onChange={(e) => {
                      this.setState(
                        {
                          job_type: e.target.value,
                          
                          delivery_typeValidated:
                            e.target.value === "" ? true : false,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                          if (this.state.customerId > 0) {
                            this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                dd.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                dd.drop_off_date,
                                dd.drop_off_postal_code,
                                'Delivery',
                                dd.drop_off_address,
                                this.state.address,
                                null,
                                false,
                                dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                              );

                             let delivery_array = this.state.delivery_array;
                              delivery_array[deli_index].amount = client;
                              delivery_array[deli_index].driver_payout = payout;
                              delivery_array[deli_index].vendor_payout = subcon;
                              delivery_array[deli_index].base_charge = base_charge;
                              delivery_array[deli_index].surcharge = surcharge;
                              this.setState({
                                delivery_array: delivery_array,
                              });
                            })
                            }
                        }
                      );
                      if (this.state.job_steps.length > 0) {
                        let step = this.state.job_steps[0];

                        step.job_step_name =
                          e.target.value !== "" ? e.target.value : "Delivery";
                        let job_steps = [step];
                        this.setState({
                          job_steps,
                        });
                      }
                    }}
                  >
                    <option value=""> Select Job Type </option>
                    <option value={"Standard"}>Standard </option>
                    <option value={"Project"}>Project</option>
                  </Select>
                </div>
              </Col>
           
            </Row>
          <Accordion 
            defaultActiveKey={['0']} 
            alwaysOpen 
            id={'1'}
          >
          <Accordion.Item eventKey="0" className="pe-0 me-0 position-relative">
          <Accordion.Header 
          className={
            this.state.collection_data.do_validated ||
            this.state.collection_data.package_validated ||
            this.state.collection_data.email_validated ||
            this.state.collection_data.postal_code_validated ||
            this.state.collection_data.address_validated ||
            this.state.collection_data.drop_off_date_validated ||
            this.state.collection_data.contact_no_validated ||
            this.state.collection_data.alt_contact_no_validated ||
            this.state.collection_data.drop_off_time_validated ? "pe-0 me-0 d-flex justify-content-between error-highlight" : "pe-0 me-0 d-flex justify-content-between"} >
              <div className=" d-flex align-items-center ">
                <div className="pe-2">Pick Up Point</div>
              </div>

          </Accordion.Header>
          <Accordion.Body>
            {/* ============= Collection ============== */}
            <Row className="max-xs-width-control-900 p-4">
              {/* <div className="fs-5 font-medium text-start">Collection Point</div>
              <hr /> */}
              
              <Col xs={12}>
                <Col xs={12} md={6} className="d-flex flex-md-row flex-column mb-2">
                  <div className="max-xs-width-control-900 text-end-style col-4">
                    <span className="">Pick up</span> Date*
                  </div>
                  <Col xs={12} md={8} lg={8} className="max-xs-width-control-900 py-2">
                    <div className="max-xs-width-control-900 ps-2 col-12">
                      <CustomInput
                        placeholder={"DD/MM/YYYY"}
                        color={"secondary"}
                        type={"date"}
                        endAdornment={(obj) => {
                          return (
                            <Box
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              <CalendarIcon />
                            </Box>
                          );
                        }}
                        error={this.state.collection_data.drop_off_date_validated }
                        required={true}
                        value={this.state.collection_data.drop_off_date}
                        onChange={(e) => {
                          let collection_data = this.state.collection_data
                          let delivery_array = this.state.delivery_array
                          let col_error = false
                          collection_data.drop_off_date = e.target.value
                          collection_data.drop_off_date_validated = false
                          delivery_array && delivery_array.map ((dd, indexx) => {
                           if (moment(dd.drop_off_date).isBefore(moment(this.state.collection_data.drop_off_date))) {
                            delivery_array[indexx].drop_off_date_validated = true;
                            collection_data.drop_off_date_validated = true;
                            col_error = true;
                           } else {
                            delivery_array[indexx].drop_off_date_validated = false;
                           }
                          })
                  
                          if (col_error)  {
                            apiUtil.toast('Pick Up date is earlier than some delivery date')
                          }
                          this.setState(
                            {
                              collection_data : collection_data,
                              delivery_array : delivery_array,
                            },
                            () => {
                              if (this.state.customerId > 0) {
                                let {
                                  client,
                                  payout,
                                  subcon,
                                  base_charge,
                                  surcharge,
                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                  this.state.collection_data.selected_package,
                                  this.state.customer_rate,
                                  this.state.job_type,
                                  this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                                  this.state.collection_data.drop_off_date,
                                  this.state.collection_data.drop_off_postal_code,
                                  'Collection',
                                  this.state.collection_data.drop_off_address,
                                  this.state.address,
                                  null,
                                  this.state.is_deli_use_saved_address,
                                  this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                                  this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                                );
                                let collection_data = this.state.collection_data;
                                collection_data.amount = client;
                                collection_data.driver_payout = payout;
                                collection_data.vendor_payout = subcon;
                                collection_data.base_charge = base_charge;
                                collection_data.surcharge = surcharge;
                                this.setState({
                                  collection_data: collection_data,
                                });
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Col>
              </Col>
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-2">
                D.O Number
                </div>
                <div className="max-xs-width-control-900 ps-2 col-10">
                  <CustomInput
                    placeholder={"D.O Number"}
                    type={"text"}
                    required
                    error={this.state.collection_data.do_validated}
                    value={this.state.collection_data.do_number}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.do_number = e.target.value
                      collection_data.do_validated = false
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
              <div className="max-xs-width-control-900 w-100 align-items-center justify-content-between py-2">
                  <div className="max-xs-width-control-900 text-end-style w-100">
                  <div className="dropdown">
                    <div className="dropdown-header d-flex justilfy-content-center align-items-center">
                      <span className="fs-6">Saved Address</span>
                      <span className="dropdown-arrow"></span>
                    </div>
                    <div className="dropdown-content address-overflow">
                    <CustomInput
                      placeholder={"Search Address"}
                      type={"text"}
                      onChange={(e) => {
                        let address = this.state.deepCloneAddress
                        address = address.filter(dd => dd.address && (dd.address.includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value.toLowerCase().trim())  || (dd.address.toLowerCase() + ', ' + dd.building_no?.toLowerCase()).includes(e.target.value.toLowerCase())))
                        this.setState({
                          address : address
                        })
                      }}
                    />
                    {this.state.isLoading ? <Card className="cd-shadow d-flex justify-content-center align-items-center p-4"><CircularProgress size={35} /></Card>
                    : this.state.address && this.state.address.map((dd)=> {
                    return <div className={this.state.collection_data.drop_off_latitude == dd.latitude && this.state.collection_data.drop_off_longitude == dd.longitude ? 'p-2 pointer-cursor active-address address-text-left' : 'p-2 pointer-cursor address-text-left'}  onClick={(e) => { 
                      let value = this.state.deepCloneAddress.filter(address => address.id == dd.id)
                      let collection_data = this.state.collection_data
                      if (value[0]) {
                        let choose_addr = value[0].building_no ? value[0].address +  ', ' + value[0].building_no : value[0].address
                        collection_data.address_validated = false
                        collection_data.drop_off_latitude = value[0].latitude
                        collection_data.drop_off_longitude = value[0].longitude
                        collection_data.zip_from_address = value[0].postal_code
                        if(this.state.delivery_array.filter((obj) => obj.drop_off_address && obj.drop_off_address.trim() == choose_addr.trim()).length > 0) {
                          apiUtil.toast('Pick up and Delivery address can not be same')
                          return;                           
                        }
                        collection_data.drop_off_address = choose_addr
                        this.setState({
                          collection_data: collection_data,
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        });
                      } else {
                        collection_data.drop_off_address = ''
                        collection_data.drop_off_latitude = ''
                        collection_data.drop_off_longitude = ''
                        this.setState({
                          collection_data: collection_data,
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        })
                      }
                    }}>
                      <div className="font-medium">{dd.name}</div>
                      <div>{dd.address}{dd.building_no ? ', ' + dd.building_no : ''}</div>
                    </div>
                    })}
                    </div>
                  </div>
                  </div>

                <div className="max-xs-width-control-900 w-100 d-flex align-items-center justify-content-between">
                <div className="max-xs-width-control-900 text-end-style col-2">
                  Address*
                </div>
                <div className="max-xs-width-control-900 ps-2 col-10">
                  <CustomGoogleMapSearch
                    placeholder={"Delivery Address"}
                    required={true}
                    value={this.state.collection_data.drop_off_address}
                    error={this.state.collection_data.address_validated}
                    onBlur={() => {
                      let collection_data = this.state.collection_data;
                      let address = collection_data.drop_off_address;
                      console.log('blur address',address)
                      collection_data.drop_off_address = address
                      this.callGeoCoderAddressApi(address, (data) => {
                        if(data){
                          collection_data.drop_off_latitude = data.lat
                          collection_data.drop_off_longitude = data.lng
                          collection_data.zip_from_address = data.zip
                        } else {
                          collection_data.drop_off_latitude = ''
                          collection_data.drop_off_longitude = ''
                          collection_data.zip_from_address = null
                        }

                        collection_data.address_validated = false
                        this.setState({
                          collection_data: collection_data,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address,
                              false
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        });
                      });                        
                    }}
                    onPaste={(e)=> {
                      e.preventDefault()
                      let collection_data = this.state.collection_data;
                      if (e.clipboardData.getData('Text') == null || e.clipboardData.getData('Text') == '') {
                        collection_data.drop_off_address = null
                        collection_data.drop_off_latitude = null
                        collection_data.drop_off_longitude = null
                        collection_data.zip_from_address = null
                        this.setState({
                          collection_data: collection_data,
                        });
                      } else {
                        let address = e.clipboardData.getData('Text')
                        collection_data.drop_off_address = address
                        this.setState({
                          collection_data: collection_data,
                        });
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      if (e.target.value == null || e.target.value == '') {
                        collection_data.drop_off_address = null
                        collection_data.drop_off_latitude = null
                        collection_data.drop_off_longitude = null
                        this.setState({
                          collection_data: collection_data,
                        });
                      } else {
                        //let address = apiUtil.getGmapAddress(e.target.value);

                        collection_data.drop_off_address = e.target.value
                        collection_data.address_validated = false
                        this.setState({
                          collection_data: collection_data,
                        });
                      }
                    }}
                    onPlacesChanged={(place, e) => {
                      let address = apiUtil.getGmapAddress(place);
                      let collection_data = this.state.collection_data;
                      collection_data.drop_off_address = address;
                      collection_data.address_validated = false
                      collection_data.drop_off_latitude = place.geometry.location.lat();
                      collection_data.drop_off_longitude = place.geometry.location.lng();
                      this.setState({
                        collection_data: collection_data,
                      },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        });
                    }}
                  />
                </div>
                </div>
              </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                  Recipient
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Recipient"}
                    type={"text"}
                    value={this.state.collection_data.drop_off_contact_name}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_contact_name = e.target.value
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Postal Code
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Postal Code"}
                    // value={this.state.collection_postal_code}
                    error={this.state.collection_data.postal_code_validated}
                    type={"number"}
                    // required={true}
                    labelComponent={
                      <CustomTooltip title={"Allow only 6 digits number"} />
                    }
                    onPaste={(e) => {
                      if (
                        /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                      ) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 6);
                    }}
                    value={this.state.collection_data.drop_off_postal_code}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_postal_code = e.target.value
                      collection_data.postal_code_validated = false
                      this.setState(
                        {
                          collection_data : collection_data,
                          
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 position-relative">
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                  Contact No.
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Contact No."}
                    type={"text"}
                    error={this.state.collection_data.contact_no_validated}
                    value={this.state.collection_data.drop_off_contact_no}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_contact_no = e.target.value
                      collection_data.contact_no_validated = false
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
                <FontAwesomeIcon icon={faPlusCircle} className="ps-2 text-end-style add_remove_btn_position" style={this.state.collection_data.is_collection_alt == true ? { display: 'none' } : { color: '#007CDE' }}
                 onClick={() => {
                  let collection_data = this.state.collection_data
                  collection_data.is_collection_alt = true
                  this.setState({
                    collection_data : collection_data,
                  });
                }} />
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Company
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Company"}
                    type={"text"}
                    value={this.state.collection_data.drop_off_name}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_name = e.target.value
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                  Time Window*
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8 d-flex">
                  <Select
                    className="select-width-control-100 custom-input-component"
                    error={this.state.collection_data.drop_off_time_validated }
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"From"}
                    value={this.state.collection_data.from_time}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.from_time = e.target.value
                      collection_data.drop_off_time_validated = false
                      this.setState(
                        {
                          collection_data: collection_data,
                          
                        },
                        () => {
                          let timeslot = this.getTimeWindow(this.state.collection_data.from_time,this.state.collection_data.to_time);   
                          collection_data.time_window = timeslot  
                          this.setState({
                            collection_data: collection_data,
                          }, () => {
                            if (this.state.customerId > 0 && this.state.job_type != '') {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                this.state.collection_data.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                                this.state.collection_data.drop_off_date,
                                this.state.collection_data.drop_off_postal_code,
                                'Collection',
                                this.state.collection_data.drop_off_address,
                                this.state.address,
                                null,
                                this.state.is_deli_use_saved_address,
                                this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                                this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                              );
                              let collection_data = this.state.collection_data;
                              collection_data.amount = client;
                              collection_data.driver_payout = payout;
                              collection_data.vendor_payout = subcon;
                              collection_data.base_charge = base_charge;
                              collection_data.surcharge = surcharge;
                              this.setState({
                                collection_data: collection_data,
                              });
                            }                        
                          });
                        });
                      if (this.state.job_steps.length > 0) {
                        let step = this.state.job_steps[0];

                        step.job_step_name =
                          e.target.value !== "" ? e.target.value : "Delivery";
                        let job_steps = [step];
                        this.setState({
                          job_steps,
                        });
                      }
                    }}
                  >
                    <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                  </Select>
                  <div className="text-end-style m-2">To</div>
                  <Select
                    className="select-width-control-100 custom-input-component"
                    error={this.state.collection_data.drop_off_time_validated }
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"From"}
                    value={this.state.collection_data.to_time}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.to_time = e.target.value
                      collection_data.drop_off_time_validated = false
                      this.setState(
                        {
                          collection_data: collection_data,
                          
                        },
                        () => {
                          let timeslot = this.getTimeWindow(this.state.collection_data.from_time,this.state.collection_data.to_time);   
                          collection_data.time_window = timeslot  
                          this.setState({
                            collection_data: collection_data,
                          }, () =>{
                            if (this.state.customerId > 0 && this.state.job_type != '') {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                this.state.collection_data.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                                this.state.collection_data.drop_off_date,
                                this.state.collection_data.drop_off_postal_code,
                                'Collection',
                                this.state.collection_data.drop_off_address,
                                this.state.address,
                                null,
                                this.state.is_deli_use_saved_address,
                                this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                                this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                              );
                              let collection_data = this.state.collection_data;
                              collection_data.amount = client;
                              collection_data.driver_payout = payout;
                              collection_data.vendor_payout = subcon;
                              collection_data.base_charge = base_charge;
                              collection_data.surcharge = surcharge;
                              this.setState({
                                collection_data: collection_data,
                              });   
                            }                        
                          });
                        }
                      );
                      if (this.state.job_steps.length > 0) {
                        let step = this.state.job_steps[0];

                        step.job_step_name =
                          e.target.value !== "" ? e.target.value : "Delivery";
                        let job_steps = [step];
                        this.setState({
                          job_steps,
                        });
                      }
                    }}
                  >
                     <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Email Address
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Recipient Email"}
                    type={"text"}
                    error={this.state.collection_data.email_validated}
                    value={this.state.collection_data.email_address}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.email_address = e.target.value
                      collection_data.email_validated = false
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className={this.state.collection_data.is_collection_alt == false ? "display-none" : "max-xs-width-control-900 d-flex py-2 position-relative"} >
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                Alternative Contact
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Alternative Contact"}
                    type={"text"}
                    error={this.state.collection_data.alt_contact_no_validated}
                    value={this.state.collection_data.drop_off_alternate_contact}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_alternate_contact = e.target.value
                      collection_data.alt_contact_no_validated = false
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
                <FontAwesomeIcon icon={faXmarkCircle} className="ps-2 text-end-style add_remove_btn_position" style={this.state.collection_data.is_collection_alt == false ? { color: '#ff0000', display: 'none' } : { color: '#ff0000' }} 
                 onClick={() => {
                  let collection_data = this.state.collection_data
                  collection_data.is_collection_alt = false
                  collection_data.drop_off_alternate_contact = ''
                  this.setState({
                    collection_data : collection_data,
                  });
                }} />
              </Col>
              <Col xs={12} md={12} lg={6}></Col>
            
              <Row className="max-xs-width-control-1200 p-4">
                <Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Price
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                  <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      type={"number"}
                      disabled={this.state.currentRole == 'Customer Template Role'? true : false}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      value={this.state.collection_data.amount}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {

                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let collection_data = this.state.collection_data
                        collection_data.amount = e.target.value
                        this.setState({
                          collection_data: collection_data,
                        });
                      }}
                    />
                  </div>
                </Col>
                {this.state.currentRole != 'Customer Template Role' &&<><Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Driver Payout
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                  <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      placeholder={"Payout"}
                      value={this.state.collection_data.driver_payout}
                      type={"number"}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let collection_data = this.state.collection_data
                        collection_data.driver_payout = e.target.value
                        this.setState({
                          collection_data: collection_data,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Vendor Payout
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                    <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      placeholder={"Payout"}
                      value={this.state.collection_data.vendor_payout}
                      type={"number"}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let collection_data = this.state.collection_data
                        collection_data.vendor_payout = e.target.value
                        this.setState({
                          collection_data: collection_data,
                        });
                      }}
                    />
                  </div>
                </Col></>}
              </Row>
            </Row>

            <Row className="max-xs-width-control-900 p-4">
              <div className="fs-5 font-medium text-start w-50">Package Details</div>
              <hr />
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-start w-25 text-end">
                  Item Description
                </div>
                <div className="max-xs-width-control-900 ps-2 w-75">
                  <RTextEditor
                    editorState={description}
                    error={this.state.drop_off_descriptionValidated}
                    value={this.state.collection_data.drop_off_description}
                    placeholder={"Enter your description here..."}
                    onChange={(editorState) => {
                      let collection_data = this.state.collection_data
                      collection_data.drop_off_description = editorState
                      this.setState({
                        collection_data: collection_data,
                        
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                  <div className="max-xs-width-control-900 text-end-style w-50">
                    <span data-tooltip="Size limit (L+W+H) <80cm / 1KG">
                      XS Packages <InfoIcon />
                    </span>
                  </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"5"}
                    type={"number"}
                    value={this.state.collection_data.xs_packages}
                    error={this.state.collection_data.package_validated}
                    inputProps={{min: 0}}
                    onPaste={(e) => {
                      if (
                        /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                      ) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.xs_packages = e.target.value
                      collection_data.package_validated = false
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 1
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 1,
                            name: "XS",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 1
                        );
                      }
                      collection_data.selected_package = selected_package
                      this.setState(
                        {
                          collection_data:collection_data,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <120cm / 5KG">
                    S Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"5"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={this.state.collection_data.s_packages}
                    error={this.state.collection_data.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      collection_data.s_packages = e.target.value
                      collection_data.package_validated = false
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 2
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 2,
                            name: "S",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 2
                        );
                      }
                      collection_data.selected_package = selected_package
                      this.setState(
                        {
                          collection_data:collection_data,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <150cm / 15KG">
                    M Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={this.state.collection_data.m_packages}
                    error={this.state.collection_data.package_validated}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      collection_data.m_packages = e.target.value;
                      collection_data.package_validated = false;
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 3
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 3,
                            name: "M",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 3
                        );
                      }
                      collection_data.selected_package = selected_package
                      this.setState(
                        {
                          collection_data : collection_data,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <180cm / 25KG">
                    L Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={this.state.collection_data.l_packages}
                    error={this.state.collection_data.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      collection_data.l_packages = e.target.value;
                      collection_data.package_validated = false;
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 4
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 4,
                            name: "L",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 4
                        );
                      }
                      collection_data.selected_package = selected_package
                      this.setState(
                        {
                          collection_data: collection_data,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              this.state.collection_data.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                              this.state.collection_data.drop_off_date,
                              this.state.collection_data.drop_off_postal_code,
                              'Collection',
                              this.state.collection_data.drop_off_address,
                              this.state.address,
                              null,
                              this.state.is_deli_use_saved_address,
                              this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                              this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                            );
                            let collection_data = this.state.collection_data;
                            collection_data.amount = client;
                            collection_data.driver_payout = payout;
                            collection_data.vendor_payout = subcon;
                            collection_data.base_charge = base_charge;
                            collection_data.surcharge = surcharge;
                            this.setState({
                              collection_data: collection_data,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Please check with Ops">
                    Oversized Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={this.state.collection_data.oversized_packages}
                    error={this.state.collection_data.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      collection_data.oversized_packages = e.target.value;
                      collection_data.package_validated = false;
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 6
                      );
                      if (e.target.value > 0 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 6,
                            name: "Oversized Package",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 6
                        );
                      }
                      collection_data.selected_package = selected_package 
                      this.setState({
                          collection_data: collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="LxWxH (1.1M x 1.2M x 1.5M) / 100KG">
                    Pallets <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={this.state.collection_data.pallets}
                    error={this.state.collection_data.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data;
                      collection_data.pallets = e.target.value;
                      collection_data.package_validated = false;
                      let selected_package = this.state.collection_data.selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 5
                      );
                      if (e.target.value > 0 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 5,
                            name: "Pallets",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 5
                        );
                      }
                      collection_data.selected_package = selected_package
                      this.setState({
                        collection_data: collection_data,
                      },
                      () => {
                        if (this.state.customerId > 0) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            this.state.collection_data.selected_package,
                            this.state.customer_rate,
                            this.state.job_type,
                            this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                            this.state.collection_data.drop_off_date,
                            this.state.collection_data.drop_off_postal_code,
                            'Collection',
                            this.state.collection_data.drop_off_address,
                            this.state.address,
                            null,
                            this.state.is_deli_use_saved_address,
                            this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                            this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                          );
                          let collection_data = this.state.collection_data;
                          collection_data.amount = client;
                          collection_data.driver_payout = payout;
                          collection_data.vendor_payout = subcon;
                          collection_data.base_charge = base_charge;
                          collection_data.surcharge = surcharge;
                          this.setState({
                            collection_data: collection_data,
                          });
                        }
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Depalletization Request
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <Select
                    className="max-xs-width-control-900 custom-input-component"
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"Depalletization Request"}
                    value={this.state.collection_data.depalletization_request}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.depalletization_request = e.target.value
                      this.setState({
                        collection_data: collection_data,
                        
                      });
                    }}
                  >
                    <option value=""> Select Depalletization Request </option>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Size in cm<br />(L + W + H)
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Size"}
                    type={"text"}
                    value={this.state.collection_data.size_l_w_h}
                    // onPaste={(e) => {
                    //   if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                    //     e.preventDefault();
                    //     return false;
                    //   }
                    // }}
                    // onKeyDown={(e) => {
                    //   if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                    //     e.preventDefault();
                    //   }
                    // }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.size_l_w_h = e.target.value
                      this.setState({
                        collection_data: collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Weight (KG)
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Weight"}
                    type={"text"}
                    value={this.state.collection_data.weight}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.weight = e.target.value
                      this.setState({
                        collection_data: collection_data,
                      });
                    }}
                  />
                </div>
              </Col>
            { this.state.currentRole != 'Customer Template Role' ? <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Waiting Time
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Waiting Time"}
                    type={"text"}
                    value={this.state.collection_data.waiting_time}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.waiting_time = e.target.value
                      this.setState({
                        collection_data : collection_data,
                      });
                    }}
                  />
                </div>
              </Col> : ''}
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Additional Manpower
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"No, movers"}
                    type={"number"}
                    value={this.state.collection_data.no_of_movers}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.no_of_movers = e.target.value
                      this.setState({
                        collection_data: collection_data,
                      },
                      () => {
                        if (this.state.customerId > 0) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            this.state.collection_data.selected_package,
                            this.state.customer_rate,
                            this.state.job_type,
                            this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
                            this.state.collection_data.drop_off_date,
                            this.state.collection_data.drop_off_postal_code,
                            'Collection',
                            this.state.collection_data.drop_off_address,
                            this.state.address,
                            null,
                            this.state.is_deli_use_saved_address,
                            this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
                            this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
                          );
                          let collection_data = this.state.collection_data;
                          collection_data.amount = client;
                          collection_data.driver_payout = payout;
                          collection_data.vendor_payout = subcon;
                          collection_data.base_charge = base_charge;
                          collection_data.surcharge = surcharge;
                          this.setState({
                            collection_data: collection_data,
                          });
                        }
                      })
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-start w-25 text-end">
                  Notes to Driver-Partner
                </div>
                <div className="max-xs-width-control-900 ps-2 w-75">
                  <CustomInput
                    placeholder={"Enter driver notes here..."}
                    value={this.state.collection_data.driver_notes}
                    multiline={true}
                    rows={5}
                    required={false}
                    onChange={(e) => {
                      let collection_data = this.state.collection_data
                      collection_data.driver_notes = e.target.value
                      this.setState({
                        collection_data: collection_data,
                        
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        
        {this.state.delivery_array && this.state.delivery_array.map ((dd, deli_index) => {
          return (
              <Accordion 
                defaultActiveKey={['0']} 
                alwaysOpen 
                id={'1'}
              >
              <Accordion.Item eventKey="0" className="pe-0 me-0 position-relative">
              <Accordion.Header 
              className={
                  dd.do_validated ||
                  dd.package_validated ||
                  dd.email_validated ||
                  dd.postal_code_validated ||
                  dd.address_validated ||
                  dd.drop_off_date_validated ||
                  dd.contact_no_validated ||
                  dd.alt_contact_no_validated ||
                  dd.drop_off_time_validated ? "pe-0 me-0 d-flex justify-content-between error-highlight" : "pe-0 me-0 d-flex justify-content-between"} >
                  <div className=" d-flex align-items-center " style={{width:"270px"}}>
                    <div className="pe-2">Delivery Point ( {deli_index + 1} )</div>
                  </div>
                  <div className=" d-flex align-items-center w-100 justify-content-end">
                <div className="pe-2 me-3" 
               ><FontAwesomeIcon icon={faTrash} style={{ color: '#ff0000' }} 
               onClick={()=> {
                this.setState(prevState => ({
                  delivery_array: prevState.delivery_array.filter((element, ind) => ind !== deli_index)
                }));
              }}/></div>
              </div>
              </Accordion.Header>
              <Accordion.Body>
              {/* ============= Collection ============== */}
              <Row className="max-xs-width-control-900 p-4">
              {/* <div className="fs-5 font-medium text-start">Collection Point</div>
              <hr /> */}
              <Col xs={12}>
                <Col xs={12} md={6} className="d-flex flex-md-row flex-column mb-3">
                  <div className="max-xs-width-control-900 text-end-style col-4">
                    Delivery Date*
                  </div>
                  <div className="max-xs-width-control-900 ps-2 col-8">
                    <CustomInput
                      placeholder={"DD/MM/YYYY"}
                      color={"secondary"}
                      type={"date"}
                      endAdornment={(obj) => {
                        return (
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <CalendarIcon />
                          </Box>
                        );
                      }}
                      error={dd.drop_off_date_validated }
                      required={true}
                      value={dd.drop_off_date}
                      onChange={(e) => {
                        if (this.state.collection_data.drop_off_date == null || this.state.collection_data.drop_off_date == '' ) {
                          apiUtil.toast("Please input the Pick Up date!", "checked_circle")
                          let delivery_array = this.state.delivery_array
                          delivery_array[deli_index].drop_off_date = ''
                          delivery_array[deli_index].drop_off_date_validated = true
                          this.setState({
                            delivery_array : delivery_array,
                          })
                        } else if (moment(e.target.value).isBefore(moment(this.state.collection_data.drop_off_date))) {
                          apiUtil.toast("Delivery date can not be ealier than Pick Up date", "checked_circle")
                          let delivery_array = this.state.delivery_array
                          delivery_array[deli_index].drop_off_date = ''
                          delivery_array[deli_index].drop_off_date_validated = true
                          this.setState({
                            delivery_array : delivery_array,
                          })
                        } else {
                          let delivery_array = this.state.delivery_array
                          delivery_array[deli_index].drop_off_date = e.target.value
                          delivery_array[deli_index].drop_off_date_validated = false
                          this.setState(
                            {
                              delivery_array : delivery_array,
                              
                            },
                            () => {
                              if (this.state.customerId > 0) {
                                
                                  let {
                                    client,
                                    payout,
                                    subcon,
                                    base_charge,
                                    surcharge,
                                  } = this.state.grantedAccess.getPriceAndDriverPayout(
                                    dd.selected_package,
                                    this.state.customer_rate,
                                    this.state.job_type,
                                    dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                    dd.drop_off_date,
                                    dd.drop_off_postal_code,
                                    'Delivery',
                                    dd.drop_off_address,
                                    this.state.address,
                                    null,
                                    false,
                                    dd.drop_off_latitude, dd.drop_off_longitude,
                                    dd.no_of_movers, dd.zip_from_address
                                  );
                
                                 let delivery_array = this.state.delivery_array;
                                  delivery_array[deli_index].amount = client;
                                  delivery_array[deli_index].driver_payout = payout;
                                  delivery_array[deli_index].vendor_payout = subcon;
                                  delivery_array[deli_index].base_charge = base_charge;
                                  delivery_array[deli_index].surcharge = surcharge;
                                  this.setState({
                                    delivery_array: delivery_array,
                                  });
                                
                              }
                            }
                          );
                        }
                        
                      }}
                    />
                  </div>
                </Col>
              </Col>
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-2">
                D.O Number
                </div>
                <div className="max-xs-width-control-900 ps-2 col-10">
                  <CustomInput
                    placeholder={"D.O Number"}
                    type={"text"}
                    required
                    error={dd.do_validated}
                    value={dd.do_number}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].do_number = e.target.value
                      delivery_array[deli_index].do_validated = false
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
              <div className="max-xs-width-control-900 w-100 py-2">
                  <div className="max-xs-width-control-900 text-end-style w-100">
                  <div className="dropdown">
                    <div className="dropdown-header d-flex justilfy-content-center align-items-center">
                      <span className="fs-6">Saved Address</span>
                      <span className="dropdown-arrow"></span>
                    </div>
                    <div className="dropdown-content address-overflow">
                    <CustomInput
                      placeholder={"Search Address"}
                      type={"text"}
                      onChange={(e) => {                     
                        let address = this.state.deepCloneAddress
                        address = address.filter(dd => dd.address && (dd.address.includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value) || dd.address.toLowerCase().includes(e.target.value.toLowerCase().trim()) || (dd.address.toLowerCase() + ', ' + dd.building_no?.toLowerCase()).includes(e.target.value.toLowerCase())))
                        this.setState({
                          address : address
                        })
                      }}
                    />
                    {this.state.isLoading ? <Card className="cd-shadow d-flex justify-content-center align-items-center p-4"><CircularProgress size={35} /></Card>
                    : this.state.address && this.state.address.map((dd)=> {
                    return <div className={this.state.delivery_array[deli_index].drop_off_latitude == dd.latitude && this.state.delivery_array[deli_index].drop_off_longitude == dd.longitude ? 'p-2 pointer-cursor active-address address-text-left' : 'p-2 pointer-cursor address-text-left'}  onClick={(e) => { 
                      let value = this.state.deepCloneAddress.filter(address => address.id == dd.id)
                      let delivery_array = this.state.delivery_array
                      
                      if (value[0]) {
                        let drop_address = value[0].building_no ? value[0].address +  ', ' + value[0].building_no : value[0].address

                        let d_selected_package = delivery_array[deli_index].selected_package
                        let d_time_window = delivery_array[deli_index].time_window
                        let d_period_of_delivery = this.state.period_of_delivery
                        let d_drop_off_date = delivery_array[deli_index].drop_off_date
                        let d_drop_off_postal_code = delivery_array[deli_index].drop_off_postal_code
                       
                        let zip_from_address = null
                        this.callGeoCoderAddressApi(drop_address, (data) => {
                        if(data){
                          delivery_array[deli_index].drop_off_address = value[0].building_no ? value[0].address +  ', ' + value[0].building_no : value[0].address 
                          delivery_array[deli_index].address_validated = false
                          delivery_array[deli_index].drop_off_latitude = value[0].latitude
                          delivery_array[deli_index].drop_off_longitude = value[0].longitude
                          delivery_array[deli_index].zip_from_address = parseInt(data.zip)
                          zip_from_address = parseInt(data.zip)
                          this.recalculate_colleciton();
                        } else {
                          delivery_array[deli_index].drop_off_latitude = ''
                          delivery_array[deli_index].drop_off_longitude = ''
                          delivery_array[deli_index].zip_from_address = ''                           
                        }
                        if(this.state.collection_data.drop_off_address && delivery_array[deli_index].drop_off_address.trim() == this.state.collection_data.drop_off_address.trim()) {
                          apiUtil.toast('Pick up and Delivery address can not be same')
                          return;
                        }  

                        this.setState({
                          delivery_array: delivery_array,
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              d_selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              d_time_window != '' ? d_time_window : d_period_of_delivery,
                              d_drop_off_date,
                              d_drop_off_postal_code,
                              'Delivery',
                              drop_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        });
                        });

                      } else {
                        delivery_array[deli_index].drop_off_address = ''
                        delivery_array[deli_index].drop_off_latitude = ''
                        delivery_array[deli_index].drop_off_longitude = ''
                        this.setState({
                          delivery_array: delivery_array,
                          address: this.state.deepCloneAddress,
                          deepCloneAddress: _.cloneDeep(this.state.deepCloneAddress),
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        })
                        this.fileInput.current.value = '';
                      }
                    }}>
                      <div className="font-medium">{dd.name}</div>
                      <div>{dd.address}{dd.building_no ? ', ' + dd.building_no : ''}</div>
                    </div>
                    })}
                    </div>
                  </div>
                  </div>
                  <div className="max-xs-width-control-900 w-100 d-flex align-items-center justify-content-between ">
                    <div className="max-xs-width-control-900 text-end-style col-2">
                    Address*
                    </div>
                    <div className="max-xs-width-control-900 ps-2 col-10">
                      <CustomGoogleMapSearch
                        placeholder={"Delivery Address"}
                        required={true}
                        value={dd.drop_off_address}
                        error={dd.address_validated}
                        onBlur={() => {
                          let delivery_array = this.state.delivery_array;  
                          let zip_from_address = null;
                          this.callGeoCoderAddressApi(delivery_array[deli_index].drop_off_address, (data) => {
                            if(data){
                              delivery_array[deli_index].drop_off_latitude = data.lat
                              delivery_array[deli_index].drop_off_longitude = data.lng
                              delivery_array[deli_index].zip_from_address = parseInt(data.zip)
                              zip_from_address = parseInt(data.zip)
                              this.recalculate_colleciton();
                              if (this.state.customerId > 0) {
                                let {
                                  client,
                                  payout,
                                  subcon,
                                  base_charge,
                                  surcharge,
                                } = this.state.grantedAccess.getPriceAndDriverPayout(
                                  dd.selected_package,
                                  this.state.customer_rate,
                                  this.state.job_type,
                                  dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                  dd.drop_off_date,
                                  dd.drop_off_postal_code,
                                  'Delivery',
                                  dd.drop_off_address,
                                  this.state.address,
                                  null,
                                  false,
                                  dd.drop_off_latitude, dd.drop_off_longitude,
                                  dd.no_of_movers, dd.zip_from_address,
                                  false
                                );
                              
                               let delivery_array = this.state.delivery_array;
                                delivery_array[deli_index].amount = client;
                                delivery_array[deli_index].driver_payout = payout;
                                delivery_array[deli_index].vendor_payout = subcon;
                                delivery_array[deli_index].base_charge = base_charge;
                                delivery_array[deli_index].surcharge = surcharge;
                                this.setState({
                                  delivery_array: delivery_array,
                                });
                              }
                            } else {
                              delivery_array[deli_index].drop_off_latitude = ''
                              delivery_array[deli_index].drop_off_longitude = ''
                              delivery_array[deli_index].zip_from_address = null
                            }
                          });  
                          this.setState({
                            delivery_array: delivery_array,
                          });                          
                        }}
                        onPaste = {(e) => {
                          e.preventDefault()
                          let delivery_array = this.state.delivery_array;
                          if (e.clipboardData.getData('Text') == null || e.clipboardData.getData('Text') == '') {
                            delivery_array[deli_index].drop_off_address = null
                            delivery_array[deli_index].drop_off_latitude = ''
                            delivery_array[deli_index].drop_off_longitude = ''
                            delivery_array[deli_index].zip_from_address = ''
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          } else {
                            let address = e.clipboardData.getData('Text');
                            let zip_from_address = null
                            delivery_array[deli_index].drop_off_address = address
                            this.setState({
                              delivery_array: delivery_array,
                            });
                            
                          }           
                        }}
                        onChange={(e) => {
                          let delivery_array = this.state.delivery_array;
                          if (e.target.value == null || e.target.value == '') {
                            delivery_array[deli_index].drop_off_address = null
                            delivery_array[deli_index].drop_off_latitude = ''
                            delivery_array[deli_index].drop_off_longitude = ''
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          } else {
                            let address = apiUtil.getGmapAddress(e.target.value);
                            let zip_from_address = null;
                            delivery_array[deli_index].drop_off_address = e.target.value
                            delivery_array[deli_index].address_validated = false;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        }}
                        onPlacesChanged={(place, e) => {
                          let address = apiUtil.getGmapAddress(place);
                          let delivery_array = this.state.delivery_array;
                          delivery_array[deli_index].drop_off_address = address;
                          delivery_array[deli_index].address_validated = false;
                          delivery_array[deli_index].drop_off_latitude = place.geometry.location.lat();
                          delivery_array[deli_index].drop_off_longitude = place.geometry.location.lng();
                          this.recalculate_colleciton();
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                              dd.no_of_movers, dd.zip_from_address
                            );
                          
                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                          }
                          this.setState({
                            delivery_array: delivery_array,
                          });
                        }}
                      />
                    </div>
                  </div>
              </div>              
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                  Recipient
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Recipient"}
                    type={"text"}
                    value={dd.drop_off_contact_name}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_contact_name = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Postal Code
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Postal Code"}
                    error={dd.postal_code_validated}
                    type={"number"}
                    // required={true}
                    labelComponent={
                      <CustomTooltip title={"Allow only 6 digits number"} />
                    }
                    onPaste={(e) => {
                      if (
                        /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                      ) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 6);
                    }}
                    value={dd.drop_off_postal_code}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_postal_code = e.target.value
                      delivery_array[deli_index].postal_code_validated = false
                      this.setState(
                        {
                          delivery_array : delivery_array,
                          
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                            this.recalculate_colleciton();
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2 position-relative">
                <div className="max-xs-width-control-900 text-end-style col-4 me-1">
                  Contact No.
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Contact No."}
                    type={"text"}
                    error={dd.contact_no_validated}
                    value={dd.drop_off_contact_no}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_contact_no = e.target.value
                      delivery_array[deli_index].contact_no_validated = false
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
                <FontAwesomeIcon icon={faPlusCircle} className="ps-2 text-end-style add_remove_btn_position" style={dd.is_collection_alt == true ? { display: 'none' } : { color: '#007CDE' }} 
                onClick={() => {
                  let delivery_array = this.state.delivery_array
                  delivery_array[deli_index].is_collection_alt = true
                  this.setState({
                    delivery_array : delivery_array,
                  });
                }}  />
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Company
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Company"}
                    type={"text"}
                    value={dd.drop_off_name}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_name = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-4">
                  Time Window*
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8 d-flex">
                  <Select
                    className="select-width-control-100 custom-input-component"
                    error={dd.drop_off_time_validated}
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"From"}
                    value={dd.from_time}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].from_time = e.target.value
                      delivery_array[deli_index].drop_off_time_validated = false
                      this.setState(
                        {
                          delivery_array : delivery_array,
                          
                        },
                        () => {
                          let timeslot = this.getTimeWindow(this.state.delivery_array[deli_index].from_time,this.state.delivery_array[deli_index].to_time);   
                          delivery_array[deli_index].time_window = timeslot  
                          this.setState({
                            delivery_array: delivery_array,
                          }, () => {
                            if (this.state.customerId > 0 && this.state.job_type != '') {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                dd.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                this.state.delivery_array[deli_index].time_window != '' ? this.state.delivery_array[deli_index].time_window : this.state.period_of_delivery,
                                dd.drop_off_date,
                                dd.drop_off_postal_code,
                                'Delivery',
                                dd.drop_off_address,
                                this.state.address,
                                null,
                                false,
                                dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                              );

                             let delivery_array = this.state.delivery_array;
                              delivery_array[deli_index].amount = client;
                              delivery_array[deli_index].driver_payout = payout;
                              delivery_array[deli_index].vendor_payout = subcon;
                              delivery_array[deli_index].base_charge = base_charge;
                              delivery_array[deli_index].surcharge = surcharge;
                              this.setState({
                                delivery_array: delivery_array,
                              });
                            }                        
                          });
                        });
                      if (this.state.job_steps.length > 0) {
                        let step = this.state.job_steps[0];

                        step.job_step_name =
                          e.target.value !== "" ? e.target.value : "Delivery";
                        let job_steps = [step];
                        this.setState({
                          job_steps,
                        });
                      }
                    }}
                  >
                    <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                  </Select>
                  <div className="text-end-style m-2">To</div>
                  <Select
                    className="select-width-control-100 custom-input-component"
                    error={dd.drop_off_time_validated}
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"From"}
                    value={dd.to_time}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].to_time = e.target.value
                      delivery_array[deli_index].drop_off_time_validated = false
                      this.setState(
                        {
                          delivery_array : delivery_array,
                          
                        },
                        () => {
                          let timeslot = this.getTimeWindow(this.state.delivery_array[deli_index].from_time,this.state.delivery_array[deli_index].to_time);   
                          delivery_array[deli_index].time_window = timeslot  
                          this.setState({
                            delivery_array: delivery_array,
                          }, () =>{
                            if (this.state.customerId > 0 && this.state.job_type != '') {
                              let {
                                client,
                                payout,
                                subcon,
                                base_charge,
                                surcharge,
                              } = this.state.grantedAccess.getPriceAndDriverPayout(
                                dd.selected_package,
                                this.state.customer_rate,
                                this.state.job_type,
                                dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                                dd.drop_off_date,
                                dd.drop_off_postal_code,
                                'Delivery',
                                dd.drop_off_address,
                                this.state.address,
                                null,
                                false,
                                dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                              );

                             let delivery_array = this.state.delivery_array;
                              delivery_array[deli_index].amount = client;
                              delivery_array[deli_index].driver_payout = payout;
                              delivery_array[deli_index].vendor_payout = subcon;
                              delivery_array[deli_index].base_charge = base_charge;
                              delivery_array[deli_index].surcharge = surcharge;
                              this.setState({
                                delivery_array: delivery_array,
                              });  
                            }                        
                          });
                        }
                      );
                      if (this.state.job_steps.length > 0) {
                        let step = this.state.job_steps[0];

                        step.job_step_name =
                          e.target.value !== "" ? e.target.value : "Delivery";
                        let job_steps = [step];
                        this.setState({
                          job_steps,
                        });
                      }
                    }}
                  >
                    <option value="0:00"> 0:00 </option>
                     <option value="0:30"> 0:30 </option>
                     <option value="1:00"> 1:00 </option>
                     <option value="1:30"> 1:30 </option>
                     <option value="2:00"> 2:00 </option>
                     <option value="2:30"> 2:30 </option>
                     <option value="3:00"> 3:00 </option>
                     <option value="3:30"> 3:30 </option>
                     <option value="4:00"> 4:00 </option>
                     <option value="4:30"> 4:30 </option>
                     <option value="5:00"> 5:00 </option>
                     <option value="5:30"> 5:30 </option>
                     <option value="6:00"> 6:00 </option>
                     <option value="6:30"> 6:30 </option>
                     <option value="7:00"> 7:00 </option>
                     <option value="7:30"> 7:30 </option>
                     <option value="8:00"> 8:00 </option>
                     <option value="8:30"> 8:30 </option>
                     <option value="9:00"> 9:00 </option>
                     <option value="9:30"> 9:30</option>
                     <option value="10:00"> 10:00 </option>
                     <option value="10:30"> 10:30 </option>
                     <option value="11:00"> 11:00 </option>
                     <option value="11:30"> 11:30 </option>
                     <option value="12:00"> 12:00 </option>
                     <option value="12:30"> 12:30 </option>
                     <option value="13:00"> 13:00 </option>
                     <option value="13:30"> 13:30 </option>
                     <option value="14:00"> 14:00 </option>
                     <option value="14:30"> 14:30 </option>
                     <option value="15:00"> 15:00 </option>
                     <option value="15:30"> 15:30 </option>
                     <option value="16:00"> 16:00 </option>
                     <option value="16:30"> 16:30 </option>
                     <option value="17:00"> 17:00 </option>
                     <option value="17:30"> 17:30 </option>
                     <option value="18:00"> 18:00 </option>
                     <option value="18:30"> 18:30 </option>
                     <option value="19:00"> 19:00 </option>
                     <option value="19:30"> 19:30 </option>
                     <option value="20:00"> 20:00 </option>
                     <option value="20:30"> 20:30 </option>
                     <option value="21:00"> 21:00 </option>
                     <option value="21:30"> 21:30 </option>
                     <option value="22:00"> 22:00 </option>
                     <option value="22:30"> 22:30 </option>
                     <option value="23:00"> 23:00 </option>
                     <option value="23:30"> 23:30 </option>
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style col-3">
                  Recipient Email
                </div>
                <div className="max-xs-width-control-900 ps-2 col-9">
                  <CustomInput
                    placeholder={"Recipient Email"}
                    type={"text"}
                    error={dd.email_validated}
                    value={dd.email_address}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].email_address = e.target.value
                      delivery_array[deli_index].email_validated = false
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className={dd.is_collection_alt == false ? "display-none" : "max-xs-width-control-900 d-flex py-2 position-relative"} >
                <div className="max-xs-width-control-900 text-end-style col-4">
                  Alternative Contact
                </div>
                <div className="max-xs-width-control-900 ps-2 col-8">
                  <CustomInput
                    placeholder={"Alternative Contact"}
                    type={"text"}
                    error={dd.alt_contact_no_validated }
                    value={dd.drop_off_alternate_contact}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_alternate_contact = e.target.value
                      delivery_array[deli_index].alt_contact_no_validated = false
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
                <FontAwesomeIcon icon={faXmarkCircle} className="ps-2 text-end-style add_remove_btn_position" style={dd.is_collection_alt == false ? { color: '#ff0000', display: 'none' } : { color: '#ff0000' }} 
                onClick={() => {
                  let delivery_array = this.state.delivery_array
                  delivery_array[deli_index].is_collection_alt = false
                  delivery_array[deli_index].drop_off_alternate_contact = ''
                  this.setState({
                    delivery_array : delivery_array,
                  });
                }} />
              </Col>
              <Col xs={12} md={12} lg={6}></Col>
             
              <Row className="max-xs-width-control-1200 p-4">
                <Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Price
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                  <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      type={"number"}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      disabled={this.state.currentRole == 'Customer Template Role' ? true : false}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      value={dd.amount}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let delivery_array = this.state.delivery_array
                        delivery_array[deli_index].amount = e.target.value
                        this.setState({
                          delivery_array : delivery_array,
                        });
                      }}
                    />
                  </div>
                </Col>
                {this.state.currentRole != 'Customer Template Role' && <><Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Driver Payout
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                  <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      placeholder={"Payout"}
                      value={dd.driver_payout}
                      type={"number"}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let delivery_array = this.state.delivery_array
                        delivery_array[deli_index].driver_payout = e.target.value
                        this.setState({
                          delivery_array : delivery_array,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={4}
                  className="max-xs-width-control-1200 d-flex py-2"
                >
                  <div className="max-xs-width-control-1200 text-end-style w-50">
                    Vendor Payout
                  </div>
                  <div className="max-xs-width-control-1200 ps-2 w-50">
                    <Input
                      className="form-control d-flex align-items-center p-0 input-new-order-form"
                      placeholder={"Payout"}
                      value={dd.vendor_payout}
                      type={"number"}
                      startAdornment={<span className="bg-light py-3 px-3 me-1">$</span>}
                      endAdornment={<span className="bg-light py-3 px-2">SGD</span>}
                      onPaste={(e) => {
                        if (
                          /^\d+(\.\d{1,5})?$/.test(
                            e.clipboardData.getData("Text")
                          ) == false
                        ) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value == '' || e.target.value == null || parseInt(e.target.value) == 0) {
                          apiUtil.toast("If all payout is 0 or empty, auto calculation will take place")
                        }
                        let delivery_array = this.state.delivery_array
                        delivery_array[deli_index].vendor_payout = e.target.value
                        this.setState({
                          delivery_array : delivery_array,
                        });
                      }}
                    />
                  </div>
                </Col></>}
              </Row>
            </Row>

            <Row className="max-xs-width-control-900 p-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="fs-5 font-medium text-start w-50">Package Details</div>
              <div className="text-end w-50"><CustomCheckbox
                    label={'Fill in pick up package details'}
                    checked={dd.collection_package}
                    onChange={(checked) => {
                      let delivery_array = this.state.delivery_array
                      if (checked == true) {
                        delivery_array[deli_index].xs_packages = this.state.collection_data.xs_packages
                        delivery_array[deli_index].s_packages = this.state.collection_data.s_packages
                        delivery_array[deli_index].m_packages = this.state.collection_data.m_packages
                        delivery_array[deli_index].l_packages = this.state.collection_data.l_packages
                        delivery_array[deli_index].oversized_packages = this.state.collection_data.oversized_packages
                        delivery_array[deli_index].pallets = this.state.collection_data.pallets
                        delivery_array[deli_index].collection_package = checked
                        delivery_array[deli_index].package_validated = false
                        delivery_array[deli_index].selected_package = _.cloneDeep(this.state.collection_data.selected_package)
                        this.setState({
                          delivery_array: delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                          });
                      } else {
                        delivery_array[deli_index].xs_packages = ''
                        delivery_array[deli_index].s_packages = ''
                        delivery_array[deli_index].m_packages = ''
                        delivery_array[deli_index].l_packages = ''
                        delivery_array[deli_index].oversized_packages = ''
                        delivery_array[deli_index].pallets = ''
                        delivery_array[deli_index].collection_package = false
                        delivery_array[deli_index].selected_package = []
                        this.setState({
                          delivery_array: delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                          });
                      }
                    }}
              /></div>
              </div>
              <hr />
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-start w-25 text-end">
                  Item Description
                </div>
                <div className="max-xs-width-control-900 ps-2 w-75">
                  <RTextEditor
                    editorState={description}
                    error={this.state.drop_off_descriptionValidated}
                    value={dd.drop_off_description}
                    placeholder={"Enter your description here..."}
                    onChange={(editorState) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].drop_off_description = editorState
                      this.setState({
                        delivery_array : delivery_array,
                        
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                  <div className="max-xs-width-control-900 text-end-style w-50">
                    <span data-tooltip="Size limit (L+W+H) <80cm / 1KG">
                      XS Packages <InfoIcon />
                    </span>
                  </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"5"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.xs_packages}
                    error={dd.package_validated}
                    onPaste={(e) => {
                      if (
                        /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                      ) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].xs_packages = e.target.value
                      delivery_array[deli_index].package_validated = false
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 1
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 1,
                            name: "XS",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 1
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package
                      this.setState(
                        {
                          delivery_array : delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <120cm / 5KG">
                    S Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"5"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.s_packages}
                    error={dd.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array;
                      delivery_array[deli_index].s_packages = e.target.value
                      delivery_array[deli_index].package_validated = false
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 2
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 2,
                            name: "S",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 2
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package
                      this.setState(
                        {
                          delivery_array : delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <150cm / 15KG">
                    M Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.m_packages}
                    error={dd.package_validated}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array;
                      delivery_array[deli_index].m_packages = e.target.value;
                      delivery_array[deli_index].package_validated = false
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 3
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 3,
                            name: "M",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 3
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package
                      this.setState(
                        {
                          delivery_array : delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Size limit (L+W+H) <180cm / 25KG">
                    L Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.l_packages}
                    error={dd.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array;
                      delivery_array[deli_index].l_packages = e.target.value;
                      delivery_array[deli_index].package_validated = false;
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 4
                      );
                      if (e.target.value >= 1 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 4,
                            name: "L",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 4
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package
                      this.setState(
                        {
                          delivery_array : delivery_array,
                        },
                        () => {
                          if (this.state.customerId > 0) {
                            let {
                              client,
                              payout,
                              subcon,
                              base_charge,
                              surcharge,
                            } = this.state.grantedAccess.getPriceAndDriverPayout(
                              dd.selected_package,
                              this.state.customer_rate,
                              this.state.job_type,
                              dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                              dd.drop_off_date,
                              dd.drop_off_postal_code,
                              'Delivery',
                              dd.drop_off_address,
                              this.state.address,
                              null,
                              false,
                              dd.drop_off_latitude, dd.drop_off_longitude,
                                dd.no_of_movers, dd.zip_from_address
                            );

                           let delivery_array = this.state.delivery_array;
                            delivery_array[deli_index].amount = client;
                            delivery_array[deli_index].driver_payout = payout;
                            delivery_array[deli_index].vendor_payout = subcon;
                            delivery_array[deli_index].base_charge = base_charge;
                            delivery_array[deli_index].surcharge = surcharge;
                            this.setState({
                              delivery_array: delivery_array,
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="Please check with Ops">
                    Oversized Packages <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.oversized_packages}
                    error={dd.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array;
                      delivery_array[deli_index].oversized_packages = e.target.value;
                      delivery_array[deli_index].package_validated = false
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 6
                      );
                      if (e.target.value > 0 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 6,
                            name: "Oversized Package",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 6
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package 
                      this.setState({
                          delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  <span data-tooltip="LxWxH (1.1M x 1.2M x 1.5M) / 100KG">
                    Pallets <InfoIcon />
                  </span>
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"3"}
                    type={"number"}
                    inputProps={{min: 0}}
                    value={dd.pallets}
                    error={dd.package_validated}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-" || e.key == ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array;
                      delivery_array[deli_index].pallets = e.target.value;
                      delivery_array[deli_index].package_validated = false
                      let selected_package = delivery_array[deli_index].selected_package;
                      let index = selected_package.findIndex(
                        (dd) => dd.package_id === 5
                      );
                      if (e.target.value > 0 && e.target.value !== "") {
                        if (index === -1) {
                          let pack = {
                            package_id: 5,
                            name: "Pallets",
                            package_quantity: e.target.value,
                          };
                          selected_package.push(pack);
                        } else {
                          selected_package[index]["package_quantity"] =
                            e.target.value;
                        }
                      } else {
                        selected_package = selected_package.filter(
                          (pp) => pp.package_id !== 5
                        );
                      }
                      delivery_array[deli_index].selected_package = selected_package
                      this.setState({
                        delivery_array : delivery_array,
                      },
                      () => {
                        if (this.state.customerId > 0) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            dd.selected_package,
                            this.state.customer_rate,
                            this.state.job_type,
                            dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                            dd.drop_off_date,
                            dd.drop_off_postal_code,
                            'Delivery',
                            dd.drop_off_address,
                            this.state.address,
                            null,
                            false,
                            dd.drop_off_latitude, dd.drop_off_longitude,
                            dd.no_of_movers, dd.zip_from_address
                          );

                         let delivery_array = this.state.delivery_array;
                          delivery_array[deli_index].amount = client;
                          delivery_array[deli_index].driver_payout = payout;
                          delivery_array[deli_index].vendor_payout = subcon;
                          delivery_array[deli_index].base_charge = base_charge;
                          delivery_array[deli_index].surcharge = surcharge;
                          this.setState({
                            delivery_array: delivery_array,
                          });
                        }
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Depalletization Request
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <Select
                    className="max-xs-width-control-900 custom-input-component"
                    style={{
                      width: "100%",
                      border: "1px solid #E0E1E4",
                      borderRadius: "4px",
                      padding: "",
                      backgroundColor: "transparent",
                    }}
                    placeholder={"Depalletization Request"}
                    value={dd.depalletization_request}
                    native
                    disableUnderline
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].depalletization_request = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                        
                      });
                    }}
                  >
                    <option value=""> Select Depalletization Request </option>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                  </Select>
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Size in cm<br />(L + W + H)
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Size"}
                    type={"text"}
                    value={dd.size_l_w_h}
                    // onPaste={(e) => {
                    //   if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                    //     e.preventDefault();
                    //     return false;
                    //   }
                    // }}
                    // onKeyDown={(e) => {
                    //   if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                    //     e.preventDefault();
                    //   }
                    // }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].size_l_w_h = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Weight (KG)
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Weight"}
                    type={"text"}
                    value={dd.weight}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].weight = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col>
            { this.state.currentRole != 'Customer Template Role' ? <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Waiting Time
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"Waiting Time"}
                    type={"text"}
                    value={dd.waiting_time}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].waiting_time = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      });
                    }}
                  />
                </div>
              </Col> : ''}
              <Col xs={12} md={4} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-end-style w-50">
                  Additional Manpower
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomInput
                    placeholder={"No, movers"}
                    type={"number"}
                    value={dd.no_of_movers}
                    onPaste={(e) => {
                      if (/^\d+$/.test(e.clipboardData.getData("Text")) == false) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "e" || e.key == "E" || e.key == "-" || e.key == "-" || e.key == "-") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].no_of_movers = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                      },
                      () => {
                        if (this.state.customerId > 0) {
                          let {
                            client,
                            payout,
                            subcon,
                            base_charge,
                            surcharge,
                          } = this.state.grantedAccess.getPriceAndDriverPayout(
                            dd.selected_package,
                            this.state.customer_rate,
                            this.state.job_type,
                            dd.time_window != '' ? dd.time_window : this.state.period_of_delivery,
                            dd.drop_off_date,
                            dd.drop_off_postal_code,
                            'Delivery',
                            dd.drop_off_address,
                            this.state.address,
                            null,
                            false,
                            dd.drop_off_latitude, dd.drop_off_longitude,
                            dd.no_of_movers, dd.zip_from_address
                          );

                         let delivery_array = this.state.delivery_array;
                          delivery_array[deli_index].amount = client;
                          delivery_array[deli_index].driver_payout = payout;
                          delivery_array[deli_index].vendor_payout = subcon;
                          delivery_array[deli_index].base_charge = base_charge;
                          delivery_array[deli_index].surcharge = surcharge;
                          this.setState({
                            delivery_array: delivery_array,
                          });
                        }
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={12} className="max-xs-width-control-900 d-flex py-2">
                <div className="max-xs-width-control-900 text-start w-25 text-end">
                  Notes to Driver-Partner
                </div>
                <div className="max-xs-width-control-900 ps-2 w-75">
                  <CustomInput
                    placeholder={"Enter driver notes here..."}
                    value={dd.driver_notes}
                    multiline={true}
                    rows={5}
                    required={false}
                    onChange={(e) => {
                      let delivery_array = this.state.delivery_array
                      delivery_array[deli_index].driver_notes = e.target.value
                      this.setState({
                        delivery_array : delivery_array,
                        
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
              </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        })}
        </Container>
      )

   

  };

  customersDetailsForm = () => {
    return (
      <Box>
        <Grid container>
          {/* <Grid item xs={12}>
                  <CustomLabel
                      label={'Customers Details'}
                      weight={'bold'}
                      size={'md'}
                  />
                  <Divider light />
              </Grid> */}
          <Grid item xs={12}>
            <Container maxWidth={"xs"}>
              <Box clone>
                <Grid container>
                  {this.isCustomerRequired ? (
                    <>
                      <Box clone>
                        <Grid item xs={12}>
                          <CustomAutosuggest
                            label={"Order Placed By"}
                            placeholder={
                              "Enter any keyword to search for customers"
                            }
                            labelComponent={
                              <CustomTooltip
                                title={
                                  "Select from your list of customers. Create one if u dont have them in your list."
                                }
                              />
                            }
                            // helperText={<Box>
                            //   <Grid container alignItems={'center'}>
                            //       <Box clone>
                            //           <Grid item xs={'auto'}>Customer not in list?</Grid>
                            //       </Box>
                            //       <Box clone>
                            //           <Grid item xs={'auto'}>
                            //               <CustomButton
                            //                   className={'underline'}
                            //                   href={'/'}
                            //                   color={'primary'}
                            //                   onClick={(e) => {
                            //                       e.preventDefault();
                            //                       e.stopPropagation();

                            //                       this.setState({
                            //                         openDialogCustomer: true,
                            //                         itemDialogCustomer: null,
                            //                       });
                            //                   }}
                            //               >
                            //                   Create customer
                            //               </CustomButton>
                            //           </Grid>
                            //       </Box>
                            //   </Grid>
                            // </Box>}
                            items={this.state.customerItems}
                            isLoading={this.state.customerItemsIsLoading}
                            value={this.state.customerName}
                            // error={this.state.customerNameValidated}
                            //required={true}
                            onChange={(value) => {
                              if (value) {
                                if (this.state.customerId > 0) {
                                  this.setState(
                                    {
                                      //customerNameValidated: false,
                                      customerName: value.label,
                                      customerId: value.value,
                                      customerItem: value.item,
                                      
                                    },
                                    () => {
                                      this.setState({
                                        openDialog: true,
                                        openDialogItem: value.item,
                                      });
                                    }
                                  );
                                } else {
                                  this.setState(
                                    {
                                      //customerNameValidated: false,
                                      customerName: value.label,
                                      customerId: value.value,
                                      customerItem: value.item,
                                      
                                    },
                                    () => {
                                      this.addNewCustomerDetails(value.item);
                                    }
                                  );
                                }
                              } else {
                                this.setState(
                                  {
                                    //customerNameValidated: true,
                                    customerName: "",
                                    customerId: "",
                                    customerItem: null,
                                    
                                  },
                                  () => {
                                    this.setState({
                                      branchItems: [],
                                      departmentItems: [],
                                    });
                                  }
                                );
                              }
                            }}
                            onSearch={(value, e) => {
                              this.setState(
                                {
                                  customerName: value,
                                  
                                },
                                () => {
                                  this.callCustomerReadApi(value, (arr) => {
                                    this.setState(
                                      {
                                        customerItems: arr.sort((a, b) => {
                                          const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                                          const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                                          if (nameA < nameB) {
                                            return -1;
                                          }
                                          if (nameA > nameB) {
                                            return 1;
                                          }
                                        
                                          // names must be equal
                                          return 0;
                                        }),
                                      },
                                      () => {
                                        e.showAllSuggestions();
                                      }
                                    );
                                  });
                                }
                              );
                            }}
                            onClick={(value, e) => {
                              this.callCustomerReadApi(value, (arr) => {
                                this.setState(
                                  {
                                    customerItems: arr.sort((a, b) => {
                                      const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                                      const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                    
                                      // names must be equal
                                      return 0;
                                    }),
                                  },
                                  () => {
                                    e.showAllSuggestions();
                                  }
                                );
                              });
                            }}
                            onInit={() => {
                              this.callCustomerReadApi();
                            }}
                          />
                        </Grid>
                      </Box>
                      <Box clone>
                        <Grid item xs={12}>
                          <CustomInput
                            label={"Recipient Name"}
                            placeholder={"Recipient Name"}
                            value={this.state.drop_off_name}
                            // error={this.state.drop_off_contact_nameValidated}
                            //required={true}
                            onChange={(e) => {
                              this.setState({
                                drop_off_name: e.target.value,
                                //drop_off_nameValidated: (e.target.value !== '') ? false : true,
                                
                              });
                            }}
                          />
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <Box clone>
                      <Grid item xs={12}>
                        <CustomInput
                          label={"Recipient Name"}
                          placeholder={"Recipient Name"}
                          value={this.state.drop_off_name}
                          //error={this.state.drop_off_contact_nameValidated}
                          //required={true}
                          onChange={(e) => {
                            this.setState({
                              drop_off_name: e.target.value,
                              //drop_off_nameValidated: (e.target.value !== '') ? false : true,
                              
                            });
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Recipient Email"}
                        placeholder={"Recipient Email"}
                        type={"email"}
                        value={this.state.notification_email}
                        error={this.state.notification_emailValidated}
                        onChange={(e) => {
                          this.setState({
                            notification_email: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Recipient Contact"}
                        placeholder={"Recipient Contact"}
                        value={this.state.drop_off_contact_no}
                        error={this.state.drop_off_contact_noValidated}
                        errorText={"Phone number is invalid"}
                        // required={true}
                        onChange={(e) => {
                          this.setState({
                            drop_off_contact_no: e.target.value.replaceAll(
                              /\s/g,
                              ""
                            ),
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>

                  <Box clone>
                    <Grid item xs={12}>
                      <CustomGoogleMapSearch
                        label={"Address*"}
                        placeholder={"Address"}
                        value={this.state.drop_off_address}
                        error={this.state.drop_off_addressValidated}
                        required={true}
                        onChange={(e) => {
                          this.deliveryAddressOnChangeHandler(e.target.value);
                          this.setState({
                            drop_off_address: e.target.value,
                            drop_off_addressValidated:
                              e.target.value != "" && e.target.value != null
                                ? false
                                : true,
                          });
                        }}
                        onPlacesChanged={(place, e) => {
                          // this.deliveryAddressOnChangeHandler(place)
                          let address = apiUtil.getGmapAddress(place);
                          this.setState({
                            // job_steps: [],
                            drop_off_address: address,
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng(),
                            drop_off_addressValidated:
                              address !== "" ? false : true,
                          });
                          this.deliveryAddressOnChangeHandler(address);
                        }}
                      // onBlur={(e)=> {
                      //   this.deliveryAddressOnChangeHandler(this.state.drop_off_address)
                      // }}
                      // onClick ={()=> {
                      //   this.setState({
                      //     job_steps: []
                      //   });
                      // }}
                      />
                    </Grid>
                  </Box>

                  <Box clone>
                    <Grid item xs={12}>
                      <span></span>
                      <CustomInput
                        label={"Postal Code"}
                        placeholder={"Postal Code"}
                        value={this.state.drop_off_postal_code}
                        error={this.state.drop_off_postal_codeValidated}
                        type={"number"}
                        // required={true}
                        labelComponent={<CustomTooltip title={"postal code"} />}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        onChange={(e) => {
                          this.setState({
                            drop_off_postal_code: e.target.value,
                            
                            drop_off_postal_codeValidated:
                              e.target.value === "" ? true : false,
                          });
                        }}
                      />
                    </Grid>
                  </Box>

                  {/* <Box clone>
                                  <Grid item xs={12}>
                                      <CustomSelectOption
                                          label={'Department'}
                                          placeholder={'Select department'}
                                          value={this.state.department_id}
                                          items={this.state.departmentItems}
                                          onChange={(value, item) => {
                                              this.setState({
                                                department_id: value,
                                                departmentItem: item,
                                                
                                              }, () => {
                                                let picItems = [];
                                                if(item && item.item && item.item.contact_people && item.item.contact_people.length > 0){
                                                  picItems = item.item.contact_people.map((item, i) => {
                                                    return {
                                                      uuid: uuid(),
                                                      id: item.id,
                                                      drop_off_name: item.first_name,
                                                      notification_email: item.email,
                                                      drop_off_contact_no: item.contact_no,
                                                      remove: false,
                                                    }
                                                  });
                                                }
                                                this.setState({
                                                  picItems: picItems,
                                                });
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box> */}

                  {/* <Box clone>
                                  <Grid item xs={12}>
                                      <CustomInput
                                          label={'Tracking Number'}
                                          placeholder={'Tracking Number'}
                                          type={'text'}
                                          value={this.state.item_tracking_number}
                                          onChange={(e) => {
                                              this.setState({
                                                  item_tracking_number: e.target.value,
                                                  
                                              });
                                          }}
                                      />
                                  </Grid>
                              </Box> */}
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"D.O Number"}
                        placeholder={"D.O Number"}
                        type={"text"}
                        value={this.state.do_number}
                        onChange={(e) => {
                          this.setState({
                            do_number: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>

                  {this.state.grantedAccess.isPermissionActionAllowed(
                    this.state.currentRole,
                    "Zone"
                  ) &&
                    this.state.currentRole !== "Customer Template Role" && (
                      <Box clone>
                        <Grid item xs={12}>
                          <Box className="max-xs-width-control-900 custom-label-component">
                            <InputLabel>Zone</InputLabel>
                          </Box>
                          <Select
                            className="max-xs-width-control-900 custom-input-component"
                            style={{
                              width: "100%",
                              border: "1px solid #E0E1E4",
                              borderRadius: "4px",
                              padding: "",
                              backgroundColor: "transparent",
                            }}
                            placeholder={"Select Zone here..."}
                            value={this.state.zone_id}
                            native
                            required={false}
                            disableUnderline
                            onChange={(e) => {
                              this.setState({
                                zone_id: e.target.value,
                                
                              });
                            }}
                          >
                            <option value=""> Select Zone </option>
                            {this.state.zoneItems &&
                              this.state.zoneItems.length > 0 &&
                              Object.keys(this.state.zoneItems).map((zone) => (
                                <option
                                  key={this.state.zoneItems[zone].id}
                                  value={this.state.zoneItems[zone].id}
                                >
                                  {this.state.zoneItems[zone].polygon_name}
                                </option>
                              ))}
                          </Select>
                        </Grid>
                      </Box>
                    )}

                  <Box clone>
                    <Grid item xs={12}>
                      <Box className="max-xs-width-control-900 custom-label-component">
                        <CustomLabel
                          label={"Packages"}
                          labelComponent={
                            <CustomTooltip
                              title={
                                "Job Type need to be choosen first to calculate driver payout and price."
                              }
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                  {this.state.grantedAccess.admin_roles.includes(
                    this.state.currentRole
                  ) && (
                      <Box clone mt={3}>
                        <Grid item xs={12}>
                          <CustomInput
                            label={"Price"}
                            placeholder={"0.00"}
                            type={"number"}
                            color={"secondary"}
                            startAdornment={<p>$</p>}
                            // endAdornment={<span>SGD</span>}
                            value={this.state.amount}
                            // error={this.state.amountValidated}
                            // required={true}
                            onChange={(e) => {
                              this.setState({
                                amount: e.target.value,
                                // amountValidated: (e.target.value !== '') ? false : true,
                                
                              });
                            }}
                          />
                        </Grid>
                      </Box>
                    )}
                  <Box clone>
                    <Grid item xs={12}>
                      {this.state.grantedAccess.isPermissionActionAllowed(
                        this.state.currentRole,
                        "Driver Payout"
                      ) && (
                          <CustomInput
                            label={"Driver Payout"}
                            placeholder={"Enter driver payout here..."}
                            value={this.state.driver_payout}
                            type={"number"}
                            startAdornment={<p>$</p>}
                            endAdornment={<span>SGD</span>}
                            required={false}
                            onChange={(e) => {
                              this.setState({
                                driver_payout: e.target.value,
                              });
                            }}
                          />
                        )}
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Document"}
                        placeholder={"Document"}
                        type={"text"}
                        value={this.state.document}
                        onChange={(e) => {
                          this.setState({
                            document: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Boxes"}
                        placeholder={"5"}
                        type={"text"}
                        value={this.state.boxes}
                        onChange={(e) => {
                          this.setState({
                            boxes: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Pallets"}
                        placeholder={"3"}
                        type={"text"}
                        value={this.state.pallets}
                        onChange={(e) => {
                          this.setState({
                            pallets: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>

                  <Box clone>
                    <Grid item xs={12}>
                      <CustomInput
                        label={"Cartons"}
                        placeholder={"3"}
                        type={"text"}
                        value={this.state.cartons}
                        onChange={(e) => {
                          this.setState({
                            cartons: e.target.value,
                            
                          });
                        }}
                      />
                    </Grid>
                  </Box>

                  <Box clone>
                    <Grid item xs={12}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={"auto"}>
                            <CustomCheckbox
                              label={"Notify recipient"}
                              checked={this.state.is_notify_customer}
                              onChange={(checked) => {
                                this.setState(
                                  {
                                    is_notify_customer: checked,
                                    
                                  },
                                  () => {
                                    if (!checked) {
                                      this.setState({
                                        is_tracking_link_required: false,
                                      });
                                    }
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={"auto"}>
                            <CustomCheckbox
                              label={"Send tracking link"}
                              checked={this.state.is_tracking_link_required}
                              onChange={(checked) => {
                                this.setState(
                                  {
                                    is_tracking_link_required: checked,
                                    
                                  },
                                  () => {
                                    if (checked) {
                                      this.setState({
                                        is_notify_customer: true,
                                      });
                                    }
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    );
  };

  personInChargeForm = () => {
    if (this.isCustomerRequired) {
      return (
        <Box>
          <DepartmentPICList
            items={this.state.picItems}
            onAddNew={(data) => {
              this.setState({
                picItems: data,
                
              });
            }}
            onEdit={(data) => {
              this.setState({
                picItems: data,
                
              });
            }}
            onRemove={(data) => {
              this.setState({
                picItems: data,
                
              });
            }}
          />
        </Box>
      );
    }
  };

  itemsInJobForm = () => {
    return (
      <Box>
        <ItemsInJobList
          items={this.state.itemsInJobList}
          onAddNew={(data) => {
            this.setState({
              itemsInJobList: data,
              
            });
          }}
          onEdit={(data) => {
            this.setState({
              itemsInJobList: data,
              
            });
          }}
          onRemove={(data) => {
            this.setState({
              itemsInJobList: data,
              
            });
          }}
        />
      </Box>
    );
  };

  jobStepForm = () => {
    return (
      <Box
        style={{
          width: "102.4%",
          maxWidth: "102.4%",
          margin: "0px",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <StepsList
          items={this.state.job_steps}
          isAddJob={this.state.id === 0}
          onAddNew={(data) => {
            this.setState({
              job_steps: data,
              
            });
          }}
          onEdit={(data) => {
            this.setState({
              job_steps: data,
              
            });
          }}
          onRemove={(data) => {
            this.setState({
              job_steps: data,
              
            });
          }}
        />
      </Box>
    );
  };

  assignWorkersForm = () => {
    return (
      <>
        {/* <Grid container>
            <Grid item xs={12} className="max-xs-width-control-900 pt-5">
                <CustomLabel
                    label={<React.Fragment>Assign Driver <small className={'muted'}>Optional</small></React.Fragment>}
                    labelComponent={<CustomTooltip title={'The job status will be \'Unassigned\' if no drivers are assigned here.'} />}
                    weight={'bold'}
                    size={'md'}
                />
                <Divider light />
            </Grid> */}
        {/* <Grid item xs={12}>
                <Container maxWidth={'xs'}> */}
        <Grid item xs={12} className="max-xs-width-control-900 pt-5">
          {/* <Container maxWidth={'xs'}> */}

          <Box clone>
            <Grid item xs={12}>
              {this.state.grantedAccess.isPermissionActionAllowed(
                this.state.currentRole,
                "Driver & Manpower Listing"
              ) && (
                  <CustomAutosuggest
                    label={"Driver-Partner"}
                    placeholder={"Enter any keyword to search for Driver"}
                    items={this.state.workerItems}
                    isLoading={this.state.workerItemsIsLoading}
                    value={this.state.workerName}
                    onChange={(value) => {
                      if (value) {
                        this.setState(
                          {
                            workerName: value.label,
                            workerId: value.value,
                            workerItem: value.item,
                            
                          },
                          () => {
                            let orderStatusItem = apiUtil.customFilter(
                              this.state.orderStatuses,
                              "label",
                              "assigned"
                            );
                            if (orderStatusItem) {
                              this.setState({
                                order_status: orderStatusItem.item,
                                order_status_id: orderStatusItem.value,
                              });
                            }
                          }
                        );
                      } else {
                        this.setState(
                          {
                            workerName: "",
                            workerId: "",
                            workerItem: null,
                            
                          },
                          () => {
                            let orderStatusItem = apiUtil.customFilter(
                              this.state.orderStatuses,
                              "label",
                              "not assigned"
                            );
                            if (orderStatusItem) {
                              this.setState({
                                order_status: orderStatusItem.item,
                                order_status_id: orderStatusItem.value,
                              });
                            }
                          }
                        );
                      }
                    }}
                    onSearch={(value, e) => {
                      this.setState(
                        {
                          workerName: value,
                          
                        },
                        () => {
                          e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase())));
                        }
                      );
                    }}
                    onClick={(value, e) => {
                      e.showNewSuggestions(this.state.workerItems.filter(dd => dd.label.includes(value) || dd.label.toLowerCase().includes(value) || dd.label.toLowerCase().includes(value.toLowerCase())));
                    }}
                    onInit={() => {
                      this.callWorkerDriverReadApi();
                    }}
                  />
                )}
            </Grid>
          </Box>

          <Box clone>
            <Grid item xs={12}>
              {this.state.grantedAccess.isPermissionActionAllowed(
                this.state.currentRole,
                "Driver & Manpower Listing"
              ) && (
                  <CustomAutosuggest
                    label={"Driver-Partner Assistant"}
                    placeholder={
                      "Enter any keyword to search for Driver Assistant"
                    }
                    // helperText={<Box>
                    //     <Grid container alignItems={'center'}>
                    //         <Box clone>
                    //             <Grid item xs={'auto'}>Logistic assistant not in list?</Grid>
                    //         </Box>
                    //         <Box clone>
                    //             <Grid item xs={'auto'}>
                    //                 <CustomButton
                    //                     className={'underline'}
                    //                     href={'/'}
                    //                     color={'primary'}
                    //                     onClick={(e) => {
                    //                         e.preventDefault();
                    //                         e.stopPropagation();

                    //                         this.setState({
                    //                           openDialogLogisticsAssistance: true,
                    //                           itemDialogLogisticsAssistance: null,
                    //                         });
                    //                     }}
                    //                 >
                    //                     Create new Logistics assistant
                    //                 </CustomButton>
                    //             </Grid>
                    //         </Box>
                    //     </Grid>
                    // </Box>}
                    items={this.state.manPowerItems}
                    isLoading={this.state.manPowerItemsIsLoading}
                    value={this.state.manPowerName}
                    onChange={(value) => {
                      if (value) {
                        this.setState({
                          manPowerName: value.label,
                          manPowerId: value.value,
                          manPowerItem: value.item,
                          
                        });
                      } else {
                        this.setState({
                          manPowerName: "",
                          manPowerId: "",
                          manPowerItem: null,
                        });
                      }
                    }}
                    onSearch={(value, e) => {
                      this.setState(
                        {
                          manPowerName: value,
                          
                        },
                        () => {
                          this.callWorkerManPowerReadApi(value, (arr) => {
                            this.setState(
                              {
                                manPowerItems: arr,
                              },
                              () => {
                                e.showAllSuggestions();
                              }
                            );
                          });
                        }
                      );
                    }}
                    onClick={(value, e) => {
                      this.callWorkerManPowerReadApi(value, (arr) => {
                        this.setState(
                          {
                            manPowerItems: arr,
                          },
                          () => {
                            e.showAllSuggestions();
                          }
                        );
                      });
                    }}
                    onInit={() => {
                      this.callWorkerManPowerReadApi();
                    }}
                  />
                )}
            </Grid>
          </Box>
        </Grid>
        {/* </Container>
            </Grid> */}
        {/* </Grid>*/}
      </>
    );
  };
  /* END FORM */

  addNewCustomerDetails = (data) => {
    const { isOnBoarding, onBoardingCustomer } = this.context;

    if (!isOnBoarding) {
      /* ADD NEW CUSTOMER DETAILS */
      if (data && data.id) {
        this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
          let customerAddresses =
            customerData &&
              customerData.customer_addresses &&
              customerData.customer_addresses.length > 0
              ? customerData.customer_addresses
              : [];
          let branchItems = customerAddresses.map((item, i) => {
            return {
              value: item.id,
              label: item.name,
              item: item,
            };
          });

          let departmentItems = [];
          if (
            customerData &&
            customerData.departments &&
            customerData.departments.length > 0
          ) {
            departmentItems = customerData.departments.map((item, i) => {
              return {
                value: item.id,
                label: item.name,
                item: item,
              };
            });
          }

          this.setState(
            {
              branchItems: branchItems,
              departmentItems: departmentItems,
            },
            () => {
              let drop_off_address = "";
              let drop_off_postal_code = "";
              let order_branch_id =
                data &&
                  data.customer_addresses &&
                  data.customer_addresses[0] &&
                  data.customer_addresses[0].id
                  ? data.customer_addresses[0].id
                  : "";
              let drop_off_addressItem = branchItems.filter(
                (x) => x.value === order_branch_id
              );
              if (drop_off_addressItem && drop_off_addressItem.length > 0) {
                drop_off_address = drop_off_addressItem[0].item.address_line1;
                drop_off_postal_code = drop_off_addressItem[0].item.postal_code;
              }
              this.setState({
                // order_branch_id: order_branch_id,
                // drop_off_address: drop_off_address,
                // drop_off_postal_code: drop_off_postal_code
              });

              let departmentItem = null;
              let department_id =
                this.state.departmentItems &&
                  this.state.departmentItems[0] &&
                  this.state.departmentItems[0].value
                  ? this.state.departmentItems[0].value
                  : "";
              let departmentArray =
                this.state.departmentItems &&
                  this.state.departmentItems.length > 0
                  ? this.state.departmentItems.filter(
                    (x) => x.value === department_id
                  )
                  : [];
              if (departmentArray && departmentArray.length > 0) {
                departmentItem = departmentArray[0];
              }
              this.setState({
                // department_id: department_id,
                // departmentItem: departmentItem,
              });

              let picItems = [];
              if (
                departmentItem &&
                departmentItem.item &&
                departmentItem.item.contact_people &&
                departmentItem.item.contact_people.length > 0
              ) {
                for (
                  let x = 0;
                  x < departmentItem.item.contact_people.length;
                  x++
                ) {
                  let item = departmentItem.item.contact_people[x];

                  picItems.push({
                    uuid: uuid(),
                    id: item.id,
                    drop_off_name: item.first_name,
                    drop_off_contact_no: item.contact_no,
                    notification_email: item.email,
                    remove: false,
                  });

                  this.setState({
                    // notification_email: item.email,
                    // drop_off_name: item.first_name + item.last_name,
                    // drop_off_contact_name: item.first_name,
                    // drop_off_contact_no: item.contact_no,
                  });
                }
              }

              this.setState({
                picItems: picItems,
              });

              /* STEPS SECTION */
              let orderStatusItem = apiUtil.customFilter(
                this.state.jobStepStatuses,
                "label",
                "Pending"
              );
              let job_step_status = orderStatusItem ? orderStatusItem : null;

              let job_step_pic = "";
              let job_step_pic_contact = "";
              let picItem =
                picItems && picItems.length > 0 ? picItems[0] : null;
              if (picItem) {
                job_step_pic = picItem.drop_off_name;
                job_step_pic_contact = picItem.drop_off_contact_no;
              }

              let job_steps = this.state.job_steps;
              if (job_steps && job_steps.length > 0) {
                job_steps = job_steps.map((item, i) => {
                  item.remove = true;
                  return item;
                });
              }

              let step = {
                uuid: uuid(),
                job_step_name: this.state.job_type
                  ? this.state.job_type
                  : "Delivery",
                is_signature_required: true,
                is_scan_required: false,
                description: "",
                order_sequence: 1,
                remove: false,
                job_step_status_id:
                  job_step_status &&
                    job_step_status.value &&
                    job_step_status.value !== ""
                    ? job_step_status.value
                    : 1,
                job_step_status: job_step_status,
                location: drop_off_address,
                latitude: 0,
                longitude: 0,
                job_step_pic: job_step_pic,
                job_step_pic_contact: job_step_pic_contact,
              };

              // this.callGeoCoderAddressApi(step.location, (data) => {
              //     if(data){
              //       this.setState({
              //         latitude: data.lat,
              //         longitude: data.lng
              //       },() => {
              //         step.latitude = data.lat;
              //         step.longitude = data.lng;
              //       })
              //     }
              // });

              job_steps.push(step);

              this.setState({
                job_steps: job_steps,
              });
              /* END STEPS SECTION */
            }
          );
        });
      }
      /* END ADD NEW CUSTOMER DETAILS */
    } else {
      let customerData = null;
      try {
        customerData = JSON.parse(onBoardingCustomer);
      } catch (e) { }

      if (customerData) {
        let customerAddresses =
          customerData &&
            customerData.addresses &&
            customerData.addresses.length > 0
            ? customerData.addresses
            : [];
        let branchItems = customerAddresses.map((item, i) => {
          return {
            value: item.uuid,
            label: item.name,
            item: item,
          };
        });

        let departmentItems = [];
        if (
          customerData &&
          customerData.departments &&
          customerData.departments.length > 0
        ) {
          departmentItems = customerData.departments.map((item, i) => {
            return {
              value: item.uuid,
              label: item.department_name,
              item: item,
            };
          });
        }

        this.setState(
          {
            branchItems: branchItems,
            departmentItems: departmentItems,
          },
          () => {
            let drop_off_address = "";
            let order_branch_id =
              data &&
                data.addresses &&
                data.addresses[0] &&
                data.addresses[0].id
                ? data.addresses[0].id
                : "";
            let drop_off_addressItem = branchItems.filter(
              (x) => x.value === order_branch_id
            );
            if (drop_off_addressItem && drop_off_addressItem.length > 0) {
              drop_off_address = drop_off_addressItem[0].item.address_line1;
            }
            this.setState({
              order_branch_id: order_branch_id,
              drop_off_address: drop_off_address,
            });

            let departmentItem = null;
            let department_id =
              this.state.departmentItems &&
                this.state.departmentItems[0] &&
                this.state.departmentItems[0].uuid
                ? this.state.departmentItems[0].uuid
                : "";
            let departmentArray =
              this.state.departmentItems &&
                this.state.departmentItems.length > 0
                ? this.state.departmentItems.filter(
                  (x) => x.uuid === department_id
                )
                : [];
            if (departmentArray && departmentArray.length > 0) {
              departmentItem = departmentArray[0];
            }
            this.setState({
              department_id: department_id,
              departmentItem: departmentItem,
            });

            let picItems = [];
            if (
              departmentItem &&
              departmentItem.item &&
              departmentItem.item.contact_people &&
              departmentItem.item.contact_people.length > 0
            ) {
              for (
                let x = 0;
                x < departmentItem.item.contact_people.length;
                x++
              ) {
                let item = departmentItem.item.contact_people[x];

                picItems.push({
                  uuid: uuid(),
                  id: item.id,
                  drop_off_name: item.first_name,
                  drop_off_contact_no: item.contact_no,
                  notification_email: item.email,
                  remove: false,
                });

                this.setState({
                  notification_email: item.email,
                  drop_off_name: item.first_name + item.last_name,
                  drop_off_contact_name: item.first_name,
                  drop_off_contact_no: item.contact_no,
                });
              }
            }

            this.setState({
              picItems: picItems,
            });
          }
        );
      }
    }
  };

  prepareCopyDataForm = (copyData) => {
    this.callDetailsApi(copyData.id, (data, itemsInTheJob) => {
      /* VENDOR */
      let vendorItems = [];
      let vendorItem = null;
      if (data && data.vendor) {
        vendorItem = {
          value: data.vendor_id ? data.vendor_id : "",
          label:
            data.vendor.application_company &&
              data.vendor.application_company.company_name &&
              data.vendor.application_company.company_name !== ""
              ? data.vendor.application_company.company_name
              : "",
          item: data.vendor ? data.vendor : null,
        };
        vendorItems.push(vendorItem);
      }
      //CHECK BOXES, DOCUMENT AND OTHERS
      if (data && data.extra_data) {
        let extraData = JSON.parse(data.extra_data);
        this.setState({
          boxes: extraData && extraData.boxes ? extraData.boxes : "",
          document: extraData && extraData.document ? extraData.document : "",
          pallets: extraData && extraData.pallets ? extraData.pallets : "",
          cartons: extraData && extraData.cartons ? extraData.cartons : "",
        });
      }
      this.setState({
        job_type: data && data.job_type ? data.job_type : "",
        assign_type:
          data && data.assign_type && data.assign_type === "vendor"
            ? false
            : true,
        vendorNameValidated: false,
        vendorName: vendorItem && vendorItem.label ? vendorItem.label : "",
        vendorId: vendorItem && vendorItem.value ? vendorItem.value : "",
        vendorItem: vendorItem && vendorItem.item ? vendorItem.item : null,
        vendorItems: vendorItems,
      });
      /* END VENDOR */

      /* STATUS */
      this.setState({
        order_status: data && data.order_status ? data.order_status : null,
        order_status_id:
          data && data.order_status_id ? data.order_status_id : null,
      });
      /* END STATUS */

      /* JOB DETAILS SECTION */
      this.setState({
        drop_off_date:
          data && data.drop_off_date && copyData.drop_off_date
            ? moment(data.drop_off_date).format(apiUtil.getDefaultDateFormat())
            : "",
        longitude:
          data && data.drop_off_longitude && copyData.drop_off_date
            ? data.drop_off_longitude
            : "",
        latitude:
          data && data.drop_off_latitude && copyData.drop_off_date
            ? data.drop_off_latitude
            : "",
        drop_off_time_planned:
          data && data.drop_off_time_planned && copyData.drop_off_time_planned
            ? data.drop_off_time_planned
            : "",
        drop_off_description:
          data && data.drop_off_description && copyData.drop_off_description
            ? data.drop_off_description
            : "",
        driver_notes: data && data.driver_notes ? data.driver_notes : "",
        driver_payout:
          data && data.driver_payout && data.driver_payout !== 0.0
            ? data.driver_payout
            : "",
        do_number:
          data && data.do_number && data.do_number !== "" ? data.do_number : "",
      });
      /* END JOB DETAILS SECTION */

      if (this.isCustomerRequired) {
        /* CUSTOMERS DETAILS SECTION */
        let customerItems = [];
        let customerItem = null;
        if (data && data.customer) {
          customerItem = {
            value: data.customer_id ? data.customer_id : "",
            label: data.company_name ? data.company_name : "",
            item: data.customer ? data.customer : null,
          };
          customerItems.push(customerItem);
        }

        this.setState(
          {
            customerNameValidated: false,
            customerName:
              customerItem && customerItem.label
                ? customerItem.label
                : data.drop_off_contact_name
                  ? data.drop_off_contact_name
                  : "",
            customerId:
              customerItem && customerItem.value ? customerItem.value : "",
            customerItem:
              customerItem && customerItem.item ? customerItem.item : null,
            customerItems: customerItems.sort((a, b) => {
              const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            
              // names must be equal
              return 0;
            }),
            amount: data && data.amount && copyData.amount ? data.amount : "",
            item_tracking_number:
              data.item_tracking_number && copyData.item_tracking_number
                ? data.item_tracking_number
                : "",
            is_tracking_link_required: data.is_tracking_link_required
              ? data.is_tracking_link_required
              : false,
            is_notify_customer: data.is_notify_customer
              ? data.is_notify_customer
              : false,
          },
          () => {
            if (data && data.customer_id) {
              this.callCustomerDetailsApi(
                this.state.customerId,
                (customerData) => {
                  let customerAddresses =
                    customerData &&
                      customerData.customer_addresses &&
                      customerData.customer_addresses.length > 0
                      ? customerData.customer_addresses
                      : [];
                  let branchItems = customerAddresses.map((item, i) => {
                    return {
                      value: item.id,
                      label: item.name,
                      item: item,
                    };
                  });

                  let departmentItems = [];
                  if (
                    customerData &&
                    customerData.departments &&
                    customerData.departments.length > 0
                  ) {
                    departmentItems = customerData.departments.map(
                      (item, i) => {
                        return {
                          value: item.id,
                          label: item.name,
                          item: item,
                        };
                      }
                    );
                  }

                  this.setState(
                    {
                      branchItems: branchItems,
                      departmentItems: departmentItems,
                    },
                    () => {
                      let drop_off_address = "";
                      let drop_off_postal_code = "";
                      let order_branch_id = '';
                      let drop_off_addressItem = branchItems.filter(
                        (x) => x.value === order_branch_id
                      );
                      if (
                        drop_off_addressItem &&
                        drop_off_addressItem.length > 0
                      ) {
                        drop_off_address =
                          drop_off_addressItem[0].item.address_line1;
                        drop_off_postal_code =
                          drop_off_addressItem[0].item.postal_code;
                      }
                      this.setState({
                        order_branch_id: order_branch_id,
                        drop_off_address:
                          drop_off_address && drop_off_address != ""
                            ? drop_off_address
                            : data.drop_off_address
                              ? data.drop_off_address
                              : "",
                        drop_off_postal_code:
                          drop_off_postal_code && drop_off_postal_code != ""
                            ? drop_off_postal_code
                            : data.drop_off_postal_code
                              ? data.drop_off_postal_code
                              : "",
                      });

                      let departmentItem = null;
                      let department_id =
                        data && data.department_id ? data.department_id : "";
                      let departmentArray =
                        departmentItems && departmentItems.length > 0
                          ? departmentItems.filter(
                            (x) => x.value === department_id
                          )
                          : [];
                      if (departmentArray && departmentArray.length > 0) {
                        departmentItem = departmentArray[0].item;
                      }
                      this.setState({
                        department_id: department_id,
                        departmentItem: departmentItem,
                      });
                    }
                  );
                }
              );
            } else {
              this.setState({
                drop_off_address: data.drop_off_address
                  ? data.drop_off_address
                  : "",
                drop_off_postal_code: data.drop_off_postal_code
                  ? data.drop_off_postal_code
                  : "",
              });
            }
          }
        );

        let picItems = [];
        let drop_off_name = [];
        if (data.drop_off_name && data.drop_off_name !== "") {
          drop_off_name = data.drop_off_name.split(",");
        }

        let drop_off_contact_no = [];
        if (data.drop_off_contact_no && data.drop_off_contact_no !== "") {
          drop_off_contact_no = data.drop_off_contact_no.split(",");
        }

        let notification_email = [];
        if (data.notification_email && data.notification_email !== "") {
          notification_email = data.notification_email.split(",");
        }

        if (drop_off_name && drop_off_name.length > 0) {
          for (let i = 0; i < drop_off_name.length; i++) {
            let name = "";
            let contact_no = "";
            let email = "";

            if (
              drop_off_name[i] &&
              drop_off_name[i] !== "" &&
              copyData.drop_off_name
            ) {
              name = drop_off_name[i];
            }
            if (drop_off_contact_no[i] && drop_off_contact_no[i] !== "") {
              contact_no = drop_off_contact_no[i];
            }

            if (
              notification_email[i] &&
              notification_email[i] !== "" &&
              copyData.drop_off_contact_email
            ) {
              email = notification_email[i];
            }

            picItems.push({
              uuid: uuid(),
              drop_off_name: name,
              drop_off_contact_no: contact_no,
              notification_email: email,
              remove: false,
            });
            this.setState({
              notification_email: email
                ? email
                : data.notification_email
                  ? data.notification_email
                  : "",
              drop_off_name: name
                ? name
                : data.drop_off_name
                  ? data.drop_off_name
                  : "",
              drop_off_contact_name: name
                ? name
                : data.drop_off_contact_name
                  ? data.drop_off_contact_name
                  : "",
              drop_off_contact_no: contact_no
                ? contact_no
                : data.drop_off_contact_no
                  ? data.drop_off_contact_no
                  : "",
            });
          }
        } else {
          this.setState({
            notification_email: data.notification_email
              ? data.notification_email
              : "",
            drop_off_name: data.drop_off_name ? data.drop_off_name : "",
            drop_off_contact_name: data.drop_off_contact_name
              ? data.drop_off_contact_name
              : "",
            drop_off_contact_no: data.drop_off_contact_no
              ? data.drop_off_contact_no
              : "",
          });
        }

        this.setState({
          picItems: picItems,
        });
        /* END CUSTOMERS DETAILS SECTION */
      } else {
        /* END RECIPIENT DETAILS SECTION */
        this.setState({
          item_tracking_number:
            data.item_tracking_number && copyData.item_tracking_number
              ? data.item_tracking_number
              : "",
          is_tracking_link_required: data.is_tracking_link_required
            ? data.is_tracking_link_required
            : false,
          is_notify_customer: data.is_notify_customer
            ? data.is_notify_customer
            : false,
          drop_off_address: data.drop_off_address ? data.drop_off_address : "",
          drop_off_name:
            data.drop_off_contact_name && copyData.drop_off_name
              ? data.drop_off_contact_name
              : "",
          drop_off_contact_name:
            data.drop_off_contact_name && copyData.drop_off_name
              ? data.drop_off_contact_name
              : "",
          drop_off_contact_no:
            data.drop_off_contact_no && copyData.drop_off_contact_no
              ? data.drop_off_contact_no
              : "",
          notification_email:
            data.notification_email && copyData.drop_off_contact_email
              ? data.notification_email
              : "",
        });
        /* END RECIPIENT DETAILS SECTION */
      }

      /* ITEMS IN JOB LIST SECTION */
      if (copyData.order_details) {
        let itemsInJobList = [];
        if (itemsInTheJob && itemsInTheJob.length > 0) {
          itemsInJobList = itemsInTheJob.map((item, i) => {
            return {
              uuid: uuid(),
              id: item.id,
              description: item.description,
              quantity: item.quantity,
              uom: item.uom,
              weight: item.weight,
              remarks: item.remarks,
              remove: false,
            };
          });
        }
        this.setState({
          itemsInJobList: itemsInJobList,
        });
      }
      /* END ITEMS IN JOB LIST SECTION */

      /* STEPS SECTION */
      if (copyData.job_steps) {
        let job_steps = [];
        if (data && data.job_steps && data.job_steps.length > 0) {
          job_steps = data.job_steps.map((item, i) => {
            let orderStatusItem = apiUtil.customFilter(
              this.state.jobStepStatuses,
              "value",
              item.job_step_status_id
            );
            let job_step_status = orderStatusItem ? orderStatusItem : null;

            return {
              uuid: uuid(),
              // id: item.id,
              // job_step_id: item.id,
              job_step_name: item.job_step_name,
              job_step_status_id:
                item.job_step_status_id && item.job_step_status_id !== ""
                  ? item.job_step_status_id
                  : 1,
              job_step_status: job_step_status,
              location: item.location,
              is_signature_required: item.is_signature_required,
              is_scan_required: item.is_scan_required,
              job_step_pic: item.job_step_pic,
              job_step_pic_contact: item.job_step_pic_contact,
              description: item.description,
              order_sequence: i + 1,
              remove: false,
            };
          });
        }
        this.setState({
          job_steps: job_steps,
        });
      }
      /* END STEPS SECTION */

      if (copyData.drop_off_worker) {
        let manPowerItem =
          data && data.extra_workers && data.extra_workers.length > 0
            ? data.extra_workers[0]
            : null;
        this.setState({
          workerItem: data.drop_off_worker,
          workerName: data.drop_off_worker_name,
          workerId: data.drop_off_worker_id,
          manPowerItem: manPowerItem,
          manPowerName: manPowerItem
            ? (manPowerItem.first_name && manPowerItem.first_name !== ""
              ? manPowerItem.first_name
              : "") +
            " " +
            (manPowerItem.last_name && manPowerItem.last_name !== ""
              ? manPowerItem.last_name
              : "")
            : "",
          manPowerId: manPowerItem ? manPowerItem.id : 0,
        });
      }
    });
  };
  // ON CHANGE JOBSETP WHEN DELIVERY ADDRESS CHANGED
  collectionAddressOnChangeHandler = (dropoff_address) => {
    /* STEPS SECTION */
    let orderStatusItem = apiUtil.customFilter(
      this.state.jobStepStatuses,
      "label",
      "Pending"
    );
    let job_step_status = orderStatusItem ? orderStatusItem : null;
    let job_step_pic = "";
    let job_step_pic_contact = "";
    let picItem =
      this.state.picItems && this.state.picItems.length > 0
        ? this.state.picItems[0]
        : null;
    if (picItem) {
      job_step_pic = picItem.drop_off_name;
      job_step_pic_contact = picItem.drop_off_contact_no;
    }

    // let job_steps = this.state.job_steps;
    // if(job_steps && job_steps.length > 0){
    //   job_steps = job_steps.map((item, i) => {
    //     item.remove = true;
    //     console.log(i)
    //     return item;
    //   });
    // }

    let step = {
      uuid: uuid(),
      job_step_name: this.state.job_type ? this.state.job_type : "Collection",
      is_signature_required: true,
      is_scan_required: false,
      description: "",
      order_sequence: 1,
      remove: false,
      job_step_status_id:
        job_step_status && job_step_status.value && job_step_status.value !== ""
          ? job_step_status.value
          : 1,
      job_step_status: job_step_status,
      location: dropoff_address,
      latitude: 0,
      longitude: 0,
      job_step_pic: job_step_pic
        ? job_step_pic
        : this.state.drop_off_contact_name,
      job_step_pic_contact: job_step_pic_contact
        ? job_step_pic_contact
        : this.state.drop_off_contact_no,
    };

    // this.callGeoCoderAddressApi(step.location, (data) => {
    //   if (data.lat == null && data.long == null) {
    //     apiUtil.toast('collection address are not reconginze Lat Long from address', 'check_circle');
    //   }
    //   if (data) {
    //     step.latitude = data.lat;
    //     step.longitude = data.lng;
    //     this.setState({
    //       collection_latitude: data.lat,
    //       collection_longitude: data.lng,
    //     });
    //   }
    // });

    let job_steps = this.state.job_steps;
    job_steps = job_steps.filter((st) => st.remove === false);

    //Edit Delivery Address
    if (this.state.id && this.state.id > 0) {
      step = this.getEditedJobStep(step);
    }

    if (job_steps && job_steps.length > 0) {
      job_steps = job_steps.map((item, i) => {
        // item.remove = true;
        if (i === 0) return step;
        return item;
      });
    } else {
      job_steps.push(step);
    }
    // job_steps.push(step);
    this.setState({
      job_steps: job_steps,
    });
    /* END STEPS SECTION */
  };

  deliveryAddressOnChangeHandler = (dropoff_address) => {
    /* STEPS SECTION */
    let orderStatusItem = apiUtil.customFilter(
      this.state.jobStepStatuses,
      "label",
      "Pending"
    );
    let job_step_status = orderStatusItem ? orderStatusItem : null;
    let job_step_pic = "";
    let job_step_pic_contact = "";
    let picItem =
      this.state.picItems && this.state.picItems.length > 0
        ? this.state.picItems[0]
        : null;
    if (picItem) {
      job_step_pic = picItem.drop_off_name;
      job_step_pic_contact = picItem.drop_off_contact_no;
    }

    // let job_steps = this.state.job_steps;
    // if(job_steps && job_steps.length > 0){
    //   job_steps = job_steps.map((item, i) => {
    //     item.remove = true;
    //     console.log(i)
    //     return item;
    //   });
    // }

    let step = {
      uuid: uuid(),
      job_step_name: this.state.job_type ? this.state.job_type : "Delivery",
      is_signature_required: true,
      is_scan_required: false,
      description: "",
      order_sequence: 1,
      remove: false,
      job_step_status_id:
        job_step_status && job_step_status.value && job_step_status.value !== ""
          ? job_step_status.value
          : 1,
      job_step_status: job_step_status,
      location: dropoff_address,
      latitude: 0,
      longitude: 0,
      job_step_pic: job_step_pic
        ? job_step_pic
        : this.state.drop_off_contact_name,
      job_step_pic_contact: job_step_pic_contact
        ? job_step_pic_contact
        : this.state.drop_off_contact_no,
    };

    // this.callGeoCoderAddressApi(step.location, (data) => {
    //   if (data.lat == null && data.long == null) {
    //     apiUtil.toast('delivery address are not reconginze Lat Long from address', 'check_circle');
    //   }
    //   if (data) {
    //     step.latitude = data.lat;
    //     step.longitude = data.lng;
    //     this.setState({
    //       delivery_latitude: data.lat,
    //       delivery_longitude: data.lng,
    //     });
    //   }
    // });

    let job_steps = this.state.job_steps;
    job_steps = job_steps.filter((st) => st.remove === false);

    //Edit Delivery Address
    if (this.state.id && this.state.id > 0) {
      step = this.getEditedJobStep(step);
    }

    if (job_steps && job_steps.length > 0) {
      job_steps = job_steps.map((item, i) => {
        // item.remove = true;
        if (i === 0) return step;
        return item;
      });
    } else {
      job_steps.push(step);
    }
    // job_steps.push(step);
    this.setState({
      job_steps: job_steps,
    });
    /* END STEPS SECTION */
  };

  //Get Edited first job step
  getEditedJobStep = (step) => {
    let job_step = this.state.job_steps;
    let edit_jop_step = {};
    if (job_step && job_step.length > 0) {
      edit_jop_step = { ...job_step[0], ...step };
      return edit_jop_step;
    }
  };

  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // alert('adsf');
    const { isOnBoarding, setOnBoardingJob } = this.context;
    // console.log("this.state.collection_data",this.state.collection_data)
    // console.log('delivery', this.state.delivery_array)
    this.setState({
      isLoading: true,
    });  
    let do_numbers = []
    let collection_data = this.state.collection_data
    let col_package_info = {
      depalletization_request: this.state.collection_data.depalletization_request,
      size_l_w_h: this.state.collection_data.size_l_w_h,
      weight: this.state.collection_data.weight,
      waiting_time: this.state.collection_data.waiting_time,
      no_of_movers: this.state.collection_data.no_of_movers,
    };

    collection_data['drop_off_time_planned'] = this.state.collection_data.from_time + ' - ' + this.state.collection_data.to_time;
    collection_data['drop_off_from_time'] = this.state.collection_data.from_time;
    collection_data['drop_off_to_time'] = this.state.collection_data.to_time;
    collection_data['customer_id'] = this.state.customerId ? this.state.customerId : null;
    collection_data['department_id'] = this.state.customerId ? this.state.departmentId : null;
    collection_data['package_info'] = JSON.stringify(col_package_info);
    collection_data['order_packages'] = this.state.collection_data.selected_package;
    collection_data['period_of_delivery'] = this.state.period_of_delivery;
    collection_data['is_project'] = this.state.job_type == 'Project' ? true : false;
    collection_data['notification_email'] = this.state.collection_data.email_address ? this.state.collection_data.email_address : null;
    collection_data['do_number'] = this.state.collection_data.do_number ? this.state.collection_data.do_number.trim() : null;
    collection_data['job_type'] = 'Collection';
    collection_data["assign_type"] = "own";
    collection_data["drop_off_worker_id"] = null;
    collection_data["driver_name"] = null;
    collection_data["extra_worker_ids"] = null;
    collection_data["manpower_name"] = null
    collection_data["amount"] = parseFloat(collection_data["amount"])
    collection_data["driver_payout"] = parseFloat(collection_data["driver_payout"])
    collection_data["vendor_payout"] = parseFloat(collection_data["vendor_payout"])

// ================ Collection Validation ====================
    let collection_validate_err = false

//  ============ D.O. AND PACKAGE validation ===============
    let do_validated = true;
    if (this.state.collection_data.do_number == null || this.state.collection_data.do_number == '' ) {
      collection_data.do_validated = true
      collection_validate_err = true
    }
    
    if((this.state.collection_data.xs_packages == '' || this.state.collection_data.xs_packages < 1) && (this.state.collection_data.s_packages == '' || this.state.collection_data.s_packages < 1) && (this.state.collection_data.m_packages == '' || this.state.collection_data.m_packages < 1) && (this.state.collection_data.l_packages == '' || this.state.collection_data.l_packages < 1) && (this.state.collection_data.oversized_packages == '' || this.state.collection_data.oversized_packages < 1) && (this.state.collection_data.pallets == '' || this.state.collection_data.pallets < 1)) {
      collection_data.package_validated = true
      collection_validate_err = true      
    }
//  ============ D.O. AND PACKAGE validation End ============


//  ============ email validation ============
    if (
      this.state.collection_data.email_address &&
      !apiUtil.emailValidation(this.state.collection_data.email_address)
    ) {
      collection_data.email_validated  = true
      collection_validate_err = true
    }
//  ============ email validation End ============

//  ============ Postal Code validation ============
    if (
      this.state.collection_data.drop_off_postal_code &&
      (this.state.collection_data.drop_off_postal_code < 0 ||
        this.state.collection_data.drop_off_postal_code === 0 ||
        this.state.collection_data.drop_off_postal_code === "" ||
        this.state.collection_data.drop_off_postal_code === null ||
        this.state.collection_data.drop_off_postal_code.length < 2)
    ) {
      collection_data.postal_code_validated  = true
      collection_validate_err = true
    }

//  ============ Postal Code validation End ============

//  ============ Address and Date validation  ============
    if (this.state.collection_data.drop_off_address === "" || this.state.collection_data.drop_off_address === null ) {
      collection_data.address_validated = true
      collection_validate_err = true
    }

    if (this.state.collection_data.drop_off_date === "" || this.state.collection_data.drop_off_date === null) {
      collection_data.drop_off_date_validated  = true
      collection_validate_err = true
    }
//  ============ Address and Date validation End ============

//  ============ Contact No validation ============
    if (
      this.state.collection_data.drop_off_contact_no &&
      !apiUtil.phoneValidationWithPlus(this.state.collection_data.drop_off_contact_no)
    ) {
      collection_data.contact_no_validated = true
      collection_validate_err = true
    }

    if (
      this.state.collection_data.drop_off_alternate_contact &&
      !apiUtil.phoneValidationWithPlus(this.state.collection_data.drop_off_alternate_contact)
    ) {
      collection_data.alt_contact_no_validated = true
      collection_validate_err = true
    }
    collection_data["drop_off_contact_no"] = this.state.collection_data.drop_off_contact_no && this.state.collection_data.drop_off_contact_no.length === 8 ? '+65' +this.state.collection_data.drop_off_contact_no : this.state.collection_data.drop_off_contact_no ;
    collection_data["drop_off_alternate_contact"] = this.state.collection_data.drop_off_alternate_contact && this.state.collection_data.drop_off_alternate_contact.length === 8 ? '+65' +this.state.collection_data.drop_off_alternate_contact : this.state.collection_data.drop_off_alternate_contact ;
//  ============ Contact No validation End ============
    let start_time = moment(this.state.collection_data.from_time,'hh:mm');
    let end_time = moment(this.state.collection_data.to_time,'hh:mm');
    let diff_time = end_time.diff(start_time, 'hours',true);
    if (this.state.collection_data.from_time == this.state.collection_data.to_time || diff_time < 0) {
      collection_data.drop_off_time_validated = true
      collection_validate_err = true
    }
    
    if(this.state.delivery_array.filter((obj) => obj.drop_off_address && this.state.collection_data.drop_off_address && obj.drop_off_address.trim() == this.state.collection_data.drop_off_address.trim()).length > 0) {
      apiUtil.toast('Pick up and Delivery address can not be same')
      collection_validate_err = true                           
    } 
// ======================= END COLLECTION VALIDATION ====================
    
    let deli_validate_err = false
    do_numbers.push(collection_data.do_number)
    let delivery_array =  _.cloneDeep(this.state.delivery_array)
    delivery_array.map((dd, ind) => {
      let del_package_info = {
        depalletization_request: dd.depalletization_request,
        size_l_w_h: dd.size_l_w_h,
        weight: dd.weight,
        waiting_time: dd.waiting_time,
        no_of_movers: dd.no_of_movers,
      };
      delivery_array[ind].drop_off_time_planned = dd.from_time + ' - ' + dd.to_time;
      delivery_array[ind].drop_off_from_time = dd.from_time;
      delivery_array[ind].drop_off_to_time = dd.to_time;
      delivery_array[ind].do_number = dd.do_number ? dd.do_number.trim() : dd.do_number;
      delivery_array[ind].customer_id = this.state.customerId ? this.state.customerId : null;
      delivery_array[ind].department_id = this.state.customerId ? this.state.departmentId : null;
      delivery_array[ind].package_info = JSON.stringify(del_package_info);
      delivery_array[ind].order_packages = dd.selected_package;
      delivery_array[ind].period_of_delivery = this.state.period_of_delivery;
      delivery_array[ind].is_project = this.state.job_type == 'Project' ? true : false;
      delivery_array[ind].collection_reference = this.state.collection_data.do_number;
      delivery_array[ind].job_type = 'Delivery';
      delivery_array[ind].notification_email = dd.email_address ? dd.email_address : null;
      delivery_array[ind]["amount"] = parseFloat(delivery_array[ind]["amount"])
      delivery_array[ind]["driver_payout"] = parseFloat(delivery_array[ind]["driver_payout"])
      delivery_array[ind]["vendor_payout"] = parseFloat(delivery_array[ind]["vendor_payout"])
      do_numbers.push(dd.do_number)

// ================ Delivery Validation ====================
//  ============ D.O. AND PACKAGE validation ===============
        let do_validated = true;
        if (dd.do_number == null || dd.do_number == '' ) {
          delivery_array[ind].do_validated = true
          deli_validate_err = true
        }
        
        if((dd.xs_packages == '' || dd.xs_packages < 1) && (dd.s_packages == '' || dd.s_packages < 1) && (dd.m_packages == '' || dd.m_packages < 1) && (dd.l_packages == '' || dd.l_packages < 1) && (dd.oversized_packages == '' || dd.oversized_packages < 1) && (dd.pallets == '' || dd.pallets < 1)) {
          delivery_array[ind].package_validated = true
          deli_validate_err = true      
        }
//  ============ D.O. AND PACKAGE validation End ============
    
    
//  ============ email validation ============
        if (
          dd.email_address &&
          !apiUtil.emailValidation(dd.email_address)
        ) {
          delivery_array[ind].email_validated  = true
          deli_validate_err = true
        }
//  ============ email validation End ============
    
//  ============ Postal Code validation ============
        if (
          dd.drop_off_postal_code &&
          (dd.drop_off_postal_code < 0 ||
            dd.drop_off_postal_code === 0 ||
            dd.drop_off_postal_code === "" ||
            dd.drop_off_postal_code === null ||
            dd.drop_off_postal_code.length < 2)
        ) {
          delivery_array[ind].postal_code_validated  = true
          deli_validate_err = true
        }
    
//  ============ Postal Code validation End ============
    
//  ============ Address and Date validation  ============
        if (dd.drop_off_address === "" || dd.drop_off_address === null ) {
          delivery_array[ind].address_validated = true
          deli_validate_err = true
        }
    
        if (dd.drop_off_date === "" || dd.drop_off_date === null) {
          delivery_array[ind].drop_off_date_validated  = true
          deli_validate_err = true
        } else if (moment(dd.drop_off_date).isBefore(moment(this.state.collection_data.drop_off_date))){
          delivery_array[ind].drop_off_date_validated  = true
          deli_validate_err = true
        }
//  ============ Address and Date validation End ============
    
//  ============ Contact No validation ============
        if (
          dd.drop_off_contact_no &&
          !apiUtil.phoneValidationWithPlus(dd.drop_off_contact_no)
        ) {
          delivery_array[ind].contact_no_validated = true
          deli_validate_err = true
        }
    
        if (
          dd.drop_off_alternate_contact &&
          !apiUtil.phoneValidationWithPlus(dd.drop_off_alternate_contact)
        ) {
          delivery_array[ind].alt_contact_no_validated = true
          deli_validate_err = true
        }

        delivery_array[ind].drop_off_contact_no = dd.drop_off_contact_no && dd.drop_off_contact_no.length === 8 ? '+65' +dd.drop_off_contact_no : dd.drop_off_contact_no ;
        delivery_array[ind].drop_off_alternate_contact = dd.drop_off_alternate_contact && dd.drop_off_alternate_contact.length === 8 ? '+65' +dd.drop_off_alternate_contact : dd.drop_off_alternate_contact ;
//  ============ Contact No validation End ============
        let start_time = moment(dd.from_time,'hh:mm');
        let end_time = moment(dd.to_time,'hh:mm');
        let diff_time = end_time.diff(start_time, 'hours',true);
        if (dd.from_time == dd.to_time || diff_time < 0) {
          delivery_array[ind].drop_off_time_validated = true
          deli_validate_err = true
        }
// ======================= END DELIVERY VALIDATION ====================
    })

//  ============ job_type && Category validation ============
     if (this.state.job_type === "") {
      this.setState({
        delivery_typeValidated: true,
      });
    }

    if (this.state.period_of_delivery === "") {
      this.setState({
        period_of_deliveryValidated: true,
      });
    }

    if (deli_validate_err || collection_validate_err || this.state.period_of_delivery === "" || this.state.job_type === "") {
      this.setState({
        collection_data:collection_data,
        delivery_array:delivery_array,
        isLoading: false,
      })
      return
    }

    delivery_array.unshift(collection_data);

    const form = e.currentTarget;
    let duplicateIndexes = delivery_array.map(e => e['do_number'] && e['do_number'].trim())
                                        .reduce((acc, curr, index, arr) => {
                                          if (arr.indexOf(curr) !== index && !acc.includes(index)) {
                                            acc.push(index);
                                          }
                                          return acc;
                                        }, []);

    if (duplicateIndexes.length >0) {
      let col_order = this.state.collection_data
      let deli_order = this.state.delivery_array
      duplicateIndexes.map(dd => {
        if (dd == 0) {
          col_order.do_validated = true
        } else {
          deli_order[dd - 1].do_validated = true
        }
      })
     
      this.setState({
        collection_data:col_order,
        delivery_array:deli_order,
        isLoading: false,
      })
      return
    }

      delivery_array[0]['check_do'] =  do_numbers
      let created_order = [];
      this.callCreateApi(delivery_array, (data) => {
        created_order.push(data);
        apiUtil.toast(
          "Successfully created " + delivery_array.length + " order(s)",
          "check_circle"
        );
        if (this.state.currentRole == 'Customer Template Role') { 
          this.props.history.push({
            pathname: "/order-schedule",
            state: {
              created_order: created_order,
              loadApis: false,
              isShouldDisableSocketToast: true,
            },
          });
        } else {
          this.props.history.push({
          pathname: this.state.currentRole == 'Customer Template Role' ? "/new-order" : "/dashboard",
            state: {
              created_order: created_order,
              loadApis: false,
              isShouldDisableSocketToast: true,
            },
          });
        }
      });   
  };
  /* END SUBMIT */

  /* DIALOG */
  customerDialog = () => {
    return (
      <CustomFormDialog
        open={this.state.openDialogCustomer}
        title={"Create New Customer"}
        onClose={() => {
          this.setState({
            openDialogCustomer: false,
            itemDialogCustomer: null,
          });
        }}
      >
        <CustomerForm
          item={this.state.itemDialogCustomer}
          onSave={(isEdit, data) => {
            if (!isEdit) {
              /* ADD NEW CUSTOMER DETAILS */
              let customerItems = this.state.customerItems;
              let customerItem = null;
              if (data && data.customer) {
                customerItem = {
                  value: data.customer.id ? data.customer.id : "",
                  label: data.customer.company_name
                    ? data.customer.company_name
                    : "",
                  item: data.customer ? data.customer : null,
                };
                customerItems.push(customerItem);
              }

              this.setState(
                {
                  customerNameValidated: false,
                  customerName:
                    customerItem && customerItem.label
                      ? customerItem.label
                      : "",
                  customerId:
                    customerItem && customerItem.value
                      ? customerItem.value
                      : "",
                  customerItem:
                    customerItem && customerItem.item
                      ? customerItem.item
                      : null,
                  customerItems: customerItems.sort((a, b) => {
                    const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  }),
                },
                () => {
                  if (data && data.customer && data.customer.id) {
                    this.callCustomerDetailsApi(
                      this.state.customerId,
                      (customerData) => {
                        let customerAddresses =
                          customerData &&
                            customerData.customer_addresses &&
                            customerData.customer_addresses.length > 0
                            ? customerData.customer_addresses
                            : [];
                        let branchItems = customerAddresses.map((item, i) => {
                          return {
                            value: item.id,
                            label: item.name,
                            item: item,
                          };
                        });

                        let departmentItems = [];
                        if (
                          customerData &&
                          customerData.departments &&
                          customerData.departments.length > 0
                        ) {
                          departmentItems = customerData.departments.map(
                            (item, i) => {
                              return {
                                value: item.id,
                                label: item.name,
                                item: item,
                              };
                            }
                          );
                        }

                        this.setState(
                          {
                            branchItems: branchItems,
                            departmentItems: departmentItems,
                          },
                          () => {
                            let drop_off_address = "";
                            let order_branch_id =
                              data &&
                                data.customer &&
                                data.customer.customer_addresses &&
                                data.customer.customer_addresses[0] &&
                                data.customer.customer_addresses[0].id
                                ? data.customer.customer_addresses[0].id
                                : "";
                            let drop_off_addressItem = branchItems.filter(
                              (x) => x.value === order_branch_id
                            );
                            if (
                              drop_off_addressItem &&
                              drop_off_addressItem.length > 0
                            ) {
                              drop_off_address =
                                drop_off_addressItem[0].item.address_line1;
                            }
                            this.setState({
                              order_branch_id: order_branch_id,
                              drop_off_address: drop_off_address,
                            });

                            let departmentItem = null;
                            let department_id =
                              data &&
                                data.customer &&
                                data.customer.departments &&
                                data.customer.departments[0] &&
                                data.customer.departments[0].id
                                ? data.customer.departments[0].id
                                : "";
                            let departmentArray =
                              departmentItems && departmentItems.length > 0
                                ? departmentItems.filter(
                                  (x) => x.value === department_id
                                )
                                : [];
                            if (departmentArray && departmentArray.length > 0) {
                              departmentItem = departmentArray[0].item;
                            }
                            this.setState({
                              department_id: department_id,
                              departmentItem: departmentItem,
                            });

                            let picItems = [];
                            if (
                              departmentItem &&
                              departmentItem.contact_people &&
                              departmentItem.contact_people.length > 0
                            ) {
                              let item = departmentItem.contact_people[0];

                              picItems.push({
                                uuid: uuid(),
                                id: item.id,
                                drop_off_name: item.first_name,
                                drop_off_contact_no: item.contact_no,
                                notification_email: item.email,
                                remove: false,
                              });
                            }

                            this.setState({
                              picItems: picItems,
                            });
                          }
                        );
                      }
                    );
                  }
                }
              );
              /* END ADD NEW CUSTOMER DETAILS */

              this.setState({
                openDialogCustomer: false,
                itemDialogCustomer: null,
              });
            }
          }}
          onClose={() => {
            this.setState({
              openDialogCustomer: false,
              itemDialogCustomer: null,
            });
          }}
        />
      </CustomFormDialog>
    );
  };

  driverDialog = () => {
    return (
      <CustomFormDialog
        open={this.state.openDialogDriver}
        title={"Create New Driver"}
        onClose={() => {
          this.setState({
            openDialogDriver: false,
            itemDialogDriver: null,
          });
        }}
      >
        <DriverForm
          item={this.state.itemDialogDriver}
          onSave={(isEdit, data) => {
            if (!isEdit) {
              /* ADD NEW DRIVER */
              let workerItems = this.state.workerItems;
              let workerItem = null;
              if (data && data.result) {
                let value = data.result.id;
                let label =
                  (data.result.first_name && data.result.first_name !== ""
                    ? data.result.first_name
                    : "") +
                  " " +
                  (data.result.last_name && data.result.last_name !== ""
                    ? data.result.last_name
                    : "");

                workerItem = {
                  value: value,
                  label: label,
                  item: data.result,
                };
                workerItems.push(workerItem);
              }

              this.setState(
                {
                  workerItems: workerItems.sort((a, b) => {
                    const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                  
                    // names must be equal
                    return 0;
                  }),
                  workerName: workerItem.label,
                  workerId: workerItem.value,
                  workerItem: workerItem.item,
                },
                () => {
                  let notAssignedStatusItem = apiUtil.customFilter(
                    this.state.orderStatuses,
                    "label",
                    "not assigned"
                  );
                  let assignedStatusItem = apiUtil.customFilter(
                    this.state.orderStatuses,
                    "label",
                    "assigned"
                  );

                  if (assignedStatusItem && notAssignedStatusItem) {
                    if (
                      this.state.order_status_id === notAssignedStatusItem.value
                    ) {
                      this.setState({
                        order_status: assignedStatusItem.item,
                        order_status_id: assignedStatusItem.value,
                      });
                    }
                  }
                }
              );
              /* END ADD NEW DRIVER */

              this.setState({
                openDialogDriver: false,
                itemDialogDriver: null,
              });
            }
          }}
          onClose={() => {
            this.setState({
              openDialogDriver: false,
              itemDialogDriver: null,
            });
          }}
        />
      </CustomFormDialog>
    );
  };

  logisticsAssistanceDialog = () => {
    return (
      <CustomFormDialog
        open={this.state.openDialogLogisticsAssistance}
        title={"Create Logistics Assistance"}
        onClose={() => {
          this.setState({
            openDialogLogisticsAssistance: false,
            itemDialogLogisticsAssistance: null,
          });
        }}
      >
        <LogisticsAssistanceForm
          item={this.state.itemDialogLogisticsAssistance}
          onSave={(isEdit, data) => {
            if (!isEdit) {
              /* ADD NEW LOGISTIC ASSISTANCE */
              let manPowerItems = this.state.manPowerItems;
              let manPowerItem = null;
              if (data && data.result) {
                let value = data.result.id;
                let label =
                  (data.result.first_name && data.result.first_name !== ""
                    ? data.result.first_name
                    : "") +
                  " " +
                  (data.result.last_name && data.result.last_name !== ""
                    ? data.result.last_name
                    : "");

                manPowerItem = {
                  value: value,
                  label: label,
                  item: data.result,
                };
                manPowerItems.push(manPowerItem);
              }

              this.setState({
                manPowerItems: manPowerItems,
                manPowerName: manPowerItem.label,
                manPowerId: manPowerItem.value,
                manPowerItem: manPowerItem.item,
              });
              /* END ADD NEW LOGISTIC ASSISTANCE */

              this.setState({
                openDialogLogisticsAssistance: false,
                itemDialogLogisticsAssistance: null,
              });
            }
          }}
          onClose={() => {
            this.setState({
              openDialogLogisticsAssistance: false,
              itemDialogLogisticsAssistance: null,
            });
          }}
        />
      </CustomFormDialog>
    );
  };

  customDialog = () => {
    return (
      <CustomDialog
        open={this.state.openDialog}
        title={"Confirm"}
        btnCloseText={"No"}
        onClose={() => {
          this.setState(
            {
              openDialog: false,
              openDialogItem: null,
            },
            () => {
              this.setState({
                branchItems: [],
                departmentItems: [],
              });
            }
          );
        }}
        onOk={() => {
          let data = this.state.openDialogItem;
          this.addNewCustomerDetails(data);
          this.setState(
            {
              openDialog: false,
              openDialogItem: null,
            },
            () => {
              if (this.state.customerId > 0) {
                let {
                  client,
                  payout,
                  subcon,
                  base_charge,
                  surcharge,
                } = this.state.grantedAccess.getPriceAndDriverPayout(
                  this.state.selected_package,
                  this.state.customer_rate,
                  this.state.job_type,
                  this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                  this.state.drop_off_date,
                  this.state.collection_postal_code,
                  'Collection',
                  this.state.collection_data.drop_off_address,
                  this.state.address,
                  null,
                  false,
                );
                this.setState({
                  collection_amount: client,
                  collection_driver_payout: payout,
                  collection_vendor_payout: subcon,
                  collection_base_charge: base_charge,
                  collection_surcharge: surcharge
                });
              }
              if (this.state.customerId > 0) {
                  let {
                    client,
                    payout,
                    subcon,
                    base_charge,
                    surcharge,
                  } = this.state.grantedAccess.getPriceAndDriverPayout(
                    this.state.selected_package,
                    this.state.customer_rate,
                    this.state.job_type,
                    this.state.time_window != '' ? this.state.time_window : this.state.period_of_delivery,
                    this.state.drop_off_date,
                    this.state.delivery_postal_code,
                    'Delivery',
                    this.state.drop_off_address,
                    this.state.address,
                    null,
                    false,
                  );
                  this.setState({
                    delivery_amount: client,
                    delivery_driver_payout: payout,
                    delivery_vendor_payout: subcon,
                    delivery_base_charge: base_charge,
                    delivery_surcharge: surcharge
                  });
                }
            }
          );
        }}
      >
        <Box>Clear existing job information?</Box>
      </CustomDialog>
    );
  };
  /* END DIALOG */

  /* PREVENT TRANSITION PROMPT */
  preventTransitionPrompt = () => {
    const { isOnBoarding } = this.context;

    if (!isOnBoarding) {
      return (
        <PreventTransitionPrompt
          // when={this.state.isFormChanged}
          when={false}
          title="Any you sure want to leave this page?"
          message={"Any unsaved changes will be lost."}
          onOk={(location) => {
            this.setState(
              {
                isFormChanged: false,
              },
              () => {
                this.props.history.push(location.pathname);
              }
            );
          }}
        />
      );
    }
  };
  /* END PREVENT TRANSITION PROMPT */

  /* STEPPER */
  setStepper = () => {
    return (
      <>
        <CustomGuideStepper
          activeStep={3}
          steps={[
            {
              label: "Create Customer",
            },
            {
              label: "Create Vehicle",
            },
            {
              label: "Create Driver",
            },
            {
              label: "Create Job",
            },
          ]}
          onClick={(index) => {
            if (index === 0) {
              this.props.history.push("/customers");
            } else if (index === 1) {
              this.props.history.push("/resources/vehicle");
            } else if (index === 2) {
              this.props.history.push("/resources/transport-operator");
            } else if (index === 3) {
              this.props.history.push("/job-list");
            }
          }}
        />
        <Box pb={2} textAlign={"center"}>
          Fill in the form and create a Job.
        </Box>
        <Box textAlign={"center"}>
          <CustomButton
            color={"primary"}
            href={"/"}
            onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
            }}
          >
            Skip All
          </CustomButton>
        </Box>
      </>
    );
  };
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return (
      <>
        <Tour
          steps={[
            {
              selector: ".job-details-step",
              content: (
                <Box>
                  <h2>Order Details</h2>
                  <p>
                    The fields here are the details required for a job.You can
                    customize these later.
                  </p>
                </Box>
              ),
            },
            {
              selector: ".job-steps-step",
              content: (
                <Box>
                  <h2>Job Steps</h2>
                  <p>
                    Need some checkpoints for your driver before the end
                    address? You can set it here.The last stop's address is your
                    receipient's address.
                  </p>
                </Box>
              ),
            },
            {
              selector: ".create-job-step",
              stepInteraction: true,
              content: (
                <Box>
                  <h2>Create Job</h2>
                  <p>Fill in the required data to create a job.</p>
                </Box>
              ),
            },
          ]}
          isOpen={this.state.isTourOpen}
          rounded={10}
          accentColor={"#007CDE"}
          // accentColor={'#64CCC9'}
          badgeContent={"#007CDE"}
          // badgeContent={'#64CCC9'}
          showNumber={false}
          showNavigation={false}
          disableInteraction={true}
          // prevButton={<></>}
          // nextButton={<></>}
          lastStepNextButton={<></>}
          onRequestClose={() => {
            this.setState({
              isTourOpen: false,
            });
          }}
        />
      </>
    );
  };
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const {
      accessToken,
      setOnBoardingCustomer,
      setOnBoardingVehicle,
      setOnBoardingDriver,
      setOnBoardingJob,
    } = this.context;

    return (
      <CustomDialog
        open={this.state.openSkipDialog}
        title={"End Tutorial"}
        btnCloseText={"No"}
        onClose={() => {
          this.setState({
            openSkipDialog: false,
          });
        }}
        onOk={() => {
          apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
            this.setState(
              {
                openSkipDialog: false,
              },
              () => {
                setOnBoardingCustomer(null);
                setOnBoardingVehicle(null);
                setOnBoardingDriver(null);
                setOnBoardingJob(null);

                apiUtil.toastOnBoarding("Done", "check_circle");
                this.props.history.push("/");
              }
            );
          });
        }}
      >
        <Box>Are you sure you want to end tutorial?</Box>
      </CustomDialog>
    );
  };
  /* END SKIP DIALOG */

  recalculate_colleciton = (address=null) => {
     let currentRole = window.localStorage.getItem('current_role')
     let user = JSON.parse(window.localStorage.getItem('user'))
     let check_address = [];
     let uploader_id = this.state.customerId;
     if (currentRole == "Super Admin Role") {
      //  console.log('delivery_array',this.state.delivery_array);
      //  console.log('address',this.state.address);
       check_address = this.state.delivery_array.filter((deli) => this.state.address.filter(obj => (deli.drop_off_address?.trim()?.toLowerCase() == obj.address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == deli.drop_off_address?.trim()?.toLowerCase()) || (deli.drop_off_postal_code == obj.postal_code || deli.zip_from_address == obj.postal_code)) && (uploader_id == obj.customer_id || obj.customer_id == null)).length > 0)
     } else {
       check_address = this.state.delivery_array.filter((deli) => this.state.address.filter(obj => (deli.drop_off_address?.trim()?.toLowerCase() == obj.address?.trim()?.toLowerCase() || ((obj.address +  ', ' + obj.building_no)?.trim()?.toLowerCase() == deli.drop_off_address?.trim()?.toLowerCase()) || (deli.drop_off_postal_code == obj.postal_code || deli.zip_from_address == obj.postal_code)) && obj.customer_id == null).length > 0)
     
     }
    //  console.log('check_address',check_address);
     if(check_address.length > 0) {
       if (this.state.customerId > 0) {
         let {
           client,
           payout,
           subcon,
           base_charge,
           surcharge,
         } = this.state.grantedAccess.getPriceAndDriverPayout(
           this.state.collection_data.selected_package,
           this.state.customer_rate,
           this.state.job_type,
           this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
           this.state.collection_data.drop_off_date,
           this.state.collection_data.drop_off_postal_code,
           'Collection',
           this.state.collection_data.drop_off_address,
           this.state.address,
           null,
           true,
           this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
           this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
         );
         let collection_data = this.state.collection_data;
         collection_data.amount = client;
         collection_data.driver_payout = payout;
         collection_data.vendor_payout = subcon;
         collection_data.base_charge = base_charge;
         collection_data.surcharge = surcharge;
         this.setState({
           collection_data: collection_data,
         });
       }
       this.setState({
         is_deli_use_saved_address: true 
       });
     } else {
       if (this.state.customerId > 0) {
         let {
           client,
           payout,
           subcon,
           base_charge,
           surcharge,
         } = this.state.grantedAccess.getPriceAndDriverPayout(
           this.state.collection_data.selected_package,
           this.state.customer_rate,
           this.state.job_type,
           this.state.collection_data.time_window != '' ? this.state.collection_data.time_window : this.state.period_of_delivery,
           this.state.collection_data.drop_off_date,
           this.state.collection_data.drop_off_postal_code,
           'Collection',
           this.state.collection_data.drop_off_address,
           this.state.address,
           null,
           false,
           this.state.collection_data.drop_off_latitude, this.state.collection_data.drop_off_longitude,
           this.state.collection_data.no_of_movers, this.state.collection_data.zip_from_address
         );
         let collection_data = this.state.collection_data;
         collection_data.amount = client;
         collection_data.driver_payout = payout;
         collection_data.vendor_payout = subcon;
         collection_data.base_charge = base_charge;
         collection_data.surcharge = surcharge;
         this.setState({
           collection_data: collection_data,
         });
       }
       this.setState({
         is_deli_use_saved_address: false 
       });       
     }         
  }
  
  render() {
    return (
      <Container fluid>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          {/* <StickyPageHeader isSmall={true}>
                {this.customStickyPageHeader()}
            </StickyPageHeader> */}

          <Container maxWidth="lg">
            {!this.state.isLoading ? (<>
              <Card className="d-flex justify-content-between align-items-center">
                <div>New Order</div><div><FontAwesomeIcon icon={faXmark} 
                onClick={()=> {
                  this.props.history.push({
                    pathname: this.state.currentRole == 'Customer Template Role' ? "/new-order" : "/dashboards/new-order",
                    state: {
                      isShouldDisableSocketToast: true,
                    },
                  });}
                }/></div>
              </Card>
              <Card className="text-center">
                {this.jobDetailsForm()}
                
                <button
                  className="upload-style-border me-2 primary-color mt-4"
                  onClick={(e) => {
                    this.props.history.push({
                      pathname: this.state.currentRole == 'Customer Template Role' ? "/new-order" : "/dashboards/new-order",
                      state: {
                        isShouldDisableSocketToast: true,
                      },
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="upload-style-border me-2 primary-color mt-4"
                  onClick={(e) => {
                   this.setState(prevState => ({ delivery_array: [...prevState.delivery_array, _.cloneDeep(this.state.delivery_data)] }),()=> {
                    // console.log('delivery', this.state.delivery_array)
                   })
                  }}
                >
                  Add Delivery
                </button>
                {this.state.delivery_array && this.state.delivery_array.length > 0 
                // && 
                //   (
                //     this.state.delivery_array[0].driver_notes != null ||
                //     this.state.delivery_array[0].job_type != null ||
                //     this.state.delivery_array[0].is_notify_customer != null ||
                //     this.state.delivery_array[0].zone_id != null ||
                //     this.state.delivery_array[0].drop_off_date != null ||
                //     this.state.delivery_array[0].drop_off_time_planned != null ||
                //     this.state.delivery_array[0].drop_off_description != null ||
                //     this.state.delivery_array[0].customer_id != null ||
                //     this.state.delivery_array[0].from_time != '9:00' ||
                //     this.state.delivery_array[0].to_time != '18:00' ||
                //     this.state.delivery_array[0].drop_off_contact_name != null ||
                //     this.state.delivery_array[0].department_id != null ||
                //     this.state.delivery_array[0].notification_email != null ||
                //     this.state.delivery_array[0].drop_off_contact_no != null ||
                //     this.state.delivery_array[0].drop_off_alternate_contact != null ||
                //     this.state.delivery_array[0].drop_off_name != null ||
                //     this.state.delivery_array[0].order_branch_id != null ||
                //     this.state.delivery_array[0].drop_off_address != null ||
                //     this.state.delivery_array[0].drop_off_postal_code != null ||
                //     this.state.delivery_array[0].driver_payout != null ||
                //     this.state.delivery_array[0].do_number != null ||
                //     this.state.delivery_array[0].period_of_delivery != null ||
                //     this.state.delivery_array[0].time_window != '' ||
                //     this.state.delivery_array[0].time_window != '' ||
                //     this.state.delivery_array[0].xs_packages != '' ||
                //     this.state.delivery_array[0].s_packages != '' ||
                //     this.state.delivery_array[0].m_packages != '' ||
                //     this.state.delivery_array[0].l_packages != '' ||
                //     this.state.delivery_array[0].oversized_packages != '' ||
                //     this.state.delivery_array[0].pallets !='' ||
                //     this.state.delivery_array[0].amount != 0 ||
                //     this.state.delivery_array[0].driver_payout != 0 ||
                //     this.state.delivery_array[0].vendor_payout != 0 ||
                //     this.state.delivery_array[0].selected_package.length > 0
                //   )
                 ?
                <button type="submit" className="upload-style text-white mt-4">
                 Complete
                </button> : ''
                }
              </Card></>
            ) : (
              <Card>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"75vh"}
                >
                  <CircularProgress size={35} />
                </Box>
              </Card>
            )}
          </Container>
        </form>
        {this.customerDialog()}
        {this.driverDialog()}
        {this.logisticsAssistanceDialog()}
        {this.customDialog()}
        {this.setTour()}
        {this.skipDialog()}
        {this.preventTransitionPrompt()}
      </Container>
    );
  }
}

export default NewOrderForm;
