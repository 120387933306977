import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory, withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Carousel from 'react-bootstrap/Carousel';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
import apiUtil from '../../api/apiUtil.jsx';
import Footer from '../Footer/Footer.jsx';
// import logo from '../../assets/img/wn_logo.svg';
import logo from '../../assets/img/logo_black.png';
import trackerLogo from '../../assets/img/EVFY_Logo.png';
import {RoleAccessService} from "./../../data/role-access";
import sliderTemp1 from "../../assets/img/slider_bg/dashboard.png"
import sliderTemp2 from "../../assets/img/slider_bg/fleet.png"
import sliderTemp3 from "../../assets/img/slider_bg/byd.png"

function SignIn() {
  let history = useHistory();

  const { clearStorage, token, refreshToken, authenticated, setAuthenticated, setToken, setCurrentRole, setAccessToken, setRefreshToken, setTokenType, setUser, setAppPermission, setUniqueRolePermissions } = useContext(AuthContext);
  
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedResetEmail, setValidatedResetEmail] = useState(false);
  const [errorResetEmail, setErrorResetEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mailInput, setMailInput] = useState(null);
  const [showResetPass, setShowResetPass] = useState(false);

  const [showForgotPw, setShowForgotPw] = useState(false);
  const [sentEamil, setSentEmail] = useState(null);
  const [timer, setTimer] = useState(null);

  const isMounted = useRef(null);
  const emailInput = useRef();
  const resetEmailInput = useRef();
  
  const pswInput = useRef();
  const axiosRef = useRef();


  useEffect(() => {
    //check if authenticated
    // checkToken();
    if(authenticated){
      history.push("/");
        // window.onpopstate = function(event) {
        //   history.go(1);
        // };
    }

    // executed when component mounted
    axiosRef.current = apiUtil.getCancelToken();
    isMounted.current = true;

    if(timer == true){
      setTimeout(() => { 
        window.location.href ="Sign-in";
    }, 3000)
    }

    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, [timer]);

  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        setAuthenticated(false);
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(pswInput.current.lastChild.firstChild.value === ''){
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }

    if (form.checkValidity() === true) {
      setIsLoading(true);

      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data != null) {
              let appAccessPermissions = [];

              if (data && data.owner_info.application_registrations.length > 0) {
                data.owner_info.application_registrations.forEach((el) => {
                  if (el.name === "AUTH") {
                    appAccessPermissions.push("auth");
                  } else if (el.name === "WLS") {
                    appAccessPermissions.push("wls");
                  } else if (el.name === "WMS") {
                    appAccessPermissions.push("wms");
                  }
                });

                window.localStorage.setItem("appPermission", appAccessPermissions);
                window.localStorage.setItem("password", window.btoa(pswInput.current.lastChild.firstChild.value));
              }


              //Grouping the permissions of associated Users
              let uniqueRolePermissions = []
              if (data && data.owner_info && data.owner_info.roles[0].permissions.length > 0) {
                // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
                uniqueRolePermissions = data.owner_info.roles[0].permissions.filter(dd => dd.application_registration_id == 1 || dd.application_registration_id == 3);
                
                window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
              }

              setAuthenticated(true);
              setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
              setAccessToken(data.token.access_token);
              setRefreshToken(data.token.refresh_token);
              setTokenType(data.token.token_type);
              setToken(JSON.stringify(data.token));
              setUser(JSON.stringify(data));
              setAppPermission(appAccessPermissions);
              setUniqueRolePermissions(uniqueRolePermissions);

              let role = localStorage.getItem('current_role');
              let grantedMenu = new RoleAccessService;
                if(role != 'Customer Template Role') {
                  history.entries = [];
                  history.index = -1;
                  if (grantedMenu.isShouldShowMenu(role,"dashboard")) {
                    window.history.replaceState({}, document.title, '/dashboard');
                    history.push("/dashboard");
                  } else if (grantedMenu.isShouldShowMenu(role,"jobs")){
                    history.push("/analytics/order-summary");
                  } else if (grantedMenu.isShouldShowMenu(role,"customers")){
                    history.push("/customers");
                  } else if (grantedMenu.isShouldShowMenu(role,"transport_operator")){
                    history.push("/resources/transport-operators");
                  } else if (grantedMenu.isShouldShowMenu(role,"live_tracking")){
                    history.push("/live-tracking/reload");
                  } else if (grantedMenu.isShouldShowMenu(role,"vendor")){
                    history.push("/resources/vendor");
                  } else {
                    history.push("/");
                  }
                } else {
                  window.history.replaceState({}, document.title, '/customer-dashboard');
                  history.push("/customer-dashboard");
                }
              } else {
                setError({ title: error });
                setIsLoading(false);
              }
            
            
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
      });
    }
  };

  const handleResetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedResetEmail(false);
    setErrorResetEmail(null);
    setSentEmail(null)
    if(mailInput === ''){
      setValidatedResetEmail(true);
      setErrorResetEmail('Please enter your email address.');
    } else {
      // let email = resetEmailInput.current.lastChild.firstChild.value
      let param ={
        email : mailInput,
      }
      setIsLoading(true);
      authApi.validEmail('validate/email', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        callForgotPasswordApi(mailInput)
        setIsLoading(false);
      }, (error, type) => {
        // console.log("error",error)
        setErrorResetEmail(error);
        setIsLoading(false);
      });
      });
    }
  }

  const callForgotPasswordApi = email => {
    let param = {
      proper_mail: email,
      email
    }
    setIsLoading(true);
      authApi.validEmail('forgot/password', param)
      .then((result) => {

      apiUtil.parseResult(result, (data) => {
        setSentEmail('Please check your email for a message with your code')
        setIsLoading(false)
        setTimer(true)
        setMailInput(" ");
        // console.log("timer", timer);
      }, (error, type) => {
        setIsLoading(false);
      });
      });
  }

  return (
    <div className="signin fluid signin-container">
       <div className="cover">
      <div className="cover__text">
          <Carousel controls={false}>
          <Carousel.Item>
            <div className="fs-4 text-center text-white">Easy to use driver management system</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Accept jobs from Evfy and allocate to your drivers </div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
          <div className="fs-4 text-center text-white">Eco-friendly vehicle leasing and charging support</div>
            <img
              className="d-block w-100 pb-5"
              src={sliderTemp3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <div className="cover__footer"><Footer /></div> */}
    </div>
    <div className="login" component="main">
      <div className="top-menu">
        <div className="logo"><img src={trackerLogo} alt="" height="50px" /></div>
      </div>
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={1} mb={3} className="position-absolute w-100 alert-style">
          <Alert severity="error">
            { error.title  ? error.title : "Incorrect username or password!"}
            {/* {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })} */}
          </Alert>
        </Box>
      } 
      {
        errorResetEmail && !isLoading
        &&
        <Box textAlign="left" mt={1} mb={3} className="position-absolute w-100 alert-style">
          <Alert severity="error">
            {errorResetEmail}
          </Alert>
        </Box>
      }
      {
        sentEamil && !isLoading
        &&
        <Box textAlign="left" mt={1} mb={3} className="position-absolute w-100 alert-style">
          <Alert severity="success">
            {sentEamil}
          </Alert>
        </Box>
      }
     
      <div className="form-wrapper">
      {showResetPass == true?
        <>
            <form noValidate 
            // onSubmit={resetSubmit}
            >
            <Box  mb={1}>
              <div className="form-title" textAlign="left">
              Reset Password
              </div>
              <div className="form-title-inner" textAlign="left">
              Welcome. Let's set a new password for your account. Don't lose it this time okay!
              </div>
              <div className="form-title-bg">
                <ul>
                  <li>Password must be 8-20 characters long.</li>
                  <li>At least one capital letter, a number & a symbol (optional).</li>
                </ul>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                error={validatedPass} 
                helperText={errorPass}
                ref={pswInput}
              />
            </Box>
            <Button
              style={{borderRadius:"10px"}}
              type="submit"
              // fullWidth
              className="sign-in-btn"
              disabled={isLoading}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>Save Password</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
            </form>
        </>
      :
      <>
      {showForgotPw === false ?
      <form noValidate onSubmit={handleSubmit}>

          <Box textAlign="center" mb={1}>
          <div className="form-title font-italic">
          <em>Welcome Back!</em>
          </div>
          <div className="form-description">
          Log in to Evfy Tracker to access your Delivery Management Dashboard.
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={validatedPass} 
            helperText={errorPass}
            ref={pswInput}
          />
        </Box>
        {/* <Box mb={1}>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Box> */}
        
        <Box mb={2}>
          <a className="resetPass" onClick={() => {
            setShowForgotPw(true)
            setMailInput(emailInput.current.lastChild.firstChild.value)}}>
            <u>Forgot your Password?</u></a>
        </Box> 
        <Box textAlign="center">
        <Button
          style={{borderRadius:"10px"}}
          type="submit"
          // fullWidth
          className="sign-in-btn"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>LOGIN</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
        </Box>
        
        <Box mt={2} textAlign="center">
        <div className="signup-acc"> 
        <div>Don't have an account? Contact us at <a href="mailto:hello@evfy.sg">hello@evfy.sg</a></div>
        </div>
        </Box>
        </form>
       : (
        <form onSubmit={handleResetSubmit}><Box textAlign="center" mb={3}>
        <div style={{fontSize:'32px', fontWeight: 'bold', marginTop: "16px"}}>
        <em>Forgot your Password?</em>
        </div>
        <div className="form-description">
        Enter your email address below and we will guide you on how to reset your password.
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          value={mailInput}
          onChange={(e)=> {
            setMailInput(e.target.value);
          }}
          autoComplete="email"
          autoFocus
          error={validatedResetEmail} 
          helperText={errorResetEmail}
          ref={resetEmailInput}
        />
      </Box>
      <Box textAlign="center">
      <Button
        type="submit"
        style={{borderRadius:"10px"}}
        // fullWidth
        className="sign-in-btn"
        disabled={isLoading}
      >
        <Box pl={2} pr={2} style={{ color: 'white' }}>RESET</Box>
        {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
      </Button>
      </Box>
      
      <Box mt={2} textAlign="center"><a  className="resetPass" onClick={() => setShowForgotPw(false)}><u>Back to Login</u></a></Box> </form>
      )}
      </> 
}
        <div className='footer-position'><Footer /></div>
      </div>
     
    </div>
    </div>
  );
}

export default withRouter(SignIn);