import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import wmsApi from "../../../api/wmsApi.jsx";
import BreadCrumb from '../../../components/Navs/Breadcrumb';
import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core';
import OldTable from '../../../components/Table/OldTable.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Card from '../../../components/Card/Card.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';
import CustomCalendar from '../../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../../components/CustomFields/CustomUploadButton.jsx';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import {RoleAccessService} from '../../../data/role-access'; 
import CustomPieChart from '../../../components/Charts/PieCharts';
import CustomLineChart from '../../../components/Charts/LineCharts';
import CustomVerticleBarChart from '../../../components/Charts/VerticleBarCharts';
import CustomBarChart from '../../../components/Charts/BarCharts';
import CustomGoogleMap from '../../../components/GoogleMap/CustomGoogleMapWithMarkers';

import Scales from '../../../assets/img/analytic_icons/Scales.svg'
import NaturalFood from '../../../assets/img/analytic_icons/NaturalFood.svg'
import Checkmark from '../../../assets/img/analytic_icons/Checkmark.svg'
import FullVersion from '../../../assets/img/analytic_icons/FullVersion.svg'
import Fragile from '../../../assets/img/analytic_icons/Fragile.svg'
import EditDeliveryTerms from '../../../assets/img/analytic_icons/EditDeliveryTerms.svg'
import Estimate from '../../../assets/img/analytic_icons/Estimate.svg'
import NormalPieChart from '../../../components/Charts/NormalPieChart';
import BoxImportant from '../../../assets/img/analytic_icons/BoxImportant.svg'
import CustomSwitchClient from '../../../components/CustomFields/CustomSwitchClient.jsx';
const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;


class ClientOverview extends Component {
  static contextType = AuthContext;
  pages = 1;
  limits = 10;

  constructor(props){
    super(props);
   
    this.state = {
      jobTab: 1,
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().subtract(6, 'days'),
        endDate: moment(),
      },
      startDate:moment().subtract(6, 'days'),
      endDate: moment(),
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,
      status_total: 0,
      isLoading: false,
      isLoadingOrder:false,
      isLoadingSize:false,
      isLoadingPayout:false,
      isLoadingClient: false,
      isLoadingMap: false,
      data: [],
      company_logo: '',
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      total_delivery: '',
      stats : [],

      delivery_perday: [],
      order_by_size: [],
      recent_activity : [],
      client_driver_billing: [],
      order_by_client : [],
      delivery_collection_map : [],
      order_data: [],
      overview_data: [],
      driver_entity: [],
      factors: [],
      is_on: false,
      failed_status_id: null,
      co2_ev_saving: 0,
      co2EvLoading: false,
      grennerLoading: false,
      greener_count: 0
    }
  }
  

  componentDidMount() {
    this.callInitApi();
  }

  callInitApi = () => {
    this.overviewDataApi();
    this.callFactorApi();
    this.reasonApi();
    this.getGrennerChoice();
    this.loadStatusApi(()=> {
      this.callReadApi();
    });
    this.getCo2Ev();    
  }

// API START =================
  getGrennerChoice = () => {
    const { accessToken } = this.context;
    
    this.setState({
      grennerLoading: true,
    }, () => {    
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
      
      ldsApi.read('/analytic/greener_choice/', param, accessToken)
      .then((result) => {
        // recent_activity
        apiUtil.parseResult(result, (data) => {
          this.setState({
            greener_count: data.result,
            grennerLoading: false,
          });
        }, (error, type) => {
          this.setState({
            driver_entity: [],
            grennerLoading: false,
          });
        });
  
      });
      
    });    
  }
  
  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        let AckTep = {}
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            AckTep = item
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 0,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            this.setState({
              failed_status_id: item.status_details.id,
            })
            return status.splice(7, 0, {
              order: 1,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } 
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        // status.unshift({
        //   id: 0,
        //   status: 'Total Orders',
        //   total: total,
        // });
  
        status.forEach(st=>{
          if(st.status == "Assigned") {
            st.total = st.total + AckTep.total_job
          }
        })
        
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
          isLoadingStatus: false,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }
  
  getCo2Ev = () => {
    const { accessToken } = this.context;
    
    this.setState({
      co2EvLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };
  
        ldsApi.read('/analytic/co2_ev_saving/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              co2_ev_saving: data.result.co2e_saving ? data.result.co2e_saving : 0,
              co2EvLoading: false,
            });
          }, (error, type) => {
            this.setState({
              co2_ev_saving: 0,
              co2EvLoading: false,
            });
          });
  
      });
    });     
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: this.state.customer_id,
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                  if(callback){
                    callback(result, total);
                  }
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }

  reasonApi = () => {
    const { accessToken } = this.context;
    let id = 0
    if(this.state.user.owner_info.customer_id != null && this.state.user.owner_info.customer_id.id != '') {
      id = this.state.user.owner_info.customer_id
    }
    this.setState({
      isLoadingReason: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        customer_id: id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
      };

        ldsApi.read('/analytic/reasons/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            let dataArr = (data.result && data.result.length > 0) ? data.result : []
            let other_value = 0;
            dataArr.map((dd, index)=> {
              if (index > 6) {
                other_value = other_value + dd.value
              }
            })
            dataArr = dataArr.slice(0,5)
            dataArr.push({
              name: 'others',
              value: other_value,
            })
            this.setState({
              order_reasons: dataArr,
              reason_total: data.total,
              isLoadingReason: false,
            });
          }, (error, type) => {
            this.setState({
              order_reasons: [],
              reason_total: [],
              isLoadingReason: false,
            });
          });

      });
    });
  }

  callReadApi = (search = '', do_num = '', uploader = '', callback=null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        order_status: this.state.failed_status_id,
        start_date: startDate,
        customer_id: this.state.customer_id,
        end_date: endDate,
        take: this.limits,
        page: this.pages,
        sort: "latest"
      };

      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              order_data: (data.result && data.result.length > 0) ? data.result : [],
              isLoading: false,
            });
            if(callback){
              callback(data);
            }
          }, (error, type) => {
            if(callback) {
              callback([]);
            }
            this.setState({
              order_data: [],
              total: 0,
              uploaders: [],
              isLoading: false,
            });
          });
      });
    });
  }

  overviewDataApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingClient: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let id = 0
      if(this.state.user.owner_info.customer_id != null && this.state.user.owner_info.customer_id.id != '') {
        id = this.state.user.owner_info.customer_id
      }

      let param = {
        customer_id: id,
        take: this.state.limit,
        start_date: startDate,
        end_date: endDate,
        overview_type: 'customer'
      };

        ldsApi.read('/analytic/overview/', param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              overview_data: data.result,
              isLoadingClient: false,
            });
          }, (error, type) => {
            this.setState({
              overview_data: [],
              isLoadingClient: false,
            });
          });

      });
    });
  }

  callFactorApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingFactor: true
    }, () => {
      let id = 0
      if(this.state.user.owner_info.customer_id != null && this.state.user.owner_info.customer_id.id != '') {
        id = this.state.user.owner_info.customer_id ? this.state.user.owner_info.customer_id : this.state.user?.owner_info?.customers?.[0]?.id
      } else {
        id = this.state.user?.owner_info?.customers?.[0]?.id;
      }

        wmsApi.details('/factors', id, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            const order = { S: 1, E: 2, C: 3, D: 4 };

            const sortedArray = data.result.sort((a, b) => {
              const firstCharA = a.title.charAt(0).toUpperCase();
              const firstCharB = b.title.charAt(0).toUpperCase();

              return order[firstCharA] - order[firstCharB];
            });

            this.setState({
              factors: sortedArray,
              isLoadingFactor: false,
            });
          }, (error, type) => {
            this.setState({
              factors: [],
              isLoadingFactor: false,
            });
          });

      });
    });
  }

  updateFactorApi = (id) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoadingFactor: true
    }, () => {

      let param = {
        is_on: this.state.is_on,
      };

        wmsApi.update('factors/'+ id, param, accessToken)
        .then((result) => {
          // recent_activity
          apiUtil.parseResult(result, (data) => {
            this.setState({
              // factors: data.result,
              isLoadingFactor: false,
            });
          }, (error, type) => {
            this.setState({
              // factors: [],
              isLoadingFactor: false,
            });
          });

      });
    });
  }
// API END ==================

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }} style={{pointerEvents: this.state.isLoading || this.state.isLoadingOrder || this.state.isLoadingClient || this.state.isLoadingPayout || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  overviewHeader = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.complete_delivery ? this.state.overview_data.complete_delivery : 0) + " / " + (this.state.overview_data.all_delivery ? this.state.overview_data.all_delivery : 0)}</div>
        <div className="fs-6 ps-2 font-medium">Deliveries Completed</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.on_time_deliveries ? parseFloat(this.state.overview_data.on_time_deliveries).toFixed(2) : '0.00')+'%'} </div>
        <div className="fs-6 ps-2 font-medium">On-Time Deliveries</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.co2EvLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.co2_ev_saving && this.state.co2_ev_saving != 0? parseFloat(this.state.co2_ev_saving).toFixed(2) : '0.00') + 'kg'}</div>
        <div className="fs-6 ps-2 font-medium">CO2e Savings</div>
      </Card>
    </Col>
    <Col xs={12} md={3} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 analytic-overview-card bg-blue-card  w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="fs-4 font-medium ps-2 pb-2 primary-color">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  "$"+ (this.state.overview_data.profit ? parseFloat(this.state.overview_data.profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00')} </div>
        <div className="fs-6 ps-2 font-medium">Delivery Charges</div>
      </Card>
    </Col>
  </Row>
  }

  overviewTopCard = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap: '1.5em'}}>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2 secondary-color">My Green Score  <img src={NaturalFood} style={{width:'40px', height:'40px'}}/></div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : apiUtil.calculateGreenScore(this.state.overview_data, this.state.factors)}</div>
        {/* <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={NaturalFood} style={{width:'40px', height:'40px'}}/></div> */}
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px  w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">% of Successful Jobs</div>
        <div className="fs-1 font-bold ps-2 pb-2 mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  (this.state.overview_data.job_status_precent ? parseFloat(this.state.overview_data.job_status_precent).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')} </div>
        {/* <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Estimate} style={{width:'40px', height:'40px'}}/></div> */}
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">% of EV Deliveries</div>
        <div className="fs-1 font-bold ps-2 pb-2  mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  (this.state.overview_data.ev_delivery_precent ? parseFloat(this.state.overview_data.ev_delivery_precent).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')}</div>
      </Card>
    </Col>
  </Row>
  }

  overviewTopLeftCard = () => {
    return <Col xs={12} md={12} lg={4} className="d-flex justify-content-center p-0 m-0"><Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-400px w-100 d-flex flex-column justify-content-start align-items-start">
        <div className="fs-3 font-bold pb-2 text-center">Other Factors</div>
        {this.state.factors && this.state.factors.map((dd, index) => {
         return <div className="ps-2 pb-2 mt-4 d-flex w-100 justify-content-between" style={{borderBottom: '1px solid #B9BABB'}} key={index}>
          <div className="fs-5 font-medium">{dd.title}</div> 
            <div className="form">
              <div>
                <input type="checkbox" id={`check-${dd.id}`} checked={dd.is_on} onChange={(e)=> {
                   const updatedFactors = this.state.factors.map((factor) => {
                    if (factor.id === dd.id) {
                      return { ...factor, is_on: !factor.is_on };
                    }
                    return factor;
                  });
              
                  this.setState({ factors: updatedFactors, is_on : !dd.is_on }, () => {
                    this.updateFactorApi(dd.id);
                  });
                }} />
                <label for={`check-${dd.id}`}></label>
              </div>
            </div>
          </div>

        })}
        
      </Card>
      </Col> 

  }


  overviewTopRigthCard = () => {
    return <Col xs={12} md={12} lg={8} className="p-0 m-0 d-flex justify-content-center">
      <div className='w-100'><Row className="p-0 m-0 overflow-hidden" style={{rowGap:'2.5em'}}>
    <Col xs={12} md={12} lg={6} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2 ">% of Scheduled Jobs</div>
        <div className="fs-1 font-bold ps-2 pb-2 mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  (this.state.overview_data.scheduled_job_percent ? parseFloat(this.state.overview_data.scheduled_job_percent).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')}</div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={6} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px  w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">% of Non-Time Specific Jobs</div>
        <div className="fs-1 font-bold ps-2 pb-2 mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> :  (this.state.overview_data.non_time_job_percent ? parseFloat(this.state.overview_data.non_time_job_percent).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')}</div>
        {/* <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Estimate} style={{width:'40px', height:'40px'}}/></div> */}
      </Card>
    </Col>
    <Col xs={12} md={12} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card bg-secondary-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-6 font-medium" style={{overflow: 'auto'}}>
          <div style={{textAlign: 'justify', paddingBottom:'9px'}}>The SG transportation and logistics industry presently accounts for 14.8% of the total carbon emissions. While <strong>transitioning to EVs has the potential to cut emissions by 30%</strong>, we need to take comprehensive approach to sustainability beyond making the switch.</div>
          <div style={{textAlign: 'justify',}}>Evfy’s approach goes beyond EVs - the green score criteria play a significant role in gauging our combined efforts, encompassing aspects like <strong>successful delivery</strong> of goods and <strong>operational efficiency</strong> in consolidating orders at a <strong>central warehouse</strong>. Join us in making a positive environmental impact.</div>
        </div>
      </Card>
    </Col>
  </Row></div>
  </Col>
  }

  overviewBottomCard = () => {
    return <Row className="px-4 pt-4 overflow-hidden" style={{rowGap:'1.5em'}}>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Greener Choice </div>
        <div className="fs-1 font-bold ps-2 pb-2 secondary-color d-flex h-100 justify-content-center align-items-center">{this.state.grennerLoading? <CircularProgress size={35} className="spinner-card" /> : (this.state.greener_count > 0 ? this.state.greener_count : 0)}</div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Checkmark} style={{width:'35px', height:'35px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px  w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Avg. Cost Per Job</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.average_job_value ? "$" + parseFloat(this.state.overview_data.average_job_value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$0.00')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Estimate} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
    <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
      <Card className="mx-3 position-relative analytic-overview-card h-180px w-100 d-flex flex-column justify-content-start align-items-center">
        <div className="fs-5 ps-2 font-medium pb-2">Delivery Damage Rate</div>
        <div className="fs-1 font-bold ps-2 pb-2 primary-color  mt-4">{this.state.isLoadingClient? <CircularProgress size={35} className="spinner-card" /> : (this.state.overview_data.delivery_damage_rate ? parseFloat(this.state.overview_data.delivery_damage_rate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%" : '0.00%')} </div>
        <div style={{position: "absolute", bottom: '7px', right:'7px'}}><img src={Fragile} style={{width:'40px', height:'40px'}}/></div>
      </Card>
    </Col>
  </Row>
  }

  reasonPie = () => {
    return <Card className="mx-3 analytic-chart-card d-flex flex-column h-350px w-100 align-items-center" style={{overflowY: 'auto'}}>
            <div className="fs-5 font-medium ps-2 pb-2">Reasons for Failure</div>
            {this.state.isLoadingReason? 
              <CircularProgress size={35} className="spinner-card" /> :
              <div className="h-400px w-100">
              <CustomPieChart 
                data={this.state.order_reasons} 
                dataKey={'value'} 
                key={'value'} 
                value1={'name'} 
                value2={'value'} 
                vertical_align={"center"} 
                align={"bottom"} 
                status_total={this.state.reason_total} 
                reason={true}
              /></div>
            }
            </Card>
  }

  customTable = () => {
    return <OldTable 
      ref={this.refTable}
      className={'job-table-reason w-100 reason_for_failure'}
      isLoading={this.state.isLoading}
      paging={false}
      limit={this.limits}
      page={this.pages}
      onPageChange={(page,limit) => {
        this.pages  = page;
        this.limits =  limit;  
        this.callReadApi();   
    }}
      data={this.state.order_data}
      Toolbar={this.customToolbar}
      maxBodyHeight={270}
      Row={this.customRow}
      columns={[
        { 
          title: "Do Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row) => {
            return (row && row.do_number && row.do_number !== '') ? <span style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
          }
        },
        { 
          title: "Delivery Address", 
          field: "drop_off_address",
          width: "40%",
          render: (row) => {
            return row.drop_off_address ? row.drop_off_address : null;
          }
        },
        { 
          title: "Reason", 
          field: "reasons",
          customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 && a.job_steps[0].order_attempts[a.job_steps[0].order_attempts.length - 1].reason ? a.job_steps[0].order_attempts[a.job_steps[0].order_attempts.length - 1].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 && b.job_steps[0].order_attempts[b.job_steps[0].order_attempts.length - 1].reason ? b.job_steps[0].order_attempts[b.job_steps[0].order_attempts.length - 1].reason: '').toString().toLowerCase().trim()),
          render: (row, type) => {
            if(type === 'row'){
              let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
              if(jobStep) {
              let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
              return failed_reason;
              } 
            } else {
              return row;
            }
          }
        },
      ]}
    />
  }

  render() {
    return <Container fluid>
        <Row className="px-4 pt-4 overflow-hidden">
        <Col xs={12} md={12} lg={6} className="p-0 m-0 d-flex align-items-center">
          <div className="fs-4 font-medium mx-3">Analytics for {moment(this.state.startDate).format(apiUtil.getImportDateFormat())} to {moment(this.state.endDate).format(apiUtil.getImportDateFormat())}</div>
        </Col>
        <Col xs={12} md={12} lg={6} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center max-xs-width-control" style={{pointerEvents: this.state.isLoading || this.state.isLoadingClient || this.state.isLoadingStatus || this.state.isLoadingPayout ? 'none': 'auto' }}>
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApi();
                });
              }}
            />
        </Col>
      </Row>
      {this.overviewHeader()}
      {this.overviewTopCard()}
      <Row className="px-4 pt-4" style={{rowGap:'1.5em'}}>
            {this.overviewTopLeftCard()}
            {this.overviewTopRigthCard()}
      </Row>

        {this.overviewBottomCard()}
      <Row className="px-4 py-4" style={{rowGap:'1.5em'}}>
        <Col xs={12} md={12} lg={4} className="p-0 m-0 d-flex justify-content-center">
            {this.reasonPie()}
        </Col>
        <Col xs={12} md={12} lg={8} className="p-0 m-0 d-flex justify-content-center">
          <Card className={'mx-3 recent-activities analytic-chart-card h-350px w-100'}>
            <div className='fs-5 pb-2 font-medium mb-2'>Reasons for failure</div>
            {this.customTable()}
          </Card>
        </Col>

       
      
      </Row>

    </Container>;
  }
}

export default ClientOverview;