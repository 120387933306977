
import React, { useEffect, useState } from 'react';
import SquareBox from './SquareBox';

function MatrixGrid(props) {
  const color_data = [
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null, null, null, null],
        ];
  const rows = 10;
  const cols = 10;
  const {data, total} = props
  const [hoveredIndex, setHoveredIndex] = useState(null);
  // Calculate the percentage for each "total" value and round to integers
  const roundedPercentages = data.map(obj => Math.round((obj.total / total) * 100));

  // Calculate the difference between the sum of rounded percentages and 100
  const diff = 100 - roundedPercentages.reduce((sum, value) => sum + value, 0);

  // Adjust the last rounded percentage to make the sum exactly 100
  roundedPercentages[roundedPercentages.length - 1] += diff;
  
  // Define color codes based on rounded percentages
  const colors = ["FF2386", "F9CD4D", "FF8906", "1ACE85", "F14254", "696A79"];
  const renderMatrix = () => {
    // Calculate the percentage for each "total" value and round to integers
    const roundedPercentages = data.map(obj => Math.round((obj.total / total) * 100));
   
    // Calculate the total sum of roundedPercentages
    const totalSum = roundedPercentages.reduce((sum, value) => sum + value, 0);

    // Calculate the color positions based on roundedPercentages
    const colorPositions = [];
    let currentPosition = 0;

    for (let i = 0; i < roundedPercentages.length; i++) {
      const percentage = roundedPercentages[i];
      const count = Math.round((percentage / 100) * color_data.length * color_data[0].length);
      for (let j = 0; j < count; j++) {
          colorPositions.push(currentPosition);
      }
      currentPosition = (currentPosition + 1) % colors.length;
    }

    // Assign colors to the data array based on color positions
    for (let i = 0; i < color_data.length; i++) {
    for (let j = 0; j < color_data[i].length; j++) {
        const colorIndex = colorPositions[i * color_data[i].length + j];
        color_data[i][j] = colors[colorIndex];
    }
    }
    console.log('roundedPercentages',roundedPercentages);
    const matrix = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < cols; j++) {
        const index = i * cols + j;
        const percentage = roundedPercentages[colorPositions[index]] ? roundedPercentages[colorPositions[index]] : 0;

        row.push( <div
            key={`${i}-${j}`}
            style={{ position: 'relative', display:"inline-block"}}
            onMouseEnter={() => setHoveredIndex(i * cols + j)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {(props.showLable && ((color_data[i][j] != color_data[i][j + 1] && typeof(color_data[i][j + 1]) != 'undefined') || (color_data.length == (i + 1) && color_data[i].length == (j + 1)))) && <p style={{position: 'absolute',right: '1px',top: '5px',fontSize: '12px',fontWeight: 'bold'}}>{`${percentage}%`}</p>}
            <SquareBox color={`${color_data[i][j]}`} />
            {hoveredIndex === i * cols + j && (
              <div
                style={{
                  position: 'absolute',
                  top: '-20px',
                  left: '60%',
                  transform: 'translate(-50%, -50%)',
                  color: '#000',
                  backgroundColor: '#fff',
                  padding: '2px 15px',
                  borderRadius: '8px',
                  fontWeight:'800',
                  boxShadow: 'rgba(32, 32, 32, 0.1) 0px 1px 2px 0px'
                }}
              >
                {`${percentage}%`}
              </div>
            )}
          </div>);
      }
      matrix.push(<div key={i} className="matrix-row">{row}</div>);
    }
    return matrix;
  };

  return <div className='d-flex justify-content-evenly align-items-center mt-2'>
        <div className="matrix-grid">{renderMatrix()}</div>
        <div>
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="FF2386" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Pending</div>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="F9CD4D"/>
                <div  className="pb-1 ps-3 fs-6 fw-medium">Assigned</div>
            </div>
            {/*<div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="F9CD4D" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Assigned To Vendor</div>
            </div>*/}
            {/*<div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="a9629e" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Acknowledged</div>
            </div>*/}
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="FF8906" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">In Progress</div>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="1ACE85" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Completed</div>
            </div>
            {/*<div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="BB7BFD" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">On Hold</div>
            </div>*/}
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="F14254" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Failed</div>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="696A79" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Cancelled</div>
            </div>
            {/*<div className='d-flex justify-content-start align-items-center'>
                <SquareBox color="000000" />
                <div  className="pb-1 ps-3 fs-6 fw-medium">Self-collect</div>
            </div>*/}
        </div>
  </div>;
}

export default MatrixGrid;
