import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import BreadCrumb from '../../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faDownload } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as TimeIcon } from '../../../assets/img/icons/time.svg';
import { ReactComponent as ImportIcon } from '../../../assets/img/icons/import.svg';
import {Verdana} from '../../../components/Fonts/Verdana'
import { Anmollipi } from '../../../components/Fonts/Anmollipi.jsx';
import { InterLight } from '../../../components/Fonts/InterLight.jsx';
import { InterBold } from '../../../components/Fonts/InterBold.jsx';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';
import GroupButton from '../../../components/GroupButton/GroupButton.jsx';
import CircleIcon from '../../../components/CircleIcon/CircleIcon.jsx';
import StatusList from '../../../components/Status/StatusList.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import CustomCalendar from '../../../components/Calendar/CustomCalendar.jsx';
import CustomUploadButton from '../../../components/CustomFields/CustomUploadButton.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import JobImportDialog from '../../Jobs/Partial/JobImportDialog';
import QRCode  from "qrcode.react";
import EVFYLogo from '../../../assets/img/evfy-logo2.png';
import location from '../../../assets/img/Vector.png';
import building from '../../../assets/img/building.png';
import box from '../../../assets/img/box.png';
import comment from '../../../assets/img/comment.png';
import person from '../../../assets/img/person.png';
import time from '../../../assets/img/access_time.png';
import phone from '../../../assets/img/local_phone.png';
import bottomTxt from '../../../assets/img/bottom-txt.png';
import EVFYLogoForLabel from '../../../assets/img/EVFY_Logo.png';
import ReactDOM from 'react-dom';
import Barcode from 'react-barcode';
import jsPDF from "jspdf";

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

class CustomerOrderSchedule extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      jobTab: 1,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      view: 'month',
      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: false,
      status: [],
      statusSelected: null,
      order_status: null,

      isLoading: false,
      data: [],
      company_logo: '',
      orders_logo: [],
      openImportDialog: false,
      openImportDialogItem: null,
      openImportDialogIsLoading: false,
      order_data: []
    }
  }
  
  componentDidMount() {
    this.callInitApis();
    //this.callOrderReadApi();
  }

  callInitApis = () => {
    this.loadStatusApi();
    this.callReadApi();
  }
  loadStatusApi = () => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let status = [];
        let ackTemp = {}
        let AssignToVendorTemp = {}
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 8,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            return status.splice(3, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            // return status.splice(4, 0, {
            //   order: 3,
            //   id: item.status_details.id,
            //   status: item.status_details.status,
            //   text: item.status_details.status,
            //   total: item.total_job,
            // });
            ackTemp = item
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 7,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 10,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
            // return status.splice(10, 0, {
            //   order: 2,
            //   id: item.status_details.id,
            //   status: item.status_details.status,
            //   text: item.status_details.status,
            //   total: item.total_job,
            // });
            AssignToVendorTemp = item
          }
        });

        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
  
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
        });

        // status.forEach(st=>{
        //   if(st.status == "In Progress") {
        //     st.total = st.total + ackTemp.total_job
        //   }
        // })
        // 
        // if( Object.keys(AssignToVendorTemp).length !== 0 ) {
        //   status.forEach(st=>{
        //     if(st.status == "Assigned") {
        //       st.total = st.total + AssignToVendorTemp.total_job
        //     }
        //   })
        // }

        this.setState({
          status: status,
          status_total: total,
        });
      }
    });
  }

  callOrderReadApi = () => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              order_data: (data.result && data.result.length > 0) ? data.result : [],
              company_logo : data.company_logo_base_64? data.company_logo_base_64 : '',
              orders_logo : data.orders_image_base64.length > 0? data.orders_image_base64 : [],
              total: data.total,
              isLoading: false,
            });

            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  callReadApi = (isLoading = true, search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: isLoading
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search ? search.trim() : search,
        start_date: startDate,
        end_date: endDate,
        order_status: this.state.order_status,
        light_data: true,
      };

      ldsApi.read('orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if (data.result && data.result.length > 0) {
              
              // let groupedResults = _.groupBy(data.result, (r) => moment(r.drop_off_time_planned).format(apiUtil.getDefaultDateFormat()));
              let groupedResults = _.groupBy(data.result, (r) => moment(r.drop_off_date).format(apiUtil.getDefaultDateFormat()));
              
              if(groupedResults){
                let groupedData = Object.keys(groupedResults).map(key => groupedResults[key]);
                
                let result = [];
                if(groupedData && groupedData.length > 0){
                  for(let i = 0; i < groupedData.length; i++){
                    let array = groupedData[i];

                    // let dateTime = (groupedData[i][0].drop_off_time_planned && groupedData[i][0].drop_off_time_planned !== '') ? groupedData[i][0].drop_off_time_planned : groupedData[i][0].drop_off_date;
                    let dateTime = (groupedData[i][0].drop_off_date && groupedData[i][0].drop_off_date !== '') ? groupedData[i][0].drop_off_date : groupedData[i][0].drop_off_date;
                    // console.log('date', groupedData[i][0].drop_off_date, moment(dateTime).toDate())
                    result.push({
                      id: i,
                      start: moment(dateTime).toDate(),
                      end: moment(dateTime).toDate(),
                      title: <div className={'second'}>{(array && array.length > 0) ? array.length : 0} Orders</div>,
                    });
                  }

                  this.setState({
                    data: (result && result.length > 0) ? result : [],
                    view: this.state.view,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    data: [],
                    isLoading: false,
                  });
                }
              } else {
                this.setState({
                  data: [],
                  isLoading: false,
                });
              }
            } else {
              this.setState({
                data: [],
                isLoading: false,
              });
            }

            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              isLoading: false,
            });
          });
      });
    });
  }
  /* END API */


  /* IMPORT API */
  callImportApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('create/orders/multiple', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END IMPORT API */

  /* PDF GENERATE */ 

  generatePDF = () => {
    const { accessToken } = this.context;
    let selectedRow = this.rowData;
    let ids = '';
    if(selectedRow != null && selectedRow != '') {
      selectedRow.map((item, i) => { ids += item.id +','; });
    } 
    ids = ids.slice(0, -1);
    
    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + 'multiple_generate_label.pdf?ids=' + ids,'_blank');    
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
    }
  } 
  
  generatePOD = ( ) => {
    let ids = '';
    this.state.order_data.map((item, i) => { ids += item.id +','; });
    ids = ids.slice(0, -1);
    ids = ids.slice(0, -1);

    if(ids != "") {
      window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    } else {
      apiUtil.toast("Please select data for generation", "check_circle")
      // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?start_date=' + moment(this.state.startDate).format("YYYY-MM-DD") + "&end_date=" + moment(this.state.endDate).format("YYYY-MM-DD") + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
    }
    // window.open(REACT_APP_LDS_API_URL + '/multiple_generate_pod.pdf?ids=' + ids + '&is_customer=true' + '&application_company_id=' + this.state.user.owner_info.application_company_id,'_blank');
  }


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={{ xs: 0, md: 1 }} pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={'auto'}>
            <CustomDateRangePicker
              range={this.state.rangeDate}
              onChange={(range) => {
                this.setState({
                  view: 'month',
                  rangeDate: range,
                }, () => {
                  this.callInitApis();
                });
              }}
            />
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomUploadButton 
              color={'primary'}
              accept={'.xls, .xlsx'}
              onChange={(files) => {
                if(files && files.length > 0){
                  this.setState({
                    openImportDialog: true,
                    openImportDialogItem: files[0],
                  });
                }
              }}
            >
              {/* <ImportIcon /> */}
              Import Jobs
            </CustomUploadButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/jobs-form');
              }}
            >
              + New Job
            </CustomButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.jobTab}
              buttons={[ "Job List", "Job Schedule" ]}
              onClick={(selected, btn) => {
                if(selected === 0){
                  this.props.history.push('/job-list');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'} className="py-4">
      <Box clone>
        <Grid item xs={12} lg={true} className="d-flex justify-content-center align-self-center">
          {this.state.isLoadingStatus? 
          <CircularProgress size={35} /> :
          <Box className={'custom-status-list-wrapper'}>
            <StatusList
              selected={(this.state.statusSelected > 0) ? this.state.statusSelected : 0}
              items={this.state.status.map((item, i) => {
                return <StatusItem
                text={<div style={{ width: 90, height: 90 }}>
                   <CircularProgressbar value={this.state.status_total == 0? 1 : item.total/100} maxValue={this.state.status_total == 0? 1 : this.state.status_total/100} strokeWidth={14} text={`${item.total}`}  
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    // rotation: 0.25,
                
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',

                    // Text size
                    textSize: '24px',
                
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                
                    // Colors
                    pathColor: `${
                      item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' :
                      item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' :  
                      item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                      item.status.replaceAll(' ', '-').trim().toLowerCase() == 'assigned-to-vendor'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                    textColor:  `${
                      item.status.replace(' ', '-').trim().toLowerCase() == 'not-assigned' ? '#75b8c8 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'info-received' ? '#0094ff ' :
                      item.status.replace(' ', '-').trim().toLowerCase() == 'on-hold' ? '#BB7BFD ' :  
                      item.status.replace(' ', '-').trim().toLowerCase() == 'pending' ? '#FF2386 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'total-orders' ? '#007CDE ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assign-pending'?'#777777 ' : 
                      item.status.replaceAll(' ', '-').trim().toLowerCase() == 'assigned-to-vendor'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'assigned'? '#F9CD4D ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'acknowledged'?'#a9629e ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'in-progress'?'#FF8906 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'completed' ? '#1ACE85 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'self-collect'?'#000000 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'cancelled'?'#696A79 ' : 
                      item.status.replace(' ', '-').trim().toLowerCase() == 'failed'?' #F14254 ' : ' #000000'}`,
                    trailColor: '#c3c3c3',
                    backgroundColor: '#AE0264',
                  })}/>
                  {/* <FontAwesomeIcon icon={faCircle} component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}/> */}
                  {/* <Icon component={'i'} className={'custom-dot status ' + (item.status.replace(' ', '-').trim().toLowerCase())}>lens</Icon> */}
                  {/* <span>{item.status}</span> */}
                </div>}
                count={<span className={
                  'circle-progress-label ' + item.status.replaceAll(' ', '-').trim().toLowerCase()}>{item.status}</span>}
              />
              })}
              onClick={(index) => {
                let defaultGroupOrder = -1;
                if(index > 1){
                  defaultGroupOrder = 0;
                }

                this.setState({
                  statusSelected: index,
                  order_status: (index && index > 0) ? this.state.status[index].id : null,
                  current_status_name: (index && index > 0) ? this.state.status[index].status : null,
                  defaultGroupOrder: defaultGroupOrder,
                  total_current_status_job: (index && index > 0) ? this.state.status[index].total : 0,
                }, () => {
                  this.callInitApis();
                  this.callOrderReadApi();
                });
              }}
            />
          </Box>
          }
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* CALENDAR */
  customCalendar = () => {
    if(!this.state.isLoading){
      return <CustomCalendar
        events={this.state.data}
        view={this.state.view}
        isLoading = {this.state.isLoadingStatus}
        date={(this.state.rangeDate && this.state.rangeDate.startDate) ? new Date(this.state.rangeDate.startDate) : null}
        onClick={(item) => {
          if(item && item.start){
            let date = moment(item.start).format(apiUtil.getDefaultDateFormat());
            
            this.props.history.push({
              pathname: '/order-summary-list',
              state: {
                date: date,
                order_status: this.state.order_status,
                index: this.state.statusSelected
              }
            });
          }
        }}
        onNavigate={(date, type) => {
          let range = {
            startDate: moment(date).startOf(type),
            endDate: moment(date).endOf(type),
          };

          this.setState({
            view: type,
            rangeDate: range,
          }, () => {
            this.loadStatusApi();
            this.callOrderReadApi();
            this.callReadApi(false);
          });
        }}
        onView={(date, type) => {
          //console.log(date, type);
          let range = {
            startDate: moment(date).startOf(type),
            endDate: moment(date).endOf(type),
          };
          
          this.setState({
            view: type,
            rangeDate: range,
          }, () => {
            this.loadStatusApi();
            this.callOrderReadApi();
            this.callReadApi(false);
          });
        }}
      />
    } else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }
  }
  /* END CALENDAR */


  /* DIALOG */
  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Orders'}
      maxWidth={'xl'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
        });
      }}
    >
      <JobImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
          });
        }}
        onImport={(form) => {
          this.setState({
            openImportDialogIsLoading: true
          }, () => {
            this.callImportApi(form, (data) => {
              let all_jobs = (form && form.length > 0) ? form.length : 0;
              let failed_orders = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
              let invalid_company_code_orders = (data && data.invalid_company_code_orders && data.invalid_company_code_orders.length > 0) ? data.invalid_company_code_orders.length : 0;
              let saved_orders = (data && data.saved_orders && data.saved_orders.length > 0) ? data.saved_orders.length : 0;
              
              if(failed_orders > 0){
                apiUtil.toast(failed_orders + ' of ' + all_jobs + ' Orders have not successfully imported', 'check_circle', 'error');
              }
              if(invalid_company_code_orders > 0){
                apiUtil.toast(invalid_company_code_orders + ' of ' + all_jobs + ' company code does not match, Orders have not successfully imported', 'check_circle', 'error');
              }

              if(saved_orders > 0){
                apiUtil.toast(saved_orders + ' of ' + all_jobs + ' Orders successfully imported', 'check_circle', 'success');
              }
  
              this.callReadApi(false);
  
              this.setState({
                openImportDialog: false,
                openImportDialogItem: null,
                openImportDialogIsLoading: false,
              });
            });
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */

  /* EXPORT API */
  callExportXLSApi = () => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      
      let selectedRow = this.rowData;
      let ids = '';
      if(selectedRow != null && selectedRow != '') {
        selectedRow.map((item, i) => { ids += item.id +','; });
      } 

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_ids: ids,
        do_number: this.do_number,
        uploader: this.uploder_id,
        search: this.search,
        order_status_ids: this.state.order_status,
      };

      ldsApi.exportXLS('admin/api/orders/delivery/export', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Jobs ${moment(startDate).format('DD-MM-YYYY')} - ${moment(endDate).format('DD-MM-YYYY')}.xlsx`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */

  render() {
    return <Container fluid>
         <Row className="p-4">
        <Col xs={12} md={12} className="d-flex justify-content-end align-items-center">
        <CustomDateRangePicker
              id={'select-dates-step-cus w-50'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  view: 'month',
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callInitApis();
                  this.callOrderReadApi();
                });
              }}
            />
             <Button className="upload-style"
                disabled={this.state.isLoading}
                onClick={() => {
                  this.setState({
                  downloadMenu:true
                });}}
        ><FontAwesomeIcon icon={faDownload} />
        </Button>
          <Menu
            id="download-menu-job"
            anchorEl={this.state.downloadMenu}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(this.state.downloadMenu)}
            onClose={() => {
                this.setState({
                  downloadMenu: null
                });
            }}
          >
            <MenuItem onClick={() => {this.callExportXLSApi(); }} > Export CSV </MenuItem>
            <MenuItem 
            // onClick={() => { this.generatePDF()}} 
            disabled> Generate Label </MenuItem>
            <MenuItem  
            // onClick={() => { this.generatePOD() }} 
            disabled> Generate POD </MenuItem>
          </Menu>
        </Col>
      </Row>

      <Card className={'pb-0'}>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customCalendar()}
      </Card>

      {this.importDialog()}
      <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>
    </Container>;
  }
}
export default CustomerOrderSchedule;
